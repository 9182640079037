import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "Provision Ubuntu Desktop with Java Development Environment done using Vagrant on Windows 10",
  "description": "A detailed step by step walkthrough to provision Ubuntu Desktop with Java Development Environment done using Vagrant",
  "date": "2020-02-05T23:46:37.121Z",
  "updated": "2020-02-05T23:46:37.121Z",
  "cover": "../../../images/blog-banners/setup-banner-1200x690.png",
  "category": "setup",
  "tags": ["Vagrant", "Setup"],
  "keywords": ["Vagrant", "Java Development environment", "java", "dev envrionment", "vagrant setup", "ubuntu java", "ubuntu java dev env", "Java setup on ubuntu"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const ExternalLinksListContainer = makeShortcode("ExternalLinksListContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Vagrant`}</code>{` is a provisioning tool for virtual machines and provides an easy way to build and manage virtual machine environment best suited for setting up development environments.  It can provision a virtual machine on VirtualBox, VMware, AWS etc. Vagrant works with the configuration defined in a file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Vagrantfile`}</code>{`, which can be committed in the source control.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`VirtualBox`}</code>{` is basically inception for your computer. You can use VirtualBox to run entire sandboxed operating systems within your own computer. It is the default provider for vagrant.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Ubuntu`}</code>{` is a free and open-source Linux distribution based on Debian. Ubuntu is officially released in three editions: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Desktop`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Server`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Core`}</code>{` for the internet of things devices and robots. All the editions can run on the computer alone, or in a virtual machine.`}</p>
    <p>{`This article provides step by step details on provisioning Ubuntu Desktop using Vagrant on Windows 10 with all applications needed for Java Development Environment.`}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Vagrant and Virtual Box are the only prerequisites that are needed on Windows 10 to provision Java Development Environment on desired VM. Download, Install and restart your machine if prompted for.`}</p>
    <ul>
      <li parentName="ul">{`Download and Install Oracle Virtual Box for windows available from `}<ExternalLink href="https://www.virtualbox.org/wiki/Downloads" mdxType="ExternalLink"><a parentName="li" {...{
            "href": "https://www.virtualbox.org/wiki/Downloads"
          }}>{`https://www.virtualbox.org/wiki/Downloads`}</a></ExternalLink></li>
      <li parentName="ul">{`Download and Install Vagrant for Windows available from `}<ExternalLink href="https://www.vagrantup.com/downloads.html" mdxType="ExternalLink"><a parentName="li" {...{
            "href": "https://www.vagrantup.com/downloads.html"
          }}>{`https://www.vagrantup.com/downloads.html`}</a></ExternalLink></li>
    </ul>
    <p>{`Executing the below command should Verify if Vagrant is installed successfully`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` vagrant -v

Vagrant `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2.2`}</span>{`.7`}</code></pre></div>
    <h2 {...{
      "id": "vagrantfile"
    }}>{`Vagrantfile`}</h2>
    <p>{`Create a sample directory or go inside your project folder and enter`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` vagrant init`}</code></pre></div>
    <p>{`This will generate a minimal Vagrantfile, which we will modify to suit our need. We can choose different boxes packaged for Vagrant from official repository at `}<ExternalLink href="https://app.vagrantup.com/boxes/search" mdxType="ExternalLink"><a parentName="p" {...{
          "href": "https://app.vagrantup.com/boxes/search"
        }}>{`https://app.vagrantup.com/boxes/search`}</a></ExternalLink>{`. We will be provisioning Ubuntu Desktop in this article.`}</p>
    <h2 {...{
      "id": "use-ubuntu-desktop-vagrant-box"
    }}>{`Use Ubuntu Desktop Vagrant box`}</h2>
    <ExternalLink href="https://app.vagrantup.com/peru/boxes/ubuntu-18.04-desktop-amd64" mdxType="ExternalLink">peru/ubuntu-18.04-desktop-amd64</ExternalLink> is the vagrant box that we will be using to provision our development environment in this article.
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/75eefb5350010eeebb92fb02e6f30169/f1d1f/ubuntu_vagrant_image.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "72.39263803680981%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB2klEQVQ4y32Sy27TQBSGvQEWCNZseAwES1YI3oBLoFIRqOIJUFcsuKyJqiLgIXgcxE2UOnHGjtPGd48v50MzTlInUTvSp3MZ6/c5v8a5/w4+fIPb+7BzAPfewt03cGcfHryHW6/h+jO48QKuPYWbe3B1AJcewpVHcHkDZ+dAePUVHn8U9r4ILz/D7qGwe4jNn38yd/DEMOziYCgMhsvYBxzsEc5OPz/vSO87WcMR6ZJWBFnQLmk36v59S1f35FoBZzITfo3Bn2X88Rp+e3DkC+MQjhT27p8PP0fw3QU3gL8KfrhdPJ1HxHFMFMVorXF0BUUFdSPoGsoKqmZZC4UWyqqLWbmo9VnfiCxpmgZHlzlFnpCmC5KIKKmYJ9DZcZGv230ny2uCk4YoLkjTzP7FTFfVrDw1uta7tl1Der4vcZIkxR0plB+glMLzJnieh1IT/CCwmH4YhiRJsoZZ087ZFzRruu4xo9GY6XSKUj7eZEIYzojjxBpuMAJpmlqyLLOxL7hauSgKzJTmozzPMbXB5KZ3Hkawqqptwe3HevHD3vRsSzDO4DgQxlNQJ4I6hbyoKUszaWmnLcvS1nVdbwn0825lLcxTIc6EJDdvDbSuV0KFsaHshPuC5034H/fdGUJFJYRvAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Ubuntu Desktop Vagrant Box",
              "title": "Ubuntu Desktop Vagrant Box",
              "src": "/static/75eefb5350010eeebb92fb02e6f30169/a6d36/ubuntu_vagrant_image.png",
              "srcSet": ["/static/75eefb5350010eeebb92fb02e6f30169/222b7/ubuntu_vagrant_image.png 163w", "/static/75eefb5350010eeebb92fb02e6f30169/ff46a/ubuntu_vagrant_image.png 325w", "/static/75eefb5350010eeebb92fb02e6f30169/a6d36/ubuntu_vagrant_image.png 650w", "/static/75eefb5350010eeebb92fb02e6f30169/f1d1f/ubuntu_vagrant_image.png 739w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Ubuntu Desktop Vagrant Box`}</figcaption>{`
  `}</figure></p>
    <p>{`Delete Vagrantfile that is created and run the below command to initialize one with Ubuntu Desktop. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` vagrant init peru/ubuntu-18.04-desktop-amd64 `}</code></pre></div>
    <p>{`This will create file Vagrantfile which have reference to the used public box.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`vm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`box `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"peru/ubuntu-18.04-desktop-amd64"`}</span></span></code></pre></div>
    <p>{`Run Vagrant command to provision the VM with the available Vagrantfile`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` vagrant up`}</code></pre></div>
    <p>{`This will use the default provider `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`virtualbox`}</code>{` and will download the virtualbox Ubuntu Desktop image and starts it up. You should observe something similar after running the above command. Image being downloaded is of around ~1.45 GB and it would take couple of minutes to complete.`}</p>
    <p>{`You should observe something similar after running the above command.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0191a66884c9bddbec1a1e22f38024f2/f53a0/vagrant_up_fedora.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.447852760736193%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABE0lEQVQY01WR7Y6DIBBFfZht3dr6iVYEURRR+/4PdDZiTLY/Tu5lSO4wQzS7gXWbccsYdHaGae7pTYvuWzrV0LyLwLstESKjbvJAmv3ySuMvotFqFm/Zdodfp+Ct7TkaTbMJ+HVGqYaqSqlEiqhzsjwhyx/kxbdG2jRMs2YYJYc3Q4sZW0YrA4NtsbNisDLUj/tOC+r3+VJRZ1QiC03KKiWaFsWy9fjd4FbN5Dusk7hV4XeNGUsa+aSsHzRdQtUkFCImK288X3de6T2MevqYaFkUfu3Z94Ft61lXjfeafTd8PgPLEe4k0yQxg0JKQduKMPIRcOzxP5HSDy50f2qnTq6z0glFGfNIfkiet8AVdn3GFfgH9E/EOFpbE54AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "vagrant up fedora",
            "title": "vagrant up fedora",
            "src": "/static/0191a66884c9bddbec1a1e22f38024f2/a6d36/vagrant_up_fedora.png",
            "srcSet": ["/static/0191a66884c9bddbec1a1e22f38024f2/222b7/vagrant_up_fedora.png 163w", "/static/0191a66884c9bddbec1a1e22f38024f2/ff46a/vagrant_up_fedora.png 325w", "/static/0191a66884c9bddbec1a1e22f38024f2/a6d36/vagrant_up_fedora.png 650w", "/static/0191a66884c9bddbec1a1e22f38024f2/e548f/vagrant_up_fedora.png 975w", "/static/0191a66884c9bddbec1a1e22f38024f2/f53a0/vagrant_up_fedora.png 1062w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ExternalLinksListContainer title={'References'} links={[{
      'url': 'https://github.com/cosee/ubuntu-ansible-desktop-setup'
    }, {
      'url': 'https://medium.com/the-lazy-programmer/automating-your-dev-environment-setup-with-vagrant-and-ansible-c6790b3257a0'
    }, {
      'url': 'https://www.edureka.co/blog/development-environment-using-vagrant/'
    }, {
      'url': 'http://ssledz.github.io/blog/2015/11/08/java-development-environment-with-vagrant-part-1/'
    }, {
      'url': 'https://blog.versioneye.com/2015/05/05/setting-up-a-dev-environment-with-vagrant/'
    }]} mdxType="ExternalLinksListContainer">
    </ExternalLinksListContainer>
    <p><a parentName="p" {...{
        "href": "https://dev.to/deepu105/my-beautiful-linux-development-environment-2afc"
      }}>{`https://dev.to/deepu105/my-beautiful-linux-development-environment-2afc`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/lvancrayelynghe/ansible-ubuntu"
      }}>{`https://github.com/lvancrayelynghe/ansible-ubuntu`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/cosee/ubuntu-ansible-desktop-setup"
      }}>{`https://github.com/cosee/ubuntu-ansible-desktop-setup`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      