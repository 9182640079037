import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PostCardMinimal from "~/components/PostCardMinimal/postCardMinimal"
import _ from 'lodash'
import Categories from '~/utils/CategoryConstants'

import BlogPostsWrapper, { SecTitle } from "./style"

type PostsProps = {}

const Posts: React.FunctionComponent<PostsProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(
        filter: {
          frontmatter: {
            templateKey: {eq: "article"},
            published: {eq: false}
          }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM YYYY [</span>]")
              title
              description
              tags
              category
              cover {
                childImageSharp {
                  gatsbyImageData(
                    width: 325
                    height: 325
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const Posts = Data.allMdx.edges
  return (
    <BlogPostsWrapper>
      <SecTitle>TODOs</SecTitle>
      {Posts.map(({ node }: any) => {
        const title = node.frontmatter.title || node.fields.slug
        const categoryInfo =  _.get(Categories, node.frontmatter.category)
        return (
          <PostCardMinimal
            key={node.fields.slug}
            title={title}
            image={
              node.frontmatter.cover == null
                ? null
                : node.frontmatter.cover.childImageSharp.gatsbyImageData
            }
            url={node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
            category={categoryInfo}
          />
        )
      })}
      
    </BlogPostsWrapper>
  )
}

export default Posts
