import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "A Step by Step guide to create CRUD Rest API using Spring Boot + Spring JDBC",
  "description": "A detailed step by step to create CRUD Rest API using Spring Boot + Spring JDBC",
  "date": "2020-02-03T23:46:37.121Z",
  "updated": "2020-02-03T23:46:37.121Z",
  "cover": "../../../images/blog-banners/crud-springboot-banner-1200x690.png",
  "category": "crudFromScratch",
  "tags": ["Java", "Spring Boot", "CRUD", "Spring JDBC"],
  "keywords": ["Step by Step guide using Spring Boot", "Spring Boot", "CRUD", "Java", "Java8", "Spring JDBC", "Rest API", "Java Rest API", "CRUD Rest API", "Step by Step guide"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://mkyong.com/spring-boot/spring-boot-jdbc-examples/"
      }}>{`https://mkyong.com/spring-boot/spring-boot-jdbc-examples/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.javacodegeeks.com/2019/09/spring-boot-testing-junit-5.html"
      }}>{`https://www.javacodegeeks.com/2019/09/spring-boot-testing-junit-5.html`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mkyong.com/spring-boot/spring-boot-junit-5-mockito/"
      }}>{`https://mkyong.com/spring-boot/spring-boot-junit-5-mockito/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.logicbig.com/tutorials/spring-framework/spring-data-access-with-jdbc/jdbc-test-utils.html"
      }}>{`https://www.logicbig.com/tutorials/spring-framework/spring-data-access-with-jdbc/jdbc-test-utils.html`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://subscription.packtpub.com/book/application_development/9781783983780/1/ch01lvl1sec15/exploring-spring-jdbc"
      }}>{`https://subscription.packtpub.com/book/application_development/9781783983780/1/ch01lvl1sec15/exploring-spring-jdbc`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      