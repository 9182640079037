import * as React from 'react';
import {FaTasks} from 'react-icons/fa'

import {
  TodoWrapper
} from './index.style';

const Todo = ({children}) => {
  return (
    <TodoWrapper><FaTasks/> <b>TODO: </b>{children}</TodoWrapper>
  );
};

export default Todo;