import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import _ from "lodash"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import dateFormat from "dateformat"
import {
  MDX_COMPONENTS,
  MDX_SHORTCODES,
} from "~/utils/mdx/MDXComponentConstants"

import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription,
  PostTags,
} from "./postDetails.style"

type PostDetailsProps = {
  title: string
  date?: string
  preview?: any
  description: any
  tags?: []
  className?: string
  imagePosition?: "left" | "top"
}

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  tags,
  className,
  imagePosition,
  ...props
}) => {
  const addClass: string[] = ["post_details"]
  const lastUpdatedDate = dateFormat(date, "dd mmm, yyyy")
  const altImage = "2much2learn - "+title

  if (imagePosition == "left") {
    addClass.push("image_left")
  }

  if (className) {
    addClass.push(className)
  }

  //Random Placeholder Color
  const placeholderColors = [
    "#55efc4",
    "#81ecec",
    "#74b9ff",
    "#a29bfe",
    "#ffeaa7",
    "#fab1a0",
    "#e17055",
    "#0984e3",
    "#badc58",
    "#c7ecee",
  ]
  const setColor =
    placeholderColors[Math.floor(Math.random() * placeholderColors.length)]

  return (
    <PostDetailsWrapper {...props} className={addClass.join(" ")}>
      {imagePosition == "left" ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <GatsbyImage image={preview} alt={altImage} backgroundColor={setColor} />
            </PostPreview>
          )}
        </>
      ) : (
        ""
      )}

      {imagePosition == "top" ? (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>Last modified: {lastUpdatedDate}</PostDate>
        </>
      ) : (
        ""
      )}

      {imagePosition == "top" ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <GatsbyImage image={preview} alt={altImage} backgroundColor={setColor} />
            </PostPreview>
          )}
        </>
      ) : (
        ""
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {imagePosition == "left" ? (
          <>
            <PostTitle>{title}</PostTitle>
            <PostDate>{date}</PostDate>
          </>
        ) : (
          ""
        )}
        <MDXProvider components={{ ...MDX_COMPONENTS, ...MDX_SHORTCODES }}>
          <PostDescription className="post_des">
            <MDXRenderer>
              {description}
            </MDXRenderer>
          </PostDescription>
        </MDXProvider>
        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  )
}

PostDetails.defaultProps = {
  imagePosition: "top",
}

export default PostDetails
