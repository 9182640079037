import styled from "styled-components"

export const MuchLogoText = styled.span`
    font-family: 'Montserrat', sans-serif;
    float: left;
    margin-top: 0px;
    font-size: 32px;
    letter-spacing: 5px;
    font-weight: 700;
    color: #000000;
    @media (max-width: 575px) {
        font-size: 18px;
        margin-top: 12px;
    }
`;

export const LearnLogoText = styled.span`
    font-family: 'Montserrat', sans-serif;
    float: right;
    margin-left: 6px
    margin-top: 8px;
    font-size: 32px;
    letter-spacing: 5px;
    font-weight: 700;
    color: #000000;
    @media (max-width: 575px) {
        font-size: 18px;
        margin-top: 12px;
    }
`;

export const MuchLogoTextMobile = styled.span`
    font-family: 'Montserrat', sans-serif;
    margin-left: 6px;
    float: left;
    font-size: 18px;
    margin-top: 12px;
    letter-spacing: 3px;
    font-weight: 700;
    color: #000000;
`;

export const LearnLogoTextMobile = styled.span`
    font-family: 'Montserrat', sans-serif;
    margin-left: 6px;
    float: right;
    font-size: 18px;
    margin-top: 12px;
    letter-spacing: 3px;
    font-weight: 700;
    color: #000000;
`;