import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SocialProfile from "~/components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/dev.2much2learn/",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/2much2learn/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/dev_2much2learn",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoLinkedin />,
    url: "https://www.linkedin.com/company/2much2learn",
    tooltip: "Linked In",
  },
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.png/" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            quality: 100
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About 2much2learn.com</h2>
        <p>2much2learn.com is a platform to share knowledge and experience from like-minded authors to the developer's community.</p>
        <p>We would like to build an online community focusing on publishing articles on Java, Frontend and Container Technologies, targeted at the newbies stepping up to build up their career, junior developers, senior developer or anyone else who wants to learn new stuff. We hope all our articles will save you some time and get things done quickly.</p>
        <p>Please feel free to share your views about the blog via comments. If you wish to contact us, please drop us an email or connect to us on our social profiles</p>
        <p>Nonetheless, We always think at some point of time... There's always 2 much 2 learn !!!</p>
      </AboutPageTitle>
      
      <SocialProfiles>
        <SocialProfile items={SocialLinks} />
      </SocialProfiles>

      {/*
      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>
      <AboutDetails>
        <h2>Hey there, what’s up? This is Madan Narra</h2>
        <p>
          2much2learn.com is a long due blog that I wanted to start and publish articles on technologies that I keep learning and working on.
        </p>
        <p>
          Wanted to pair with other like minded authors who can share their experiences under the same umbrella.
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
      */}
    </AboutWrapper>
  )
}

export default About
