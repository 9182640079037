import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "A Step by Step guide on Containerizing Spring Boot CRUD RESTful API Application with Jib",
  "description": "A detailed step by step guide on Containerizing Spring Boot CRUD RESTful API Application with Jib and test drive the app by deploy the app with docker.",
  "date": "2020-02-24T15:46:37.121Z",
  "updated": "2020-02-24T15:46:37.121Z",
  "cover": "../../../images/blog-banners/setup-banner-1200x690.png",
  "category": "setup",
  "tags": ["Jib", "Docker", "Containerization", "Spring Boot", "featured"],
  "keywords": ["Containerizing Spring Boot CRUD RESTful API with Jib", "Spring Boot with Jib", "Container with Jib", "Build Docker images with Jib", "Java with Jib", "Maven with Jib", "Gradle with Jib", "Create Java container image with Jib", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article-feb20-containerizing-spring-boot-crud-restful-api-application-with-jib" mdxType="GithubRepo">containerizing-spring-boot-crud-restful-api-application-with-jib</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Containerization is process of packaging executable code along with the runtime environment which can be deployed on a virtual image. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker`}</code>{` is the de facto system for containerizing applications.`}</p>
    <p>{`Docker needs us to create and maintain `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dockerfile`}</code>{`, which we need to use to create the Docker image by running a Docker daemon as root, wait for builds to complete, and finally push the image to a remote registry or Docker Hub.`}</p>
    <p>{`But this is cumbersome for Java developers who just want to build a Jar and have it deployed and bootstrapped in a container with very minimal steps. To address this challenge for Java developers, Google introduced `}<ExternalLink href="https://github.com/GoogleContainerTools/jib" mdxType="ExternalLink">{`Jib`}</ExternalLink>{`.`}</p>
    <h2 {...{
      "id": "what-is-jib"
    }}>{`What is Jib?`}</h2>
    <p><strong parentName="p">{`Jib`}</strong>{` is open-source Java containerizer that lets Java developers build containers using the existing Java tools we use to build and package our application`}</p>
    <p>{`Jib is a fast and simple container image builder that handles all the steps of packaging your application into a container image. It does not require us to write a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{` or have docker installed, and can be included as plugin in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Maven`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Gradle`}</code>{` which creates containerized Java application in no time.`}</p>
    <p>{`Below images helps us to visualise the difference between the usual `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker build flow`}</code>{` against the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`jib build flow`}</code>{`.`}</p>
    <p><strong parentName="p">{`Docker build flow`}</strong></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bcb0ef0f7c841cd4614ff9d1a910807e/0a47e/docker_build_flow.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "18.404907975460123%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNUlEQVQY0wXBPy8DYQAH4DdpIhJhJCwYDeZ+AZvEIBFllIiF2JmIxCcQo0RjMdZSjTaRDg0daYRU2zvuer3evf8P995df56HGGMujTHHt3f1mSRJT5MkuTIm3QM0QSpnMcpeALhxHFuMMZsyZqdpZgH4zrLsA0jmXF+Q11aLPD03CfF9X3Au6sX77pJUusI5/xLq5wIAKTW8ZaFjnhgDY8xIqgiMSwRBgJBS6CiKa423RWdAV/ueV7Isu0hq1erU+dnJmPTeJznnu5SxI6X1GiFTuevyZ74/FH/D4RBBGELoGCGTcFwHjuMgpBw3j3TB9tTOYDBod3u9JpnOr5D9g8Nct2fNR1EUSimhot8HwCUYyQkt6TrnYpsxtqkkK0jBC0qpgtZ6i1G6AfDxjg/S6bRJuVIl/6ye/kPyjZspAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Docker Build Flow",
              "title": "Docker Build Flow",
              "src": "/static/bcb0ef0f7c841cd4614ff9d1a910807e/0a47e/docker_build_flow.png",
              "srcSet": ["/static/bcb0ef0f7c841cd4614ff9d1a910807e/222b7/docker_build_flow.png 163w", "/static/bcb0ef0f7c841cd4614ff9d1a910807e/ff46a/docker_build_flow.png 325w", "/static/bcb0ef0f7c841cd4614ff9d1a910807e/0a47e/docker_build_flow.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Docker Build Flow`}</figcaption>{`
  `}</figure></p>
    <p><strong parentName="p">{`Jib build flow`}</strong></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d1f6fe46354a84c9cebc058c53f8c795/0a47e/jib_build_flow.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "7.975460122699387%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnklEQVQI12N4/+V3wv///xkqVnznsWv+wmHX/BmE2UHYvvkTu1Xrf1a/unOcUVUbeCza/7M4NH9ktW/+zALCDs1fWNo3fGMH6X/6+ovfmzdvbjC8fPs5GiRQvuwLt33zZw771s+c9i0Q7ND8mcuu5TuXc9MrbvfG+7y2rd+57Vq+8Ng3f+axb/nM49DymTdtzhdBkP7bTz6lvH//7j8AkYFVYOCSTbQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Jib Build Flow",
              "title": "Jib Build Flow",
              "src": "/static/d1f6fe46354a84c9cebc058c53f8c795/0a47e/jib_build_flow.png",
              "srcSet": ["/static/d1f6fe46354a84c9cebc058c53f8c795/222b7/jib_build_flow.png 163w", "/static/d1f6fe46354a84c9cebc058c53f8c795/ff46a/jib_build_flow.png 325w", "/static/d1f6fe46354a84c9cebc058c53f8c795/0a47e/jib_build_flow.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Jib Build Flow`}</figcaption>{`
  `}</figure></p>
    <p>{`To summarize, Jib takes advantage of layering in Docker images and integrates with your build system to optimize Java container image builds in the following ways:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`Simple`}</code>{` - Jib is implemented in Java and runs as part of your Maven or Gradle build. You do not need to maintain a Dockerfile, run a Docker daemon, or even worry about creating a fat JAR with all its dependencies. Since Jib tightly integrates with your Java build, it has access to all the necessary information to package your application. Any variations in your Java build are automatically picked up during subsequent container builds.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`Fast`}</code>{` - Jib takes advantage of image layering and registry caching to achieve fast, incremental builds. It reads your build config, organizes your application into distinct layers (dependencies, resources, classes) and only rebuilds and pushes the layers that have changed. When iterating quickly on a project, Jib can save valuable time on each build by only pushing your changed layers to the registry instead of your whole application.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`Reproducible`}</code>{` - Jib supports building container images declaratively from your Maven and Gradle build metadata, and as such can be configured to create reproducible build images as long as your inputs remain the same.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Having Docker installed on your machine is not necessary to containerize Java applications using Jib. But to test drive the image, we need to have Docker installed.`}</p>
    <h3 {...{
      "id": "install-docker-on-windows-10-home-edition"
    }}>{`Install Docker on Windows 10 Home Edition`}</h3>
    <p>{`As `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Desktop`}</code>{` does not support `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows 10 Home Edition`}</code>{`, we need to install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Toolbox for Windows`}</code>{`. Follow the detailed steps provided `}<ExternalLink href="https://docs.docker.com/v17.12/toolbox/toolbox_install_windows/" mdxType="ExternalLink">{`here`}</ExternalLink>{` to get docker toolbox installed and configured.`}</p>
    <p>{`If everything is installed and configured as suggested, running the below command should list the version of docker.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` -v

Docker version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19.03`}</span>{`.1, build 74b1e89e8a`}</code></pre></div>
    <h3 {...{
      "id": "signup-to-docker-hub"
    }}>{`Signup to Docker Hub`}</h3>
    <p>{`Running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mvn jib:build`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gradle jib`}</code>{` command, Jib builds and pushes the image to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker hub`}</code>{` by default. To successfully push the image to docker hub, one has to signup with `}<ExternalLink href="https://hub.docker.com/" mdxType="ExternalLink">{`hub.docker.com`}</ExternalLink>{` and configure the credentials as mentioned in next step.`}</p>
    <h3 {...{
      "id": "login-to-docker-to-configure-the-credential-store"
    }}>{`Login to docker to configure the credential store`}</h3>
    <p>{`The Docker Engine uses external credentials store to keep user credentials. Using an external store is more secure than storing credentials in the Docker configuration file.`}</p>
    <p>{`To use a credentials store, we need an external helper program to interact with a specific keychain or external store. Docker requires the helper program to be in the host $PATH environment variable.`}</p>
    <p>{`For configuring external helper on Windows, download the latest release `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-credential-wincred-VERSION-amd64.zip`}</code>{` file from `}<ExternalLink href="https://github.com/docker/docker-credential-helpers/releases" mdxType="ExternalLink">{`Microsoft Windows Credential Manager`}</ExternalLink>{`. Download the contents to any specific location `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`E:\\binaries\\docker-credential-wincred-v0.6.3-amd64`}</code>{` and add to %PATH% environment variable.`}</p>
    <p>{`Before proceeding further, run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker logout`}</code>{` to remove the credentials if you are currently logged in. Run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker login`}</code>{` and provide the credentials when prompted. If successful, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`config.json`}</code>{` will be created under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`%HOME%\\.docker`}</code>{` and looks something like below:`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`config.json`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"auths"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"https://index.docker.io/v1/"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"HttpHeaders"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"User-Agent"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Docker-Client/19.03.1 (windows)"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"credsStore"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"wincred"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`wincred`}</code>{` is the native binary credential store on windows where as its `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`osxkeychain`}</code>{` on macOS and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pass`}</code>{` on Linux.`}</p>
    <h2 {...{
      "id": "adding-jib-to-application"
    }}>{`Adding Jib to Application`}</h2>
    <h3 {...{
      "id": "clone-springboot-crud-restful-api-application-project"
    }}>{`Clone SpringBoot CRUD RESTful API application project`}</h3>
    <p>{`To try Jib, we need a working Java application which we can build, containerize, start and access it. Clone this project `}<ExternalLink href="https://github.com/2much2learn/article-feb20-crud-rest-api-using-spring-boot-spring-data-jpa" mdxType="ExternalLink">{`crud-rest-api-using-spring-boot-spring-data-jpa`}</ExternalLink>{` to quick start the learning.`}</p>
    <p>{`Go through article `}<ExternalLink href="https://2much2learn.com/crud-rest-api-using-spring-boot-spring-data-jpa/" mdxType="ExternalLink">{`A Step by Step guide to create CRUD RESTful APIs using Spring Boot + Spring Data JPA with H2 in-memory database`}</ExternalLink>{` for further details of the project.`}</p>
    <p>{`Run below command to verify if the project is built and packaged successfully without any issues.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` mvn clean package`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Gradle`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` gradle clean build`}</code></pre></div>
    <h3 {...{
      "id": "maven-configuration"
    }}>{`Maven Configuration`}</h3>
    <p>{`Add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`jib-maven-plugin`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pom.xml`}</code>{`. Value configured for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration > to > image`}</code>{` will be the name of the container image built and deployed to docker hub.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`pom.xml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`build`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`plugins`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      ...
      ...
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`plugin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
				`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`com.google.cloud.tools`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
				`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`artifactId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`jib-maven-plugin`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`artifactId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
				`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`2.0.0`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
				`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`configuration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
					`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`to`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
						`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`2much2learn/java8-spring-boot-crud-restful-api-with-jib`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
					`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`to`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
				`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`configuration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
			`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`plugin`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      ...
      ...
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`plugins`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`build`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "gradle-configuration"
    }}>{`Gradle Configuration`}</h3>
    <p>{`Add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`com.google.cloud.tools.jib`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`plugin to build.gradle`}</code>{`. Value configured for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`jib.to.image`}</code>{` will be the name of the container image built and deployed to docker hub.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`build.gradle`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`plugins `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
    id `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'com.google.cloud.tools.jib'`}</span></span>{` version `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'2.0.0'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

jib`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`image `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'2much2learn/java8-spring-boot-crud-restful-api-with-jib'`}</span></span></code></pre></div>
    <h3 {...{
      "id": "extended-configuration"
    }}>{`Extended Configuration`}</h3>
    <p>{`Jib provides many configurations to customize the image that is built. Go through `}<ExternalLink href="https://github.com/GoogleContainerTools/jib/tree/master/jib-maven-plugin#extended-usage" mdxType="ExternalLink">{`Extended Usage`}</ExternalLink>{` for more details.`}</p>
    <p>{`Of the whole, we can customize the image by using the below configuration for Java Applications.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`configuration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`from`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` (1)
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`openjdk:8u222-jre`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` 
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`from`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`to`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` (2)
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`localhost:5000/java8-spring-boot-crud-restful-api-with-jib`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`credHelper`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`wincred`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`credHelper`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`tags`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`customtag`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`latest`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`tag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`tags`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`to`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`container`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` 
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlags`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` (3)
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`-Xms512m`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`-Xmx2048m`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`-Xdebug`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`-XX:+PrintGCDetails`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
	  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`-XX:+HeapDumpOnOutOfMemoryError`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlag`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`jvmFlags`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`mainClass`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`mainClass`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`args`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`arg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`arg1`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`arg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`arg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`arg2`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`arg`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`args`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`labels`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` (4)
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`key1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`value1`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`key1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`key2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`value2`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`key2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`labels`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`container`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`configuration`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Gradle`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`jib `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` from `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` 
    image `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'openjdk:8u222-jre'`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` to `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` 
    image `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'localhost:5000/java8-spring-boot-crud-restful-api-with-jib'`}</span></span>{`
    credHelper `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'wincred'`}</span></span>{`
    tags `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'customtag'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'latest'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  container `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` jvmFlags `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'-Xms512m'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'-Xmx2048m'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'-Xdebug'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'-XX:+PrintGCDetails'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'-XX:+HeapDumpOnOutOfMemoryError'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` 
    mainClass `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication'`}</span></span>{`
    args `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'arg1'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'arg2'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` labels `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`key1`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'value1'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`key2`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`'value2'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ol>
      <li parentName="ol">{`The base image to be used`}</li>
      <li parentName="ol">{`Pushed to a local docker repository`}</li>
      <li parentName="ol">{`Runs by calling java -Xms512m -Xmx2048m -Xdebug -cp app/libs/*:app/resources:app/classes com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication arg1 args`}</li>
      <li parentName="ol">{`Configure labels for applying metadata to the image created`}</li>
    </ol>
    <h2 {...{
      "id": "build-the-container-image"
    }}>{`Build the Container Image`}</h2>
    <p>{`To build the container image with jib, run the build command as below to compile the project and create and push the image.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` mvn clean package jib:build

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`  
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Container entrypoint `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` to `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`java, -cp, /app/resources:/app/classes:/app/libs/*, com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`  
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Built and pushed image as 2much2learn/java8-spring-boot-crud-restful-api-with-jib 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Executing tasks:
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100.0`}</span>{`% complete 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`  
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` ------------------------------------------------------------------------ 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` BUILD SUCCESS
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`INFO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` ------------------------------------------------------------------------ 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Gradle`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` gradle clean jib

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
Got output:

credentials not found `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` native keychain

Using credentials from Docker config `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`C:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`Users`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`narra`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`.docker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`config.json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` 2much2learn/java8-spring-boot-crud-restful-api-with-jib

Container entrypoint `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`set`}</span>{` to `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`java, -cp, /app/resources:/app/classes:/app/libs/*, com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

Built and pushed image as 2much2learn/java8-spring-boot-crud-restful-api-with-jib
Executing tasks:
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`88.9`}</span>{`% complete
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` launching layer pushers


BUILD SUCCESSFUL `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` 5m 49s
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` actionable tasks: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` executed, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` up-to-date`}</code></pre></div>
    <p>{`Images created with above build commands will be pushed to the registry and will not be available unless it is pulled.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List Docker Images`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` images
REPOSITORY          TAG                 IMAGE ID            CREATED             SIZE
hello-world         latest              fce289e99eb9        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span>{` months ago       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`.84kB
tomcat              `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8.0`}</span>{`                 ef6a7c98d192        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` months ago       356MB

~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` pull 2much2learn/java8-spring-boot-crud-restful-api-with-jib

~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` images
REPOSITORY                                                TAG                 IMAGE ID            CREATED             SIZE
hello-world                                               latest              fce289e99eb9        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span>{` months ago       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`.84kB
tomcat                                                    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8.0`}</span>{`                 ef6a7c98d192        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{` months ago       356MB
2much2learn/java8-spring-boot-crud-restful-api-with-jib   latest              e946277f08df        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`50`}</span>{` years ago        168MB`}</code></pre></div>
    <p>{`To create the image directly with Docker daemon and not pull it from registry, run the below build command.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Delete Image before trying with Docker demon`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` image `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` 2much2learn/java8-spring-boot-crud-restful-api-with-jib`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` mvn clean package jib:dockerBuild`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Gradle`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` gradle clean jibDockerBuild`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`~:\\> docker images
REPOSITORY                                                TAG                 IMAGE ID            CREATED             SIZE
hello-world                                               latest              fce289e99eb9        13 months ago       1.84kB
tomcat                                                    8.0                 ef6a7c98d192        17 months ago       356MB
2much2learn/java8-spring-boot-crud-restful-api-with-jib   latest              e946277f08df        50 years ago        168MB`}</code></pre></div>
    <p>{`Run the below command to inspect the image that is built by jib.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Inspecting image built by jib`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` inspect 2much2learn/java8-spring-boot-crud-restful-api-with-jib

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Id"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"sha256:e946277f08dfbc25a28f17a348544d96592b1d854d21f1c61fde316fe5703f62"`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"RepoTags"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2much2learn/java8-spring-boot-crud-restful-api-with-jib:latest"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"RepoDigests"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Parent"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Comment"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"classes"`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Created"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1970-01-01T00:00:00Z"`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Container"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Env"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin"`}</span>{`,
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"SSL_CERT_FILE=/etc/ssl/certs/ca-certificates.crt"`}</span>{`,
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"JAVA_VERSION=8u242"`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Cmd"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` null,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Image"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Volumes"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WorkingDir"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Entrypoint"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"java"`}</span>{`,
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-cp"`}</span>{`,
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/app/resources:/app/classes:/app/libs/*"`}</span>{`,
                `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"com.toomuch2learn.springboot2.crud.catalogue.CrudCatalogueApplication"`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OnBuild"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` null,
            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Labels"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Architecture"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"amd64"`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Os"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"linux"`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Size"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`167980775`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VirtualSize"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`167980775`}</span>{`,
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Only part of the output is shown for reference. Of the whole, below are few which might need some insight:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Created`}</strong>{` - Checkout `}<ExternalLink href="https://github.com/GoogleContainerTools/jib/blob/master/docs/faq.md#why-is-my-image-created-48-years-ago" mdxType="ExternalLink">{`Why is my image created 48+ years ago?`}</ExternalLink>{` for more details.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Entrypoint`}</strong>{` - Command configured for entrypoint points to the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Java main class`}</code>{` which should be bootstrapped to start the application using `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`java`}</code>{`.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "launching-the-container"
    }}>{`Launching the Container`}</h2>
    <p>{`Run the below command to bootstrap the SpringBoot application.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Command to start the application`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:8080 -t 2much2learn/java8-spring-boot-crud-restful-api-with-jib`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Command to start the application for specific spring profile`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -e `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"SPRING_PROFILES_ACTIVE=dev"`}</span>{` -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:8080 -t 2much2learn/java8-spring-boot-crud-restful-api-with-jib`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Command to start the application with debugging capability`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` $ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -e `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"JAVA_TOOL_OPTIONS=-agentlib:jdwp=transport=dt_socket,address=5005,server=y,suspend=n"`}</span>{` -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:8080 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5005`}</span>{`:5005 -t 2much2learn/java8-spring-boot-crud-restful-api-with-jib`}</code></pre></div>
    <p>{`This should start the application running on port 8080 internally and also exposed on host’s port 8080.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/38662a22c0d06561bc7c089e118b5c41/d777c/docker-image-started.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "16.56441717791411%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAhElEQVQI123OSw7DIAxF0WwlIWDAhmKHfNr97+tVoh20agdHV5bewFNtEbURqtKoVI8sK7i4IYtDzPO3tAyB5h+TGsM2gZpAVcAcEGh5iW6UaEGMDjF9Wv/e0/UwnJdBO6FUwnXvuFmBLxmhb/CtInHAfujYHadhP9q7Oh5hCcjskdKKJ98UWcTeos8fAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Docker image started",
              "title": "Docker image started",
              "src": "/static/38662a22c0d06561bc7c089e118b5c41/a6d36/docker-image-started.png",
              "srcSet": ["/static/38662a22c0d06561bc7c089e118b5c41/222b7/docker-image-started.png 163w", "/static/38662a22c0d06561bc7c089e118b5c41/ff46a/docker-image-started.png 325w", "/static/38662a22c0d06561bc7c089e118b5c41/a6d36/docker-image-started.png 650w", "/static/38662a22c0d06561bc7c089e118b5c41/e548f/docker-image-started.png 975w", "/static/38662a22c0d06561bc7c089e118b5c41/3c492/docker-image-started.png 1300w", "/static/38662a22c0d06561bc7c089e118b5c41/d777c/docker-image-started.png 1555w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Docker image started`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "configure-port-forwarding-rules-for-virtualbox-image"
    }}>{`Configure Port Forwarding Rules for VirtualBox Image`}</h2>
    <p>{`Before testing the application, we need to configure `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`port forwarding`}</code>{` for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VirtualBox`}</code>{` image that is created as part of the Docker setup on Windows 10 Home Edition. Follow the steps to configure port forwarding for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ssh`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`port 8080`}</code>{` as below:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Right Click on the default image and choose `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Settings`}</code></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Choose `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Network`}</code>{` > `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Adapter1`}</code>{` > expand `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Advanced`}</code>{` > click `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Port Forwarding`}</code></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Configure `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Port Forwarding`}</code>{` rules as below`}</p>
      </li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fc42a8cc94f2cdcd6797a86aef5110a2/0786c/VirtualBox_portforwarding.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "26.380368098159508%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA8ElEQVQY03WQu07DQBBF/aOIxy/wT0Aa6KiRQhGJgoIGlFjYIfLbu44Xv3f3oCyksBRGOpqZq5k70nhfu5i6SKmLBJHsGJQE04PuYOpo25amaWiaYz5NXdco9Y33HqYs/b3j8XXL/cpnual4WktWviSKIjdcFiVCSCopSeLEmaRpilLK9XES87Z+wVs8f3B+G3C1CLm8C7hwdcDZTcj1wydZXnCIIs+pqj1ZllIr5TQsjONAWZaM08g22OCJIuP/sPR9jzGGrmvp+8G94KgdmKbJoSeNlBWekJVb1cagjZ1hrP21/cuzU9bOdK01Qgh+AL83eZihdeRtAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Configure VirtualBox Portforwarding",
              "title": "Configure VirtualBox Portforwarding",
              "src": "/static/fc42a8cc94f2cdcd6797a86aef5110a2/a6d36/VirtualBox_portforwarding.png",
              "srcSet": ["/static/fc42a8cc94f2cdcd6797a86aef5110a2/222b7/VirtualBox_portforwarding.png 163w", "/static/fc42a8cc94f2cdcd6797a86aef5110a2/ff46a/VirtualBox_portforwarding.png 325w", "/static/fc42a8cc94f2cdcd6797a86aef5110a2/a6d36/VirtualBox_portforwarding.png 650w", "/static/fc42a8cc94f2cdcd6797a86aef5110a2/0786c/VirtualBox_portforwarding.png 663w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Configure VirtualBox Portforwarding`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "testing-the-running-crud-restful-apis-via-postman"
    }}>{`Testing the running CRUD RESTful APIs via Postman`}</h2>
    <p>{`API testing tool `}<ExternalLink href="https://www.postman.com/" mdxType="ExternalLink">{`Postman`}</ExternalLink>{` is one of the most popular tools available. The ease of Accessibility, creating environments & collections to persist test cases which validate the response status & body and Automated testing with `}<ExternalLink href="https://www.npmjs.com/package/newman" mdxType="ExternalLink">{`Newman`}</ExternalLink>{` which is a command-line collection runner for Postman.`}</p>
    <p>{`Below are the tests we execute to verify the application that is started. Ensure to add header `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Content-Type: application/json`}</code>{` which is needed for most of the tests.`}</p>
    <p>{`⭐ Download and refer to complete `}<ExternalLink href="https://github.com/2much2learn/article-feb20-containerizing-spring-boot-crud-restful-api-application-with-jib/blob/master/SpringBoot_Data_JPA_H2_CatalogueCRUD.postman_collection.json" mdxType="ExternalLink">{`Postman Collection`}</ExternalLink>{` for all the below tests.`}</p>
    <h3 {...{
      "id": "application-health"
    }}>{`Application Health`}</h3>
    <p>{`Spring Actuator exposes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/health`}</code>{` endpoint which will expose the status of the application.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: GET - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/actuator/health`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "433px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d51cea5484de503d30fe03b5aa2410fa/55fc0/postman_health.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.50920245398773%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABqElEQVQoz42S627bMAyF/f5PWARNlsHrEsk3ybrffAbSdtr1xzABn3gEUZRIsctuhZI9nPiAefRI0xNlGZBnyZCO4wNpVcilIKWEUgpobNuG76OLOUFYhWVeMA4DQvB84KTWihACYggcjHTO+RXwO926rhilhNYapOd5xjRNGIYBUkqGtBCCGcfxtaY98v9KRw5vb2+vA4/Hg7nf77her3i/vrO9XC7M7XbjNUH6vPSkozRSjHtahz11iokta/I7Uv6K9/4vumlR0MbyQinF1hiDVWtMVsNED2vsqyRnDc8LSGeyh+7Mzwv87zu/0nnPG3y797DRI+SEGD5ffv4yfRZ/HAWsDbnWPaDTCv39hufziUWp3c4zF188BcZh5GJTfWiPXkr0fQ8pBGdnfv2AFR9H26SEcZr4kNaK07bOYTUGSi0w68olIE0+zjmGOoECe6qjmhFWtQekKbeG1ip89NhqQfEOLSfkUhFzwb/HZ3NvW6OAG5wPSDlBSoHkHewgEY2G9RHWB2ytotZyUA+Odatotey0ugdstaK1wilzx2PvemyNIcf/5Q+G2p3zlz34igAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Application Health",
              "title": "Application Health",
              "src": "/static/d51cea5484de503d30fe03b5aa2410fa/55fc0/postman_health.png",
              "srcSet": ["/static/d51cea5484de503d30fe03b5aa2410fa/222b7/postman_health.png 163w", "/static/d51cea5484de503d30fe03b5aa2410fa/ff46a/postman_health.png 325w", "/static/d51cea5484de503d30fe03b5aa2410fa/55fc0/postman_health.png 433w"],
              "sizes": "(max-width: 433px) 100vw, 433px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Application Health`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "add-catalogue-item"
    }}>{`Add Catalogue Item`}</h3>
    <p>{`Below are two postman requests which we will use to create Catalogue Items. One of the Catalogue item will be used to update it in the later tests.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: POST - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/api/v1/`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Request Body`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sku"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CTLG-123-0001"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"The Avengers"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"description"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Marvel's The Avengers Movie"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"category"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Movies"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"price"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"inventory"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/40529c67ef1129b77439763bdd911ecb/798d4/postman_create_catalogue.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "31.901840490797547%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA5klEQVQY042QyU4EMQxE+///EokBenrLYiedpfNQMiME4gCHJ/lQdpVrMsbQ2fcday0qgqoSQngSv81/M63rynEcLOvK+zzzersxz/Mw2LaNfV04tofmP0zWOaIKKUWSGoJZyDkP0nlSolJiGKltVHxQfFSkJ0ontRRyp+tTYupvRu85tSB7pqQKNFp7Aly1Yu7vOGtxzmGcHfV475Fej3hyNy+VqV/Xu2V5Ofh4A/WNGBpRG7W2r+N9sXftnUNEEPEjdZ+DOEpOQzcZ5/D2xGxKyBepNs7ym3pdpL50PT74ybCltYtPuxPQocunqkUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Create Catalogue Item",
              "title": "Create Catalogue Item",
              "src": "/static/40529c67ef1129b77439763bdd911ecb/a6d36/postman_create_catalogue.png",
              "srcSet": ["/static/40529c67ef1129b77439763bdd911ecb/222b7/postman_create_catalogue.png 163w", "/static/40529c67ef1129b77439763bdd911ecb/ff46a/postman_create_catalogue.png 325w", "/static/40529c67ef1129b77439763bdd911ecb/a6d36/postman_create_catalogue.png 650w", "/static/40529c67ef1129b77439763bdd911ecb/e548f/postman_create_catalogue.png 975w", "/static/40529c67ef1129b77439763bdd911ecb/798d4/postman_create_catalogue.png 976w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create Catalogue Item`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0cac73126dac5a32aa89366312184cab/024d6/postman_create_catalogue_2.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "31.901840490797547%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA5klEQVQY04XQyW7DIBCAYb//Q/bURq6DWYfFYPNXJk6q9NLDJzHSMNtkjMFai9YaozXe+yHG+JDSwzP+x6SU4iyq1Mq8LHzebszfM+u6Dvq+YO4L9mr81/n36Ywn5z0pCq0WWgls7k5rbai10krm2AopRlwSfBIkRSSn4ZV75Y+COQS2WAm6kmXn2Du9X2AI64IzmhAE693rNHKuKkI5h9p3pq1VkvIsH4r5q+NMJ0onxc7enuWglDJWCt4hEhCR39tJoOY4BpiMtXiXsTqRtp16dEp7l2vnODq1VY69vZpAf3v3fvADctzQdmYdnwUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Create Catalogue Item",
              "title": "Create Catalogue Item",
              "src": "/static/0cac73126dac5a32aa89366312184cab/a6d36/postman_create_catalogue_2.png",
              "srcSet": ["/static/0cac73126dac5a32aa89366312184cab/222b7/postman_create_catalogue_2.png 163w", "/static/0cac73126dac5a32aa89366312184cab/ff46a/postman_create_catalogue_2.png 325w", "/static/0cac73126dac5a32aa89366312184cab/a6d36/postman_create_catalogue_2.png 650w", "/static/0cac73126dac5a32aa89366312184cab/024d6/postman_create_catalogue_2.png 961w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create Catalogue Item`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "get-catalogue-items"
    }}>{`Get Catalogue Items`}</h3>
    <p>{`Get Catalogue Items that are persisted by the requests.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: GET - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/api/v1/`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/5ca3f314f4333af75ff9372764698a87/57dc1/postman_get_catalogue_items.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "80.3680981595092%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB2klEQVQ4y5VTCW7bMBDU//+YFknb2NZBUjyX5xS7so24qNNUwICSIM7OQU0xWththlsusOsFZDVasCiUUFvDGAP/c02b1liVgjIGy7phWVdZ53nGsiwwxmDf9y9j0tpAKSUPajuI5ssFl/NZ7rXWQmrMbX2O3RhMwWhkIhBlZMpIJaPUgpwzaq3ovaO1htq6PJdSHsDvUkqw3kEnj0m9/8C2LKKQ1WxXtdu6wTknk611iEahEKGPIUM+QgbWKplPiQj6mlOIAd47BO/hnEWMEc5aOO+R3I5aspT0JyFjjI7ROyZgINeC1ipaYZsFA9zsDbiv/L73dmx+gok/oJxlQ1QbDLesPb6fNHYfZXphO7Wi9fZh0N8x9dGvhOMgfHuF0Rpn5RC4JCrwkRC5tFIPnZ8p5CA5R1G4rdi+vRzFhATKBZEKUj5IOfwb4VOFVHgDSTY5BsRlBnEZKSPXCioHQsoPhM9IhTDeCIMX8ty62OP8Hq/P1QkhN8wk91JOZ8zrjpd3hdNmoWyQLFlh/YrlzAolw4FkFPZfP+VcGh/FYrnazvWm+B+l0J0QSPy/vr0eBz2QNByupbhId4WfWk45I1KSg03eI8wXROex+4RcDrKjbbZcRQXn/Qy/AeJJ4Wi35YsVAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Get Catalogue Items",
              "title": "Get Catalogue Items",
              "src": "/static/5ca3f314f4333af75ff9372764698a87/a6d36/postman_get_catalogue_items.png",
              "srcSet": ["/static/5ca3f314f4333af75ff9372764698a87/222b7/postman_get_catalogue_items.png 163w", "/static/5ca3f314f4333af75ff9372764698a87/ff46a/postman_get_catalogue_items.png 325w", "/static/5ca3f314f4333af75ff9372764698a87/a6d36/postman_get_catalogue_items.png 650w", "/static/5ca3f314f4333af75ff9372764698a87/57dc1/postman_get_catalogue_items.png 718w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Get Catalogue Items`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "update-catalogue-item"
    }}>{`Update Catalogue Item`}</h3>
    <p>{`Update one of the Catalogue Item by its SKU number.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: PUT - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/api/v1/{sku}`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Request Body`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"sku"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CTLG-123-0001"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"The Avengers"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"description"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Marvel's The Avengers Movie"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"category"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Movies"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"price"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`95.99`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
	`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"inventory"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/25004705c0df25fa11028d8cd979c9fe/58213/postman_update_catalogue.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "34.355828220858896%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABCElEQVQoz5WQyY7dMAwE/f8/mVPw7PjZ1L5LFdCZDBAgcxgIhW5eWk1u1lru+34wxlBrpbX2ic7fYTvP8wlTPfaDXXntHIf6neu6EGO4RZD/cctnIWXThj4EnHNcxvB+/+KW+5mNtVgjJLlIzmK9R7zDKsE/GmIkpURUjZFNU0vwzNGZxTPc9filb03mGKwx6K3inSXmREwJnxMhJVLO9N4/GGyansUTpPB+TZxoEPS2GEO9RsPsjXv/iRFBt9Iz6M2NGJz3OGvJVtha77iXcP44OV4dbxc5L2KAUhZz/flAtdRKCIFSCqXkDwq1lkdbzWzWeWJoeFcIZVDH+ofS/zJhTXj6fgX8Blw0HhilrNL9AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Update Catalogue Items",
              "title": "Update Catalogue Items",
              "src": "/static/25004705c0df25fa11028d8cd979c9fe/a6d36/postman_update_catalogue.png",
              "srcSet": ["/static/25004705c0df25fa11028d8cd979c9fe/222b7/postman_update_catalogue.png 163w", "/static/25004705c0df25fa11028d8cd979c9fe/ff46a/postman_update_catalogue.png 325w", "/static/25004705c0df25fa11028d8cd979c9fe/a6d36/postman_update_catalogue.png 650w", "/static/25004705c0df25fa11028d8cd979c9fe/58213/postman_update_catalogue.png 902w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Update Catalogue Items`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "get-catalogue-item-by-sku"
    }}>{`Get Catalogue Item by SKU`}</h3>
    <p>{`Get the updated Catalogue Item by its SKU. Verify if the fields that are updated compared to the add request is reflected in thus Get Request.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: GET - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/api/v1/{sku}`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2d6f9e88ee528a211a9290348e9d4de9/a0209/postman_get_catalogue_item.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.760736196319016%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQklEQVQoz5WSi27jIBBF/f9fmWyrOuZhg80bm7MyyT602qYp0hHMIN07wzBYLVjlhJUTaTXsYaPEQK2V1hrfWaUUBjXPLMYgtUYqhVQaIQRSSpRSvzHGvMSgtcYaw7lP08Q03RjHkdvt9ojvvCyYQiDESIwRlwKpZGKI5Jw5jqOzHwel1t7S//DOoTbLGj2DlhJjbFefH+33qu0jtyw4M5M2y/miv0w+YzDWsq4W7z3eO1IMBO8IZ+XB485ccH1QR2tfC+a9UmPALTNrzBiXsC4C/0640drxJUOqlRo85seVWSiu48y7sISUsD6xhYQLqbvfTZ4z5FMwBez1glYCva3s+94vexvtzun+SpVDzJk9RZbLhU1MjHrjQ628CYtYHO9y7fHfYk8rTDlRSmYvmaMWSt3JpZJK7V/lPJ/8EXze8k951FgOW6Ys3QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Get Catalogue Item",
              "title": "Get Catalogue Item",
              "src": "/static/2d6f9e88ee528a211a9290348e9d4de9/a6d36/postman_get_catalogue_item.png",
              "srcSet": ["/static/2d6f9e88ee528a211a9290348e9d4de9/222b7/postman_get_catalogue_item.png 163w", "/static/2d6f9e88ee528a211a9290348e9d4de9/ff46a/postman_get_catalogue_item.png 325w", "/static/2d6f9e88ee528a211a9290348e9d4de9/a6d36/postman_get_catalogue_item.png 650w", "/static/2d6f9e88ee528a211a9290348e9d4de9/a0209/postman_get_catalogue_item.png 725w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Get Catalogue Item`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "delete-catalogue-item"
    }}>{`Delete Catalogue Item`}</h3>
    <p>{`Delete one of the Catalogue Item persisted earlier by its SKU.`}</p>
    <p><strong parentName="p">{`Http Method`}</strong>{`: DELETE - `}<strong parentName="p">{`Request Url`}</strong>{`: http://localhost:8080/api/v1/{sku}`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/495627965ff40eeb4bea555b210c4ba1/01dae/postman_delete_catalogue_item.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "31.901840490797547%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAwUlEQVQY042Q224DIQxE+f/vjLSwa+6IOzuRaRvloVWKdDQjGNnGYsaAaAie0YQcPEbJ6K1ijIH7vvHfw1lxXRekOmGsxUUEqdTmOA4opTZSShARrLUfEca6Hfbe7wv2DDfSWsMY8yoWQviIiKRQS0YpBTlnpFpQW92+94455wtewQ/8xso5lyJ0ibAlQejjgVOp/eX3aXhC7kik4ZxDohNr8k6/dvUXYqyFmBJqLei9obX2pn37TatYa2Kt9a2/8wS2Ac/q8oOfOAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Delete Catalogue Items",
              "title": "Delete Catalogue Items",
              "src": "/static/495627965ff40eeb4bea555b210c4ba1/a6d36/postman_delete_catalogue_item.png",
              "srcSet": ["/static/495627965ff40eeb4bea555b210c4ba1/222b7/postman_delete_catalogue_item.png 163w", "/static/495627965ff40eeb4bea555b210c4ba1/ff46a/postman_delete_catalogue_item.png 325w", "/static/495627965ff40eeb4bea555b210c4ba1/a6d36/postman_delete_catalogue_item.png 650w", "/static/495627965ff40eeb4bea555b210c4ba1/01dae/postman_delete_catalogue_item.png 721w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Delete Catalogue Items`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "stopping-the-container"
    }}>{`Stopping the Container`}</h2>
    <p>{`As the container is started with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-t`}</code>{` option, output written by the application will be rendered on the console. Open up another console and run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker ps`}</code>{` which lists containers that are currently running.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List of Containers running`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`

CONTAINER ID        IMAGE                                                     COMMAND                  CREATED             STATUS              PORTS                    NAMES
4b91a669e106        2much2learn/java8-spring-boot-crud-restful-api-with-jib   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"java -cp /app/resou…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` minutes ago      Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` minutes       `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:8080-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`/tcp   nervous_knuth`}</code></pre></div>
    <p>{`Run command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker stop <CONTAINERID>`}</code>{` to stop the container.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop Container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` stop 4b91a669e106
4b91a669e106`}</code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In this article, we checked out how to build and publish docker images using Google’s Jib using Maven and Gradle. This minimizes lot of effort for Java Developers to build container images and testing them with ease without any hassle on setting up runtime environment and running the application.`}</p>
    <GithubRepo href="https://github.com/2much2learn/article-feb20-containerizing-spring-boot-crud-restful-api-application-with-jib" mdxType="GithubRepo">containerizing-spring-boot-crud-restful-api-application-with-jib</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      