import styled, { css } from 'styled-components'
import { BookContent } from '@styled-icons/boxicons-regular/BookContent'
import { Close as Cross } from '@styled-icons/material/Close'
import mediaQuery from '~/utils/mediaQuery'
import { themeGet } from '@styled-system/theme-get';

const openTocDiv = css`
  background: white;
  color: ${props => props.theme.textColor};
  padding: 0.7em 1.2em;
  border-radius: 0.5em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
  border: 1px solid ${props => props.theme.borderColor};
`

export const TocDiv = styled.div`
  position: fixed;
  top: 20%;
  left: 0;
  max-width: 20em;
  bottom: 0px;
  z-index: 10000;
  background: #e5e5e5;
  padding: 10px 10px 10px 10px;
  border-radius: 0px 10px 0px 0px;
  display: ${props => props.open ? `block` : `none`};
`

export const NavDiv = styled.div`
  padding: 2px;
  overflow-y: auto;
  height: 95%; 
`

export const Title = styled.h2`
  margin: 0;
  padding-bottom: 0.5em;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: auto auto 1fr;
`

export const TocLink = styled.a`
  color: ${({ theme, active }) => (active ? theme.linkColor : 'black')};
  font-weight: ${props => props.active && `bold`};
  display: block;
  margin-left: ${props => props.depth + `em`};
  border-top: ${props =>
    props.depth === 0 && `1px solid #e5e5e5`};
    cursor: pointer;
`

export const TocIcon = styled(BookContent)`
  width: 1em;
  margin-right: 0.2em;
`

const openerCss = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #f73b98;
  color: #fff;
  transition: 0.15s ease-in-out;
  &:hover {
    background-color: ${themeGet("colors.primary", "#D10068")};
  }

  position: fixed;
  left: 20px;
  bottom: 30px;
  ${mediaQuery.minPhablet} {
    bottom: calc(1vh + 1em);
  }
  padding: 0.5em 0.3em 0.5em 0.3em;
  transform: translate(${props => (props.open ? `-100%` : 0)});
`

const closerCss = css`
  margin-left: 1em;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 50%;
`

export const TocToggle = styled(Cross).attrs(props => ({
  as: props.opener && BookContent,
  size: props.size || `1.6em`,
}))`
  z-index: 2;
  transition: 0.3s;
  justify-self: end;
  :hover {
    transform: scale(1.1);
  }
  display: ${props => !props.open ? `block` : `none`};
  ${props => (props.opener ? openerCss : closerCss)};
  cursor: pointer;
`