import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "Setup Jenkins CI/CD Pipeline on Windows 10",
  "description": "A detailed step by step walkthrough on setting up Jenkins CI/CD pipleine on Windows 10 for Node Project",
  "date": "2020-01-05T23:46:37.121Z",
  "updated": "2020-01-05T23:46:37.121Z",
  "cover": "../../../images/blog-banners/cicd-banner-1200x690.png",
  "category": "cicd",
  "tags": ["Jenkins", "CI/CD"],
  "keywords": ["continuous integration", "continious deployment", "CI/CD", "Jenkins", "Jenkins on Windows 10", "Node Project CI/CD"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://medium.com/@mosheezderman/how-to-set-up-ci-cd-pipeline-for-a-node-js-app-with-jenkins-c51581cc783c"
      }}>{`https://medium.com/@mosheezderman/how-to-set-up-ci-cd-pipeline-for-a-node-js-app-with-jenkins-c51581cc783c`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dzone.com/articles/how-to-install-jenkins-on-windows"
      }}>{`https://dzone.com/articles/how-to-install-jenkins-on-windows`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dzone.com/articles/learn-how-to-setup-a-cicd-pipeline-from-scratch"
      }}>{`https://dzone.com/articles/learn-how-to-setup-a-cicd-pipeline-from-scratch`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      