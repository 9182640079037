import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "Converting String to Stream of chars",
  "description": "Converting String to Stream of chars",
  "date": "2020-01-05T23:46:37.121Z",
  "updated": "2020-01-05T23:46:37.121Z",
  "cover": "../../../images/blog-banners/microservice-banner-1200x690.png",
  "category": "javastreams",
  "tags": ["java", "streams", "featured"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`Java 8 introduced the Stream API, with functional-like operations for processing sequences. If you want to read more about it, have a look at this article.`}</p>
    <p>{`In this quick article, we’ll see how to convert a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{` to a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream`}</code>{` of single characters.`}</p>
    <h2 {...{
      "id": "conversion-using-chars"
    }}>{`Conversion Using chars()`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{` API has a new method – `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chars()`}</code>{` – with which we can obtain an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream`}</code>{` from a String object. This simple API returns an instance of IntStream from the input String.`}</p>
    <p>{`Simply put, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`IntStream`}</code>{` contains an integer representation of the characters from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{` object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` testString `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"String"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span>{` intStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` testString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`chars`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`It’s possible to work with the integer representation of the characters without converting them to their `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Character`}</code>{` equivalent. This can lead to some minor performance gains, as there will be no need to box each integer into a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Character`}</code>{` object.`}</p>
    <p>{`However, if we’re to display the characters for reading, we need to convert the integers to the human-friendly `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Character`}</code>{` form:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Character`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` characterStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` testString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`chars`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`mapToObj`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`char`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "conversion-using-codepoints"
    }}>{`Conversion Using codePoints()`}</h2>
    <p>{`Alternatively, we can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`codePoints()`}</code>{` method to get an instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`IntStream`}</code>{` from a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`String`}</code>{`. The advantage of using this API is that Unicode supplementary characters can be handled effectively.`}</p>
    <p>{`Supplementary characters are represented by Unicode surrogate pairs and will be merged into a single codepoint. This way we can correctly process (and display) any Unicode symbol:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span>{` intStream1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` testString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`codePoints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We need to map the returned `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`IntStream`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream<Character>`}</code>{` to display it to users:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Character`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` characterStream2 
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` testString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`codePoints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`mapToObj`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`char`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "conversion-to-a-stream-of-single-character-strings"
    }}>{`Conversion to a Stream of Single Character Strings`}</h2>
    <p>{`So far, we’ve been able to get a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream`}</code>{` of characters; what if we want a Stream of single character `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Strings`}</code>{` instead?`}</p>
    <p>{`Just as specified earlier in the article, we’ll use either the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`codePoints()`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chars()`}</code>{` methods to obtain an instance of IntStream that we can now map to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream<String>`}</code>{`.`}</p>
    <p>{`The mapping process involves converting the integer values to their respective character equivalents first.`}</p>
    <p>{`Then we can use String.valueOf() or Character.toString() to convert the characters to a String object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` stringStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` testString`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`codePoints`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`mapToObj`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`valueOf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`char`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In this quick tutorial, we learn to obtain a stream of Character from a String object by either calling `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`codePoints()`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chars()`}</code>{` methods.`}</p>
    <p>{`This allows us to take full advantage of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream API`}</code>{` – to conveniently and effectively manipulate characters.`}</p>
    <p>{`As always, code snippets can be found over on GitHub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      