import React from "react"
import _ from "lodash"
import PromotionImage from "~/images/ads/468x60.jpg"
import {
  AdWrapper,
} from "./style"

type SidebarProps = {}

const Sidebar: React.FunctionComponent<SidebarProps> = () => {

  return (
    <AdWrapper>
      <a href="#" >
        <img src={PromotionImage} alt="Promotion"/>
      </a>
    </AdWrapper>
  )
}

export default Sidebar