import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": false,
  "showSideBar": true,
  "author": "narramadan",
  "title": "A brief introduction to different Programming Paradigms",
  "description": "A brief introduction to different Programming Paradigms that are to be known to every developer which will help them to choose the right one for their use case implementation.",
  "date": "2020-02-07T18:46:37.121Z",
  "updated": "2020-02-07T18:46:37.121Z",
  "cover": "../../../images/blog-banners/programming-paradigm-banner-1200x690.png",
  "category": "programming",
  "tags": ["Programming", "Java", "featured"],
  "keywords": ["Programming", "Paradigm", "Programming Paradigm", "Functional Programming", "Object Oriented Programming", "Imperative Programming", "Declarative Programming", "Functional Reactive Programming", "Different programming paradigm in Java", "Developers need to know", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const ExternalLinksListContainer = makeShortcode("ExternalLinksListContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-exactly-is-programming-paradigm"
    }}>{`What exactly is Programming Paradigm?`}</h2>
    <p>{`A programming paradigm is a style or way of programming which defines how we think and approach the problems. There can be more than one type programming paradigms. Here is a brief list of the common ones:`}</p>
    <ul>
      <li parentName="ul">{`Imperative Programming`}</li>
      <li parentName="ul">{`Functional Programming`}</li>
      <li parentName="ul">{`Object-oriented Programming`}</li>
      <li parentName="ul">{`Aspect-oriented Programming`}</li>
      <li parentName="ul">{`Reactive Programming`}</li>
      <li parentName="ul">{`Functional Reactive Programming`}</li>
    </ul>
    <p>{`There are lots of programming languages that use one or some of these strategies when they are implemented. And that strategy is a paradigm.`}</p>
    <p>{`Let’s briefly go through each one of these.`}</p>
    <h3 {...{
      "id": "imperative-programming"
    }}>{`Imperative Programming`}</h3>
    <p>{`Imperative programming is the style of programming in which there is a sequence of statements that change the state of the program. Control flow in imperative programming is explicit i.e, commands show how the computation takes place, step by step. Each step affects the global state of the computation.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` grandtotal `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

grandtotal `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Sysout`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`grandtotal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Imperative programming says how to do something. As shown above, we can see the state of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`grandtotal`}</code>{` changed from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`6`}</code>{` by some series of sequential commands. This makes the programs simpler for a someone to read and write. Imperative programming can be written almost in every programming language.`}</p>
    <h3 {...{
      "id": "functional-programming"
    }}>{`Functional Programming`}</h3>
    <p>{`Functional Programming is about avoiding reassigning variables, avoiding mutable data structures, avoiding state and favouring functions all-the-way. It is mainly used to perform mathematical functions or logical operations that doesn’t change the state of variables defined in the program.`}</p>
    <p>{`Functions implemented should be idempotent by nature i.e, make that same call repeatedly while producing the same result.`}</p>
    <p>{`Functions are classified as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Pure`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Impure`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Higher Order`}</code>{` Functions.`}</p>
    <p><strong parentName="p">{`Pure Functions`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ObjectWithPureFunction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This is an example of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Pure Functions`}</code>{`. As shown above, sum() method/function only depends on the input parameters and will not have any side effects modifying the state of variables that are defined outside of the function.`}</p>
    <p><strong parentName="p">{`Impure Functions`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ObjectWithImpureFunction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` nextValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` nextValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Notice how `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`add()`}</code>{` method uses a instance/member variable to calculate its return value, and it also modifies the state of the member variable, so it has a side effect. This state changing makes this function to be termed as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Impure`}</code>{`.`}</p>
    <p><strong parentName="p">{`Higher Order Functions`}</strong></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Higher order functions`}</code>{` are functions that either accept other functions as arguments or returns a function as a result. With the introduction of Lambda expressions in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Java 8`}</code>{`, Java supports higher order functions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` names `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Arrays`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Madan"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Meenakshi"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Manya"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collections`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`first`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` second`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` first`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` second`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`As shown above, sort function is an example of a Higher order function that accepts a lambda expression. The expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(first, second) -> first.length() - second.length()`}</code>{` is a lambda expression of type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Comparator<String>`}</code>{`.`}</p>
    <ul>
      <li parentName="ul">{`The `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`(first, second)`}</code>{` are parameters of the compare method of Comparator.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`first.length() - second.length()`}</code>{` is the function body that compares the length of two names.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`->`}</code>{` is the lambda operator that separates parameters from the body of the lambda.`}</li>
    </ul>
    <h3 {...{
      "id": "object-oriented-programming"
    }}>{`Object-oriented Programming`}</h3>
    <p>{`Object Oriented Programming a.k.a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OOP`}</code>{` is the most popular programming paradigm. It has unique advantages like the modularity of the code and the ability to directly associate real-world business problems in terms of code. It proposes the creation of templates (or classes) which can define the state and behavior of the instances they model. The instances interact with each other by sending them messages via methods.`}</p>
    <p>{`This programming paradigm defines four principles:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Abstraction`}</code>{` — Process of hiding the implementation details from the user. Оnly the functionality will be provided to the user.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Encapsulation`}</code>{` — Mechanism of wrapping the data (variables) and code acting on the data (methods) together as a single unit.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Inheritance`}</code>{` — Process where one class acquires the properties (methods and fields) of another. With the use of inheritance the information is made manageable in a hierarchical order.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Polymorphism`}</code>{` — Ability of an object to take on many forms when a parent class reference is used to refer to a child class object.`}</li>
    </ul>
    <h3 {...{
      "id": "aspect-oriented-programming"
    }}>{`Aspect-oriented Programming`}</h3>
    <p>{`Aspect Oriented Programming a.k.a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AOP`}</code>{` is a programming paradigm that aims to increase `}<em parentName="p">{`modularity`}</em>{` by allowing the `}<em parentName="p">{`separation of cross-cutting concerns`}</em>{`.`}</p>
    <p>{`It does so by adding additional behaviour to existing code (an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`advice`}</code>{`) without modifying the code itself, instead separately specifying which code is modified via a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pointcut`}</code>{` specification, such as “log all function calls when the function’s name begins with ‘set’“. This allows behaviours that are not central to the business logic (such as logging) to be added to a program without cluttering the code, core to the functionality.`}</p>
    <p>{`One can think of AOP as a debugging tool or as a user-level tool. Advice should be reserved for the cases where you cannot get the function changed (user level) or do not want to change the function in production code (debugging).`}</p>
    <p>{`Standard terminology used in Aspect-oriented programming may include:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Cross-cutting concerns`}</code>{` - These are aspects of a program that affect other concerns.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Advice`}</code>{` - These are actions taken for a particular join point.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Pointcut`}</code>{` - These are set of one or more JoinPoint where an advice should be executed.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Aspect`}</code>{` - The combination of the pointcut and the advice is termed an aspect.`}</li>
    </ul>
    <h3 {...{
      "id": "reactive-programming"
    }}>{`Reactive Programming`}</h3>
    <p>{`Reactive programming is all about handling `}<em parentName="p">{`asynchronous streams of data`}</em>{`. It is concerned with data streams and the propagation of change.`}</p>
    <p>{`In reactive programming, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Observables`}</code>{` emit data, and send it to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`subscribers`}</code>{`. This can be seen as data being `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PUSHed`}</code>{` in reactive programming, as opposed to data being `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PULLed`}</code>{` in imperative programming, where you explicitly request data (iterating over collection, requesting data from the DB, etc).`}</p>
    <p>{`Building blocks of Reactive Programming:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Observables`}</code>{` - Observables are the data source/stream that can emit multiple values, just one, or none. They can also emit errors and can be infinite or finite, in which case they emit their completion event.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Subscribers`}</code>{` - Subscribers `}<strong parentName="li">{`subscribe`}</strong>{` to Observables. They consume/observe the data and also receive the errors and completion events from the Observable.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Operators`}</code>{` - They are used to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`create`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`transform`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`filter`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`combine`}</code>{` Observables.`}
        <ul parentName="li">
          <li parentName="ul">{`create - timers, ranges, from other data sources`}</li>
          <li parentName="ul">{`transform - map, buffer, group, scan, etc`}</li>
          <li parentName="ul">{`filter - filter, distinct, skip, debounce, etc`}</li>
          <li parentName="ul">{`zip, merge, combine latest, etc`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Schedulers`}</code>{` - It is a mechanism that allows us to easily add threading to our Observables and Subscribers.`}</li>
    </ul>
    <p>{`The `}<ExternalLink href="https://www.reactivemanifesto.org/" mdxType="ExternalLink">{`Reactive Manifesto`}</ExternalLink>{` is a document that defines the core principles of reactive programming.`}</p>
    <p><ExternalLink href="https://github.com/ReactiveX/RxJava" mdxType="ExternalLink">RxJava</ExternalLink> – <ExternalLink href="https://github.com/ReactiveX" mdxType="ExternalLink">Reactive Extensions</ExternalLink> for the JVM – a library for composing asynchronous and event-based programs using observable sequences for the Java VM.</p>
    <h3 {...{
      "id": "functional-reactive-programming"
    }}>{`Functional Reactive Programming`}</h3>
    <p>{` Reactive programming, with the concept of Functional programming is termed as functional reactive programming a.k.a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FRP`}</code>{`.`}</p>
    <p>{` FRP helps us to think about asynchronous programs (high-level abstractions), makes the flow of your application easier, and improves standard error handling (data structure = less code, less bugs). That is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reactive`}</code>{` part. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`functional`}</code>{` part is the reactive extensions. Rx allow you to manipulate and combine streams of events. Together, that is really the power of functional reactive programming: the ability to combine functions, operate, and transform the stream of events.`}</p>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ExternalLinksListContainer title={'References'} links={[{
      'name': 'What is meant by Programming Paradigms',
      'url': 'https://medium.com/@darrion/what-is-meant-by-programming-paradigms-9b965a62b7c7'
    }, {
      'name': 'Functional Programming Patterns With Java 8',
      'url': 'https://dzone.com/articles/functional-programming-patterns-with-java-8'
    }, {
      'name': '5 Things to Know About Reactive Programming',
      'url': 'https://developer.okta.com/blog/2018/09/21/reactive-programming-with-spring'
    }, {
      'name': 'Get Started with Reactive Programming in Spring',
      'url': 'https://dzone.com/articles/5-things-to-know-about-reactive-programming'
    }, {
      'name': 'Functional Reactive Programming in Java',
      'url': 'https://academy.realm.io/posts/droidcon-gomez-functional-reactive-programming/'
    }]} mdxType="ExternalLinksListContainer">
    </ExternalLinksListContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      