import React from "react"
import { graphql } from "gatsby"
import Layout from "~/components/layout"
import AuthorsPage from "~/containers/Authors"
import SEO from "~/components/seo"

const AuthorsIndexPage = (props: any) => {
  const { data } = props

  return (
    <Layout>
      <SEO
        title="Authors Page"
        description={data.site.siteMetadata.description}
      />
      <AuthorsPage />
    </Layout>
  )
}

export default AuthorsIndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
