import * as React from "react"
import TodosPageWrapper from "./style"
import Todos from "./Todos"

type TodosPageProps = {}

const TodosPage: React.FunctionComponent<TodosPageProps> = ({
  ...props
}) => {
  return (
    <TodosPageWrapper {...props}>
      <Todos />
    </TodosPageWrapper>
  )
}

export default TodosPage
