import * as React from 'react';
import { Collapse, Box, Button, useDisclosure } from "@chakra-ui/react";

const Collapsable = (props) => {

    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);

    const { isOpen, onToggle } = useDisclosure();

    return (
        <Box mb="1rem">
            <Collapse {...props} in={isOpen}>
                {props.children}
            </Collapse>
            <Button size="sm" onClick={onToggle} mt="1rem" variantColor="pink" variant="solid">
                Show {isOpen ? "Less" : "More"}
            </Button>
        </Box>
    );
};

export default Collapsable;