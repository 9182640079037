import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "API-First Development with Micronaut and Swagger",
  "description": "A detailed step by step guide on creating Micronaut service with API-first approach using Swagger",
  "date": "2021-11-01T15:46:37.121+10:00",
  "updated": "2021-11-01T15:46:37.121+10:00",
  "cover": "../../../images/blog-banners/springboot-docker-k8s-banner-1200x690.png",
  "category": "microservice",
  "tags": ["API-First", "Micronaut", "OpenAPI", "featured"],
  "keywords": ["Step by Step guide using Micronaut", "Micronaut", "Maven", "Java", "Java8", "Gradle", "API-First with Micronaut", "Micronaut API First", "Maven OpenAPI", "Gradle OpenAPI", "Micronaut openAPI", "Micronaut Swagger", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      