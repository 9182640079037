import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "Chained Exceptions in Java",
  "description": "In this article, we’ll have a very brief look at what Exception is and go in depth about discussing the chained exceptions in Java",
  "date": "2019-12-17T23:46:37.121Z",
  "updated": "2019-12-17T23:46:37.121Z",
  "cover": "../../../images/blog-banners/Java-banner-1200x690.png",
  "category": "testing",
  "tags": ["java", "exceptions", "featured"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`In this article, we’ll have a very brief look at what Exception is and go in depth about discussing the chained exceptions in Java.`}</p>
    <p>{`Simply put, an exception is an event that disturbs the normal flow of the program’s execution. Let’s now see exactly how we can chain exceptions to get better semantics out of them.`}</p>
    <h2 {...{
      "id": "chained-exceptions"
    }}>{`Chained Exceptions`}</h2>
    <p>{`Chained Exception helps to identify a situation in which one exception causes another Exception in an application.`}</p>
    <p><strong parentName="p">{`For instance, consider a method which throws an ArithmeticException`}</strong>{` because of an attempt to divide by zero but the actual cause of exception was an I/O error which caused the divisor to be zero.The method will throw the ArithmeticException to the caller. The caller would not know about the actual cause of an Exception. Chained Exception is used in such situations.`}</p>
    <p>{`This concept was introduced in JDK 1.4.`}</p>
    <p>{`Let’s see how chained exceptions are supported in Java.`}</p>
    <h2 {...{
      "id": "throwable-class"
    }}>{`Throwable Class`}</h2>
    <p>{`Throwable class has some constructors and methods to support chained exceptions. Firstly, let’s look at the constructors.`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Throwable(Throwable cause)`}</code>{` – Throwable has a single parameter, which specifies the actual cause of an Exception.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Throwable(String desc, Throwable cause)`}</code>{` – this constructor accepts an Exception description with the actual cause of an Exception as well.`}</li>
    </ul>
    <p>{`Next, let’s have a look at the methods this class provides:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`getCause()`}</code>{` method – This method returns the actual cause associated with current Exception.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`initCause()`}</code>{` method – It sets an underlying cause with invoking Exception`}</li>
    </ul>
    <h1 {...{
      "id": "example"
    }}>{`Example`}</h1>
    <p>{`Now, let’s look at the example where we will set our own Exception description and throw a chained Exception:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyChainedException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ArithmeticException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Top Level Exception."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initCause`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IOException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"IO cause."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ArithmeticException`}</span>{` ae`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Caught : "`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` ae`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Actual cause: "`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` ae`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getCause`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`    
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`As guessed, this will lead to:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Caught`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`java`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`lang`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`ArithmeticException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Top`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Level`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`
Actual`}</span>{` cause`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`java`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`io`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`IOException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` IO cause`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span></code></pre></div>
    <h2 {...{
      "id": "why-chained-exceptions"
    }}>{`Why Chained Exceptions?`}</h2>
    <p>{`We need to chain the exceptions to make logs readable. Let’s write two examples. First without chaining the exceptions and second, with chained exceptions. Later, we will compare how logs behave in both of the cases.`}</p>
    <p>{`To start, we will create a series of Exceptions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Throwable`}</span>{` cause`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cause`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Both Constructors`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, let’s start using the above exceptions in code examples.`}</p>
    <h3 {...{
      "id": "without-chaining"
    }}>{`Without Chaining`}</h3>
    <p>{`Let’s write an example program without chaining our custom exceptions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`printStackTrace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Leave not sanctioned."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Team Lead Upset"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In the example above, logs will look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`TeamLeadUpsetException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Team`}</span>{` lead `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Upset`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`46`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`34`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`29`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` in thread `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"main"`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`
  NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Leave`}</span>{` not sanctioned`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`37`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`29`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3 {...{
      "id": "with-chaining"
    }}>{`With Chaining`}</h3>
    <p>{`Next, let’s write an example with chaining our custom exceptions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
             `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Leave not sanctioned."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TeamLeadUpsetException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Team lead Upset."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Finally, let’s look at the logs obtained with chained exceptions:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` in thread `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"main"`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`NoLeaveGrantedException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Leave`}</span>{` not sanctioned`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` 
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`36`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`29`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Caused`}</span>{` by`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`TeamLeadUpsetException`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Team`}</span>{` lead `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Upset`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`howIsTeamLead`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`44`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    at `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`com`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`baeldung`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`chainedexception`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`exceptions`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MainClass`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getLeave`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`MainClass`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`java`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`34`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` more`}</code></pre></div>
    <p>{`We can easily compare shown logs and conclude that the chained exceptions lead to cleaner logs.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In this article, we had a look at chained exceptions concept.`}</p>
    <p>{`The implementation of all examples can be found in the Github project – this is a Maven-based project, so it should be easy to import and run as it is.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      