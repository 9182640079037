import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "~/components/layout"
import Author from "~/containers/Authors/Author"
import PostCard from "~/components/PostCard/postCard"
import SEO from "~/components/seo"
import { TagPostsWrapper, TagPageHeading, TagName } from "./templates.style"

const _ = require('lodash')

const AuthorTemplate = ({ pageContext, data }: any) => {
  
  const authorAlias = pageContext.author
  //console.log("====> AuthorTemplate :: pageContext :: ", authorAlias, "data :: ", data)

  var { edges, totalCount } = data.allMdx

  edges =  _.map(edges, function(o) {
    if (o.node.frontmatter.author.alias == authorAlias) return o;
  });
  edges = _.without(edges, undefined)

  const author = edges.length > 0 ? edges[0].node.frontmatter.author : undefined;

  //console.log("============> edges.length :: ", edges.length)

  return (
    <Layout>
      {author && (<SEO title={author.name} description={`A collection of ${edges.length} post`} />)}

      <TagPostsWrapper>
        <TagPageHeading>
          {author && (<Author {...author} totalPosts={edges.length}></Author>)}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.fields.slug}
            title={node.frontmatter.title}
            url={node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  )
}

export default AuthorTemplate

export const pageQuery = graphql`
  query {

    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { 
          templateKey: {eq: "article"}
          published: {eq: true}
        } 
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMM YYYY [</span>]")
            title
            author {
              order
              alias
              name
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 210
                    height: 210
                    quality: 100
                  )
                }
              }
              jobTitle
              description
              social {
                facebook
                github
                instagram
                linkedin
                twitter
              }
            }
            category
            tags
            description
          }
        }
      }
    }
  }
`
