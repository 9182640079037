import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"
import FeaturePost from "~/components/FeaturePost/featurePost"
import PromotionImage from "~/images/ad.png"
import {
  SidebarWrapper,
  SidebarWidger,
  WidgetTitle,
  TagItem
} from "./style"

type SidebarProps = {}

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            templateKey: {eq: "article"},
            published: {eq: true}
          }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 5
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM YYYY [</span>]")
              title
              description
              tags
              cover {
                childImageSharp {
                  gatsbyImageData(
                    width: 90
                    height: 90
                    quality: 100
                  )
                }
              }
            }
          }
        }
        group(field: frontmatter___tags) {
          totalCount
          fieldValue
        }
      }
    }
  `)

  const Posts = Data.allMdx.edges
  const Tags = Data.allMdx.group

  return (
    <SidebarWrapper>
      {/* TODO: Ad
      <SidebarWidger>
        <WidgetTitle>Promotion</WidgetTitle>
        <a
          href="https://1.envato.market/r1jdv"
          aria-label="Get StoryHub"
          target="_blank"
        >
          <img src={PromotionImage} alt="Get StoryHub" />
        </a>
      </SidebarWidger>
      */}
      <SidebarWidger>
        <WidgetTitle>Latest Post</WidgetTitle>
        {Posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.fields.slug
          //Random Placeholder Color
          const placeholderColors = [
            "#55efc4",
            "#81ecec",
            "#74b9ff",
            "#a29bfe",
            "#ffeaa7",
            "#fab1a0",
            "#e17055",
            "#0984e3",
            "#badc58",
            "#c7ecee",
          ]
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ]

          return (
            <FeaturePost
              key={node.fields.slug}
              title={title}
              image={
                node.frontmatter.cover == null
                  ? null
                  : node.frontmatter.cover.childImageSharp.gatsbyImageData
              }
              url={node.fields.slug}
              tags={node.frontmatter.tags}
              placeholderBG={setColor}
            />
          )
        })}
      </SidebarWidger>

      <SidebarWidger>
        <WidgetTitle>Tags</WidgetTitle>
        {Tags.map((tag: any) => (
          <TagItem key={tag.fieldValue}>
            <span>#</span>
            <Link to={`/tags/${_.kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} <span>({tag.totalCount})</span>
            </Link>
          </TagItem>
        ))}
      </SidebarWidger>
      {/*
      <SidebarWidger>
        <WidgetTitle>Instagram</WidgetTitle>
        {InstagramPhotos ? (
          <InstagramWrapper>
            {InstagramPhotos.map(({ node }: any) => {
              //Random Placeholder Color
              const placeholderColors = [
                "#55efc4",
                "#81ecec",
                "#74b9ff",
                "#a29bfe",
                "#ffeaa7",
                "#fab1a0",
                "#e17055",
                "#0984e3",
                "#badc58",
                "#c7ecee",
              ]
              const setColor =
                placeholderColors[
                  Math.floor(Math.random() * placeholderColors.length)
                ]

              return (
                <InstagramPhoto key={node.id}>
                  <a
                    href={`https://www.instagram.com/p/${node.id}`}
                    target="_blank"
                  >
                    <Img
                      fluid={node.localFile.childImageSharp.fluid}
                      alt="Instagram Photo"
                      backgroundColor={setColor}
                    />
                  </a>
                </InstagramPhoto>
              )
            })}
          </InstagramWrapper>
        ) : (
          ""
        )}
      </SidebarWidger>
      */}
    </SidebarWrapper>
  )
}

export default Sidebar
