import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "showSideBar": true,
  "author": "narramadan",
  "title": "Node, NPM, Yarn Command Cheatsheets",
  "description": "Syntax cheatsheets for quick reference to get things done with Node, NPM, Yarn.",
  "date": "2020-03-15T15:00:00.000Z",
  "updated": "2020-03-15T15:00:00.000Z",
  "cover": "../../../images/blog-banners/cheatsheet-banner-1200x690.png",
  "category": "cheatsheets",
  "tags": ["node", "npm", "yarn", "cheatsheets"],
  "keywords": ["node", "yarn", "npm", "node cheatsheets", "npm cheatsheets", "yarn cheatsheets", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "node"
    }}>{`Node`}</h2>
    <h2 {...{
      "id": "npm"
    }}>{`NPM`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm --version`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm info`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install`}</code>{` - Will install packages listed in the package.json file`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install --save pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pkg-name@1.0.0`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pkg-name --save-dev`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pkg-name --peer`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install pkg-name --optional`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install -g pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm update`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm uninstall pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm run script-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm init`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm pack`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm link`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm outdated`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm publish`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm run`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm cache clean`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm login`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm test`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install --production`}</code>{` - TODO`}</p>
    <h2 {...{
      "id": "yarn"
    }}>{`Yarn`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn version`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn info`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn install`}</code>{` - Will install packages listed in the package.json file`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add pgk-name@1.0.0`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add pkg-name --dev`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add pkg-name--peer`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add --optional`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn global add pkg-name`}</code>{` - Careful, yarn add global pkg-name adds packages global and pkg-name locally!`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn upgrade`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn remove pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn run script-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn init`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn clean`}</code>{` - Frees up space by removing unnecessary files and folders from dependencies`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn pack`}</code>{` - Creates a compressed gzip archive of the package dependencies`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn link`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn outdated`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn publish`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn run`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn cache clean`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn login (and logout)`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn test`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn --production`}</code>{` - Builds a dependency graph on why this package is being used`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn why pkg-name`}</code>{` - TODO`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn licenses ls`}</code>{` -  Inspect the licenses of your dependencies`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn licenses generate-disclaimer`}</code>{` - Automatically create your license dependency disclaimer`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      