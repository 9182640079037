import React, { useState } from "react"
import { Link } from "gatsby"
import { IoIosSearch, IoIosClose, IoLogoGithub } from "react-icons/io"
import { DrawerProvider } from "../Drawer/DrawerContext"
import Logo from "./Logo"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
import SearchContainer from "~/containers/SearchContainer/SearchContainer"
import HeaderWrapper, {
  NavbarWrapper,
  LogoWrapper,
  MenuWrapper,
  NavSearchButton,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper,
} from "./Navbar.style"

import applauseImage from "~/images/applause.svg"

type NavbarProps = {
  className?: string
}

const MenuItems = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Titbits",
    url: "/categories/titbits/",
  },{
    label: "Cheatsheets",
    url: "/categories/cheatsheets/",
  },
  {
    label: "Authors",
    url: "/authors",
  },
  {
    label: "About",
    url: "/about",
  },
  /*
  {
    label: "Contact",
    url: "/contact",
  }
  */
]

const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: "",
  })

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    })
  }

  // Add all classs to an array
  const addAllClasses = ["header"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <HeaderWrapper className={addAllClasses.join(" ")} {...props}>
      <NavbarWrapper className="navbar">
        <DrawerProvider>
          <MobileMenu items={MenuItems}/>
        </DrawerProvider>
        <LogoWrapper>
          <Logo/>
        </LogoWrapper>
        <MenuWrapper>
          <Menu items={MenuItems} />
        </MenuWrapper>
        <NavSearchButton
          as="a" 
          href="/post-stats"
        >
          <img width="23px" height="23px" src={applauseImage}/>
        </NavSearchButton>
        <NavSearchButton
          as="a" 
          href="https://github.com/2much2learn"
          target="_blank"
        >
          <IoLogoGithub size="23px" />
        </NavSearchButton>
        <NavSearchButton
          type="button"
          aria-label="search"
          onClick={toggleHandle}
        >
          <IoIosSearch size="23px" />
        </NavSearchButton>
      </NavbarWrapper>

      <NavSearchWrapper className={state.toggle === true ? "expand" : ""}>
        <NavSearchFromWrapper>
          <SearchContainer />
          <SearchCloseButton
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </SearchCloseButton>
        </NavSearchFromWrapper>
      </NavSearchWrapper>
    </HeaderWrapper>
  )
}

export default Navbar
