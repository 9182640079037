import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "author": "gyayakdoshi",
  "title": "Configuring Centralized logging with Kafka and ELK stack",
  "description": "A detailed step by step walk through on configuring Centralized logging with Kafka and ELK stack",
  "date": "2020-06-06T10:46:37.121Z",
  "updated": "2020-06-06T19:46:37.121Z",
  "cover": "../../../images/blog-banners/kafka-elk-banner-1200x690.png",
  "category": "setup",
  "tags": ["Kafka", "ELK", "Centralized Logging", "Setup", "featured"],
  "keywords": ["Kafka", "ELK", "Kibana", "Centralized logging", "Kafka logging", "Kafka centralized logging", "docker compose centralized logging", "docker kafka elk", "gyayakdoshi", "narramadan", "Madan Narra", "Gyayak Doshi"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const ExternalLinksListContainer = makeShortcode("ExternalLinksListContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article_june13_centralized-logging-with-kafka-and-elk-stack" mdxType="GithubRepo">centralized-logging-with-kafka-and-elk-stack</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`In real world, any production incident soon turns out to be scary when we have to go through `}<font style={{
        "color": "red"
      }}><strong parentName="p">{`hell lot`}</strong></font>{` of logs.`}</p>
    <p>{`Some times it will be very difficult to replicate the issue by analyzing data is scattered across different log files. This is when Centralized Logging comes to rescue to stitch together all the logs into one place.`}</p>
    <p>{`But this comes with a challenge if centralizing logging tool is not configured to handle `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Log-bursting`}</code>{` situations. Log-bursting event causes saturation, log transmission latency and sometimes log loss which should never occurs on production systems.`}</p>
    <p>{`To handle such situation, we can publish logs to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Kafka`}</code>{` which acts as a buffer in front of Logstash to ensure resiliency. This is one of many best ways to deploy the ELK Stack to reduce log overload.`}</p>
    <p>{`In this article, We shall orchestrate complete solution using docker to configure Kafka with ELK stack to enable centralized logging capabilities.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3f6beab9551cb0ec320b5be5f03d78cf/2cefc/IMG_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.23312883435584%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEBElEQVQ4y31SbUyTZxQ9b6F8jQJuQFBYJoOJA2cyN9xGFmfiRhhhAcEpOiTIRpZttqKmtQQVQaCjKBhwUXQq4MIQCDj8QNPVTRwLBfmYFmFSRzWF0dJ2bQMWaPvepe9w/7abnOTJfZ577r3nPDjWaUTtj0745fTgpMIZUnXdHnlauRhXq6TlnyctD5AcKFgrFotXi8Xi10QiUaBcLkdGRgb+M0paJr0qr82dP9ph6ZF1WvXyq7Y5WaeNjnfZLWXtM++L7hI6uu551lVV8d3vo6Oj8b9R0GyQF7db6FCrifLrH9O+xsdU0GxwFF+2UWGr+dYUERC13huAB4BQgZ8fPHg8rpYBEARADIAFUO1OCi/oxoX1kyRp0tsrr5odxW0Gx8FvNU7JqVHaX9XfvQNYuYPvvWYXw6wGsB9AGoAkAIkAvLBEiqWOyKoZLc2qHactx9WUVTNCmdVqSpUN0rbKYcUnexsDE4hAn9Uw2/8p3gggls/nrxUIBPEvRkSsiIyJCY5PSBAQEUNEPOxudSGltHfTR7IhdXLZYGeKbKghrVy1/r2Cm75bDyuQ9Bsxpa++CwLg7cUNhCNHitDQeA6mPhXILQkQAiBg6QwIm2axQapMyG4mpFZpkFqmwjuiNmRWqJj8nwkx0fEgPh+y8nIPdxGxbMzkE+2h+cWFvazNVuiYnZW6iKSsy5WDtz49hcwTo8yGwjuQqQiZ32h56V8P8FKP/oLNsn6kVwxyTZuamjClGfGc0T7EX7qJ7K6fmumhZpRmjCaanJoinU5HZrNZj8QSFRJL+vBBSR9vo+QGdt8llHw3yK3iBsuyYF0LYFkXem8rebeuX0GPUvH6lY4L9VabuYYWFs865+11LpY963K5DmJijRSPYiXMH7EH0LvqK8T7RXATxa0Kx8Dlon+J3bAaRnDxTC0zPjyA7xUXYey7/UzDZQAEnNGED0H++SDkwYAEBlgSNjYc/cUZbue8n2Fuopvvu+lNjqS77qTnGJl4wznS0AcN/cvmiTw5lwk7Qb6iVwg7g8bwhvtXeSA4OMzNSURRzqcz43Z10ZjLpp4mokoA/s+vDHsJEHjRAELpTlQfOXV6IsriphVVn8G+zV80FpaeXgciXPIPeEESGhoOwJec9PaNzhbKPbyHHlmMRA5HO4AV+ZERkUOAzw+5L8cd28XYzivO0SWjWdhiswPBQFBgXnpaSN7Wdb8CXr8HCXwoOdmH081qi7+n1VLutZs0bLYSORbbuLxwz3MpADM/qo0Rfim1JtbKaduQQvjxfSUQFhXpuxLwDwMCkL2FVx8oQAsRnOoRbmWL2fzA8KfuvlE//WTR6ax4qh6BhYhHvT3u+zCDfqpHoxnXzZhM26f1evwNgfEqvq58QAIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 2",
            "title": "IMG 2",
            "src": "/static/3f6beab9551cb0ec320b5be5f03d78cf/a6d36/IMG_2.png",
            "srcSet": ["/static/3f6beab9551cb0ec320b5be5f03d78cf/222b7/IMG_2.png 163w", "/static/3f6beab9551cb0ec320b5be5f03d78cf/ff46a/IMG_2.png 325w", "/static/3f6beab9551cb0ec320b5be5f03d78cf/a6d36/IMG_2.png 650w", "/static/3f6beab9551cb0ec320b5be5f03d78cf/e548f/IMG_2.png 975w", "/static/3f6beab9551cb0ec320b5be5f03d78cf/3c492/IMG_2.png 1300w", "/static/3f6beab9551cb0ec320b5be5f03d78cf/2cefc/IMG_2.png 1400w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "technology-stack"
    }}>{`Technology stack`}</h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://kafka.apache.org/" mdxType="ExternalLink">Apache Kafka</ExternalLink> - Collects logs from application & queues it.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://github.com/yahoo/CMAK" mdxType="ExternalLink">Kafka Manager</ExternalLink> - A web-based management system for Kafka developed at Yahoo
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.elastic.co/logstash" mdxType="ExternalLink">Logstash</ExternalLink> - aggregates the data from the Kafka topic, processes it and ships to Elasticsearch
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.elastic.co/what-is/elasticsearch" mdxType="ExternalLink">Elasticsearch</ExternalLink> - indexes the data.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.elastic.co/what-is/kibana" mdxType="ExternalLink">Kibana</ExternalLink> - for analyzing the data.
      </li>
    </ul>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Solution tried out in this article is setup and tested on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Mac OS`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ubuntu OS`}</code>{`. If you are on windows and would like to make your hands dirty with Unix, then I would recommend going through `}<ExternalLink href="https://2much2learn.com/configure-java-dev-env-on-dual-boot-ubuntu-with-windows-10/" mdxType="ExternalLink">{`Configure development environment on Ubuntu 19.10`}</ExternalLink>{` article which has detailed steps on setting up development environment on Ubuntu.`}</p>
    <p>{`At the least, you should have below system requirements and tools installed to try out the application:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Docker`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Docker Compose`}</code></li>
      <li parentName="ul"><strong parentName="li">{`System Requirements`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Processor: 4 Core`}</li>
          <li parentName="ul">{`Memory: 6 GB RAM`}</li>
          <li parentName="ul">{`Swap: 2 GB.`}</li>
          <li parentName="ul">{`Disk image size: 100 GB`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "configuring-kafka--kafka-manager"
    }}>{`Configuring Kafka & Kafka Manager`}</h2>
    <p>{`Kafka Docker images are published by multiple authors. Below are two which are trusted by the most`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/r/wurstmeister/kafka" mdxType="ExternalLink">wurstmeister/kafka</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/r/spotify/kafka" mdxType="ExternalLink">spotify/kafka</ExternalLink>
      </li>
    </ul>
    <p>{`For this article, we are using the image published by `}<strong parentName="p">{`Spotify`}</strong>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spotify/kafka`}</code></p>
    <h3 {...{
      "id": "why-did-we-choose-spotifykafka-image-"
    }}>{`Why did we choose spotify/kafka image ?`}</h3>
    <p>{`The main hurdle of running Kafka in Docker is that it depends on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Zookeeper`}</code>{`. Compared to other Kafka docker images, this image runs both Zookeeper and Kafka in the same container. `}</p>
    <p>{`This results to:`}</p>
    <ul>
      <li parentName="ul">{`No dependency on an external Zookeeper host, or linking to another container`}</li>
      <li parentName="ul">{`Zookeeper and Kafka are configured to work together out of the box`}</li>
    </ul>
    <p>{`For seamless orchestration, we shall bootstrap all the required containers with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Compose`}</code>{` for this setup.`}</p>
    <p>{`Below is the snapshot for configuring `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Kafka`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Kafka Manager`}</code>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Spotify Kafka image in docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kafka Server & Zookeeper Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafkaserver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"spotify/kafka:latest"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafka
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`hostname`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkaserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 2181`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2181`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9092`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9092`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ADVERTISED_HOST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkaserver
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ADVERTISED_PORT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9092`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Kafka Manager`}</code>{` is a tool from Yahoo for managing Apache Kafka cluster. Below is snapshot of its configuration in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Kafka Manager image in docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kafka Manager docker image, it is a web based tool for managing Apache Kafka.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafka_manager`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mzagar/kafka-manager-docker:1.3.3.4"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkamanager
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#configures the kafka manager docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9000`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9000`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links kafka_manager container to kafkaserver container to communicate.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkaserver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`kafkaserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ZK_HOSTS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kafkaserver:2181"`}</span></code></pre></div>
    <h2 {...{
      "id": "configuring-elasticsearch"
    }}>{`Configuring Elasticsearch`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Elasticsearch`}</code>{` docker-compose simple configuration in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{` something like this`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Elasticsearch image in docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Elasticsearch Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`elasticsearch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/elasticsearch/elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` elasticsearch
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9200`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9200`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9300`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9300`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet`}</code></pre></div>
    <h2 {...{
      "id": "configuring-kibana"
    }}>{`Configuring Kibana`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Kibana`}</code>{` docker-compose simple configuration in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{`  something like this`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Kibana image in docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kibana Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kibana`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/kibana/kibana`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kibana
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 5601`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5601`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links kibana container & elasticsearch container to communicate`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`elasticsearch
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# You can control the order of service startup and shutdown with the depends_on option.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'elasticsearch'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <h2 {...{
      "id": "configuring-logstash"
    }}>{`Configuring Logstash`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Logstash`}</code>{` docker-compose simple configuration in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{` something like this`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Logstash image in docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Logstash Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`logstash`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/logstash/logstash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` logstash
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links elasticsearch container & kafkaserver container  & logstash container to communicate`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`elasticsearch
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkaserver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`kafkaserver
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# You can control the order of service startup and shutdown with the depends_on option.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'elasticsearch'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'kafkaserver'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Mount host volumes into docker containers to supply logstash.config file`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/private/config-dir:/usr/share/logstash/pipeline/'`}</span></code></pre></div>
    <p>{`Next, we will configure a Logstash pipeline that pulls our logs from a Kafka topic, process these logs and ships them on to Elasticsearch for indexing.`}</p>
    <h3 {...{
      "id": "setup-logstash-pipeline"
    }}>{`Setup Logstash pipeline`}</h3>
    <p>{`Pipelines are configured in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`logstash.conf`}</code>{`. Below is brief notes on what we are configuring:`}</p>
    <ul>
      <li parentName="ul">{`Accept logs from Kafka topics for the configured kafka cluster`}</li>
      <li parentName="ul">{`Filter messages and apply transformation rules as per requirement. For this setup, we are converting UTC datetime to a specific timezone when persisted to index.`}</li>
      <li parentName="ul">{`Log messages captured from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`SIT`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`UAT`}</code>{` environments are captured to their individual index.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`logstash.conf`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`# Plugin Configuration. This input will read events from a Kafka topic.
# Ref Link - https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//www.elastic.co/guide/en/logstash/current/plugins-inputs-kafka.html`}</span>{`

input `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  kafka `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    bootstrap_servers => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kafkaserver:9092"`}</span>{`
    topics => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"sit.catalogue.item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"uat.catalogue.item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    auto_offset_reset => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"earliest"`}</span>{`
    decorate_events => `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

# Filter Plugin. A filter plugin performs intermediary processing on an event.
# Ref Link - https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//www.elastic.co/guide/en/logstash/current/filter-plugins.html`}</span>{`

filter `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  json `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    source => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"message"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    remove_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
      `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"[message]"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  if (!`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`latency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` or `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`latency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`==`}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span>{`) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      add_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        latency => `}<span parentName="code" {...{
            "className": "token number"
          }}>{`-1`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  date `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    match => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    timezone => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Europe/London"`}</span>{`
    target => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"app_ts"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    remove_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  if (`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`@metadata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`kafka`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`topic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` == `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"uat.catalogue.item"`}</span>{`) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      add_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        indexPrefix => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"uat-catalogue-item"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`else if (`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`@metadata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`kafka`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`topic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` == `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"sit.catalogue.item"`}</span>{`) `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      add_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        indexPrefix => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"sit-catalogue-item"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`else`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      add_field => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        indexPrefix => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"unknown"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

#An output plugin sends event data to a particular destination. Outputs are the final stage in the event pipeline.
# Ref Link - https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`//www.elastic.co/guide/en/logstash/current/output-plugins.html`}</span>{`
output `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  elasticsearch `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    hosts => `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"elasticsearch:9200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    index => `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"%{[indexPrefix]}-logs-%{+YYYY.MM.dd}"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Below is detailed explanation of different bits configured in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`logstash.conf`}</code>{` file:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`decorate_events`}</code>{`: Metadata is only added to the event if the decorate_events option is set to true (it defaults to false).`}</li>
    </ul>
    <p>{`Please note that @metadata fields are not part of any of your events at output time. If you need these information to be inserted into your original event, you’ll have to use the mutate filter to manually copy the required fields into your event.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`decorate_events `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mutate`}</code>{`: The mutate filter allows you to perform general mutations on fields. You can rename, remove, replace, and modify fields in your events. Below is example of mutation on json field `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`latency`}</code>{`.
If the latency is not provided from the source application we can set it to default  `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`-1`}</code></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`latency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`or`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`latency`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`""`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    add_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      latency `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`remove_field => message`}</code>{`: Logstash output produces  `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`message`}</code>{` field. Storing this raw `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`message`}</code>{` field to elasticsearch is only adding unused or redundant data. so we can remove `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`message`}</code>{` field using  below filter`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  remove_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"[message]"`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`Date Filter`}</code>{` plugin: The date filter is used for parsing dates from fields, and then using that date or timestamp as the logstash timestamp for the event.In below example we are using `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`time_stamp`}</code>{` field from JSON (which is in UTC time formate provided by source application) & converting it to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`"Europe/London"`}</code>{` timezone.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`date `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  match `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"time_stamp"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  timezone `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"Europe/London"`}</span></span>{`
  target `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"app_ts"`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  remove_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"time_stamp"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`index-prefix`}</code>{` field: We are creating index prefix `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`"uat-catalogue-item"`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`"sit-catalogue-item"`}</code>{` based on the metadata info provided by kaka broker. As creating multiple cluster for POC will be bit heavier. so we are using same cluser to demonstrat two enviroment.
we  are using below meta field  to identify kafka topic for diffrent enviroment.`}</li>
    </ul>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`[@metadata][kafka][topic]`}</code>{` : original Kafka topic name from where the message was consumed.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`If message consumed from uat kafka topic add prefix `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"uat-catalogue-item"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`kafka`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`topic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"uat.catalogue.item"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    add_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      indexPrefix `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"uat-catalogue-item"`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`If message consumed from sit kafka topic add prefix `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"sit-catalogue-item"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`kafka`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`topic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"sit.catalogue.item"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    add_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      indexPrefix `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"sit-catalogue-item"`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`If message consumed from any other kafka topic add index prefix `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"unknown"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  mutate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    add_field `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      indexPrefix `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"unknown"`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "note-update-volumes-in-docker-composeyml"
    }}>{`Note: Update volumes in docker-compose.yml`}</h3>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure log stash pipeline`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'LOCATION_OF_LOG_STASH_CONF_FILE:/usr/share/logstash/pipeline/'`}</span></code></pre></div>
    <p>{`Syntax for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LOCATION_OF_LOG_STASH_CONF_FILE`}</code>{` varies based on OS.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`MAC/Linux OS`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`volumes
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/private/config-dir:/usr/share/logstash/pipeline/'`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`WINDOWS`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}>{`volumes
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'//C/Users/config-dir:/usr/share/logstash/pipeline/'`}</span></code></pre></div>
    <h2 {...{
      "id": "bit-insights-on-docker-networks"
    }}>{`Bit insights on Docker Networks`}</h2>
    <p>{`Docker networking utilizes already existing Linux Kernel Networking features like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iptables`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`namespaces`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bridges`}</code>{` etc. With Docker Networking, we can connect various docker images running on same host or across multiple hosts. By default, three network modes are active in Docker.`}</p>
    <ol>
      <li parentName="ol">{`Bridge`}</li>
      <li parentName="ol">{`Host`}</li>
      <li parentName="ol">{`Null`}</li>
    </ol>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Bridge Network driver`}</code>{` provides single host networking capabilities. By default containers connect to Bridge Network. Whenever container starts, it is provided an internal IP address. All the containers connected to the internal bridge can now communicate with one another. But they can’t communicate outside the bridge network.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Use bridge network for all the container, keeping all the container in same network will simplify the communication between the container.`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafkanet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`driver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bridge`}</code></pre></div>
    <h2 {...{
      "id": "updating-host-file"
    }}>{`Updating host file`}</h2>
    <p>{`And the final step is to configure the links that are registered between the containers in docker-compose.yml file. As we are setting up everything on the same system, we need to resolve the links to localhost i.e `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`127.0.0.1`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token number"
          }}>{`127.0`}</span>{`.0.1 kafkaserver
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`127.0`}</span>{`.0.1 elasticsearch`}</code></pre></div>
    <h2 {...{
      "id": "putting-it-all-together"
    }}>{`Putting it all together`}</h2>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kafka Server & Zookeeper Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafkaserver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"spotify/kafka:latest"`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafka
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`hostname`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkaserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 2181`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2181`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9092`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9092`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ADVERTISED_HOST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkaserver
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ADVERTISED_PORT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9092`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kafka Manager docker image, it is a web based tool for managing Apache Kafka.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafka_manager`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"mzagar/kafka-manager-docker:1.3.3.4"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kafkamanager
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#configures the kafka manager docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9000`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9000`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links kafka_manager container to kafkaserver container to communicate.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkaserver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`kafkaserver
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ZK_HOSTS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kafkaserver:2181"`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Elasticsearch Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`elasticsearch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/elasticsearch/elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` elasticsearch
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9200`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9200`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 9300`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9300`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Kibana Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kibana`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/kibana/kibana`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` kibana
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Make a port available to services outside of Docker`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 5601`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5601`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links kibana container & elasticsearch container to communicate`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`elasticsearch
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# You can control the order of service startup and shutdown with the depends_on option.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'elasticsearch'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Logstash Docker Image`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`logstash`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.elastic.co/logstash/logstash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`6.4.0
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`container_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` logstash
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# It Links elasticsearch container & kafkaserver container  & logstash container to communicate`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` elasticsearch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`elasticsearch
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkaserver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`kafkaserver
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configures docker image to run in bridge mode network`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` kafkanet
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# You can control the order of service startup and shutdown with the depends_on option.`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`depends_on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'elasticsearch'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'kafkaserver'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Mount host volumes into docker containers to supply logstash.config file`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/private/config-dir:/usr/share/logstash/pipeline/'`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Use bridge network for all the container, keeping all the container in same network will simplify the communication between the container.`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kafkanet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`driver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bridge
`}</code></pre></div>
    <h2 {...{
      "id": "launching-the-services"
    }}>{`Launching the services`}</h2>
    <p>{`Once the docker-compose.yml file is ready, open your favorite terminal in the folder which contains it and run:`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Launch services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up`}</code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/20c9126c0f3c25c38271cf4f58da8caf/2b984/IMG_14.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "36.19631901840491%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABYlAAAWJQFJUiTwAAABDElEQVQoz62Q6Y6DMAyE8/6vV24IouUIdzjKLZiVvSpS9/da+jQmSoaxhZQ2HMdG/IqRlgmKuoBuNXSnofUvXddhWRbM88y6riv3xLZtDPXTNEFI6cPzXNRVjbIv0M89tnX7enCeJ/Z9x3EcX/qB7tBPqBdN88I4DlAqg23bsCyLcRwHnuchCALEcQylFNI0RZZlnLosS9ZxHO9JKL2YpxRUSqUwTROGYcD3fYRhyERRhOfzyUqGeZ6jbVvUdc0mf0v0b4UTQJYleDwMNqRkZCqlhOu6nJIMKA3tiZQYhoFTffZKiP7dsKHWLZIkuVMURcFK33ROI9NZVVU8LqVsmua+R+a0S3FdF/6zfgCO0ROjV2datAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 14",
              "title": "This should result something as below in your console.",
              "src": "/static/20c9126c0f3c25c38271cf4f58da8caf/a6d36/IMG_14.png",
              "srcSet": ["/static/20c9126c0f3c25c38271cf4f58da8caf/222b7/IMG_14.png 163w", "/static/20c9126c0f3c25c38271cf4f58da8caf/ff46a/IMG_14.png 325w", "/static/20c9126c0f3c25c38271cf4f58da8caf/a6d36/IMG_14.png 650w", "/static/20c9126c0f3c25c38271cf4f58da8caf/e548f/IMG_14.png 975w", "/static/20c9126c0f3c25c38271cf4f58da8caf/3c492/IMG_14.png 1300w", "/static/20c9126c0f3c25c38271cf4f58da8caf/2b984/IMG_14.png 2164w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`This should result something as below in your console.`}</figcaption>{`
  `}</figure></p>
    <p>{`Fire up a new terminal & check status of the containers`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Check status`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ece3717e3cae08d604e299cb6c8b8e62/bc81a/IMG_8.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "12.269938650306749%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAb0lEQVQI1z2MywrFIAxE+/+f57JgXbQqPhEfoCjMJVncQJgJh5xLCIGYIpRSkFJy5pxBE0LAox7EGGGtRa2Ve+8d931Da429N8458N6jlILr/V4uzjkYY1hGD2stzpQSxhgsm3P+GQmI0U2MtrWGH18alwyhQ5HPAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 8",
              "title": "This should result something as below in your console.",
              "src": "/static/ece3717e3cae08d604e299cb6c8b8e62/a6d36/IMG_8.png",
              "srcSet": ["/static/ece3717e3cae08d604e299cb6c8b8e62/222b7/IMG_8.png 163w", "/static/ece3717e3cae08d604e299cb6c8b8e62/ff46a/IMG_8.png 325w", "/static/ece3717e3cae08d604e299cb6c8b8e62/a6d36/IMG_8.png 650w", "/static/ece3717e3cae08d604e299cb6c8b8e62/e548f/IMG_8.png 975w", "/static/ece3717e3cae08d604e299cb6c8b8e62/3c492/IMG_8.png 1300w", "/static/ece3717e3cae08d604e299cb6c8b8e62/bc81a/IMG_8.png 2862w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`This should result something as below in your console.`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "play-around-with-the-provisioned-services-to-configure-centralized-logging-capabilities"
    }}>{`Play around with the provisioned services to configure Centralized Logging Capabilities`}</h2>
    <ul>
      <li parentName="ul">{`Access `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://localhost:9200/`}</code>{` in browser to verify if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Elasticsearch`}</code>{` is up & running`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "436px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a5289ec60b3cf6e111a6fb6e91474a32/8574c/IMG_24.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "71.16564417177914%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByUlEQVQ4y52SuW5iQRRE+RoSMgiISSAgJOE3LSQW8QsIEGLfzb5vZjemrHOlhxiP7ZGmpaL70d11q+q2y+fzye12G/x+vzwej4LBoMLhsKLRqCKRiEKhkAKBgLxe7z/henl5UTKZVCKRUCwWUzweVzqdNqRSKQN7Dtj/DS5J2u/3Oh6PLHW5XLTdbrXb7fQ/wwVBsVhUoVBQv99XLpdTuVzWYrFQtVpVqVRSo9GwNeeGw6HW67UBER8fH7rdbg+43t/fVa/XjaTZbOr19VWr1cqqQTqfz9Xr9QwUZO50OjbjjHG/3w2mkB8I8vm8KpWKVV0ulxqNRmb9cDiIos6Fr+OZjNkIN5uNWUOpM5PhZDKxNeR8Y8+5+B3xQyF5oA7LmUxGg8HADpArhOPx2JSfz2ddr1drHDidTobnQg/LDiHWyYj/UEYzyJc1iqfTqeVKvt1u13KlyB+E2IGQiyhCWa1Ws+pcbrVaRvDT+CtDwie7bDZrxABivh2FPJ12u22F2MMBQpzuE4dlyNuhKXSWrFCCNWbeHFZZQ8AZLM9mM3sydJ8ZQfA8HjZVuMgFp0lYhfDt7c3+h5Rz7P1m3fUc6Heggz/tfQUKPwGpfPEwMd5cVwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 24",
              "title": "Elasticsearch is UP",
              "src": "/static/a5289ec60b3cf6e111a6fb6e91474a32/8574c/IMG_24.png",
              "srcSet": ["/static/a5289ec60b3cf6e111a6fb6e91474a32/222b7/IMG_24.png 163w", "/static/a5289ec60b3cf6e111a6fb6e91474a32/ff46a/IMG_24.png 325w", "/static/a5289ec60b3cf6e111a6fb6e91474a32/8574c/IMG_24.png 436w"],
              "sizes": "(max-width: 436px) 100vw, 436px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Elasticsearch is UP`}</figcaption>{`
  `}</figure></p>
    <ul>
      <li parentName="ul">{`Access `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://localhost:5601/`}</code>{` in browser to verify if `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Kibana`}</code>{` is up & running`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9897b7b91342e2a16b8ff061d5e8ae72/2c960/IMG_25.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "59.50920245398773%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACV0lEQVQoz51Ty2oUQRRtIZ+gIOo/uPQXFB+4EQV3QRmR4CIkERSRLF0IRmJmshglPhbiRkX/QXysBCVisnBmumf6VTVdXV3V1VV1pGrGzt6CC/f0vX3uPfdWBaeureHE1WUsrj/Ey2d9dHs9PNnawubmJnq9nsdb3a73e3Pfxbvdrjfn97a30e8/xcbGYwQnH7xG0HmEux8/w508JxCMoVYK/3OCY4t3EJzrYLn/xn9IKoE/pYBuGlilsF8U+BCG3reqxs+c40vMAKO9/aAC30kFwKLkHMHxzjqCC0tY2Xk767AS2COkrbhLCrza22/xr4zia5TMgLX4Fkb4NAw9oaxrBEev30dwfgmrO+98zigMEQ4GKMsSqmmQRBHy4RCsLKG18TgbR/5nKSXi0cibkBKNUggOL95DcPEWVucdEsYR0wLaGI+ZrJFxAWMMrLWYVhKkkh4ba0H/YWtRuw4XLq8gOHOjJRSqgagVGmOgjUWtDUSjW8nKYRfXxudUSkPO4weEpzvtDHlVgRICVglwIVFw4X2X7CTBGJSMoeAVSlmDVdLLb5oGQogDwtsv3gNaYTiegBCCNE0wSjM0xrYLIIxhfxQiy3OkSYI4TTGOE4wnsSdTboYLV+aEz2dLGUwSJEkCSqf4XXCM0hw5pbC6QSYkdpMUaZphSilIJTAhFINoDOburpN86NKyJ2yXMmU+qPVsLq6yM6M1ylphzARIwbx8rg3SUiAl1D8ET3jEbfnsTazNCSMyBaEUnHMwpZFNi7a6VA1IyZFmGbi7VsaCshKTOPFFHeFf++tQBiygTYAAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 25",
              "title": "Kibana is UP",
              "src": "/static/9897b7b91342e2a16b8ff061d5e8ae72/a6d36/IMG_25.png",
              "srcSet": ["/static/9897b7b91342e2a16b8ff061d5e8ae72/222b7/IMG_25.png 163w", "/static/9897b7b91342e2a16b8ff061d5e8ae72/ff46a/IMG_25.png 325w", "/static/9897b7b91342e2a16b8ff061d5e8ae72/a6d36/IMG_25.png 650w", "/static/9897b7b91342e2a16b8ff061d5e8ae72/e548f/IMG_25.png 975w", "/static/9897b7b91342e2a16b8ff061d5e8ae72/3c492/IMG_25.png 1300w", "/static/9897b7b91342e2a16b8ff061d5e8ae72/2c960/IMG_25.png 2870w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kibana is UP`}</figcaption>{`
  `}</figure></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Access `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`http://localhost:9000`}</code>{` in browser to manage Kafka Cluster`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Follow series of steps to configure the cluster`}</p>
      </li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "588px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ee04690c3190fd74a6897290fc7f297e/9bbaf/IMG_15.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.171779141104295%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5UlEQVQoz5WSSaqGQAyEvf9lPIorBYeFoCL6KyrOYz2+BsGFi2dDkc7YqU4s27ZV17WSJFGapgbjOKqqKpVlqXmeP8FyHEfLsqhtW3Vdp6ZptK6rhmFQ3/fatu0TLKrSEcq+7wYUvAO4f4H1K0sVRWGKfqX3Svm6LnHO8zQdQZ+XkP/FHW8oh2Eo3/cVBIEZAp3ydzf4yze8+aZpkhXHsTzPk+u6hjpGWudF5FsxYvAjn3ZDmTXBwYE+1PM8VxRFyrJMx3EYGxIQw0bgp0v0O4aBWqwKBZ+TJZCkt7UhiU7IQ6I/fX9QArcbTT365AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 15",
              "title": "Kafka Manager is UP",
              "src": "/static/ee04690c3190fd74a6897290fc7f297e/9bbaf/IMG_15.png",
              "srcSet": ["/static/ee04690c3190fd74a6897290fc7f297e/222b7/IMG_15.png 163w", "/static/ee04690c3190fd74a6897290fc7f297e/ff46a/IMG_15.png 325w", "/static/ee04690c3190fd74a6897290fc7f297e/9bbaf/IMG_15.png 588w"],
              "sizes": "(max-width: 588px) 100vw, 588px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kafka Manager is UP`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "584px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4535c3a2f54958ec899684abce19a4d6/e05eb/IMG_16.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.012269938650306%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABU0lEQVQoz31SXUvDMBTt/8c3ERHm4170xYEV/ECcvjiorFPBCiNCN2ftxra2adokbY/cjMiYbhcOSc49vTm5t47ruuh2u4iiCKPRCIwxs48mEzzf34H1n5B9R+BCIBcFimI3nPF4jGS5hNYaZVkaspQSmr0hDQZIvkI0nwxIYwANFOW03gqnrmtMp1PM5/PfgkqW6Nz6OGrf4Lh9hVb7GvtnfZw+fqAQudEIIf53qJRCkiTI8xxSSuOu0grnHkPr8hWHFy84cAfY6/g4eXg3DuUOOOsH65Bur6RALQWgBKALg0atcttgHKZpCs/z0Ov14Pu+eTq5zTgH5zmyjCNdA+crkMbuCVmWmdWhqnEcYzabYbFYGCFxJCDY8yZsoT89pP7Rr0LPbZoGNCRKDIdDBEFgHBNvp1hVFajv9E0YhkZveTNlElNQgkhaCTbsJZuwscn/ABqdrRZBs/FKAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 16",
              "title": "Click on Cluster & Add cluster",
              "src": "/static/4535c3a2f54958ec899684abce19a4d6/e05eb/IMG_16.png",
              "srcSet": ["/static/4535c3a2f54958ec899684abce19a4d6/222b7/IMG_16.png 163w", "/static/4535c3a2f54958ec899684abce19a4d6/ff46a/IMG_16.png 325w", "/static/4535c3a2f54958ec899684abce19a4d6/e05eb/IMG_16.png 584w"],
              "sizes": "(max-width: 584px) 100vw, 584px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Click on Cluster & Add cluster`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "569px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3180751ee6578c3e4768625e0d446e94/854dc/IMG_17.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.37423312883436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABF0lEQVQoz52R607DMAyF8/7vNWmClQoBYtWKtrLe6DYlaZu0aS4HJWyDny2RPtlS7GPnhEghIKUM9H2PcRwxDEPIhRCzudWT4kQRbTZYr9eIogir1QpxHN8HLCEI9nIAYyxAKUXTNLhcLou2+wvxzV6McwbnHG7HWrsYrTXIdrtFmqZIkgRKqXBhjPk3pG1bcM7ho5/gt/wVnSl+rQ8bFkUefCvLAlVV4jPLUNfV9eFuAT82kcfnFA9xgvhtj6fXPbKqQ95IfDGNLzqP2kc2QSkNcjhWyI418vKEM+0gRgupHMRgFyFH/ykG5OV9h48sx+5whFAGUluIyUBOdhG+Z/KCZ8rB2y64YKyDtu4e52IdwDqJbpjwDW2lUq1anRLaAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 17",
              "title": "Configure cluster & click on `Save",
              "src": "/static/3180751ee6578c3e4768625e0d446e94/854dc/IMG_17.png",
              "srcSet": ["/static/3180751ee6578c3e4768625e0d446e94/222b7/IMG_17.png 163w", "/static/3180751ee6578c3e4768625e0d446e94/ff46a/IMG_17.png 325w", "/static/3180751ee6578c3e4768625e0d446e94/854dc/IMG_17.png 569w"],
              "sizes": "(max-width: 569px) 100vw, 569px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Configure cluster & click on \`Save`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "569px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/037fc12b87fc2fe3de0d9a56f635397b/854dc/IMG_18.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "37.423312883435585%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwElEQVQoz5XMTU/CQACE4f3//8tE0UaLFZTWRArssjQt3c+yvbzGEo1Hengyl5kRTdMgpURrjVJqSuccIXi8nyeEgCg3JU+PGau3NYuHBflLjrOeGAZiiPPEAaFMzdHu0e7wR5kdsq+R5ja//ZNViFzek+3uqM4FVfc6+TwXs1XdktqUCD8G/nMpYC8em+YxyU9bsTxGnmUg218VeqDsRjbtPB/tyFefEGUbeT8FVtpPqnZgaxLbPl3zRj9nB3vhG4sVDgzBDSXUAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 18",
              "title": "Click on Go to cluster view",
              "src": "/static/037fc12b87fc2fe3de0d9a56f635397b/854dc/IMG_18.png",
              "srcSet": ["/static/037fc12b87fc2fe3de0d9a56f635397b/222b7/IMG_18.png 163w", "/static/037fc12b87fc2fe3de0d9a56f635397b/ff46a/IMG_18.png 325w", "/static/037fc12b87fc2fe3de0d9a56f635397b/854dc/IMG_18.png 569w"],
              "sizes": "(max-width: 569px) 100vw, 569px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Click on Go to cluster view`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "581px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3577245213b162e17f8be9dee03236bd/92d15/IMG_19.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "32.515337423312886%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA90lEQVQoz6WRwU7CQBRF+8Wu/RH/xo246AbdgJVESIgCAk4LnZZ22r6ZY+YZCW7xJncz7+bdkzdJVVW0bYtzjqZpqOv6X06exmPSNOVxNGKxWOjiWHL1woO1mOLAV1FQWqu0kfRaJ8uXCfVuA4MjDAPiPSJCVJ7nZFnGbDajLEt9E49moowxf2ciJNvJlGL9iXmbIx9LYtSL4L0/O4Sgb9KfEGeR3hFAiWJpPJNmvCcpXjPsesNxPsfv1tpECPwqzw3bvaE7rqgfbqnub2ie73TWd53eexiGcz7hQkp3QRatP39q6FtLt0px7yP6/RQfUKqf/nDOfwPh8RmYCvq/BgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 19",
              "title": "Click on kafka_cluster",
              "src": "/static/3577245213b162e17f8be9dee03236bd/92d15/IMG_19.png",
              "srcSet": ["/static/3577245213b162e17f8be9dee03236bd/222b7/IMG_19.png 163w", "/static/3577245213b162e17f8be9dee03236bd/ff46a/IMG_19.png 325w", "/static/3577245213b162e17f8be9dee03236bd/92d15/IMG_19.png 581w"],
              "sizes": "(max-width: 581px) 100vw, 581px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Click on kafka_cluster`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "585px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9cd5ce287b6784cf60f3b815d01b80cb/78a22/IMG_21.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "47.852760736196316%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkklEQVQoz3WS627bMAyF9f5PM2CPsB8FumFIsWyJnSZxuiSWfIstxZJsJz7D0aaiKFYBH0TRFHlIWSwWCxyPx0CWZZAyx+NG4dNjhs/fXnDIaxQyR9t20FrjdDqh6zrsDwf8ShI0bYeyqpDnOaSUEIWSsLZH3/cwxsBai0Oh8ZTV+PHS4GIsbN/DOQ/vPa7XK5z3kM8psu9f0WxTmLJAZ0woJLreo3cexuiggM7RW0zeYnQ9Bu8xjiOGYQjQdsMA8/MJx4cv8MkSUGdweecgMnlB2RooJfH7X+tN06AsK0ilgmoqc869wm4uVYVGKUzOwjuLcZpCrABmYL5jnmfEFe3b7RZUMeHbnUk7rdFqjUvbBiiChUSSpNhsNtjtdmEnafrXV9d1mBkrR3gmvBzhjOnjOESSJFitVthut1iv18FeLpeBqqr+225M+hYWo3rB34Bz4+u+X/f7PSRlTFEUUEoFFRzFNE2vxDN3cT6fQ/8M5OyYJMIzq/M7C1IhfR/BO2K/34fA+AjvYSux4EcxESb8AzCN/DVsqG67AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 21",
              "title": "Navigate to Create a Topic",
              "src": "/static/9cd5ce287b6784cf60f3b815d01b80cb/78a22/IMG_21.png",
              "srcSet": ["/static/9cd5ce287b6784cf60f3b815d01b80cb/222b7/IMG_21.png 163w", "/static/9cd5ce287b6784cf60f3b815d01b80cb/ff46a/IMG_21.png 325w", "/static/9cd5ce287b6784cf60f3b815d01b80cb/78a22/IMG_21.png 585w"],
              "sizes": "(max-width: 585px) 100vw, 585px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Navigate to Create a Topic`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/cab8c/IMG_22.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.601226993865026%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVQoz41Ry26EMAzc/+4X9dZf6LmX3pCouKQgpPLKC+iKJFNNpKwC7La1NMTE9njsXJzbILVB27ao6zqiaRoYYzDPM6y1N/A/B++cc6CFEOJ5cRsJLYT4RFEUqKoKZVlCax2L1nWNOJInnAjpWGOwbRtyYyLvpmnCMAxYliX+H+G93xPyI6XCOI5xTBaSjIk8pZQxlhryPkci2hFyPCEE+r6Po6VkErIJ41TJk4W/EtJJRfm4TKYq3ieV3BnzGU85dxSGWNR1/UlFGpl7JNj4z5HpfC8WVythtIoq8pGVUrEJCennsYc7/GgVXosvqPkKBL97FKoiEXf770d5eRN4en5HITpoOcI5fyvmGvhYHD1X9lCh9wEnOyQlO6q7R/gDnrBaBHGhJmMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 22",
              "title": "Create topic for SIT environment - `sit.catalogue.item`",
              "src": "/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/a6d36/IMG_22.png",
              "srcSet": ["/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/222b7/IMG_22.png 163w", "/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/ff46a/IMG_22.png 325w", "/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/a6d36/IMG_22.png 650w", "/static/f3e0ccf9e8b6482fa35d12d27ed0c89a/cab8c/IMG_22.png 744w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create topic for SIT environment - \`sit.catalogue.item\``}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "575px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0354d47aab7da43b53495062d97f8483/59415/IMG_35.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "68.09815950920245%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQ4y5VSW46bMBRljd1Ad9TfLqeNUlX96HxEZSImhIQQlA4jxMM2GIzNqa4npoSmI82Vjmzka9/zwGuaBkII1HUNzjkYYxa0p7P3witqjlP6jIeHn/i6WsH3fazXa2w2GzvovfCatoWU3cSS1iVDKSX6vkfXdXb/JkNqqqoSxhhQjeNoQd9aa7uXskWe5yjL0g6ks//BY6LF0z5GzVsI2YO3PdpeQw4GUhlIDdSMIzmdkKYpiqKYht19kC5kWYE4ihHtDtgHe+QlRzMAojcoWw2l8U8Rc3p0CU+IBrt9hGNyxmBGkHBalTZQg4EZiSFDHMc4Ho84HA4W5DPVnB0N8ZRSKIsCnDNoPcBYKU7O60o+u6AoOAKFNJc+MXQvu2k2kGsor41/z5d1PxTG8Mt/xNMutJKiKLKMnEdUFMR2u7VSwzBEEATIssyezcOxkjEaYFTXiRrDMNyY7L7nl8imZd8kOWcSX/zf+BZkYE2H4drs/kcqYkwsq6qyq2PueubwfoQv+PBphY+fv2N7esHzJUVds+kCFdmSJAkulwvO57MNZJz5fOOhNgZcKgipbsyfM1yGck+qk/wHJgY6uZHFZqMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 35",
              "title": "Create topic for UAT environment - `uat.catalogue.item`",
              "src": "/static/0354d47aab7da43b53495062d97f8483/59415/IMG_35.png",
              "srcSet": ["/static/0354d47aab7da43b53495062d97f8483/222b7/IMG_35.png 163w", "/static/0354d47aab7da43b53495062d97f8483/ff46a/IMG_35.png 325w", "/static/0354d47aab7da43b53495062d97f8483/59415/IMG_35.png 575w"],
              "sizes": "(max-width: 575px) 100vw, 575px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Create topic for UAT environment - \`uat.catalogue.item\``}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "publishing-log-message-to-kafka-topics"
    }}>{`Publishing log message to kafka topics`}</h2>
    <p>{`It’s time to start preparing messages to be published to Kafka Topics. Log messages can be defined in any format. But if they are in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JSON`}</code>{` format, we can try to capture many details so that it provides additional capabilities when viewing the entries in Kibana.`}</p>
    <p>{`Below is the sample log message which we prepared to showcase for this article. As observed, we tried to capture many details that are needed as per the requirement.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Log Message in JSON format`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 # Application Name  
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"catalogue-item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Client Request Id
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"crid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1BFXCD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

#Unique Id
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"uuid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ec25cdc8-a336-11ea-bb37-0242ac130002"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Message
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"catalogue-item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1BFXCD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"uuid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ec25cdc8-a336-11ea-bb37-0242ac130002"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Create Catlogue PayLoad - {'sku':'CTLG-123-0001','name':'The Avengers','description':'Marvel's The Avengers Movie','category':'Movies','price':0,'inventory':0}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SUCCESS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"latency"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Postman API Client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Catalogue DataBase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2020-05-31T13:22:10.120Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Status Code - SUCCESS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`ERROR`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`WARN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`INFO
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SUCCESS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Time that passes between a user action and the resulting response
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"latency"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Sorce of message
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Postman API Client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Destination of message
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Catalogur Database"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`

# Application Time Stamp
`}<span parentName="code" {...{
            "className": "token property"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2020-05-31T13:22:10.120Z"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Start Kafka producer using the scripts available in the provisioned docker container. Run the below command to start the producer for the provided topic.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Login to container shell using below command`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# CONTAINER_NAME - kafka`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`CONTAINER_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /bin/bash`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start producer`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` opt/kafka_2.11-0.10.1.0/bin

$ ./kafka-console-producer.sh --broker-list kafkaserver:9092 --topic uat.catalogue.item`}</code></pre></div>
    <p>{`Copy and paste the below messages one by one in the console to publish the messages to the provided topic.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Sample messages`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`## Message published to \`uat.catalogue.item\` kafka topic

## SUCCESS Message
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"catalogue-item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1BFXCD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"uuid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ec25cdc8-a336-11ea-bb37-0242ac130002"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Create Catlogue PayLoad - {'sku':'CTLG-123-0001','name':'The Avengers','description':'Marvel's The Avengers Movie','category':'Movies','price':0,'inventory':0}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"SUCCESS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"latency"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Postman API Client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Catalogue DataBase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2020-05-31T13:22:10.120Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

## WARN Message
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"catalogue-item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1BFXCD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"uuid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ec25cdc8-a336-11ea-bb37-0242ac130002"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Create Catlogue PayLoad - {'sku':'CTLG-123-0001','name':'The Avengers','description':'Marvel's The Avengers Movie','category':'Movies','price':0,'inventory':0}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"WARN"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"latency"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Postman API Client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Catalogue DataBase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2020-05-31T13:22:10.120Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

## ERROR Message
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"app"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"catalogue-item"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"crid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1BFXCD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"uuid"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ec25cdc8-a336-11ea-bb37-0242ac130002"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"msg"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Create Catlogue PayLoad - {'sku':'CTLG-123-0001','name':'The Avengers','description':'Marvel's The Avengers Movie','category':'Movies','price':0,'inventory':0}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"status"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ERROR"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"latency"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"source"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Postman API Client"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"destination"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Catalogue DataBase"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"time_stamp"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"2020-05-31T13:22:10.120Z"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7706e0470aa002ab987ea232a0c62ce0/d7ceb/IMG_26.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "20.245398773006134%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAo0lEQVQY002P2QqFMAxE/f8PVLxt3RArWsUugj6NTKRyHw7ZJ0lhjIFSStBaYxgGdF2Hvu8FxuM4YpomwXuP4ziEGOPnk33fUXC4qioRpIBz7humkLUW67qKvyzLV5/nWfLMZbtt7hWs6xpt24povphLmM+X556maVCWJYzRUOon3xCK8soipYQYA84zgf4bR4Tgxf6T63yblj0hBOG6Ltz3jQdkdyjc7miiUgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG 26",
              "title": "Messages published to UAT topic",
              "src": "/static/7706e0470aa002ab987ea232a0c62ce0/a6d36/IMG_26.png",
              "srcSet": ["/static/7706e0470aa002ab987ea232a0c62ce0/222b7/IMG_26.png 163w", "/static/7706e0470aa002ab987ea232a0c62ce0/ff46a/IMG_26.png 325w", "/static/7706e0470aa002ab987ea232a0c62ce0/a6d36/IMG_26.png 650w", "/static/7706e0470aa002ab987ea232a0c62ce0/e548f/IMG_26.png 975w", "/static/7706e0470aa002ab987ea232a0c62ce0/3c492/IMG_26.png 1300w", "/static/7706e0470aa002ab987ea232a0c62ce0/d7ceb/IMG_26.png 1446w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Messages published to UAT topic`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "configuring-kibana-1"
    }}>{`Configuring Kibana`}</h2>
    <ul>
      <li parentName="ul">{`Access `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://localhost:5601/`}</code>{` in browser to popup `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Kibana UI`}</code>{` and follow the below series of steps to create `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Index`}</code>{` and start `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Visualizing`}</code>{` messages that are pushed to Kafka Topic.`}</li>
      <li parentName="ul">{`Click `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Management`}</code>{` on side navigation bar.`}</li>
      <li parentName="ul">{`Click on `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Index Patterns`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5a81a7bc1edfb063a2acc4cb5675d529/ddc6c/IMG_27.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.404907975460123%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAeUlEQVQY042P3QrDMAhG8/6vOdjobxrSUJtovqH0IksZVDg3HvxUN00jNu/BXFBrhYj8oD1cPPFumBesW8BxZmv0ZBZQYYOvgBYNVXcWhljgThgiYYzHbUC3ronwCQkvvyNShpY0PjPjHZKhwQ7dyT3m8N+3L4tUfAFC6DtEjLEy9QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 27",
            "title": "IMG 27",
            "src": "/static/5a81a7bc1edfb063a2acc4cb5675d529/a6d36/IMG_27.png",
            "srcSet": ["/static/5a81a7bc1edfb063a2acc4cb5675d529/222b7/IMG_27.png 163w", "/static/5a81a7bc1edfb063a2acc4cb5675d529/ff46a/IMG_27.png 325w", "/static/5a81a7bc1edfb063a2acc4cb5675d529/a6d36/IMG_27.png 650w", "/static/5a81a7bc1edfb063a2acc4cb5675d529/e548f/IMG_27.png 975w", "/static/5a81a7bc1edfb063a2acc4cb5675d529/3c492/IMG_27.png 1300w", "/static/5a81a7bc1edfb063a2acc4cb5675d529/ddc6c/IMG_27.png 1534w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`It will navigate to blow Screen`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/57cf70df5d971d0011c3e11e2e6ea713/5d030/IMG_28.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.282208588957054%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABYklEQVQoz31Sa2+DMAzk//++Vh3jw1oxoEBCeT8TbjpPYbRrG+nkxHHOOdve4XBAEAQ4nU7wfR/H41HO1loYY8S+w7qu2C9PKYWiKKC1FnB/u93Q9z26rkNd16iqCk3TyJ7WncuyxDAMQkRiwuMjOmldIEFS+hjkfnKPZ74VXhiGiKIIeZ7jer0iTVNBHMeSwGV/uR4l8zckG8fxaTx/t5e0Ye/bS27bVurGeji59NE6yf8ItzPuiUno6kXsm8MEbAI77YIfZT6rhBCSgN1mzRx5yQRaS32zXKHvBxj71yArbL+JjOEI7SQnSYIsy6SWtKlSGMIz1iyGTUKsXz7GokBWToiyFp9hhY9LieC7xjlpEKsOVTtJAo8j42aP0EpBcw4LDRNdYJoatu9g51keTLNB188YxgXdsGCcFtHuxsvjAFMqa8ZOvxuRV3d7t+eGmtJJuCzLBrOzAmPu7rc4Yzb8AL7apVranZ4cAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 28",
            "title": "IMG 28",
            "src": "/static/57cf70df5d971d0011c3e11e2e6ea713/a6d36/IMG_28.png",
            "srcSet": ["/static/57cf70df5d971d0011c3e11e2e6ea713/222b7/IMG_28.png 163w", "/static/57cf70df5d971d0011c3e11e2e6ea713/ff46a/IMG_28.png 325w", "/static/57cf70df5d971d0011c3e11e2e6ea713/a6d36/IMG_28.png 650w", "/static/57cf70df5d971d0011c3e11e2e6ea713/5d030/IMG_28.png 771w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Create index pattern for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sit`}</code>{` logs`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49859d54ab279446ea417f600d2bf0cf/f2331/IMG_29.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.447852760736193%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxUlEQVQY042QwW4DIQxE+f/fXEWtQinsssUL2LyKTXKIqlQZay4+eJ7HXS4XlmXBe09rjUOE4zhOixz03pkaY7x0rRUROe1iSsQYCSGQc0bHQM0wU0wVM/v32DBDez+DJ5D79IGUVkoRWm3wk1ERdlGk6o2O9zQDnI+R6bCuxC2jrTJU6Wqo/qWbat3Ipd8Ayk7fM6lUSm24D3/l+h34SpEsQjN7xL18c9YwA20G9o7Nl+8Ablu3s0x749Az6bjPo5K5N34BQ2bXd/N37F0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 29",
            "title": "IMG 29",
            "src": "/static/49859d54ab279446ea417f600d2bf0cf/a6d36/IMG_29.png",
            "srcSet": ["/static/49859d54ab279446ea417f600d2bf0cf/222b7/IMG_29.png 163w", "/static/49859d54ab279446ea417f600d2bf0cf/ff46a/IMG_29.png 325w", "/static/49859d54ab279446ea417f600d2bf0cf/a6d36/IMG_29.png 650w", "/static/49859d54ab279446ea417f600d2bf0cf/e548f/IMG_29.png 975w", "/static/49859d54ab279446ea417f600d2bf0cf/3c492/IMG_29.png 1300w", "/static/49859d54ab279446ea417f600d2bf0cf/f2331/IMG_29.png 1427w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Configure Settings`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71201d5179f39540f08167f8f6a4e736/52ab5/IMG_30.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.67484662576687%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1ElEQVQY032P3U7EIBBGeXdfxlvfwcT4BCZed9XYLG1pgYECxwzdvas7yQmTYX6+zwzDwHC5oG8IgZQzKaWOiJBz5h6ttVO0R2e13wTvccuCtbajn/u+k5VbXmuh1vrvwlJK71PMdXZ8j5ZpnnHOISnjNk9StTESouD98e6l8jgaZrxafn5HFrcyL47JrV0+fqNJJKaCl0KQ0nNVdLevoUckl47mJkpicltfpBbV3nHr4FTHzap21AaybkQfKbVhtKzFdjLwCPpc6zy/ffD08s7r5xd/bG3VBoYDjeEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 30",
            "title": "IMG 30",
            "src": "/static/71201d5179f39540f08167f8f6a4e736/a6d36/IMG_30.png",
            "srcSet": ["/static/71201d5179f39540f08167f8f6a4e736/222b7/IMG_30.png 163w", "/static/71201d5179f39540f08167f8f6a4e736/ff46a/IMG_30.png 325w", "/static/71201d5179f39540f08167f8f6a4e736/a6d36/IMG_30.png 650w", "/static/71201d5179f39540f08167f8f6a4e736/e548f/IMG_30.png 975w", "/static/71201d5179f39540f08167f8f6a4e736/3c492/IMG_30.png 1300w", "/static/71201d5179f39540f08167f8f6a4e736/52ab5/IMG_30.png 1420w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`If Index created sucessfully will navigate to blow screen`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8049538666b87102454b33d66dd837fe/6c86f/IMG_31.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.171779141104295%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVQoz5VRy04DMQzM//8VRyRuvdD2Bq0Qry40ibN5OIMmbEracoBII69f47HX3Kz3uN+9wDsHcR7BC8QLqlb852mtKDnD3G132Dy9wx4mbB/XuH1YYbXfYPqYICIIIWCe5zPEGDET9GNEzIqiiigCU90RSgSBDR6TP+JTbCMiIZtKKQ055xNSSj+2aPuOKcG8HQTPr67J5qo55at1enMjXgg5iJZDGafPOlO0oiz38t7DOdfuoaoNtda2IsFHP5N4yfeaDjMqYROn9mQv5mTmerwshN8op1rVCtOL+PoavxHypnVQfrnFmcKRkNNHwq68Kfzryp2QN7TWXinsf3iM6wUhbc4FX8f9wxWN1F2cAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 31",
            "title": "IMG 31",
            "src": "/static/8049538666b87102454b33d66dd837fe/a6d36/IMG_31.png",
            "srcSet": ["/static/8049538666b87102454b33d66dd837fe/222b7/IMG_31.png 163w", "/static/8049538666b87102454b33d66dd837fe/ff46a/IMG_31.png 325w", "/static/8049538666b87102454b33d66dd837fe/a6d36/IMG_31.png 650w", "/static/8049538666b87102454b33d66dd837fe/e548f/IMG_31.png 975w", "/static/8049538666b87102454b33d66dd837fe/3c492/IMG_31.png 1300w", "/static/8049538666b87102454b33d66dd837fe/6c86f/IMG_31.png 1720w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Click `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Discover`}</code>{` on side navigation bar`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Logs available in kibana with the index created`}</p>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b0c924b80f7649ca7bde800103cad525/a429e/IMG_33.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.809815950920246%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABEklEQVQoz01Q7WrEMAzr+7/U/t4TbGx/BrfbjXFrm6b5qJ00GjJNOYMxCrIsZXh5veLyfsXf9ze28QFZHFYfMPuM0SU4n7H4gBjj2TlnbCIQVaBW7LWilIJNFMPl7ROXjy/cfn6xpwDVgqwNohVSdvRq7ZgHvsWARQVOBI+cTtIwTSNyiogpQWtF40rffqrWmjnSUgx7EWyl4Lr6U7DWimGcJoQYEUKAiFgcuvTeWzPiuq5IKVmslPkFHilGFFUU7sRoXO4P9/vdQBdUulA1oWVZ7J2TmC7pguLkEpM70VQIdnCY59lAf+jNpe6Qk5gCpdYjhVpMcp1zxrPIVI9HZJK6y2eHXXjf9/MYeSxyuyAd/gN7Sh+GxUb4xAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 33",
            "title": "IMG 33",
            "src": "/static/b0c924b80f7649ca7bde800103cad525/a6d36/IMG_33.png",
            "srcSet": ["/static/b0c924b80f7649ca7bde800103cad525/222b7/IMG_33.png 163w", "/static/b0c924b80f7649ca7bde800103cad525/ff46a/IMG_33.png 325w", "/static/b0c924b80f7649ca7bde800103cad525/a6d36/IMG_33.png 650w", "/static/b0c924b80f7649ca7bde800103cad525/e548f/IMG_33.png 975w", "/static/b0c924b80f7649ca7bde800103cad525/3c492/IMG_33.png 1300w", "/static/b0c924b80f7649ca7bde800103cad525/a429e/IMG_33.png 1717w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Visualize Message in tabular format`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/52ab5/IMG_34.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.62576687116564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA90lEQVQoz5VSy27EIAzk/3+uX9BDL5s9BMLTEOxkKtNlW63UdhdpcLCcYcbGMAt672BmpJTgnIP3HjHG8V1KwXmeOI4DrTUQEURk5BQpRmzOodU6aoxux4k/1/z58Vy7wMWMRA1lZ7QuMKpquykqJSPnjH3fx23/oXYGdR6xtI7WGUYJgvdwTpHhfRg2tQXT6m+Aqn6AqZVAVBHCCqJ3MBeIKJE8pXJCbtGoVVV1uXzgen1DjCt6/276s1AyjaOH1lpsm0cIFTnT140iL+OuMISAZVlg7YqU4nge84lorLXeMfM/z7NmEOpENaHEOiAtnpN+1bZa/gRcAcFzMQL52QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 34",
            "title": "IMG 34",
            "src": "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/a6d36/IMG_34.png",
            "srcSet": ["/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/222b7/IMG_34.png 163w", "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/ff46a/IMG_34.png 325w", "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/a6d36/IMG_34.png 650w", "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/e548f/IMG_34.png 975w", "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/3c492/IMG_34.png 1300w", "/static/c4ab6cde5e9dc97e5b9ed33edf56ba91/52ab5/IMG_34.png 1420w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`If you had made up to this point 😄 congratulations !!! You have successfully dockerizing the ELK stack with Kafka.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "270px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0549243a8c3af110d59df9e7bad41a82/6f81f/how-it-works.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHPKfYslM0WknMCX//EAB0QAAIBBAMAAAAAAAAAAAAAAAECAwAREhMhIjL/2gAIAQEAAQUCu2LFhUh7I/HobGqONCmC3nAEv//EABcRAAMBAAAAAAAAAAAAAAAAAAABERD/2gAIAQMBAT8BUJv/xAAXEQADAQAAAAAAAAAAAAAAAAAAEBJB/9oACAECAQE/AdJf/8QAHBAAAgICAwAAAAAAAAAAAAAAAAEQESFBEjKh/9oACAEBAAY/AnR38h8njRlwrWhqh0f/xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFB/9oACAEBAAE/ISzN4qUqXPZZwfOTd/DjEoaLLcxJbcxntc9vYBoAPWawT//aAAwDAQACAAMAAAAQV+f+/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPxDAoq//xAAXEQEBAQEAAAAAAAAAAAAAAAABADFx/9oACAECAQE/EERXUxl//8QAHhABAAMAAgIDAAAAAAAAAAAAAQARIVFhMUGBwfD/2gAIAQEAAT8Qw1gC+DfqMmFWAAPxBbeA/MhqFTkr2BH1TBpE8ZDpS2lpstKKb7LnPEA+IOLe+oaBCDVz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "how it works",
            "title": "how it works",
            "src": "/static/0549243a8c3af110d59df9e7bad41a82/6f81f/how-it-works.jpg",
            "srcSet": ["/static/0549243a8c3af110d59df9e7bad41a82/d2f63/how-it-works.jpg 163w", "/static/0549243a8c3af110d59df9e7bad41a82/6f81f/how-it-works.jpg 270w"],
            "sizes": "(max-width: 270px) 100vw, 270px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "some-useful-commands-for-reference"
    }}>{`Some useful commands for reference`}</h2>
    <h3 {...{
      "id": "docker-commands"
    }}>{`Docker Commands`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Builds, (re)creates, starts, and attaches to containers for a service`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Stops containers and removes containers, networks, volumes, and images created by up`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` down

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Lists all running containers in docker engine`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# List docker images`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` images `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# List all docker networks`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` network `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span></code></pre></div>
    <h3 {...{
      "id": "kafka-commands"
    }}>{`Kafka Commands`}</h3>
    <h3 {...{
      "id": "producers"
    }}>{`Producers`}</h3>
    <p>{`You can produce messages from standard input as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ ./kafka-console-producer.sh --broker-list kafkaserver:9092 --topic uat.catalogue.item`}</code></pre></div>
    <h3 {...{
      "id": "consumers"
    }}>{`Consumers`}</h3>
    <p>{`You can begin a consumer from the beginning of the log as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ ./kafka-console-consumer.sh --bootstrap-server kafkaserver:9092 --topic uat.catalogue.item --from-beginning`}</code></pre></div>
    <p>{`You can consume a single message as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ ./kafka-console-consumer.sh --bootstrap-server kafkaserver:9092 --topic uat.catalogue.item  --max-messages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p>{`You can consume and specify a consumer group as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ ./kafka-console-consumer.sh --topic uat.catalogue.item --new-consumer --bootstrap-server kafkaserver:9092 --consumer-property group.id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`elk-group`}</code></pre></div>
    <ExternalLinksListContainer title={'References'} links={[{
      'url': 'https://www.elastic.co/guide/en/logstash/current/plugins-inputs-kafka.html'
    }, {
      'url': 'https://medium.com/@caysever/docker-compose-network-b86e424fad82'
    }, {
      'url': 'https://zablo.net/blog/post/setup-apache-kafka-in-docker-on-windows/'
    }, {
      'url': 'https://medium.com/@marcelo.hossomi/running-kafka-in-docker-machine-64d1501d6f0b'
    }, {
      'url': 'https://www.elastic.co/guide/en/logstash/current/output-plugins.html'
    }, {
      'url': 'https://www.elastic.co/guide/en/logstash/current/output-plugins.html'
    }, {
      'url': 'https://www.elastic.co/guide/en/logstash/current/filter-plugins.html'
    }, {
      'url': 'https://www.elastic.co/guide/en/logstash/current/plugins-inputs-kafka.html'
    }, {
      'url': 'https://github.com/lensesio/kafka-cheat-sheet/blob/master/README.md'
    }, {
      'url': 'https://www.elastic.co/guide/en/logstash/current/plugins-filters-mutate.html'
    }]} mdxType="ExternalLinksListContainer">
    </ExternalLinksListContainer>
    <GithubRepo href="https://github.com/2much2learn/article_june13_centralized-logging-with-kafka-and-elk-stack" mdxType="GithubRepo">centralized-logging-with-kafka-and-elk-stack</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      