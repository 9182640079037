import React from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import urljoin from "url-join"
import { DiscussionEmbed } from "disqus-react"
import Layout from "~/components/layout"
import SEO from "~/components/seo"
import PostCard from "~/components/PostCard/postCard"
import PostDetails from "~/components/PostDetails/postDetails"
import Sidebar from "~/containers/Sidebar"
import Author from "~/containers/Authors/Author"
import Toc from "~/components/Toc"
import Applause from "~/components/Applause"

import Categories from '~/utils/CategoryConstants'

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
} from "react-share"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoLogoReddit,
  IoLogoLinkedin
} from "react-icons/io"
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
  BlogDetailsContent,
  BlinkingToDo
} from "./templates.style"

const BlogPostTemplate = (props: any) => {

  const post = props.data.mdx
  const { edges } = props.data.allMdx
  const title = post.frontmatter.title
  const slug = post.fields.slug
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const shareUrl = urljoin(siteUrl, slug)
  
  const categoryInfo =  _.get(Categories, post.frontmatter.category)

  console.log("===> props.data.authors :: ",props.data.authors);

  const author = post.frontmatter.author
  const authorFromArray = _.find(props.data.authors.group, ['fieldValue', author.alias]);
  const authorPublishedPosts = (authorFromArray === undefined) ? 0 : authorFromArray.totalCount;

  const disqusConfig = {
    shortname: process.env.DISQUS_NAME,
    config: { identifier: slug, title },
  }

  return (
    <>
    {post.frontmatter.cover && (
    <Layout>
      <SEO
        title={title}
        description={post.frontmatter.description || post.excerpt}
        slug={slug}
        image={post.frontmatter.cover.publicURL}
        author={author}
        publishedOn={post.frontmatter.date}
        modifiedOn={post.frontmatter.updated}
        keywords={post.frontmatter.keywords}
      />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent extendedBlogPost={!post.frontmatter.showSideBar && !post.frontmatter.showToc}>
          {!post.frontmatter.published && (
            <BlinkingToDo>~~~~~~~~~ Article is still in Progress ~~~~~~~~</BlinkingToDo>  
          )}          
          <h3><Link to={`/categories/${_.kebabCase(categoryInfo.alias)}/`}>
            {`${categoryInfo.name}`}
          </Link></h3>
          <main>
            <PostDetails
              title={post.frontmatter.title}
              date={post.frontmatter.updated}
              preview={
                post.frontmatter.cover == null
                  ? null
                  : post.frontmatter.cover.childImageSharp.gatsbyImageData
              }
              description={post.body}
            />
          </main>
          <BlogPostFooter>
            {post.frontmatter.tags == null ? null : (
              <PostTags className="post_tags">
                {post.frontmatter.tags.map((tag: string, index: number) => (
                  <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                    {`#${tag}`}
                  </Link>
                ))}
              </PostTags>
            )}
            <PostShare>
              <span>Share This:</span>
              <LinkedinShareButton title={title} summary={post.excerpt} url={shareUrl}>
                <IoLogoLinkedin />
              </LinkedinShareButton>
              <FacebookShareButton url={shareUrl} quote={post.excerpt}>
                <IoLogoFacebook />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <IoLogoTwitter />
              </TwitterShareButton>
              <PinterestShareButton
                url={shareUrl}
                media={urljoin(siteUrl, post.frontmatter.cover.publicURL)}
              >
                <IoLogoPinterest />
              </PinterestShareButton>
              <RedditShareButton
                url={shareUrl}
                title={`${post.frontmatter.title}`}
              >
                <IoLogoReddit />
              </RedditShareButton>
            </PostShare>
          </BlogPostFooter>
          
          {post.frontmatter.showAuthorInfo && 
           <Author {...author} totalPosts={authorPublishedPosts}></Author>
          }
          
          {disqusConfig.shortname && 
            <BlogPostComment>
              <DiscussionEmbed {...disqusConfig} />
            </BlogPostComment>
          }

        </BlogDetailsContent>

        {/* Table Of Content */}
        <Toc/>

        <Applause url={shareUrl}/>

        {/* Show sidebar only when it is true */}
        {post.frontmatter.showSideBar && (<Sidebar />)}
      </BlogPostDetailsWrapper>

      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) => {
              //Random Placeholder Color
              const placeholderColors = [
                "#55efc4",
                "#81ecec",
                "#74b9ff",
                "#a29bfe",
                "#ffeaa7",
                "#fab1a0",
                "#e17055",
                "#0984e3",
                "#badc58",
                "#c7ecee",
              ]
              const setColor =
                placeholderColors[
                  Math.floor(Math.random() * placeholderColors.length)
                ]
              const categoryInfo =  _.get(Categories, node.frontmatter.category)
                //console.log("====> Blog post categoryInfo", categoryInfo)
              return (
                <RelatedPostItem key={node.fields.slug}>
                  <PostCard
                    title={node.frontmatter.title || node.fields.slug}
                    url={node.fields.slug}
                    image={
                      node.frontmatter.cover == null
                        ? null
                        : node.frontmatter.cover.childImageSharp.gatsbyImageData
                    }
                    category={categoryInfo}
                    tags={node.frontmatter.tags}
                    placeholderBG={setColor}
                  />
                </RelatedPostItem>
              )
            })}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
    )}
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        date
        updated
        published
        showSideBar
        title
        description
        category
        tags
        keywords
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1200
              height: 690
              quality: 100
            )
          }
        }
        showAuthorInfo
        author {
          order
          alias
          name
          image {
            childImageSharp {
              gatsbyImageData(
                width: 210
                height: 210
                quality: 100
              )
            }
          }
          jobTitle
          description
          social {
            facebook
            github
            instagram
            linkedin
            twitter
          }
        }
      }
    }
    allMdx(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag }, published: {eq: true} }
        fields: { slug: { ne: $slug } }        
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            tags
            cover {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 370
                  height: 220
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
    authors: allMdx(
      filter: {
        frontmatter: {
          templateKey: {eq: "article"},
          published: {eq: true}
        }
      }
      sort: {order: ASC, fields: [frontmatter___author___order]}
    ) {
      group(field: frontmatter___author___alias) {
        fieldValue
        totalCount
      }
    }
  }
`
