import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Centralized Configuration in Spring Boot Microservices with Spring Cloud Config",
  "description": "A detailed step by step guide on enabling Centralized Configuration in Spring Boot Microservices with Spring Cloud Config",
  "date": "2021-01-04T15:46:37.121Z",
  "updated": "2021-01-04T15:46:37.121Z",
  "cover": "../../../images/blog-banners/springboot-config-banner-1200x690.png",
  "category": "microservice",
  "tags": ["Java", "Spring Boot", "Spring Cloud", "Microservice", "featured"],
  "keywords": ["Step by Step guide using Spring Boot", "Centralized Configuration", "Spring Cloud", "Spring Boot", "Spring Cloud Config", "Maven", "Java", "Java8", "Gradle", "Spring Cloud Config Git", "Spring Cloud Config Vault", "Spring Boot Git", "Spring Cloud Config Multi Module project", "Maven Multi Module Spring Boot Project", "Gradle Multi Module Spring Boot Project", "Spring Cloud Server", "Step by Step guide", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Support for`}</p>
    <ul>
      <li parentName="ul">{`Environment specific properties`}</li>
      <li parentName="ul">{`Encrypted property values`}</li>
      <li parentName="ul">{`Refresh/Reload properties runtime`}</li>
    </ul>
    <h2 {...{
      "id": "technology-stack-for-implementing-the-restful-apis"
    }}>{`Technology stack for implementing the Restful APIs`}</h2>
    <h2 {...{
      "id": "bootstrapping-project-with-multi-module-spring-boot-application"
    }}>{`Bootstrapping Project with Multi Module Spring Boot Application`}</h2>
    <h2 {...{
      "id": "spring-cloud-config-server"
    }}>{`Spring Cloud Config Server`}</h2>
    <h3 {...{
      "id": "securing-config-server"
    }}>{`Securing Config Server`}</h3>
    <h2 {...{
      "id": "spring-cloud-config-client"
    }}>{`Spring Cloud Config Client`}</h2>
    <h2 {...{
      "id": "dynamic-config-management-with-spring-cloud-config-bus"
    }}>{`Dynamic Config Management with Spring Cloud Config Bus`}</h2>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      