import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any
  keywords?: any
  title: string
  image?: any
  slug: string
  author?: any
  publishedOn?: any
  modifiedOn? : any
}

const SEO: React.FunctionComponent<SEOProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  image,
  slug,
  author,
  publishedOn,
  modifiedOn
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            logo
            siteUrl
          }
        }
      }
    `
  )

  const seoImage = (image) ? `https://2much2learn.com${image}` : site.siteMetadata.logo
  const seoUrl = (slug) ? `https://2much2learn.com${slug}` : site.siteMetadata.siteUrl
  const metaDescription = description || site.siteMetadata.description
  
  const articleAuthor = (author && author.social && author.social.linkedin) ? author.social.linkedin : site.siteMetadata.siteUrl
  const articlePublisher = site.siteMetadata.siteUrl
  const articlePublishedOn = publishedOn
  const articleModifiedOn = modifiedOn

  const seoAuthor = author ? (author.social && author.social.linkedin) ? author.name+' ('+author.social.linkedin+')' : author.name : site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: seoAuthor
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          property: `og:image:secure_url`,
          content: seoImage,
        },
        {
          property: `og:url`,
          content: seoUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `article:author`,
          content: articleAuthor,
        },
        {
          property: `article:publisher`,
          content: articlePublisher
        },
        {
          property: `article:published_time`,
          content: articlePublishedOn
        },
        {
          property: `article:modified_time`,
          content: articleModifiedOn
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: articleAuthor,
        },
        {
          name: `twitter:creator`,
          content: articlePublisher,
        },
        {
          name: `twitter:domain`,
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: seoImage,
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      {
        //<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5715094337709253" crossorigin="anonymous"></script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

export default SEO
