import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": false,
  "author": "meenakshivemuri",
  "title": "Can I Use Hooks Today?",
  "date": "2019-03-20",
  "updated": "2019-03-06T23:46:37.121Z",
  "description": "Node.js is an open-source, cross-platform JavaScript run-time environment for executing JavaScript code server-side. Historically, JavaScript was used primarily for client-side scripting, in which scripts written in JavaScript are embedded in a webpage’s HTML, to be run client-side by a JavaScript engine in the user’s web browser.",
  "category": "microservice",
  "tags": ["javascript", "hooks", "test", "featured"],
  "cover": "./preview.jpg",
  "slug": "can-i-use-hooks-today"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Node.js is an open-source, cross-platform JavaScript run-time environment for executing JavaScript code server-side. Historically, JavaScript was used primarily for client-side scripting, in which scripts written in JavaScript are embedded in a webpage’s HTML, to be run client-side by a JavaScript engine in the user’s web browser.`}</p>
    <p>{`Node.js enables JavaScript to be used for server-side scripting, and runs scripts server-side to produce dynamic web page content before the page is sent to the user’s web browser.`}</p>
    <p>{`Consequently, Node.js has become one of the foundational elements of the “JavaScript everywhere” paradigm, allowing web application development to unify around a single programming language, rather than rely on a different language for writing server side scripts.`}</p>
    <h2 {...{
      "id": "on-deer-horse-aboard-tritely-yikes-and-much"
    }}>{`On deer horse aboard tritely yikes and much`}</h2>
    <p>{`The Big Oxmox advised her not to do so, because there were thousands of bad
Commas, wild Question Marks and devious Semikoli, but the Little Blind Text
didn’t listen. She packed her seven versalia, put her initial into the belt and
made herself on the way.`}</p>
    <ul>
      <li parentName="ul">{`This however showed weasel`}</li>
      <li parentName="ul">{`Well uncritical so misled`}
        <ul parentName="li">
          <li parentName="ul">{`this is very interesting`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Goodness much until that fluid owl`}</li>
    </ul>
    <p>{`When she reached the first hills of the `}<strong parentName="p">{`Italic Mountains`}</strong>{`, she had a last
view back on the skyline of her hometown `}<em parentName="p">{`Bookmarksgrove`}</em>{`, the headline of
`}<a parentName="p" {...{
        "href": "http://google.com"
      }}>{`Alphabet Village`}</a>{` and the subline of her own road, the Line
Lane. Pityful a rethoric question ran over her cheek, then she continued her
way. On her way she met a copy.`}</p>
    <h3 {...{
      "id": "overlaid-the-jeepers-uselessly-much-excluding"
    }}>{`Overlaid the jeepers uselessly much excluding`}</h3>
    <p>{`But nothing the copy said could convince her and so it didn’t take long until a
few insidious Copy Writers ambushed her, made her drunk with
`}<a parentName="p" {...{
        "href": "http://google.com"
      }}>{`Longe and Parole`}</a>{` and dragged her into their agency, where
they abused her for their projects again and again. And if she hasn’t been
rewritten, then they are still using her.`}</p>
    <p>{`JavaScript run-time environment for `}<a parentName="p" {...{
        "href": "http://github.com"
      }}>{`GitHub`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      