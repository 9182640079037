import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "RESTful API Design-first development with Apicurio Studio",
  "description": "A detailed step by step guide on creating RESTful API OpenAPI interface using Apicurio following Design-first approach",
  "date": "2021-10-27T15:46:37.121+10:00",
  "updated": "2021-10-27T15:46:37.121+10:00",
  "cover": "../../../images/blog-banners/designfirst-apicurio-1200x690.png",
  "category": "designfirst",
  "tags": ["OpenAPI", "Apicurio", "featured"],
  "keywords": ["REST API design-first development with Apicurio", "Apicurio", "design-first Apicurio", "api-first Apicurio", "design-first openapi", "api-first openapi", "openapi design", "openapi studio", "Apicurio studio", "Apicurio registry", "openAPI", "swagger", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article-rest-api-design-first-development-with-apicurio" mdxType="GithubRepo">rest-api-design-first-development-with-apicurio</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`There is no specific need to start the introduction with terms like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`swagger`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`openapi`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API Specs`}</code>{`. These are defacto terms that we come across and use them on day-to-day basis as part of API Development activities.`}</p>
    <p>{`But, we need to understand three key approaches revolving around these terms which will help us to pick the right fit before we kickstart an API Project and how would `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` fit in with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Design-first`}</code>{` approach.`}</p>
    <h3 {...{
      "id": "code-first"
    }}>{`Code-first`}</h3>
    <p>{`A `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code-first`}</code>{` approach is typically the first choice by every backend developer. Once we receive the business requirements, especially the Java folks generate the project code using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`springboot initializer`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Quarkus Generator`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Micronaut Launcher`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`maven generate`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gradle init`}</code>{` and jump straight ahead creating the controller classes. This all ends by documenting the controller classes/methods with swagger annotations and configuring application to generate spec during build or runtime.`}</p>
    <p>{`A code-first approach doesn’t mean that we won’t think through on API design. Instead, we end up configuring multiple annotations scattered across the project.`}</p>
    <h3 {...{
      "id": "api-first"
    }}>{`API-first`}</h3>
    <p>{`An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API-first`}</code>{` approach means that we start understanding the business requirements and have the specification documented in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OpenAPI`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`RAML`}</code>{` followed by code implementation.`}</p>
    <p>{`This would ensure that the api specification would be the source of truth to both frontend and backend developer and would not cause any last minute surprises during integration testing.`}</p>
    <h3 {...{
      "id": "api-design-first"
    }}>{`API Design-first`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`API design-first`}</code>{` is inline to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API-first`}</code>{` approach, but a step ahead on focusing more on designing the specification in an collaborative and iterative approach. By defining and adopting few standard design principals, every team or tool would adhere to them and understands the same API design.`}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`design-first`}</code>{` approach, we spend lot of time on design, collaboration and reviewing the specification which would satisfy all the involved parties. This process can add couple of days or weeks to the delivery, but it’s worthwhile to invest on this which would greatly minimize additional thought process or reworks when implementing the API.`}</p>
    <h2 {...{
      "id": "starting-with-api-specification"
    }}>{`Starting with API Specification`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`API-first`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API design-first`}</code>{` share the same importance on documenting the API specs prior to start building the API. It is the source of truth to all the involved parties revolving around the API. The additional aspect of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API design-first`}</code>{` is to adopt and adhere to defined design principals set between the teams or within organization when we build any API.`}</p>
    <p>{`With either of these approaches, we start our build journey by drafting the API Specification (a.k.a spec) using commonly used `}<ExternalLink href="https://swagger.io/specification/" mdxType="ExternalLink">{`OpenAPI Specification`}</ExternalLink>{`. `}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OpenAPI Specification`}</code>{` is a standard format which define structure and syntax to design REST APIs. OpenAPI documents are both machine and human-readable, which enables anyone to easily determine how each API works. Engineers can use it to generate server/client stubs in different technologies, implement their services, and also perform contract testing.`}</p>
    <ExternalLink href="https://editor.swagger.io/" mdxType="ExternalLink">Swagger Editor</ExternalLink> is open source editor to design, define and document OpenAPI specification. This is the mostly used tool which is accessible online or can be cloned and deployed on-premise for internal use.
    <p>{`Likewise, there are many online/offline editors available which can be used to document or view OpenAPI spec. For the purpose of this article, we shall use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{`.`}</p>
    <h2 {...{
      "id": "why-use-apicurio-studio"
    }}>{`Why use Apicurio Studio`}</h2>
    <p><ExternalLink href="https://www.apicur.io/studio/" mdxType="ExternalLink">Apicurio Studio</ExternalLink> is a web based API Design suite which supports Design-first REST API development. It provides way for users to collaboratively and visually design APIs prior to starting the build activities.</p>
    <p>{`Apicurio Studio is part of `}<ExternalLink href="https://www.apicur.io/" mdxType="ExternalLink">{`Apicurio`}</ExternalLink>{` suite of products like `}<ExternalLink href="https://www.apicur.io/registry/" mdxType="ExternalLink">{`Apicurio Registry`}</ExternalLink>{`, `}<ExternalLink href="https://www.apicur.io/datamodels/" mdxType="ExternalLink">{`Apicurio Data Models`}</ExternalLink>{` and `}<ExternalLink href="https://www.apicur.io/apicurito/" mdxType="ExternalLink">{`Apicurito`}</ExternalLink>{`. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio`}</code>{` is completely open source backed by `}<ExternalLink href="https://www.redhat.com" mdxType="ExternalLink">{`Red Hat`}</ExternalLink>{`.`}</p>
    <p>{`This article doesn’t provide deep dive information on how to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apircurio`}</code>{`, rather we can just focus on how to get it up and running for local development activities.`}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Having Docker installed on your machine is helpful to get quickly started with Apicurio Studio.`}</p>
    <p>{`Install `}<ExternalLink href="https://hub.docker.com/editions/community/docker-ce-desktop-windows" mdxType="ExternalLink">{`Docker Desktop`}</ExternalLink>{` to get started with Docker on Windows.`}</p>
    <p>{`Post installation, run the below commands in command prompt to verify if docker & docker-compose are installed successfully or validate if they are working as expected if docker is installed previously.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` -v

Docker version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20.10`}</span>{`.8, build 3967b7d`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Compose Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` -v

Docker Compose version v2.0.0`}</code></pre></div>
    <h2 {...{
      "id": "apicurio-studio-docker-images"
    }}>{`Apicurio Studio Docker Images`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` is collectively made of below individual components.`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/r/apicurio/apicurio-studio-api" mdxType="ExternalLink">apicurio-studio-api</ExternalLink> - The RESTful API backend which is used by the user interface.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/r/apicurio/apicurio-studio-ui" mdxType="ExternalLink">apicurio-studio-ui</ExternalLink> - The user interface component serving HTML,JS and CSS for the Studio.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/r/apicurio/apicurio-studio-ws" mdxType="ExternalLink">apicurio-studio-ws</ExternalLink> - Websocket based API used by the Apicurio Studio Editor to provide real-time collaboration with other users.
      </li>
    </ul>
    <p>{`These require the below components for bootstrapping Apicurio Studio successfully.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SQL Database`}</strong>{` - Apicurio Studio requires database to persist data managed from the studio. We can configure `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Postgresql`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Mysql`}</code>{` for the same.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Identity and Access management service `}<ExternalLink href="https://www.keycloak.org/" mdxType="ExternalLink">{`Keycloak`}</ExternalLink>{` is integrated with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Apicurio Studio`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Microcks`}</code>{`.`}</li>
    </ul>
    <p>{`Optionally, we can integrate with `}<ExternalLink href="https://microcks.io/" mdxType="ExternalLink">{`Microcks`}</ExternalLink>{` which allows us to transform the API spec to create live API mocks. This is helpful for consumers to start integrating with the mocks when API is still under development stage and not yet fully implemented. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Microcks`}</code>{` is a topic for another article.`}</p>
    <p>{`Below is a quick snapshot on how these components depend on each other. Considering multiple dependent services, we need to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` to orchestrate provisioning Apicurio Studio to make it functionally up & running.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2c49b7d892ce9b8902f7c48111662a3f/dc616/apicurio-components.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.282208588957054%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB4klEQVQoz3WS2U7bQBSG/f6vUUJvIC3hrjeUsjQlhUCTOCvG8UI8zoy3cWb1zKmySEWl/TQa6Rzp1382B97RNIbSWgjN5fYxoZRSWu1+rfSOXaQcSmlZVlVVlWWptQaACGVejOcJWZGsyPOK1lyBsRYAamGmr3VIxN7GOT05aR19+HjcOm4dff7UnkxmUtu8pFxqubVpdGOEslxu5ahUZ7eTHzMEANZaZ/IcDPzUXeKBn468OAgjAJBSkEokhVjlfF0xsLYxYAGYNOMgiwk7OI/89Mu9377on9+Oe/M0jFYIF4sQdadJ58btXI+uRnFZ0aYx2ti/puPMAvTTI1/7i+40ccM8TdOa8VVKhkvcHcffx/EvH1NKuZBcyA0XASpQVh3ElNbberjgUnEhCSGNMbTeuAF58PCDh4dLwjlXjQWwQULOu7O7SbzrGZz1es0YyzJSU0owjqMoRni4CHtzdHY96lwNb8avRbUxFkyjSVl33WAarrdiAGezY78qxpiU0hizYcwNyP0C9WaroY+NMfvF7or907nz5jYarbW1Ji+qGJGBjy8fX749vTx6a8b4/gSstW+HdhDvk8YYAJuS4jlCfT9vXz6dXvTvFphxAf/CeZ+y1nLOUEajNA9RnpBK/YffhSugi1H1OXYAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apicurio Studio architecture diagram",
              "title": "Apicurio Studio architecture diagram",
              "src": "/static/2c49b7d892ce9b8902f7c48111662a3f/a6d36/apicurio-components.png",
              "srcSet": ["/static/2c49b7d892ce9b8902f7c48111662a3f/222b7/apicurio-components.png 163w", "/static/2c49b7d892ce9b8902f7c48111662a3f/ff46a/apicurio-components.png 325w", "/static/2c49b7d892ce9b8902f7c48111662a3f/a6d36/apicurio-components.png 650w", "/static/2c49b7d892ce9b8902f7c48111662a3f/dc616/apicurio-components.png 933w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Apicurio Studio architecture diagram`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "docker-compose-based-installation"
    }}>{`Docker-compose based installation`}</h2>
    <p>{`This post is based on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio's`}</code>{` official `}<ExternalLink href="" mdxType="ExternalLink">{`github repository`}</ExternalLink>{` which has multiple files. Repo that we use in this post is a slimmed minimalistic version of the same.`}</p>
    <p>{`Clone the `}<ExternalLink href="https://github.com/2much2learn/article-rest-api-design-first-development-with-apicurio" mdxType="ExternalLink">{`Repo`}</ExternalLink>{` and execute the below sequence of commands to orchestrate the services deployment.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Clone the repo`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/2much2learn/article-rest-api-design-first-development-with-apicurio.git

λ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` article-rest-api-design-first-development-with-apicurio`}</code></pre></div>
    <p>{`Replace `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<IP_ADDRESS>`}</code>{` in below files with ip address of your host where the services are orchestrated`}</p>
    <ul>
      <li parentName="ul">{`.env.template -> save as `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`.env`}</code></li>
      <li parentName="ul">{`config\\keycloak`}
        <ul parentName="li">
          <li parentName="ul">{`apicurio-realm.json.template -> save as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`apicurio-realm.json`}</code></li>
          <li parentName="ul">{`microcks-realm.json.template -> save as `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`microcks-realm.json`}</code></li>
        </ul>
      </li>
    </ul>
    <p>{`Perform below series of commands to build, verify and up the services`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Build services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` build`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Verify services that are provisioned`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` config --services`}</span>{`
apicurio-studio-db
apicurio-studio-ws
apicurio-studio-api
apicurio-studio-ui
jboss-keycloak-postgresql
jboss-keycloak
postman
mongo
microcks`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start all linked services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Verify if services are up and running`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`ps`}</span></span>{`
-- Display aggregated logs across the containers with one `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`command`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` logs -f`}</span>{`
-- or display logs `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` individual containers as needed by using the `}<span parentName="code" {...{
            "className": "token function"
          }}>{`service`}</span>{` names as listed above
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` logs -f jboss-keycloak`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` logs -f apicurio-studio-api`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`λ `}<span parentName="span" {...{
              "className": "token function"
            }}>{`docker-compose`}</span>{` logs -f apicurio-studio-ui`}</span></code></pre></div>
    <p>{`Wait for couple of minutes for all the containers to start successfully and get ready to accept requests.`}</p>
    <h2 {...{
      "id": "accessing-apicurio-studio"
    }}>{`Accessing Apicurio Studio`}</h2>
    <p>{`To design OpenAPI spec, the defacto tool is `}<ExternalLink href="https://editor.swagger.io/" mdxType="ExternalLink">{`Swagger Editor`}</ExternalLink>{` which lets us edit OpenAPI specifications in YAML inside our browser and preview documentations in real time. This requires no setup or registration. Once done drafting the spec, we need to save the YAML to local and later import to editor to modify it again.`}</p>
    <p>{`Unlike Swagger Editor, Apicurio follows a very different approach. It is backed by `}<ExternalLink href="https://www.keycloak.org/" mdxType="ExternalLink">{`Keycloack`}</ExternalLink>{` which is an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Identify and Access Management`}</code>{` service, which lets us create user accounts or the user can self register to start using Apicurio and has its own datasource to persist the users work.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` is configured to start on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`9093`}</code>{` port as defined in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{`. Service will be registered with the host ip that is configured in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.env`}</code>{` file.`}</p>
    <p>{`Access Apicurio Studio user interface by navigating to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP_ADDRESS>:9093`}</code>{`. This should pop up the login screen managed by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Keycloak`}</code>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "608px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/276fc32fa022ff282ef199977ddfb69f/18872/1-apicurio-loginscreen.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "84.04907975460122%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADB0lEQVQ4y4WS7UtTURzHD+VABEGHL0SQgQq+kVA3Z+lcbmtrc1o5Z+oW21m5O0orNah0Omc+Rfay/6R6MTZ8iIiI3kSllfaAbm96WE7d3b333HN/cTeLKLIPXw7f8+LL9/fjHBQIBPxZvFncWfx+P8Y+j8fjxdjlctntdpvNZrVaLRaLyWQyGAwtLS06nQ4xDMMEAv1+vwd7sc/ncrkx9g0NjzBM4OKliwOXB7HvfHt7e5vdbrZYjEZTq0FGr9fLYYwx9pxz9/X0dDt7urscZ06d7mi3nTSbTQazyajXNTUfa9S3NOt1zUe1mqONDVpNfaO2QX/8eFNTE/J4cWB4PHAtzIyEmJFJ//DE+StjePAmHrjhuXS9jxnp7R/q7R/q8w878WCXd+Dshas2h0utrq9XqxH2+T5uJkQAVpA4AixP0zxNC9mTp6wgsTxls4YTIc2LABBbXKmqqtJoNMjtdicSCQCglAJAKrW9/T2ZybB7uzs729ssu7ebSu3upDguAwCiKIdXlpdVKlVtbS1yudzxeBwABIFIMpRKEgDITvoTQggALC0tqVSqmpoa5HQ64/E4FSnPcfA/cs2Li4ulpaXV1dXI4XDkmjmOFwghRDxAHMcDQDQaLS4urqysRJ2dnYlEghCSTH4nhEgHkhs7FosplcqKigrU1ta2tbWV21kUqSjS/zbHYrGioqLy8nJktVpz4fTeXoZNZzeX/iVRlJsjkUh+fn5ZWdl+WALYZfk0R9I84QTKCTQjUDZrflc6I+TCCoWipKQEnTBbkp8Td6LfqoIv1bNrdTNrddOrR2691i286bi3rpldrb21WjctSz2zWjn6YuUjfbwUPXQ4T6lUIqPR9OH9xqevmUdrX568+/ZTyacbyecfks/Wk/L17b6WX33e4eHhg/sKhUIOt7a2bm5u/vph+0gEQJAkKUOB/vXOkUgEIVRYWIi0Wm0oFFpYuDs7Nz8/f3t2bn56eiY8NRWaDIfCU6Pjk+OhyfGJibFgcCwYvDk6Fg5P9fX25uXlFRQU/AC23rA2nSPIAwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apicurio Login screen managed by Keycloak",
              "title": "Apicurio Login screen managed by Keycloak",
              "src": "/static/276fc32fa022ff282ef199977ddfb69f/18872/1-apicurio-loginscreen.png",
              "srcSet": ["/static/276fc32fa022ff282ef199977ddfb69f/222b7/1-apicurio-loginscreen.png 163w", "/static/276fc32fa022ff282ef199977ddfb69f/ff46a/1-apicurio-loginscreen.png 325w", "/static/276fc32fa022ff282ef199977ddfb69f/18872/1-apicurio-loginscreen.png 608w"],
              "sizes": "(max-width: 608px) 100vw, 608px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Apicurio Login screen managed by Keycloak`}</figcaption>{`
  `}</figure></p>
    <p>{`To get started, click on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Register`}</code>{` to create an user account which would eventually navigate to studio’s dashboard page.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/49f9c0678dcd903e12925bd8e1085e88/82158/2-apicurio-registerscreen.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.29447852760735%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADVUlEQVQ4y42RXW/aVgCG/Qe2LtIybLJQCE0ikSgBgkEE7AYcB2MHAuGjUXBDgJhC7vcHtptd7XrTQiHKUtJO7CNqe9Wom3Y1TZM69WbSpqJKu2lIVrzFXexjczbjrUunZd2j9+KcIz3nPR9IuVwuFovr6+sbGxuCIBQKhatra0K5XK1Wy9fK1WqlVCqlUqlEIsGybPQMDMMggiDk+xSLxXw+z/M8n8tVKtc2NzcFQahUK8VSIZ1Ox3ViXB+GYRYWFiiKQkqlUj6fv7KysrycWozFoyzHLcaiHEfN0+HwfCg8PxcKk+RlgiAJkgwEiUCQoGma6qPLV7KZd959b//g61t3vvj47pf/yM3b95v7B839gxuf37t5+37txmcRJkoQBEmSSKFQWFpaarVaEEJN0+Bf9Ho9WVa0Xu/sCoSw2+3G4/HZ2VldXl1d5Tiu2WxCCKVnElCA1gcAIEmSLMuaqhpRAYAQdg4PWZb1+/26nMvlWJbd29uDEJ78egKA2utzttDAONfx8THLsj6fjyAIhOd5hmGM5tPTU3A+iqIYcjQa9Xg8OI4j2WyWoqjd3V0IoSiKAIC/u17EaD46OqJp2ul0ulwuJJPJhMPhnZ0dCKEsyy9tNuSpqSmn04kkk0mCIAxZkqSzlzyvmaKoiYkJXTbefXt7+/83kyQ5Ojo6OTn5gtztdlVVhedgfMFz2eFw6HIgEGg0GhBCRVHUPtq/Yez7XB4fH/9TrtfrevPTp6IoStKz/5aDwaDNZrPb7UgkEvF4PLWtLQjhb6eyArR+VAWoQNVjjBWgygowZK/Xi6Ko1WpFaJp2u91bfVkBsqq95M6dTmdmZgbDsOHhYV12ulw7ja2HT2D6/R/WrrevXm/ztfZ6o/1W66fKR4/5mr6yVm9nPvjx7buHTw47M243ZjZbLBYkFAo5HI567cMugHvf/PzJA9HIp9+J974/ufPwl5YxfSDe+rb71SOl0zlyu10mk8lsNiN+vx/H8blQKJVcyqXjq6nYyvJiNsGmYpFkjFmORZIcneToBDu/SF+m5wI4jg8NDWEYOjg4iPh8Pq/XOz09PTY2NmIfHbFfGrFfstpGLBetF622N4ctJhQzodjrg29ceG3glVcvDAwMoCiKYdgf8u+u3hud1eJm2AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Register account to login and use apicurio studio",
              "title": "Register account to login and use apicurio studio",
              "src": "/static/49f9c0678dcd903e12925bd8e1085e88/a6d36/2-apicurio-registerscreen.png",
              "srcSet": ["/static/49f9c0678dcd903e12925bd8e1085e88/222b7/2-apicurio-registerscreen.png 163w", "/static/49f9c0678dcd903e12925bd8e1085e88/ff46a/2-apicurio-registerscreen.png 325w", "/static/49f9c0678dcd903e12925bd8e1085e88/a6d36/2-apicurio-registerscreen.png 650w", "/static/49f9c0678dcd903e12925bd8e1085e88/82158/2-apicurio-registerscreen.png 696w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Register account to login and use apicurio studio`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/76897eb5083a7f49e57a40ade49993d1/f2d92/3-apicurio-dashboard.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.89570552147239%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0UlEQVQoz42RS2sTURiGZxWw2zadGZuLl0UXtk1IS7EgEly4yMpFDK5cRwiKF2y17QRq/QF2G0IW2eVv5IK7+hMsOM3MlDM445zrnItMB4aCaH34OHybl+fjPdp6ubxRWMkbxspNc2HhRi6XW87nlxYXk1lKRteXdV0vFAqVSqVerzebzUaj0Wq12u22tlnd3Fi996LTsY4+vXzzrvPq9dvdD+8/HuwdWLv7h3v7h5Zldbvd4+PPX05Oer3eYDDo9/vD4XA0GmmPnz5/9OTZ6ek3pZQQggtBY66uIC5RfyCl1GrbD9ar2+PpjMfMtm3P83wAPM9zXde7uKCUEkIQQhhjhFC6Z4u2Vru/ulabzr4yLn84wAU/PT/wgyiOYx7HUkqMsW2fn519n8/nAADf9wEAQRBgjLWtnYfVrZ3pbMaVckPmQw6ZjKiAVMQiuQ0TgjGGEKb+lNSv3b5zt1y+NZlMlFIIE8oYIRQnwyhljDEIYRRF2XsVrVgsmqY5Ho+TbjhXUmaNKCWFEAgh+Be0UqlkmmZqTlsNL8kqvSZsGEZq5jz5JMd1Hcf5r3B6dmYmhEQQ/ooijIlSilJ6fTg1SykhhI7j2vY5hFBKGYbhP8K/AUoFMqUyL9bVAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apicurio studio dashboard",
              "title": "Apicurio studio dashboard",
              "src": "/static/76897eb5083a7f49e57a40ade49993d1/a6d36/3-apicurio-dashboard.png",
              "srcSet": ["/static/76897eb5083a7f49e57a40ade49993d1/222b7/3-apicurio-dashboard.png 163w", "/static/76897eb5083a7f49e57a40ade49993d1/ff46a/3-apicurio-dashboard.png 325w", "/static/76897eb5083a7f49e57a40ade49993d1/a6d36/3-apicurio-dashboard.png 650w", "/static/76897eb5083a7f49e57a40ade49993d1/e548f/3-apicurio-dashboard.png 975w", "/static/76897eb5083a7f49e57a40ade49993d1/f2d92/3-apicurio-dashboard.png 1027w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Apicurio studio dashboard`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "design-openapi-spec-using-apicurio-studio"
    }}>{`Design OpenAPI spec using Apicurio Studio`}</h2>
    <p>{`Click on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`APIs`}</code>{` on the left navigation and choose to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Create New API`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Import API`}</code>{`. For the purpose of this article and to get our hands dirty quickly, choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Import API`}</code>{` and select `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Import from URL`}</code>{` to import the famous `}<ExternalLink href="https://editor.swagger.io/?url=https://raw.githubusercontent.com/2much2learn/article-rest-api-design-first-development-with-apicurio/master/specs/petstore-openapi3.yml" mdxType="ExternalLink">{`PetStore OpenAPI 3`}</ExternalLink>{` specification .`}</p>
    <p>{`Provide `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Petstore OpenAPI`}</code>{` `}<ExternalLink href="https://raw.githubusercontent.com/2much2learn/article-rest-api-design-first-development-with-apicurio/master/specs/petstore-openapi3.yml" mdxType="ExternalLink">{`spec url`}</ExternalLink>{` and click on import. This should create and list the API as below.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e2cff05dc975e11ca8cecaedd2604bb2/1ac66/4-apicurio-importurl.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "63.190184049079754%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACOElEQVQoz33QzWoTURQH8HmCNIQ2Kk0yH5mYpvloMyVt2tIq4qYFFVF0IRjwEUotKq3WD3wDV67d+CYFg2Qi04U7kUwmzWQyc2fuvXNnMkcSTHHR+uNwVvf8z+VwyXS6sbFZTCSK1epCPl8ul4vFYjwej8VimUxGEARRFIUJSZJEUeR5XpblbDYryzJ3VRDuLCwsJ5NLtdVKuVyv1xVFmZubSyQSkiTlcrnJU/n6RG5qcXGxVCpxklzIlZXZlPi08aytqicnJ81ms9VqqW1V07RT7VT7x48pTdNUVeWUte0Hj58otc3DN+8AwGcsYMEoDIMgpL5PGfOZT3yfMYYpjaIIpqIo4qprW8trW1Kh8uroGABc13URwphgTDzPcz3XdT1MCMbYQYgxFoZhEI5RSrn1Wzu7jxrVjZvH7z8CQBiG58FwsWhSwBjjatu3d+7er6zUX7/9AACmOeibg36vp3cNw+h19K6D0IUZ4+FCZaW2fiOTLRy8PAIAgsmIMY+NOqatm0N9YOumbVjIGKLuwNEtp2s5hoW6locw5UpKfffew+LS6vMXhwBg245j25bjdvpDvW/97pmGhQYuMRExETZd3EfkzMFnLvWozwmSnMsXZq9c298/GG+mlPpj43MHAWMsGo0u/XZqfl4Q+JmZ2N7eHgAEQXDBiSbCScpPE3/6Znz+3tOHHpdKpUVRjMfj/xmeRoz7L5t+aZ991cyeg7l0Os3zfCz2dzNCiFwOYxIwChGDiFFC/gC9pimOkVJxCAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Import OpenAPI spec from url",
              "title": "Import OpenAPI spec from url",
              "src": "/static/e2cff05dc975e11ca8cecaedd2604bb2/a6d36/4-apicurio-importurl.png",
              "srcSet": ["/static/e2cff05dc975e11ca8cecaedd2604bb2/222b7/4-apicurio-importurl.png 163w", "/static/e2cff05dc975e11ca8cecaedd2604bb2/ff46a/4-apicurio-importurl.png 325w", "/static/e2cff05dc975e11ca8cecaedd2604bb2/a6d36/4-apicurio-importurl.png 650w", "/static/e2cff05dc975e11ca8cecaedd2604bb2/1ac66/4-apicurio-importurl.png 651w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Import OpenAPI spec from url`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "579px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/448729207eef898c679e40aa95320547/c08bc/5-apicurio-importedapi.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "88.95705521472392%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADIklEQVQ4y2WT7WvbRhzHlTeBUujaNE+rJMcmD3XqOMSp2yQE3GV5WineYIV2UCh7txkKZSyQNv13+k/0XV80drI4MEbYi+3FFls66WRJPj2cT4+nTrrQjezDcfoe6MPvfroTd+Xq1eVSqTg6ev3mzVwuNz4+zvP85OTkjYx8Pj+TMTs7y8Lc3NzMzMz09HSxWOSuXLv2/df1Z9c/q1SrtVqtVCptbm4uLS0NDw8PDQ2NjIxMjKeMjaZMTKSrsbExlrnJW4IgTAmfC7f43NOn3714+VOj0fjhR0bj55S9vb29/f39NwdvDg4OXmewwM0v3lutba1v7C5UVn797SxJkiiKPM+LGZQGYZg9k//DlSsrX+482tx5tHx//cOHwzgKTYQs20G2k86WzXIfWWyY2UCWZds2V61tV1ZrS2sb99ZqxyftOI5NB/ds3Md+H3uGS0zXy7Jvup7hEEQCRAJ74GHscms732zXH+8+eb7+YOukfUpp4mBLMySoaqoC+/2+YRiu6+qGrusGQohSGgSBRzyMMVd9sLux/XDrYf3++he/ZJV930+ShGaw3v6b2dL3fdd1udvl5XJltby8WizfPTo+ppQSQjStp+smVKGqqkBRelqvp2mKokCtZxhmTGkYhql8Z7G6sVv/qv5tubLSOrqQoQYhTN8GqgZ0SzUd2XAMhBQVQlULM1J5erY4N78wX1q8fWeh1TpKKB0MBt2udN7pnnekriR3AZRU/S9JVaAGe2nlJEnCMEhlgecFnhcFoVAotFot1o9t232ErAzsuo5t+x6J4uhT20HAZEEQRTGXyzE5juMoCnVd70hABirUdKBACajIsgkh2W2hl2VRFPP5fLPZzORIBuBvxVQtoqKBZDjAdF08CHz/0ze/kEVRFDIKhUKz2aSUeoT42P3j97PT46PTdrt9ctLpdNgJXd4227MgCKxyQikm3hnot897fwIDO052ER0/CPyUdI6i6F95amqKyYeHzSRJAMKv3kuNd523Z0b6n8SxCqEsybIMAFAkSdZ14+KoWMM8z7PKlFKMMXKJaWMHE99j+JcghPwjfwSeTue2YgN2awAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Imported API",
              "title": "Imported API",
              "src": "/static/448729207eef898c679e40aa95320547/c08bc/5-apicurio-importedapi.png",
              "srcSet": ["/static/448729207eef898c679e40aa95320547/222b7/5-apicurio-importedapi.png 163w", "/static/448729207eef898c679e40aa95320547/ff46a/5-apicurio-importedapi.png 325w", "/static/448729207eef898c679e40aa95320547/c08bc/5-apicurio-importedapi.png 579w"],
              "sizes": "(max-width: 579px) 100vw, 579px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Imported API`}</figcaption>{`
  `}</figure></p>
    <p>{`Click on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Preview Documentation`}</code>{` to view the specification displayed in well formatted output powered by `}<ExternalLink href="https://github.com/Redocly/redoc" mdxType="ExternalLink">{`Redoc`}</ExternalLink>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/263d7f44ae5cec549433b9b5861bc722/f2d92/6-apicurio-previewapi.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.89570552147239%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB6klEQVQoz33SPW/TQBzH8XsD0BblwXbSOLGdxClJSmMilfRRIAYWxNoFJBgQr4CRgZEV8Z4q0TQJGWAghPhs123iu7PT2D7bMVSFSq0Cp4/+2/c3HdA1/cQ0MSEYIULIuePYENqa5kBom6Z9erqQc3aGJxPgU+p5bhiG0eWj1BuNfAipYQSWFSC0UIix6zjAVDVrMrEsazweY4zj+TzGOEbo4npeTOm/hEEA+v3BcDiCEKqqqul6p9c/bB8fdbrtTrfd7ba7vYWOe18OPx+BrycqJsSx7YBSqOnN/cfKeqtUbXBSJVtc+w+xroCB+Z3YmGASUKrphrL7qLG2KVeVTFFmCkVWKC3EiSWx9icmU2cax/Hgx/DZwfMPHz+9fff+7uaufH+r0txeSG5ul5UW+GZaE4QJxr5PB8Ofe0+evnr95uDFy4woZ6TKdTIn/iWUheoGcDQDI4QJicJQ0431Bzu3kkxBrmalciLLJ1fzV9I5gcmLTF5kCxLDi7xcA8MBRNbF94iiCOqGsvPwdiK9nGKXEsxKmruynGJTmRyXF7i8mBUkli/wch2g6cz1PNd151GkavpGay+VyZXr91hJuMNwN/qL0SRzOc1X6mA2O6eU+r4fx/EIwsbW/lKSqTWaq6XSyvX4xtDv+BcBlIGgUnK8CwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Preview API",
              "title": "Preview API",
              "src": "/static/263d7f44ae5cec549433b9b5861bc722/a6d36/6-apicurio-previewapi.png",
              "srcSet": ["/static/263d7f44ae5cec549433b9b5861bc722/222b7/6-apicurio-previewapi.png 163w", "/static/263d7f44ae5cec549433b9b5861bc722/ff46a/6-apicurio-previewapi.png 325w", "/static/263d7f44ae5cec549433b9b5861bc722/a6d36/6-apicurio-previewapi.png 650w", "/static/263d7f44ae5cec549433b9b5861bc722/e548f/6-apicurio-previewapi.png 975w", "/static/263d7f44ae5cec549433b9b5861bc722/f2d92/6-apicurio-previewapi.png 1027w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Preview API`}</figcaption>{`
  `}</figure></p>
    <p>{`Now the fun and exciting part, Click on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Edit API`}</code>{` to start editing the swagger using the graphical user interface. With multiple options at hand, Choose to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Add`}</code>{` a new `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Path`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Parameters`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Data type`}</code>{` or modify existing elements.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/9685e/7-apicurio-editapi.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.37423312883436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABvUlEQVQoz4WQTYvTUBSG8w+6nsE2+EEXTSluhJmFIAhd6EKh0HUXM/9M0I0gM0yRdiwYynRhC6IjTtoGXExSaZr2fuTm5ib33CtJnQ8E9dkc7ns4933PMR6a5r37D3Z3dkql0p2CSqVSLpcrBaZp3i2oVquNRqPZbLbb7VardXhw0Ol0jL1He6/evD7pfXj77ui4+/6k2+31ev1+/7T/m9OCwWBg2/Z4PJ5MJsPh8KNtn41GxtNnL75Pv4CUWZoqAMgykFL/D5EBABjN5y8/nU8XOF4Q7uPYQ/FPFOGIJQmXfwEAFIAQwth//OTzN2cZoAWKcCKxAJbKIAg8z6dRpLVSCtSf6CzLCCGGZVmzqbMJQ8jSbSQFgDGOKdHwj/wqCAKjXq9PZ7OUMkjEVpYAeL06cy5HF652BxSFlDIaFeSFEUq1Usvl0rCs+mw+Z4xlV3cCpdBmfeGtzn948eVXjNaIkA1CmGyhCOEkEflwrVZzXTfmHAByWymFEBiTmLGbiEqtwpBzfv3UWuexLctyHAchvJWKlsIYE0KTROhCVEoJIdJU3F6aMZY7z+cugLrd8H2fFc43973++ArO+S9f9CksrBi2mAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Edit API",
              "title": "Edit API",
              "src": "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/a6d36/7-apicurio-editapi.png",
              "srcSet": ["/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/222b7/7-apicurio-editapi.png 163w", "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/ff46a/7-apicurio-editapi.png 325w", "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/a6d36/7-apicurio-editapi.png 650w", "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/e548f/7-apicurio-editapi.png 975w", "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/3c492/7-apicurio-editapi.png 1300w", "/static/4d71f53fd5b6d11d85f4e225e0ea9e8e/9685e/7-apicurio-editapi.png 1336w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Edit API`}</figcaption>{`
  `}</figure></p>
    <p>{`Compared to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Swagger Editor`}</code>{`, It’s breeze to update the spec using inbuilt `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`source`}</code>{` editor. Click on the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API Title`}</code>{` and navigate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Source`}</code>{` to view and edit the spec. To update a specific `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Path`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Data Type`}</code>{`, click on the element and view or edit the source.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c777a2961adde4f0baa6ba20ac891004/4e814/8-apicurio-editapielement.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.828220858895705%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABu0lEQVQoz4WPXW/SUBjH+z0gLhC1tB1uGW36gq1UIU7mywWJwYtFb4xjMF3CN+CaK3bLx/LKuChSlsBwZ+1pT0/PSzFskGii+Mtz8zzJL///I1Rct/rk8UG9/nR/33Vdy7JM09Q0TSupJXWFpmm6YdiPHMd2dF0vqarjlG3bFnRV1Xd3isViPpfP5XKyLEmyXBBF+X5BLBREUZQkSVEUZVvZ2ds1DbNSqdRqtZcvnr1pNoV7+buavH188vH9h6PDt++OWsetVqvT6bQ7S9rt9skNp59Ou91ur9cbDAZng7N+vz8cDgXVrj5vvD7/8SVhC0w54ymldPEfUpTQAELBrB40modjbxwjFKMVURT6MIoTwihlf0IIoZRyzimlwoM9vf6qcTH2WIzJOhMhBAKIyXJNf4NzHkVRkiTxDcKdra2Htj36PprNLhNCbmXf930YzAEIw3BDeyGTyViW5XkTHMAFZelahiiaA4Ax3ixny+XyxWQSQ8gZu70CAK78AAQI45SnC8b/PstkwzC/jryZHwUJg4Rfx+Ty5xWKMWX8X9pKzmazhml+Ph9/m11PQzKN6DSkc+DT9f8bav8CJe7hyltoJt8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Edit specific element",
              "title": "Edit specific element",
              "src": "/static/c777a2961adde4f0baa6ba20ac891004/a6d36/8-apicurio-editapielement.png",
              "srcSet": ["/static/c777a2961adde4f0baa6ba20ac891004/222b7/8-apicurio-editapielement.png 163w", "/static/c777a2961adde4f0baa6ba20ac891004/ff46a/8-apicurio-editapielement.png 325w", "/static/c777a2961adde4f0baa6ba20ac891004/a6d36/8-apicurio-editapielement.png 650w", "/static/c777a2961adde4f0baa6ba20ac891004/e548f/8-apicurio-editapielement.png 975w", "/static/c777a2961adde4f0baa6ba20ac891004/3c492/8-apicurio-editapielement.png 1300w", "/static/c777a2961adde4f0baa6ba20ac891004/4e814/8-apicurio-editapielement.png 1335w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Edit specific element`}</figcaption>{`
  `}</figure></p>
    <p>{`Similar to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Swagger Editor`}</code>{`, we can preview the changes in live by clicking on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Live Documentation`}</code>{`. Any changes made to the swagger will be immediately reflected in the documentation view.`}</p>
    <h2 {...{
      "id": "collaborating-with-apicurio-studio"
    }}>{`Collaborating with Apicurio Studio`}</h2>
    <p>{`One of the hidden super power in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`collaboration`}</code>{` feature. API Design with mutual discussion and constant review with the team is the fundamental principal for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API Design-first`}</code>{` approach which is well versed with collaboration feature.`}</p>
    <p>{`User who wish to collaborate with other users can share the collaboration link of their API. Once the other user accepts the invitation, they can start designing the API spec collaboratively.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9b3631b2636f23c8b146c94988397629/d54e4/10-apicurio-inviteothers.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "152.76073619631902%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAfCAIAAABoLHqZAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADPElEQVQ4y6WVzW4jRRCA5+0WDjwAAol34AEQF16AG09AtBwQe+FE0EoRy4EY2ESChESbBNvj6f//rv4d1B7bcZxlFcSncrunpspd1d1V7hATmEvMJVeGCoWZ0D7EMoZc3ynFhtSBd965ABBjDCHEEHLOYx3H2j5tbJOxHj6WlFIntOPaCSm5EIILzjljDBPKOKeMYUyahnNCGWNNQwglhKaUx3Hs5ljerhjhYkCESaONdd4ba51z1jljrffONrxpOuect86VUprz7ewl/us1uvxt+ccv8s1ZDDA+mY6en9ycvrz4+fju95+qWEzZ1TXbVO934ND5m/Ph1VUvhOTaPPrpB+Y55yls771zLufcfTUbTvvmVmotJZcNtaRQ+j9Lim1eSq0VAFADY0wQQt77bqyx5AgQACDs410kdwH8vi7tEWPsECGEMa31lN6/Bj1uNmIyi43Q5S27d5u4a90fD5hsuvF/0MUYtdZSSsa4UlpKRRmjlEmpMCaUUs4Fme6XlMZarTVCWCrVnPeTmdDaUMaF0kzI5iWEUjqmOB3/ONaU8uaGHUSSUtLGRPBjcDXBGH1N3hjjnHtL2Ic7vN7iGw7fX4njN/L4Rv14o/Kjg9g4T9duJ7k0u5M79ekPt5+f9J+9nH9xMg+5jA/N6ltX3oshj/JmrOWdhSHkTthWqFCEUnJ3SRgnXDKhtq9UE6moUNrY7sNvrz767vrjF9efvLj+4Pnls68v3jvayvPr+/mevH908ezo4stXd91rDGcYzgicE/gV+VkTOB38bBIEs8E/eFxrZoO/xqaLVgVrotU5uJLASw5aZtc0NUL2NhiVvC3+8KhSgG6+6JerYb7sl/0wX/SL5WrZDwPCi37VD2hApO+HfsD9gPoBrRBerAZEqIfgADrG1p2NMiEl4zyEmHNOKaaUpoJJKe9qMG6+mg0AdFppa50xJqXU2kWrofKUqvAA3aLvF8t+NSAllXPOGOuse4pzW3nZrxbLJUbETP3WuVrKU50xJoRQ7z0AeADv/ZTtOs31uE07512HKznn1sMobbUqhLxHKfEIzrkxxm9RSllrO9jgHwMP2emnPw6t9Wbl/wRC6O/5nFL6D8Hy5rNrJcUyAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Generate link to share to other users for collaboration",
              "title": "Generate link to share to other users for collaboration",
              "src": "/static/9b3631b2636f23c8b146c94988397629/a6d36/10-apicurio-inviteothers.png",
              "srcSet": ["/static/9b3631b2636f23c8b146c94988397629/222b7/10-apicurio-inviteothers.png 163w", "/static/9b3631b2636f23c8b146c94988397629/ff46a/10-apicurio-inviteothers.png 325w", "/static/9b3631b2636f23c8b146c94988397629/a6d36/10-apicurio-inviteothers.png 650w", "/static/9b3631b2636f23c8b146c94988397629/d54e4/10-apicurio-inviteothers.png 747w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Generate link to share to other users for collaboration`}</figcaption>{`
  `}</figure></p>
    <p>{`Other users who which to participate in the design process can navigate to the link to accept the invitation. Unregistered user should register first to accept the invite.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bf2992c3944712529ad318b61b130b7b/6af66/9-apicurio-acceptinvition.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.05521472392638%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+0lEQVQoz5WRsVLDMAyG+7o8FQNPwcSxdYQ7yACkVwpNaJsmtiQrtmwlnMsxkUD5Bg26/xukfzH+YBjyJKLlcgkAp80wTrEYZ0gpFUVRVdU/ZBEx1nbWOueCCCIZYwCQmf+WY0pBJIicRvQ+eO8dcwhhQk6qMWVijKoKFh6L4rlcleXqfVvV9cfTS/m6eTu27VcmfZPlvu8BEZGQyAIcj+1ufzg0zf7QbOtd2xlEdI7JsYUMEeU8uemHqapjFs+9bTX0zCwSz/120mEc9b7Gy7v97dpoyuecK+upl5s1XFxvrh6aXNVMnbM9S++4azzhOM+s7EMwAOT4F/kTivOCvkmtjY8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Accept invitation",
              "title": "Accept invitation",
              "src": "/static/bf2992c3944712529ad318b61b130b7b/6af66/9-apicurio-acceptinvition.png",
              "srcSet": ["/static/bf2992c3944712529ad318b61b130b7b/222b7/9-apicurio-acceptinvition.png 163w", "/static/bf2992c3944712529ad318b61b130b7b/ff46a/9-apicurio-acceptinvition.png 325w", "/static/bf2992c3944712529ad318b61b130b7b/6af66/9-apicurio-acceptinvition.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Accept invitation`}</figcaption>{`
  `}</figure></p>
    <p>{`When one or ore collaborates are accessing the same API in edit mode, their avatars would be displayed on the title bar and also listed next to the element they selected or updating.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/eb3fa/11-apicurio-collaborators.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.46625766871166%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACQ0lEQVQoz22QS08TARSFZw1EEloBg4IbTcAFulCDJO7ZIxpj/BEs/QFIkHfTWomRWBI3blj4A1z5CAlqUlKKjaml0047M51HZ+i8P8NgBI03OTknN7nfTY4wOTLM8MgIF4eGSCYSCIJAX19fnBOJBMlkkr5EgvP9/QwMDDA4eIHR0THuTExw/940D2ZmmJqaYnZ2lunpaYQrly7z8NFjXm2+5tnyKnPzC6ysp1hdS5FaX+N5JkMmnSabybDxIsvLjQ22cjnevdnkbWqBrVyWXG6L7e1t0uk0wvjNSZ48neOLavO+bPKxdsQH8YjPDZdPosWO1GFXDfimw9djaSfaqdvslkRcz+bI9THsDnt7eYTx23dZXFtHbrfZ/1Hlp6RQlhQqzRbfZYtDtU2tZaJYLrLlnMr2MDyIwoggCDieQqGAcO3GLVLpbLw4alkQRHEOfB/TNGP//0Sxoij8A8zn8whXx66zkkpDGOEbBo7jEAY+ODa6LGEoDfA74DtnQP+go+gUeK63l4WlZTTDplAs0TQsLNtCKx1itVTkehW1UUNv1lB0E820MAwD3TDQND32VkuLgcX9fYSenm7mFxZRbJdSpUbbcmnbNvWahGqYSE0VRTMQK2XKlQrVegNRFDmsitQlCVlWkBpNfD/g4OAAobu7m6WlpfiDpxvguBB6RJaC1qzSFA8x1AYdXcZ3HTzPx/O82IMwjO/C314sFhG6urpi4HENstTAcdyTun0XPAfVtJB0i5rZwXQjbC/ED//u7yzwFy5Jmmh/fa+DAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Collaborating with others",
              "title": "Collaborating with others",
              "src": "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/a6d36/11-apicurio-collaborators.png",
              "srcSet": ["/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/222b7/11-apicurio-collaborators.png 163w", "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/ff46a/11-apicurio-collaborators.png 325w", "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/a6d36/11-apicurio-collaborators.png 650w", "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/e548f/11-apicurio-collaborators.png 975w", "/static/6bc73ffb0b36f64e9b64c8ed4b48cd63/eb3fa/11-apicurio-collaborators.png 1026w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Collaborating with others`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "salient-features-of-apicurio-studio"
    }}>{`Salient features of Apicurio Studio`}</h2>
    <p>{`This is the introduction post on Apicurio Studio which covers basics on provisioning and getting started with it to design our first or existing API. There are many other salient features part of the studio as below:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Importing and managing `}<ExternalLink href="https://www.asyncapi.com/" mdxType="ExternalLink">{`AsyncAPIs`}</ExternalLink>{`, which is the standard specification created to implement asynchronous APIs part of event-driven architecture.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`API Designs can be published to git repositories hosted on `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Github`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Gitlab`}</code>{` or `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Bitbucket`}</code>{` by linking them.`}</p>
      </li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/ad12c/12-apicurio-linkservices.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.171779141104295%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABeUlEQVQoz12RTWvTQRDG9wsIIfc0kaI9tEUtfUFbBaG3Hj149RN5FyJNQwMVvPQgePUghfbgwYsiVk2gweT/si8zszuzTbc2kZDmd5lhmGGeZ0Y9fra7/HBzv93hlCygtdY5kDExxmkyi4jwuKg2t58vra7ttw9Tuur3+6U2ROS9JyJEnOazsPeRPSKqRxtPFpdW37baKaU8yyQgcxgMhlrroigsAAWZwiKjGM9zOOlqC6TWd3bvPdjqdDp5SB9/mrMLtICE/xeGEALfQpgd+twREqm9l6+e7r04fv/u6Ju58/rL/Tdf/2SWCQHRWkfes9wiipTgL8yNJbW8srJQv3vQauWcPnwvPncNkB9mWVGUxhgHiIHnZP/O4bRXOkRVq9UqlUqz2UwpCcGIPRFOWv1YtgjPcRkljcR7Uo1Go1qtToa1Mda5EEK31/s7GOSF1lrPamaRq8v4Y+g+/SosoKrX69PN1trJexAAiSwFdxNp/mAUSvD/PF8D8QTOvXxWOwEAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Link Services",
              "title": "Link Services",
              "src": "/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/a6d36/12-apicurio-linkservices.png",
              "srcSet": ["/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/222b7/12-apicurio-linkservices.png 163w", "/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/ff46a/12-apicurio-linkservices.png 325w", "/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/a6d36/12-apicurio-linkservices.png 650w", "/static/10c25aadf0baabb8e3d03f1f4fd6ce8a/ad12c/12-apicurio-linkservices.png 856w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Link Services`}</figcaption>{`
  `}</figure></p>
    <ul>
      <li parentName="ul">{`Exporting designs to `}<ExternalLink href="https://microcks.io/" mdxType="ExternalLink">{`Microcks`}</ExternalLink>{` to enable `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`API Mocking`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Contract Testing`}</code>{` capabilities.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "387px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6bc86ac861b883b842cb1865a7f0555b/691c3/13-apicurio-apimocking.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.282208588957054%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABLklEQVQoz42S626DMAyF9/6vN2m3tmubcAkQaG7k6pgNKNM2VVW/X5blEx/beQo+JEgAkDbCA3jvx3F8QkStjdHGGDNa672fHgAAZnHOmbGmrmtCSFmx04kg5kfFADDhhIhrFhFhIy/8jn+IMV47pzTPHFPKiDln3FgXgVvytm3eDx3ng7h0vC+rum27lnUNY23Hd/v9bn94e/+wzq2+/ojnKMWLkFIpY4yQkvNe62WBxjjrpBQA+fbM0zR5555fXgktGGtoUewOn0VZLSukZ0KPx2PL+/ltpW50DsGXNbPWGmO01nOZ1qMZpVJCCiHkRcjZmdb/xQBQVRUhtCwrSovzmRJCh2F49M5KKc551/G+7zvOm6ZVSl0vd1/snAshxJjiQopx/aHuLtbab/EX6q+20cB6GXIAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Microcks Integration",
              "title": "Microcks Integration",
              "src": "/static/6bc86ac861b883b842cb1865a7f0555b/691c3/13-apicurio-apimocking.png",
              "srcSet": ["/static/6bc86ac861b883b842cb1865a7f0555b/222b7/13-apicurio-apimocking.png 163w", "/static/6bc86ac861b883b842cb1865a7f0555b/ff46a/13-apicurio-apimocking.png 325w", "/static/6bc86ac861b883b842cb1865a7f0555b/691c3/13-apicurio-apimocking.png 387w"],
              "sizes": "(max-width: 387px) 100vw, 387px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Microcks Integration`}</figcaption>{`
  `}</figure></p>
    <ul>
      <li parentName="ul">{`Generate Project code from the design with option to choose from wide range of frameworks.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/18f0e05496d0cd2034f3c7119ee29268/081d5/14-apicurio-generateproject.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "35.58282208588957%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABEElEQVQY022PyU7DQAyG8/6vgnoJQly4cOTCja0pqIAoadJOnJmxZ7UHTSMQSHz6ZXmV7UajUVpri5M2s0VtrIt8/WYuN9PF+th2U9tB28Hq/rB6GNsOzjdzu57O7sar521DRDPME+gJIHgfYxQppRQRSTFUp0ZFI6Gjmq4Rl+QKcwOz/tjtdv1+HA9ElGJknHLmxOJDUgDGItf2qsyyiKWwSOPQKgX78TgMAxEF70QPywIiWnfdfhhijM5XmLn8onk/wM3j9mX72ve9UoqIlkLOOads0eacrUWor4G16LyXb5qn0d5+GskJ5hkAlmERQYvBe2NMjDGEkE+kvzRyunCxPzCzMSbFiIjOuZRS/o8vn++Qk3WjmOUAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Microcks Integration",
              "title": "Microcks Integration",
              "src": "/static/18f0e05496d0cd2034f3c7119ee29268/a6d36/14-apicurio-generateproject.png",
              "srcSet": ["/static/18f0e05496d0cd2034f3c7119ee29268/222b7/14-apicurio-generateproject.png 163w", "/static/18f0e05496d0cd2034f3c7119ee29268/ff46a/14-apicurio-generateproject.png 325w", "/static/18f0e05496d0cd2034f3c7119ee29268/a6d36/14-apicurio-generateproject.png 650w", "/static/18f0e05496d0cd2034f3c7119ee29268/e548f/14-apicurio-generateproject.png 975w", "/static/18f0e05496d0cd2034f3c7119ee29268/081d5/14-apicurio-generateproject.png 1264w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Microcks Integration`}</figcaption>{`
  `}</figure></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Import `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Data Types`}</code>{` & `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Responses`}</code>{` from other designs. This should save ample time if our APIs use some common responses or data types which we can import them instead of copy pasting to the source directly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`With `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Apicurio Studio API`}</code>{` as an independent backend component, we can integrate it with any other application or automate any specific workflow part of CI/CD process.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "access-keycloack-and-microcks-services"
    }}>{`Access Keycloack and Microcks services`}</h2>
    <p>{`Along with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{`, we have other services started which we can access independently using their specific urls.`}</p>
    <h3 {...{
      "id": "keycloak"
    }}>{`Keycloak`}</h3>
    <p>{`Navigate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP_ADDRESS>:9090/auth/admin`}</code>{` to access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Keycloak`}</code>{`. Login with credentials `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/admin`}</code>{`. Upon login, we should be seeing three `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`realms`}</code>{` - master, apicurio & microcks.`}</p>
    <p>{`Instead of users to register to access Apicurio Studio, we can create couple of users in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apicurio`}</code>{` realm.`}</p>
    <h3 {...{
      "id": "microcks"
    }}>{`Microcks`}</h3>
    <p>{`Navigate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP_ADDRESS>:9900/`}</code>{` to access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Microcks`}</code>{`. This is also integrated with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Keycloak`}</code>{` and would showup login page managed by keycloak. Login with credentials `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin/admin`}</code>{` to login as the administrator or we can register a user account to explore the features it provides.`}</p>
    <p>{`At the moment we don’t have user accounts shared across both the services, rather we need to register to these services individually and use them.`}</p>
    <p>{`More details on using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Microcks`}</code>{` will be available in subsequent article.`}</p>
    <h2 {...{
      "id": "stop--start-the-services"
    }}>{`Stop & Start the services`}</h2>
    <p>{`As multiple services are provisioned to try Apicurio Studio, these might utilize most of your CPU and memory. If you want to temporarily bring these services down, we can stop all of them with ons command and start them back when needed.`}</p>
    <p>{`Run the below command from the directory where the services were started to stop and start the services`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop and start services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` stop

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` start`}</code></pre></div>
    <h2 {...{
      "id": "changing-host-ip-address"
    }}>{`Changing Host IP Address`}</h2>
    <p>{`If there is a need to reconfigure the services with a new Host ip address, then delete the files that are created `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.env`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`config\\keycloak\\apicurio-realm.json`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`config\\keycloak\\microcks-realm.json`}</code>{` and recreate them from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.template`}</code>{` files as described in previous steps.`}</p>
    <p>{`Run the below command from the directory where the services were started to recreate the services to apply the recent configuration changes.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Recreate services with env changes`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d --force-recreate`}</code></pre></div>
    <h2 {...{
      "id": "cleanup"
    }}>{`Cleanup`}</h2>
    <p>{`Inorder to try Apicurio Studio as part of this article, we provisioned multiple services through docker-compose. These services should be stooped or brought down if no longer in use. As these services creates volumes and networks, they should also be deleted to free up some space.`}</p>
    <p>{`Execute the below commands from the directory where the services were started. These should bring down the services and perform the necessary clean up.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker cleanup`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` down -v

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` system prune --volumes

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` network prune`}</code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`To conclude, using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` is a great approach to visually implement API specification following Design-first approach. Non Technical folks can document the specs with breeze without needed to bother about the complex implementation using YAML or JSON.`}</p>
    <p>{`With the power of collaboration, a rock-solid API can be designed with other team members without needing to share multiple versions of the spec file over emails and messengers.`}</p>
    <p>{`Using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` would feel like a cumbersome when managing one or two APIs, but it would definitely feel worthwhile when managing many APIs with other team members.`}</p>
    <p>{`Integration with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Microcks`}</code>{` for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API Mocking`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Contract Testing`}</code>{` is another added benefit to try and test the API spec before it is shared to API engineers or consumers to kickstart their development activities. More details on using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Microks`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` will be covered in a subsequent post.`}</p>
    <p>{`I Would recommend all API Engineers to give `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Apicurio Studio`}</code>{` a try and use it for their side-hustle projects or empower their team to adopt it and follow Design-first approach in their organization.`}</p>
    <GithubRepo href="https://github.com/2much2learn/article-rest-api-design-first-development-with-apicurio" mdxType="GithubRepo">rest-api-design-first-development-with-apicurio</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      