import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Service Registration and Discovery in Spring Boot Microservices with Spring Cloud Consul",
  "description": "A detailed step by step guide on enabling Service Registration and Discovery in Spring Boot Microservices with Spring Cloud Consul",
  "date": "2021-01-03T15:46:37.121Z",
  "updated": "2021-01-03T15:46:37.121Z",
  "cover": "../../../images/blog-banners/springboot-consul-banner-1200x690.png",
  "category": "microservice",
  "tags": ["Java", "Spring Boot", "Spring Cloud", "Microservice", "featured"],
  "keywords": ["Step by Step guide using Spring Boot", "Service Registration and Discovery", "Spring Cloud", "Spring Boot", "Spring Cloud Consul", "Maven", "Java", "Java8", "Gradle", "Spring Cloud Config Consul", "Consul Registration", "Consul Discovery", "Spring Cloud Config Multi Module project", "Maven Multi Module Spring Boot Project", "Gradle Multi Module Spring Boot Project", "Spring Cloud Server", "Step by Step guide", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article_jan_03_srv-reg-and-discvry-in-spring-boot-microservices-with-spring-cloud-consul" mdxType="GithubRepo">srv-reg-and-discvry-in-spring-boot-microservices-with-spring-cloud-consul</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`In this article, we shall dig into steps on how to enable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Registration and Discovery`}</code>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Spring Boot`}</code>{` microservices with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Spring Cloud Consul`}</code>{`.`}</p>
    <p>{`Let’s understand the below `}<strong parentName="p">{`Problem Statement`}</strong>{` before we proceed further.`}</p>
    <p>{`With current infrastructure capabilities, services can be scaled up manually or automatically which makes them deployed to different dynamic locations (host & port). Configuring access to these services within application’s static configuration files cannot be a viable option. We cannot modify and deploy the consuming application with the locations to these dynamically scaled services.`}</p>
    <p><strong parentName="p">{`Service Registration and Discovery`}</strong>{` pattern comes to the rescue to overcome this problem statement. Service Registration and Discovery, is a mechanism which enables microservices to consume other microservices `}<strong parentName="p">{`without needing to know`}</strong>{` the exact `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`access url`}</code>{`.`}</p>
    <p>{`Enabling this capability within applications along side with applicable products & libraries, developers can focus more on the business logic to get things done and never worry about configuring access details to downstream services whenever they are scaled up/down.`}</p>
    <h2 {...{
      "id": "usecase"
    }}>{`Usecase`}</h2>
    <p>{`We shall look into implementing a simple usecase which can help us understand the configuration needed around registering the services and have them discovered for seamless integration.`}</p>
    <p>{`Below is the use case we shall go through in this article. This includes three Spring Boot application exposing RESTFul endpoint and one of them being the root application and the other two being downstream applications. All the three services will be registered & discovered with Consul by using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Spring Cloud Consul`}</code></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "321px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aa8779194e96894ac7c300dae4632fb1/30592/service-consul-registration.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "96.93251533742333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACnUlEQVQ4y42U/0+SQRzH+Qvrh5prc8u5tsg1N5aaZkFzYmkODMQvPzDdsobUZi3bUtsKkSAQecQExfyGkaKC8Hy753ieu/vUI4wWmvN2P93d696fe38+9zHAmcGAAcCbjZH2hRudgfrxRG9l+cww1JKMAsCukLKEGl+tPX+9MWIO3kzkowBAT7cugikjAPByfcDBdTi4difX4Yo/Gl2xXBLWTyxlA49DjRNJ24ukrevbrS/p6UvBAIAxKyng4NpMviut/ms9i0ZFAayc8+xz4CLPgAJfykUPAuGMv4CPgEGxeAnDFAUkqewcoxpguSzIRBEwvhBmugJQWgkQK0SSSHmLEBCEC+GqLADwM8nss0+H9s+l7Vz5ZlEEVf0PXJHVdFEpsHXs9OkOY01Z3Vf3eQBQVVYj/hdGCGQJ4DQfR655KOCVibnE2Bz+uiuGtsv3C4Ie/z8wY5RQelKglFIgOpwfC2Euk89kC+kDzGXk2F4ZxpjxAmVAyzk3MKbHySiQUsVkACj9Eg66Z5TIHgruoHiGoNPH6FXPmFqxkzGmKyfzUU9qcDI1+KPwHQAEgaoUyLF4MrVc/LBKeUWjwPMUGKSFlHfD5Vl3xg79ujLSJHOwoTtstAQbrZHbCGuaWlNMep41TS8ye6ylM1Dfu9jctlCXU7KGd1tj/VHT1KZ7MjXUt9g8m/YCBUmmCDEkUSQxhJgkUyAQ2p/rCTd51gffb43bllom1uyG8USfk3tgj7UOxNpsS/e8qSHQQJQJQlCdokyAwMcdz9PIXdfyw/6oaThucXAdhqz8s9V/fXSlazhuvr9QV8C5aj+oaQ+YKOZgg4Nrd68+MfmubhYTumGzu15r+I41bJzPTFf7wblfNXros4aNPZGmt5vuP4b9BpFvBexyC9n3AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"Spring Boot service registry with Consul\"",
              "title": "Spring Boot service registry with Consul",
              "src": "/static/aa8779194e96894ac7c300dae4632fb1/30592/service-consul-registration.png",
              "srcSet": ["/static/aa8779194e96894ac7c300dae4632fb1/222b7/service-consul-registration.png 163w", "/static/aa8779194e96894ac7c300dae4632fb1/30592/service-consul-registration.png 321w"],
              "sizes": "(max-width: 321px) 100vw, 321px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Spring Boot service registry with Consul`}</figcaption>{`
  `}</figure></p>
    <p>{`Rather than providing detailed explanation on the underlying concepts around Spring Boot (YAML configuration, Spring Profiles, Docker, Kubernetes), We shall only focus on configurations and commands to execute to have the three applications built and deployed successfully.`}</p>
    <h2 {...{
      "id": "technology-stack-for-implementing-the-restful-apis"
    }}>{`Technology stack for implementing the Restful APIs`}</h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://developers.redhat.com/download-manager/file/java-11-openjdk-11.0.7.10-1.windows.redhat.x86_64.msi" mdxType="ExternalLink">Redhat OpenJDK 11</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://spring.io/projects/spring-boot" mdxType="ExternalLink">Spring Boot v2.4.1</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://maven.apache.org/" mdxType="ExternalLink">Maven v3.6.3</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://gradle.org/install/" mdxType="ExternalLink">Gradle v6.1.1</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://hub.docker.com/editions/community/docker-ce-desktop-windows" mdxType="ExternalLink">Docker Desktop for Windows</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.consul.io/" mdxType="ExternalLink">HashiCorp Consul</ExternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "hashicorp-consul"
    }}>{`HashiCorp Consul`}</h2>
    <ExternalLink href="https://www.consul.io/" mdxType="ExternalLink">Consul</ExternalLink> is a distributed, highly available, datacenter-aware, service discovery and configuration system.
    <p>{`Keeping inline to this article, Consul provides the below capabilities:`}</p>
    <ul>
      <li parentName="ul">{`Services to discover each other by storing location information (like IP addresses) in a single registry.`}</li>
      <li parentName="ul">{`Improve application resiliency by using Consul health checks to track the health of deployed services.`}</li>
    </ul>
    <p>{`Consul provides a clean Web UI, which gives a simplified view on the registered services and their instances. It also perform periodic health checks and shows the appropriate status of the service & instance availability.`}</p>
    <p>{`Consul Web UI is accessible on `}<strong parentName="p">{`port`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8500`}</code>{` by default.`}</p>
    <h2 {...{
      "id": "spring-cloud-consul"
    }}>{`Spring Cloud Consul`}</h2>
    <ExternalLink href="https://spring.io/projects/spring-cloud-consul" mdxType="ExternalLink">Spring Cloud Consul</ExternalLink> provides <ExternalLink href="https://www.consul.io/" mdxType="ExternalLink">Consul</ExternalLink> integrations for Spring Boot apps through auto configuration and binding to the Spring Environment and other Spring programming model idioms. With a few simple annotations we can quickly enable and configure the common patterns inside our application and build large distributed systems with Hashicorp’s Consul.
    <p>{`Configuring `}<ExternalLink href="https://mvnrepository.com/artifact/org.springframework.cloud/spring-cloud-starter-consul-all" mdxType="ExternalLink">{`Spring Cloud Starter Consul All`}</ExternalLink>{` dependency in Spring Boot project shall provide access to the below capabilities:`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://docs.spring.io/spring-cloud-consul/docs/2.2.4.RELEASE/reference/html/#spring-cloud-consul-discovery" mdxType="ExternalLink">Service Registration & Discovery</ExternalLink> - instances can be registered with the Consul agent and clients can discover the instances using Spring-managed beans.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://docs.spring.io/spring-cloud-consul/docs/2.2.4.RELEASE/reference/html/#spring-cloud-consul-config" mdxType="ExternalLink">Distributed Configuration</ExternalLink> - using the Consul Key/Value store.
      </li>
      <li parentName="ul">
        <ExternalLink href="https://docs.spring.io/spring-cloud-consul/docs/2.2.4.RELEASE/reference/html/#spring-cloud-consul-bus" mdxType="ExternalLink">Control Bus</ExternalLink> - Distributed control events using Consul Events.
      </li>
    </ul>
    <p>{`To keep inline to this article, we can add either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-starter-consul-all`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-starter-consul-discovery`}</code>{` dependency.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-starter-consul-discovery`}</code>{` includes the below dependencies which helps in leveraging the capabilities around `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Registration & Discovery`}</code>{`.`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring-cloud-starter-netflix-ribbon`}</code>{` - Ribbon for client side load-balancer via Spring Cloud Netflix`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring-cloud-starter-loadbalancer`}</code>{` - A client side load-balancer provided by the Spring Cloud project`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring-cloud-starter-netflix-zuul`}</code>{` - Zuul, a dynamic router and filter via Spring Cloud Netflix`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring-cloud-netflix-hystrix`}</code>{` - Hystrix for Circuit Breaker resiliency capabilities.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/866ef2881177e7c59de0981e6a56ea29/c8e86/spring-cloud-consul-flow.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.05521472392638%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQklEQVQoz3WS/U8SARjH+bP8rc0EnIukpFabq1ltuVY/tX6wl82lpnKcHm8mJtmtaAxf2pjJKZAj59SMe8M77iDuDTzQATo5qhncNYi1udaz58fvZ9/vvs+jYxiGJIkUk6EIVuLkFM1JnPyN5Vk68/P7qaZpqqr9b3S1ek1TNeEII4sIXYrSpShVikgnWL5CFZTkn80rtHi8nVcoqhyhy9Fk+VOitCortE5V65qmLfMjQ7ttIGYE0A4rej7APghmnoYFYF2E1iVohRvyM/c+coPj8XYA1YOYcXi3DRFsLRgRQRAzuhMWN9kziXcxR7Fm4FozndrQqKeJItLQkJapxBUbZohmXX9hwBpvdxBmO2GC8AvBzLONnCeWdX/OvdzIeTZynhD3fFUYg3CTg+h2kmYA7YhIzhYcEgEH0T2z1/tq76aTuLQlwwWFzp7E9yu4XCFzFVw43tzKv53AOpvOV8/AK8K4l+5bSA8spB9PJ67vFHzlH3xBoQ+qzEG10dn+ydcvhfcgZvRSt+Bkvw0znHEGUL2LsDhwsx2/uJh+GBaAsAhERDAiTkREcCn9CBFG/43duOPadsDrd/iDr30fZj0+kN2PN4uqa63G6pr6K1EMgajBRVx2kz02VB+VXC0Y2fFBc4Pvgu65RcgFv5gnBpb5J6v8aIgbDgtWhB8JsPdD/AiIdU7iXXbCNBY/tyZBrdgxedrDXINTd96kbnvZG/HD+UK18Rt5hTpQmLxC8ceb2OHSLNUHJ+/CTP8M1bspw78BNZH0rl91riQAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Spring Cloud Consul Capabilities",
              "title": "Spring Cloud Consul Capabilities",
              "src": "/static/866ef2881177e7c59de0981e6a56ea29/a6d36/spring-cloud-consul-flow.png",
              "srcSet": ["/static/866ef2881177e7c59de0981e6a56ea29/222b7/spring-cloud-consul-flow.png 163w", "/static/866ef2881177e7c59de0981e6a56ea29/ff46a/spring-cloud-consul-flow.png 325w", "/static/866ef2881177e7c59de0981e6a56ea29/a6d36/spring-cloud-consul-flow.png 650w", "/static/866ef2881177e7c59de0981e6a56ea29/c8e86/spring-cloud-consul-flow.png 758w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Spring Cloud Consul Capabilities`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "bootstrapping-project-with-multi-module-spring-boot-application"
    }}>{`Bootstrapping Project with Multi Module Spring Boot Application`}</h2>
    <p>{`Instead of bootstrapping the codebase for this usecase from scratch, we shall try to extend the codebase from my `}<ExternalLink href="https://2much2learn.com/mavengradle-based-multi-module-spring-boot-microservices/" mdxType="ExternalLink">{`previous article`}</ExternalLink>{`.`}</p>
    <p>{`Clone the source code of the article `}<ExternalLink href="https://2much2learn.com/mavengradle-based-multi-module-spring-boot-microservices/" mdxType="ExternalLink">{`Containerizing Maven/Gradle based Multi Module Spring Boot Microservices using Docker & Kubernetes`}</ExternalLink>{` from `}<ExternalLink href="https://github.com/2much2learn/article_dec_28_mavengradle-based-multi-module-spring-boot-microservices" mdxType="ExternalLink">{`here`}</ExternalLink>{`.`}</p>
    <h2 {...{
      "id": "key-changes-to-existing-codebase"
    }}>{`Key changes to existing codebase`}</h2>
    <p>{`Below configuration changes are needed on existing codebase for leveraging the capabilities for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Registration & Discovery`}</code>{` with Consul.`}</p>
    <h3 {...{
      "id": "configure-mavengradle"
    }}>{`Configure Maven/Gradle`}</h3>
    <p>{`Existing codebase is a multi-module project supporting both Maven and Gradle. `}</p>
    <p>{`To distinguish artifacts from existing codebase, we shall rename the root project artifact from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-multi-module-service`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-multi-module-consul`}</code>{`.`}</p>
    <p>{`Add the below dependencies to root project `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pom.xml`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build.gradle`}</code>{` for enabling `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`healthcheck`}</code>{` endpoint and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-consul`}</code>{` capabilities.`}</p>
    <h4 {...{
      "id": "maven"
    }}>{`Maven`}</h4>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Root Project pom.xml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`project....`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`com.toomuch2learn.microservices`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`	`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`spring-multi-module-consul`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></span>{`	`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`0.0.1-SNAPSHOT`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`	`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`name`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`spring-multi-module-consul`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`name`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></span>{`	`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`description`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Spring multi module consul service`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`description`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dependencies`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    ...
    ...
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`org.springframework.boot`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`spring-boot-starter-actuator`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></span>{`    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`org.springframework.cloud`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`spring-cloud-starter-consul-all`}<span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`artifactId`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></span>{`    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dependencies`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`project`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h4 {...{
      "id": "gradle"
    }}>{`Gradle`}</h4>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Root Project settings.gradle`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "groovy"
    }}><pre parentName="div" {...{
        "className": "language-groovy"
      }}><code parentName="pre" {...{
          "className": "language-groovy"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`rootProject`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`name `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'spring-multi-module-consul'`}</span></span>{`
include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'service-a'`}</span>{`
include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'service-b'`}</span>{`
include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'service-c'`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Root Project build.gradle`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "groovy"
    }}><pre parentName="div" {...{
        "className": "language-groovy"
      }}><code parentName="pre" {...{
          "className": "language-groovy"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
subprojects `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
	
	dependencies `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`		implementation `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'org.springframework.boot:spring-boot-starter-actuator'`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`		implementation `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'org.springframework.boot:spring-cloud-starter-consul-all'`}</span></span>{`		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
		`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`....`}</span></code></pre></div>
    <h3 {...{
      "id": "annotating-springbootapplication-class"
    }}>{`Annotating SpringBootApplication Class`}</h3>
    <p>{`We need to annotate our SpringBoot main class with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@EnableDiscoveryClient`}</code>{` alongside with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@SpringBootApplication`}</code>{`.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`@EnableDiscoveryClient`}</code>{` annotation is part of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Spring Cloud Commons`}</code>{` project which looks for implementations of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DiscoveryClient`}</code>{` available on the classpath. `}</p>
    <p>{`Adding `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@EnableDiscoveryClient`}</code>{` annotation is `}<strong parentName="p">{`no longer required`}</strong>{` as the appropriate implementation is identified by the one available in classpath. But adding this will make us aware on the capabilities that are configured in our Spring Boot application in a glance.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`ServiceAApplication.java`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`com`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`toomuch2learn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`microservices`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`servicea`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`org`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`springframework`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`boot`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span></span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`SpringApplication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`org`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`springframework`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`boot`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`autoconfigure`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span></span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`SpringBootApplication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="span" {...{
              "className": "token namespace"
            }}>{`org`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`springframework`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`cloud`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`client`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`discovery`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`EnableDiscoveryClient`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`org`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`springframework`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`cloud`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`openfeign`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span></span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`EnableFeignClients`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@SpringBootApplication`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token annotation punctuation"
            }}>{`@EnableDiscoveryClient`}</span></span><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@EnableFeignClients`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceAApplication`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SpringApplication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceAApplication`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3 {...{
      "id": "configuring-application-properties"
    }}>{`Configuring Application properties`}</h3>
    <p>{`Application configurations are maintained in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`application.yaml`}</code>{` with multiple profiles available for us to choose the right profile based on the environment the application is deployed to.`}</p>
    <p>{`Below is the application configuration for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{`, which is configured with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`service-b`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`service-c`}</code>{` url and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring.cloud.consul`}</code>{` properties.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Service A - application.yaml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`application`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`active`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dev"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`cloud`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`consul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`host`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 127.0.0.1`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`discovery`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`        `}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`instanceId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"\${spring.application.name}-\${server.port}-\${spring.cloud.client.ip-address}"`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`prefer-ip-address`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean important"
            }}>{`true`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`health-check-critical-timeout`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"1m"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token key atrule"
            }}>{`server`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`port`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`8080`}</span></span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token key atrule"
            }}>{`serviceb`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` http`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`//service`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`b`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token key atrule"
            }}>{`servicec`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`url`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` http`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`//service`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`c`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` dev

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`server`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8081`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` prod`}</code></pre></div>
    <p>{`Below are few points to note:`}</p>
    <ul>
      <li parentName="ul">{`The default port configured for the spring boot application is `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8080`}</code>{` but is overridden to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8081`}</code>{` for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dev`}</code>{` profile.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring.cloud.consul`}</code>{` properties are configured at global level to remain the same for both `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dev`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`prod`}</code>{` profile.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`spring.cloud.consul.host`}</code>{` property is pointing to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`127.0.0.1`}</code>{` and port to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8500`}</code>{` by default. This can be overridden by passing the property as environment variable based on how the services are provisioned & deployed.`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`serviceb.url`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`servicec.url`}</code>{` is configured with `}<strong parentName="li">{`Service B`}</strong>{` & `}<strong parentName="li">{`Service C`}</strong>{` `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`spring.application.name`}</code>{`. These properties will be expanded during runtime and leveraged by different `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Spring Cloud`}</code>{` capabilities - Service Discovery, Load Balancing & Circuit Breaker.`}</li>
    </ul>
    <p>{`Application properties remain the same for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service B`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service C`}</code>{` apart from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring.application.name`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`server.port`}</code></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Service B - application.yaml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`application`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`active`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"dev"`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`cloud`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`consul`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`discovery`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`instanceId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"\${spring.application.name}-\${server.port}-\${spring.cloud.client.ip-address}"`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`prefer-ip-address`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean important"
            }}>{`true`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`health-check-critical-timeout`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"1m"`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}></span><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`server`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` dev

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`server`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8082`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`profiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` prod
  `}</code></pre></div>
    <p>{`Below are few `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Spring Cloud Consul`}</code>{` configuration properties that we configured above. Refer to the `}<ExternalLink href="https://cloud.spring.io/spring-cloud-consul/reference/html/appendix.html#common-application-properties" mdxType="ExternalLink">{`documentation`}</ExternalLink>{` for the complete list of available configuration properties.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`spring.cloud.consul.host`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`localhost`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Consul agent hostname. Defaults to ‘localhost’.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`spring.cloud.consul.discovery.instanceId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique Id that identifies the instance in Consul UI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`spring.cloud.consul.discovery.prefer-ip-address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use ip address rather than hostname during registration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`spring.cloud.consul.discovery.health-check-critical-timeout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timeout to deregister services critical for longer than timeout (e.g. 30m).`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "building-docker-images"
    }}>{`Building Docker Images`}</h3>
    <p>{`Run below commands from the root project to build multi-module project artifacts and create docker images for individual modules.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven-Build and create docker images for all modules from parent`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ mvn clean package spring-boot:build-image`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Gradle-Build and create docker images for all project from parent`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ gradle clean build bootBuildImage`}</code></pre></div>
    <p>{`Executing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker images | grep spring-multi-module-consul`}</code>{` should list the below three docker images.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/71edabaa5c5aec583742a69c18cf4dc0/321ea/docker-images.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "9.202453987730062%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAdElEQVQI1wXBsRFCIQwAUMawt/6nJkqCQAgh6NnoKt45h43DWLra973w+25eTyy12yg+1S/ae7EhNqTWFOmAx90p7kslTgi4RAZiBFiIMaxr+Ly3KV99Zp/arWovIslGIwJp5yIsmu+Pm7tGhuHNpzKjufwBrU8fVgM4wEcAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Docker images created from Maven/Gradle Spring Boot build image command",
              "title": "Docker images created from Maven/Gradle Spring Boot build image command",
              "src": "/static/71edabaa5c5aec583742a69c18cf4dc0/a6d36/docker-images.png",
              "srcSet": ["/static/71edabaa5c5aec583742a69c18cf4dc0/222b7/docker-images.png 163w", "/static/71edabaa5c5aec583742a69c18cf4dc0/ff46a/docker-images.png 325w", "/static/71edabaa5c5aec583742a69c18cf4dc0/a6d36/docker-images.png 650w", "/static/71edabaa5c5aec583742a69c18cf4dc0/321ea/docker-images.png 786w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Docker images created from Maven/Gradle Spring Boot build image command`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "pushing-docker-images-to-docker-hub"
    }}>{`Pushing docker images to Docker Hub`}</h3>
    <p>{`Follow the below steps to tag the images and push them to Docker Hub.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Ensure to login to Docker`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` login`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Tag docker image before pushing to docker hub if image name is not tagged with docker hub username`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` tab `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`IMAGE_ID`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Docker_Username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`IMAGE_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` tag ffc5ec760103 `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$DOCKER_USER_NAME`}</span>{`$/springboot-servicea`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Push to docker hub`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` push `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Docker_Username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`IMAGE_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` push `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$DOCKER_USER_NAME`}</span>{`$/spring-multi-module-service-service-a`}</code></pre></div>
    <h2 {...{
      "id": "bootstrapping-consul-server"
    }}>{`Bootstrapping Consul Server`}</h2>
    <p>{`Consul is a complex system that has many different moving parts which constitutes Data Centers, Servers & Agents participating in a `}<ExternalLink href="https://www.consul.io/docs/internals/gossip" mdxType="ExternalLink">{`gossip protocol`}</ExternalLink>{`.`}</p>
    <p>{`Consul provides native installation procedure to install the server on host directly. But the easiest way to try out consul is to work with `}<ExternalLink href="https://hub.docker.com/_/consul" mdxType="ExternalLink">{`Consul Docker Image`}</ExternalLink>{`.`}</p>
    <p>{`Run the below `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{` command to kick start Consul server.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:run%20consul%20docker%20image"
    }}><pre parentName="div" {...{
        "className": "language-bash:run%20consul%20docker%20image"
      }}><code parentName="pre" {...{
          "className": "language-bash:run%20consul%20docker%20image"
        }}>{`$ docker run -d --name consul-server -p 8500:8500 -e CONSUL_BIND_INTERFACE=eth0 consul`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:check%20startup%20logs"
    }}><pre parentName="div" {...{
        "className": "language-bash:check%20startup%20logs"
      }}><code parentName="pre" {...{
          "className": "language-bash:check%20startup%20logs"
        }}>{`$ $ docker logs consul-server

==> Found address '172.17.0.2' for interface 'eth0', setting bind option...
==> Starting Consul agent...
           Version: '1.9.1'
           Node ID: '597f4fef-fdcb-734a-37f1-14faefab6615'
         Node name: '962e56017069'
        Datacenter: 'dc1' (Segment: '<all>')
            Server: true (Bootstrap: false)
       Client Addr: [0.0.0.0] (HTTP: 8500, HTTPS: -1, gRPC: 8502, DNS: 8600)
      Cluster Addr: 172.17.0.2 (LAN: 8301, WAN: 8302)
           Encrypt: Gossip: false, TLS-Outgoing: false, TLS-Incoming: false, Auto-Encrypt-TLS: false`}</code></pre></div>
    <p>{`Access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://127.0.0.1:8500`}</code>{` to open up Consul UI. Observe there is only one service registered which is the consul server itself.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8fec2c65ae9c418f3d7bd9a105ca4df9/99272/consul-ui-blank.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "41.104294478527606%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA7ElEQVQY05WOS0oDQRRFaxdi0IngHlyHiuLEBbgjDfTEUcQFqCHOA0lEHIlGgsGuX9poU9VVXfXeK2lUHEjQHA6XM7yss3V0unmYre1l6/vt1m67tXOy+rfHK9vZxgEbX/WHncubs+7teW980Z90B8+94WJHP309Yu+mvHu8f8qnD9NJhRASpX/DEhJEcJWDCAgYQ1xoHefzt/xFKFWUpQl1ZCklZzznXOmZ97V3XiutlK4qH2MM4UtjrNazPOdCSC5k5WpEYhGBW/laFNZaIkLEz/0NfYNIAE0wTChKJaR0zqUlaW4TEAI2sSQf93+jwyBtX2oAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "No other services registered yet",
              "title": "No other services registered yet !!!",
              "src": "/static/8fec2c65ae9c418f3d7bd9a105ca4df9/a6d36/consul-ui-blank.png",
              "srcSet": ["/static/8fec2c65ae9c418f3d7bd9a105ca4df9/222b7/consul-ui-blank.png 163w", "/static/8fec2c65ae9c418f3d7bd9a105ca4df9/ff46a/consul-ui-blank.png 325w", "/static/8fec2c65ae9c418f3d7bd9a105ca4df9/a6d36/consul-ui-blank.png 650w", "/static/8fec2c65ae9c418f3d7bd9a105ca4df9/99272/consul-ui-blank.png 669w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`No other services registered yet !!!`}</figcaption>{`
  `}</figure></p>
    <p>{`Run the below commands to start Spring Boot services and observe they are registered and displayed in Consul UI successfully.`}</p>
    <p>{`Start the services by running the individual fat jars. This will use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dev`}</code>{` profile as configured in application.yaml`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start fat jars`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ mvn clean package

$ java -jar service-a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`service-a-0.0.1-SNAPSHOT.jar
$ java -jar service-b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`service-b-0.0.1-SNAPSHOT.jar
$ java -jar service-c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`target`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`service-c-0.0.1-SNAPSHOT.jar`}</code></pre></div>
    <p>{`Access Consul UI after a minute to observe three new services registered as below.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/702820a6059cab03767e782698e726d1/99272/consul-ui-services-java.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "71.16564417177914%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQ0lEQVQoz5WQT0rDQBSH5w4i4kbwDN5DoQvBC3gkhQruXKoXsKT7gm1oQaRQJX86k0mmaZJJJjOZeSNtEREVnI9v8XYfv4fuTi5vjy/6B73+Ye96/+yfXu2d3hydo8XTyL8fTB+82eMwGDxH3iQe+n8ZeePIm2wdh94YratiNPVfFvPZ/JXVFVdSW9tZq38T7DeQbGUYhMslztIso5QmNKUbafJDQtOUYZxQysqCK6kQAFQFj+OYkITzhvOabCnLSjSi+bQoSppQktAsY2maCSGNAaRBk5quVitecQALAN0Ws7m/NAa6TkupdhgDAIDAQlpmGBMhhHUEaTCsyZumaYXQWoMLm3Kc4yiO8zzXWruVDZi1KOq6btvWuWysCfIIY8IYU0q5lqEStRCt2X3QqayteWPvQRAyxpw3W2u10lJKcOcDoaQB+xEVomMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Three new Spring Boot services registered with Consul",
              "title": "Three new Spring Boot services registered with Consul",
              "src": "/static/702820a6059cab03767e782698e726d1/a6d36/consul-ui-services-java.png",
              "srcSet": ["/static/702820a6059cab03767e782698e726d1/222b7/consul-ui-services-java.png 163w", "/static/702820a6059cab03767e782698e726d1/ff46a/consul-ui-services-java.png 325w", "/static/702820a6059cab03767e782698e726d1/a6d36/consul-ui-services-java.png 650w", "/static/702820a6059cab03767e782698e726d1/99272/consul-ui-services-java.png 669w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Three new Spring Boot services registered with Consul`}</figcaption>{`
  `}</figure></p>
    <p>{`Access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{` `}<strong parentName="p">{`greeting`}</strong>{` endpoint to see if API is working as expected by accessing downstream `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service B`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service C`}</code>{` APIs.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Discovery`}</code>{` should working seamlessly by itself as we haven’t configured downstream APIs access urls.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Access service-a greeting endpoint`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` http://localhost:8081/greeting

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"id"`}</span>{`:1,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Service-A - Hello, World! - Service-B - Hello, World! - 1 - Service-C - Hello, World! - 1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "deploying-with-docker-compose"
    }}>{`Deploying with Docker Compose`}</h2>
    <p>{`Instead of running docker images individually, the complete stack with Consul server can be brought `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`up`}</code>{` / `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`down`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{`.`}</p>
    <p>{`Below is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yaml`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consul`}</code>{` and all the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-boot`}</code>{` services configured individually.`}</p>
    <p>{`Keep an eye on the below specifics: `}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`consul-server`}</code>{` is linked to all `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`spring-boot`}</code>{` services.`}</li>
      <li parentName="ul">{`Additional instances for `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`service-b`}</code>{` & `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`service-c`}</code>{` are configured to verify how `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Load Balancing`}</code>{` works all by itself without any specific configuration needed from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`service-a`}</code>{` to access these downstream APIs.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`docker-compose.yaml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3.9'`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Define services`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service A`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`consul-server`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`image`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` consul`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`ports`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` 8500`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`8500`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`restart`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` always`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`environment`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"CONSUL_BIND_INTERFACE=eth0"`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`networks`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` backend`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service A`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service-a`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` 8080`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`links`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` consul`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`server`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`environment`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` spring.profiles.active=prod`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` spring.cloud.consul.host=consul`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`server`}</span>{`    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` backend

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service B - 1`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`service-b-1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.profiles.active=prod
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.cloud.consul.host=consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` backend

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service B - 2`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`service-b-2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`b
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.profiles.active=prod
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.cloud.consul.host=consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` backend

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service C - 1`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`service-c-1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`c
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.profiles.active=prod
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.cloud.consul.host=consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` backend

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Service C - 2`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`  `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`service-c-2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span></span>{`    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`c
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`links`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.profiles.active=prod
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` spring.cloud.consul.host=consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` backend

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Networks to be created to facilitate communication between containers`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`networks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  backend`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span></code></pre></div>
    <p>{`Run the below `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` commands to get the stack `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`up`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`stop`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`start`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`down`}</code>{` appropriately. `}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Validate docker-compose file and configuration`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` config`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Builds, (re)creates, starts, and attaches to containers for a service.`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Lists containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span></code></pre></div>
    <p>{`Access Consul UI after a minute to observe three services registered and `}<strong parentName="p">{`2 instances`}</strong>{` for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`service-a`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`service-b`}</code>{` as below.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "523px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7efcbbcefcf4272fe5d3676bd9a0efc1/3e286/consul-ui-services-compose.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "74.23312883435584%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABxUlEQVQ4y52Sy27TUBBA/ROoXcGn8ActLVXZ9AdY8QFBCNiwZQmoXwArGqEKERGVBhKad6B5NbGdEqWJ4zotqZVc2/celAcg2NTJSGc3c++cmdFe337A7q17vLixzsuVjRmrmzNWNnm1Gp7dm9to8fvPie08Y3/rMe+3n7K/9YTonYdT3m1E2FuP8HYtwl4IoncfobXMFrlcnnqjQblSwTBNxsJDCIE9hECxUGh926aQL6LrBtVanaauM7xyGQ6vsH9KhKdQSiHl9UzytACfilvn8uKSnmX995+aMkkMizYpcQYO3XYHp99HCjF7Rqml0IJuj/OvCdx8kVGhxChfwKvWkAqklKFU/1EefyuR7qRp2TZmt0vPdfHzRfA8lglNNpp4sc8MDhKMU2lUJodIJJfW1kS1Si32Bid+QD/2cYpIHU1nKZlrT7Z8HX+Us1lqbhvdsji1bWzfw6+foJzBfNGLHaIWGC38D4eM4p/wD79AIolIHv3tTKpwHf5WHpfL5DJROpksp8kUVrGEX/qOHFzM5ijlYjP0sjm6Zy3ahon1o83IdQlOGsjO2ZLK5w7ecQ3RNPB1k0A38Bo6Mgjm9yVDM+nwF9xgSX76oMRiAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Spring Boot services registered with Consul",
              "title": "Spring Boot services registered with Consul",
              "src": "/static/7efcbbcefcf4272fe5d3676bd9a0efc1/3e286/consul-ui-services-compose.png",
              "srcSet": ["/static/7efcbbcefcf4272fe5d3676bd9a0efc1/222b7/consul-ui-services-compose.png 163w", "/static/7efcbbcefcf4272fe5d3676bd9a0efc1/ff46a/consul-ui-services-compose.png 325w", "/static/7efcbbcefcf4272fe5d3676bd9a0efc1/3e286/consul-ui-services-compose.png 523w"],
              "sizes": "(max-width: 523px) 100vw, 523px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Spring Boot services registered with Consul`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ff4b9fb709d33a678160d6928e019551/6e9ba/consul-ui-services-instances-compose.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.601226993865026%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABgUlEQVQoz5WQW4vUQBCF8///lArKouOwPgguzmVjMptkJpNOpy+VrktXdDIICyLo4TzUy1enThWacwLAlDTnJeflf1SUm83jm7ffHh4On7fp5eUfMdUV7svy9PR0Ph67w2E2JqsScc5ZRP6GqS6yqkAXx+cWjIOUphDmOY3WAoD3gf+QyG2vqgIAIhYQwVkXXECku4nWgSghvjYRex/7/mrGyVpHxEVWDRGcC8xyPymrSlaR/NpEFCMYY/v+OgxmTpRVC2G59sO5O5vBROe9dcmDJLwzt0slS1ZCCjNOIXqAALPcYrSwMP1oqmEwdVlWnza7D++/f3wXqpol01qTmRGRJUN39lUVm8ZV9dw0t28nxik4gBR8mJrWnk5jU82jRZa09hdZBxHo+1CWsar9c5m6TpelGKPd14euuxx3+/Lxy2672X/dhrYlZlqjiTglJJZ4vkz1KXadq0/pcrklszIKaV50WZhEmCEFJP6dzCIZkZjzbEbXduHSu7abr9df8E+W1XgvxgTc3wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Listing all instances registered under a service",
              "title": "Listing all instances registered under a service",
              "src": "/static/ff4b9fb709d33a678160d6928e019551/a6d36/consul-ui-services-instances-compose.png",
              "srcSet": ["/static/ff4b9fb709d33a678160d6928e019551/222b7/consul-ui-services-instances-compose.png 163w", "/static/ff4b9fb709d33a678160d6928e019551/ff46a/consul-ui-services-instances-compose.png 325w", "/static/ff4b9fb709d33a678160d6928e019551/a6d36/consul-ui-services-instances-compose.png 650w", "/static/ff4b9fb709d33a678160d6928e019551/6e9ba/consul-ui-services-instances-compose.png 731w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Listing all instances registered under a service`}</figcaption>{`
  `}</figure></p>
    <p>{`Access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{` `}<strong parentName="p">{`greeting`}</strong>{` endpoint multiple times to see downstream APIs are accessed via round-robin fashion with internal load balancer.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/554f9aee6ab1c7cb38d86859d84e2813/1cfc2/consul-ui-services-access-compose.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "36.809815950920246%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQklEQVQY0x2PW5KrIABEs5g7ichTM2oEFQTkKWrd2f9eppyfrv45p7of7wF0vB44Gib8mck4k54j0v4T8kvZ16KfyryUAWqtpaqNQWKqprkS0+szPh8DJ+PMJtkK2cz6e1JvsbRtB4wD+cQ+1fkguRDvYUo079R5FCJxHmkDHv2IPjPmkvGFTSsT6i5tX60bSAVvEcZCQsbO4xBYSkwbbDeiDdIaPHqO+IKFYkLRP5hySd9DZTwoFwk73C+STxoTSbkpR7M54j3dHLZbfcOfGXFJboWkk6TjQpruqV21XyjkOh84FxwjTonshTqHvL9nG3vDmEsqVCMUm9d20a2QjH1/2VCdPyif9fEflQuXgstBz4uFiGLEMUJj/z53I+gF6sa6F/DOETbdc9HVFuBqgQ3IOKQ1NJZsjkgF1YoXCecF/AJVtF/zNoQYqQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Downstream APIs are accessed using spring-cloud-loadbalancer",
              "title": "Downstream APIs are accessed using spring-cloud-loadbalancer",
              "src": "/static/554f9aee6ab1c7cb38d86859d84e2813/a6d36/consul-ui-services-access-compose.png",
              "srcSet": ["/static/554f9aee6ab1c7cb38d86859d84e2813/222b7/consul-ui-services-access-compose.png 163w", "/static/554f9aee6ab1c7cb38d86859d84e2813/ff46a/consul-ui-services-access-compose.png 325w", "/static/554f9aee6ab1c7cb38d86859d84e2813/a6d36/consul-ui-services-access-compose.png 650w", "/static/554f9aee6ab1c7cb38d86859d84e2813/1cfc2/consul-ui-services-access-compose.png 900w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Downstream APIs are accessed using spring-cloud-loadbalancer`}</figcaption>{`
  `}</figure></p>
    <p>{`Proceed to stop the services or bring down the provisioned stack by running below commands.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stops running containers without removing them`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` stop`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Starts existing containers for a service`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` start`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stops containers and removes containers, networks, volumes, and images created by - up`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` down`}</code></pre></div>
    <h2 {...{
      "id": "deploying-with-kubernetes"
    }}>{`Deploying with Kubernetes`}</h2>
    <p>{`Docker Desktop is the easiest way to run Kubernetes on your windows machine. It gives ua a fully certified Kubernetes cluster and manages all the components for us.`}</p>
    <p>{`Install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Desktop`}</code>{` and ensure to `}<strong parentName="p">{`enable`}</strong>{` kubernetes from settings window.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/14a53e933acb23fd2df6146cbf2df02b/798d4/docker_desktop_kubernetes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.46625766871166%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAABeUlEQVQoz32RO24UQRCG5yQIASERKSk34UiAIxICTsA5MCCRgCzh3Zntnn5PP6bHsoMPTduyZ82K4FN1V9fjr+ru2QfL/3j63vLmc+D1p8CLM9d8T95ZXn30vP0y8fJM8nwT353vC+d9odktfeFbPzf7fSj8GB7F3fm/7vNRXndTZ67vuNlwVTJLTs1en4i5vy/HeZ3zkVFZwpRIuZLyTM4VKUd2+wPaOHJZmv+YesI30xnjUMpgraeU2liDe5UIYSLGTEqFeV7u31dya/wvXQwTwXmM0sQpUsutwr1KCCEZBsE46tZwxViPc4EQYiuwbbLS/fyj+N0rfvWaC+HY2cyUK8EHhFCtmJQKIRVSag4H2dawFlwL+xDxfj3fTtNdiECvJ3qTGKcFESpTmmm71Q5lHKO2jMqgtSWm0hKnu1VsaSNf1YVlrtSVMjdW6VEbXN/jhgF3EHjrm6oH0umRTy63VLwcsZeX2N0OOwx4F7AuNHXbD3r8KX8Bes/gW0VuxzcAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"Enable Kubernetes from Docker Desktop settings tab\"",
              "title": "Enable Kubernetes from Docker Desktop settings tab",
              "src": "/static/14a53e933acb23fd2df6146cbf2df02b/a6d36/docker_desktop_kubernetes.png",
              "srcSet": ["/static/14a53e933acb23fd2df6146cbf2df02b/222b7/docker_desktop_kubernetes.png 163w", "/static/14a53e933acb23fd2df6146cbf2df02b/ff46a/docker_desktop_kubernetes.png 325w", "/static/14a53e933acb23fd2df6146cbf2df02b/a6d36/docker_desktop_kubernetes.png 650w", "/static/14a53e933acb23fd2df6146cbf2df02b/e548f/docker_desktop_kubernetes.png 975w", "/static/14a53e933acb23fd2df6146cbf2df02b/798d4/docker_desktop_kubernetes.png 976w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Enable Kubernetes from Docker Desktop settings tab`}</figcaption>{`
  `}</figure></p>
    <p>{`Execute `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$ kubectl cluster-info`}</code>{` to verify if Kubernetes cluster is running successfully. If not, uninstall and install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Desktop`}</code>{` and ensure you receive the below message as expected to proceed with kubernetes deployment.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Verify if kubernetes is running`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$  kubectl cluster-info

Kubernetes master is running at https://kubernetes.docker.internal:6443
KubeDNS is running at https://kubernetes.docker.internal:6443/api/v1/namespaces/kube-system/services/kube-dns:dns/proxy`}</code></pre></div>
    <p>{`To deploy docker images to kubernetes, we need to configure `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Deployment`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service`}</code>{` objects and have them applied to orchestrate the deployment into kubernetes cluster.`}</p>
    <p>{`Deployment configuration files are created for services in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`individual yaml`}</code>{` file under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`k8s`}</code>{` folder.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "493px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0222cc321bc07c663bb92aa2bf11353b/f88d0/k8s-yaml-files.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.6441717791411%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB30lEQVQ4y4WS/W6iUBDFfZFt1SqCgFzulygIKOA3mza7pdbVmE02+/5vcDb3Wlvb1faPyWES5sxv5k4tGAgMowhxkiCMIiRpimEUgvg+mOCgjKFrW3DczkuYcHvmWf4+alxYKEsfu90EVRViu03wcC9RlhRF0UUcNxBFLf2z7bwVKtNLUdtULfz9Q7DfCTzct7Fe1VGuG/heNlGu65jPG9j/usF4fIeOqciMq3SacPtsQvZtWDYB4xKMC1AuQXwKLiVsRyBNG5jP7mCYFtxe53PDTWVAyiaI7yLPRyjLGbIiQRxLLJYZKKMo8jqmxZHwS8Pdtq0Ne56LSaYMFyimY4ShwHKVw/MpZtM6FvMmjM4b4aX9acPFwsLvQwuHvYnDvoNNVcfjj294erxF9fNG6/PTLYQw0HXMT+m0oU89MO4hGvWxXBXIixTpOMRgyF9JbOcY5yRXDSlz4JEuuCAYxQH6AdO57Rgvxce9fdzd1bOhzIVPHU0ZDDjiZKDpiG+ja7e/HPECoauLPWJBfSs6RRmNAsi+j55n6VDdlR4P/P9bfH0UZaYIlXLhaVUFhDoIBgxxEuq9ZnmsVUiim34c+5TX1IiqszJivKf1RHEiUaTq0ZI0xCSLIYR3tuP3hP8AOFicMxvbFEwAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"K8s configuration files\"",
              "title": "K8s configuration files",
              "src": "/static/0222cc321bc07c663bb92aa2bf11353b/f88d0/k8s-yaml-files.png",
              "srcSet": ["/static/0222cc321bc07c663bb92aa2bf11353b/222b7/k8s-yaml-files.png 163w", "/static/0222cc321bc07c663bb92aa2bf11353b/ff46a/k8s-yaml-files.png 325w", "/static/0222cc321bc07c663bb92aa2bf11353b/f88d0/k8s-yaml-files.png 493w"],
              "sizes": "(max-width: 493px) 100vw, 493px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`K8s configuration files`}</figcaption>{`
  `}</figure></p>
    <p>{`Below is the sample deployment & service configuration for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consul`}</code>{` to deploy to kubernetes. Service type is configured as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`NodePort`}</code>{`, such that it is available to access Consul UI using the host address on configured port i.e `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`32500`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`apiVersion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` apps/v1
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Deployment
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`replicas`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`matchLabels`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`labels`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`containers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`containerPort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8500`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`imagePullPolicy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Always
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` CONSUL_BIND_INTERFACE
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`value`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"eth0"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`apiVersion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` v1
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Service
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`svc
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8500`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`targetPort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8500`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nodePort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`32500`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` NodePort`}</code></pre></div>
    <p>{`Below is the sample deployment & service configuration for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{` to deploy to kubernetes.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`service-a.yaml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`apiVersion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` apps/v1
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Deployment
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` springboot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`replicas`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`matchLabels`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` springboot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`labels`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` springboot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`containers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` app
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $DOCKER_USER_NAME$/spring`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`multi`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`consul`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`containerPort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`imagePullPolicy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Always
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` spring.profiles.active`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`              `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"prod"`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`name`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` spring.cloud.consul.host`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`              `}<span parentName="span" {...{
              "className": "token key atrule"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"$(CONSUL_SERVER_SVC_SERVICE_HOST)"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`---`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`apiVersion`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` v1
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`kind`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Service
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`metadata`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` springboot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`svc
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`spec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`selector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` springboot`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`targetPort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` LoadBalancer`}</code></pre></div>
    <p>{`As observed, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{` deployment is configured with environment variables to set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring.profiles.active`}</code>{` to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prod`}</code>{`. `}</p>
    <p>{`We need to pass `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring.cloud.consul.host`}</code>{` as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`consul`}</code>{` will be not be accessible via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localhost`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`127.0.0.1`}</code>{`. For this to work, we need to configure the environment variable that are created when services are created. One of them is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CONSUL_SERVER_SVC_SERVICE_HOST`}</code>{`.`}</p>
    <p>{`Passing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$(CONSUL_SERVER_SVC_SERVICE_HOST)`}</code>{` will expand the environment variable with host of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consul`}</code>{`.`}</p>
    <p>{`Run the below `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`kubectl`}</code>{` commands to deploy or delete the stack.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Apply deployments and services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl apply -f k8s`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List deployments, services and pods after applying the change`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl get all`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Watch pods by getting all pods or for a specific app`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl get pods --watch

$ kubectl get pods -l `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`springboot-service-a --watch`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Get Environment Variables set to the pod`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`POD_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` -- `}<span parentName="code" {...{
            "className": "token function"
          }}>{`printenv`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` SERVICE`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Dump pod logs`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl logs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`POD_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

$ kubectl logs -f `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`POD_NAME`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Access Consul UI using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:32500`}</code>{` after a minute to observe `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`three services`}</code>{` registered along side with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`consul`}</code>{` service.`}</p>
    <p>{`Run the below command to scale up `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service B`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service C`}</code>{`. Access Consul UI and observe if the no of instances for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service B`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service C`}</code>{` are displayed as `}<strong parentName="p">{`3`}</strong>{` instead of `}<strong parentName="p">{`1`}</strong>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Scale deployment up/down by setting replicas`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl scale --replicas`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` deployment/springboot-service-b

$ kubectl scale --replicas`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` deployment/springboot-service-c`}</code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "299px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/96cc23c93531f878529e6613d63f8c2f/aeb78/consul-ui-services-k8s.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "138.03680981595093%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAIAAADuuAg3AAAACXBIWXMAAA7DAAAOwwHHb6hkAAADC0lEQVQ4y6WTz47TRhzH/R6ohyL1XAEvQA9Vn6AgIW5tOVOpfYAe+gSVKvFHrChQtCBEtquiXmi1dDfLxo6T4N3YCd5s/CfJ2IknY8+MHXs8nsraFMSG7LLiq49GPsxnZn4/z0iPv/j+8cXrdz69cufsqZH+uvrzn5d/Wvns6q0zX9/+5NKpkJ5+9eOTL3+49/k3989/d//cnHvnvn1w/tqDC9fK8X9+v3AUCTt+6qEiSvIpyaeUwXIswiTxER0FMYAUwBgEiReQ0VEkQunT9fWXstzuGEpDfVHd6u6bL+XaFKFCiDRnXOQoLiwoFiPFNK5U1m7cvPX8+d9rf6yvrj6qVNZWVu52jA5CUd9yHdsJEFNMkcwKURQFf4sklqQ4zPy7nLuYUg4S+Nvrhx70bcu2bXswGIRh+O5ConjfBqWMM/LC3/ICr73X1nXdMAxCyOHm4tiUMs95TGbjMQwjjDE90XlX5jzLMkwIxgQTyjJWFlScnLmc5uniwvyklPI4mfxi3LBGttZ6ZZqmpmmWZfm+f2LZpZzmaZ/YcAqtvhUEgeM4AAAI4QfVnOec4Bj4E4giOA1P0bBCCM5YkeZpHCeEzChN4zhLkmw2Y7PZYeeWIbHJxNr4p1F95jfUoNXyGuqk2fQbDU9VgSyDnZ2y8iW9l9gAdA3lV/uuaZs7NVnvdBRV3dP1rmkKIbx6/ZgqpNlgEPR6VLAIE4gQpjQiJEnTjLHyRzSbnPPl8nAINC3wxo7ZA5bj7B/gSVAkCSOEhaGnKMd0T8oxBv9uTbcVr7oNqlVQ3R5tVYebm8PNTXdjYyjLnLGll4S8Ng/q289qq0N9b7i762racHfXN4xJt4ssa9J6Fej6/IUKcQQJd01n1HsIKvtOb6cmd0yzpih6twsREkKwKBq32/NnuXhs1OkQCLkQmCY0zWiasZy/mZ1jHBjGclnXR/U6MIyDumo3Wwd11TcM3O9HvR627ammeZq2VGZxjCwrcl08GESuG7lu6DjItg+Z9i0aRbwocs4XkcRHRPqAN7/0hv0Hl33VDkRItX0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Instances registered after scaling up services",
              "title": "Instances registered after scaling up services",
              "src": "/static/96cc23c93531f878529e6613d63f8c2f/aeb78/consul-ui-services-k8s.png",
              "srcSet": ["/static/96cc23c93531f878529e6613d63f8c2f/222b7/consul-ui-services-k8s.png 163w", "/static/96cc23c93531f878529e6613d63f8c2f/aeb78/consul-ui-services-k8s.png 299w"],
              "sizes": "(max-width: 299px) 100vw, 299px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Instances registered after scaling up services`}</figcaption>{`
  `}</figure></p>
    <p>{`Access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service A`}</code>{` greeting endpoint by invoking the request to service running on port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8080`}</code>{` to see if API is working as expected by successfully accessing downstream APIs.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Access service-a greeting endpoint`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` http://localhost:8080/greeting

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"id"`}</span>{`:1,`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Service-A - Hello, World! - Service-B - Hello, World! - 1 - Service-C - Hello, World! - 1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Bring down the stack after testing the services.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Delete pods and services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ kubectl delete -f k8s`}</code></pre></div>
    <h2 {...{
      "id": "why-do-we-need-consul-on-kubernetes-"
    }}>{`Why do we need Consul on Kubernetes ?`}</h2>
    <p>{`For the purpose of this article, we are successful with provisioning `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consul`}</code>{` and leveraging the concepts of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Registration and Discovery`}</code>{` on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Kubernetes`}</code>{`.`}</p>
    <p>{`But, Kubernetes already has built in support for Service Discovery pattern. So why do we need Consul on Kubernetes ?`}</p>
    <p>{`This could be for multiple reasons which needs better understanding on what can/cannot be achieved with kubernetes. Dig into this `}<ExternalLink href="https://qr.ae/pNJlog" mdxType="ExternalLink">{`answer`}</ExternalLink>{` on Quora which lists the possible cases why `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Consul`}</code>{` might be needed compared to the default capabilities provided by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Kubernetes`}</code>{`.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`This article is an extension to my `}<ExternalLink href="https://2much2learn.com/mavengradle-based-multi-module-spring-boot-microservices/" mdxType="ExternalLink">{`previous article`}</ExternalLink>{` on implementing usecases to try out different microservice concepts and patterns.`}</p>
    <p>{`This article is useful for usecase which needs all the services registered and integrated with Consul. With the support of configuring multiple modules with Maven and Gradle, it is easy for one to build all the modules with single command and have the stack deployed seamlessly to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`kubernetes`}</code>{`.`}</p>
    <p>{`We just digged into `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Service Discovery`}</code>{` capability under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-consul`}</code>{`. This article shall be the base for trying out microservice pattern - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Centralized Configuration`}</code>{` with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud-consul`}</code>{`.`}</p>
    <p>{`Apart from the above, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`spring-cloud`}</code>{` provides lot other capabilities and this article codebase will be extended to try out some of the below.`}</p>
    <ul>
      <li parentName="ul">{`Distributed Tracing`}</li>
      <li parentName="ul">{`Fault Tolerance`}</li>
      <li parentName="ul">{`API Gateway`}</li>
      <li parentName="ul">{`And many more…`}</li>
    </ul>
    <GithubRepo href="https://github.com/2much2learn/article_jan_03_srv-reg-and-discvry-in-spring-boot-microservices-with-spring-cloud-consul" mdxType="GithubRepo">srv-reg-and-discvry-in-spring-boot-microservices-with-spring-cloud-consul</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      