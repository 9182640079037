import React, {useState, useEffect} from "react"
import _ from "lodash"
import axios from "axios"

import Layout from "~/components/layout"
import { BlogPostDetailsWrapper,BlogDetailsContent,RelatedPostTitle } from "./templates.style"
import {
  PostDescriptionWrapper,
  PostTitle,
  PostDescription
} from "~/components/PostDetails/postDetails.style"

import ExternalLink from '~/utils/mdx/components/ExternalLink';

const API = "https://api.applause-button.com"
const VERSION = "3.0.0"
const mainUrl = "2much2learn.com"

const HEADERS = {
  "Content-Type": "text/plain"
}

const getClaps = async (request) => {
  return await axios.post(`${API}/get-multiple`, 
  JSON.stringify(request), {
      headers: HEADERS
  });
}

const getPosts = async () => {
  return await axios.get(`/posts.json`);
}

const PostStatsTemplate = ({ pageContext, data }: any) => {
  console.log("====> PostStatsTemplate :: pageContext :: ", pageContext, "data :: ", data)

  const [posts, setPosts] = useState(Object)
  const [claps, setClaps] = useState(Object)

  useEffect(() => {
      const fetchData = async () => {

          const posts = await getPosts()
          //console.log("===========> posts :: ", posts)
          setPosts(posts.data)

          let urls = [];
          posts.data.forEach((post) => {
            urls.push(`${mainUrl}${post.slug}`);
          })
          //console.log("===> urls :: ", urls)

          const result = await getClaps(urls)
          //console.log("===> Results :: ",result)
          setClaps(result.data)
      }
      fetchData()
  }, []);

  return (
    <Layout>
      <PostDescriptionWrapper>
        <PostTitle>
          <p>Every applause counts. Thanks for your support.</p>
          <p>Keep Learning !!</p>
        </PostTitle>
        <PostDescription>
        <table>
          <thead>
            <tr>
              <td><b>No.</b></td>
              <td><b>Post</b></td>
              <td><b>Applauses</b></td>
            </tr>
          </thead>
          <tbody>
          {_.orderBy(claps, ['claps'], ['desc']).map((clap, index) => (
            <tr key={clap.url}>
              <td>{index+1}</td>
              <td><ExternalLink href={'https://'+clap.url}>{_.find(posts, function(o) { return mainUrl+o.slug === clap.url; }).title }</ExternalLink></td>
              <td>{clap.claps}</td>
            </tr>
          ))}
          </tbody>
        </table>
        </PostDescription>
      </PostDescriptionWrapper>
    </Layout>
  )
}

export default PostStatsTemplate