import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "nishithakapuluru",
  "title": "Curated list of frequently used terms every Java developer needs to memorize",
  "description": "Curated list of frequently used or referred terms that every Java developer needs to memorize",
  "date": "2021-10-15T15:00:00.000Z",
  "updated": "2021-10-15T15:00:00.000Z",
  "cover": "../../../images/blog-banners/cheatsheet-banner-1200x690.png",
  "category": "cheatsheets",
  "tags": ["java", "cheatsheets"],
  "keywords": ["java", "cheatsheet", "java frequently used terms", "microservices frequently used terms", "java frequently referred terms", "microservices frequently referred terms", "nishitha.kapuluru", "nishithakapuluru", "Nishitha Kapuluru", "Kapuluru Nishitha"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Below are few frequently used or referred terms that every Java developer comes across in Lexicographical order. This is a quick one-stop reference guide created for myself or for others who wish to brush up on these !!`}</p>
    <h2 {...{
      "id": "a"
    }}>{`A`}</h2>
    <h3 {...{
      "id": "agile-development"
    }}>{`Agile Development`}</h3>
    <p>{`Agile is an iterative approach to project management and software development that helps teams deliver value to their customers faster and with fewer headaches.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "499px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e2c62c37873243ec36396956460b5e8e/119c7/agile.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "80.98159509202453%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9klEQVQoz22T+09SYRjH+a3/oF/9oc1f+qE/pC2rzTYvNbU2lULIvIBajgSUXGh5SSwtZEqmTlOnM7wkOG+gBEdFNC6CCSiHkIOHyznnfRrHe/Xs2bt3e5/Pc3nf78sB1hDDIJpGNA0AEadjW9kaW18FOwZ7DohG2AiU9MvGOd8i9jDoxetKdddT3PlpoercIzkv+IqPJr8ASRwXuQyzyJHLGbJuIMMUNJXay7JWi7NDimfBmvxAfTHGT4/WPaaahchtO2nhDD5u1SqXTt5IDVU8CFTnOWR8R2kGel0E8kIk54KC75byLNzbjPI5HOIXeQ4wSTi2bY1W5sTflv+WFiZqC2H0I3i2ILQPVgNS11PiPLJJ5K3KiWve/AWzY5j1O6L7y4KMgKQAjJMI4GjvF+F0xokI0BSMdCSk+ZbiTFzGBXwPnfKcOB4ILC/FVfXhWi4h55EfJOEtW9i24R0e2FF3ekcHCY+HNC1CYzG8qwA5N3kvAMclOYH5ufHUFJ8gMyjjhsSPQDcUtJjxBf3hmjm8uRE0LOHTExFsBanqMP49hyAdtBp0BlORSMRmjXc32CU8R2UuNfrpLPHJbEQQumqp+qJA9UP6ZS6a7kesLs5nJtQKN/fmroSXWPx+8uYMYhiaoeIA4OttN3PT5gVZa4V3d1vEECMZNiQJJzNtmcCkd/p1y76hH94xzKcNRX1sFiDRkQFrdwkzd8syXC1PaGwO6MQlhTGsdHQuVZeRpzGX961XWg9m/JGftgP9+KZCucrf0cpmhyTtuo6V/WjeyPYtDba0G+acSjMJzzo71IaStrES1Zyo2yDUrAp7LaLPliqRNmfON9g4qb+aLb7TMHCt2XilZrZvfZ9zQdrMtL3964a0x1SmnBK0jPHbJp72W158wSo6jfyFnR7PQcDq9m36D0u+2QtGt0xe4hymmBhOeshEeM2vnXK2Dm/KBtfFI9a6Gft7V9DIIAr+MQ78z2iGImL4YcxPJkLo9CcxKPlxkyvrCMEfob8YVN1yHV8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Agile",
              "title": "Agile",
              "src": "/static/e2c62c37873243ec36396956460b5e8e/119c7/agile.png",
              "srcSet": ["/static/e2c62c37873243ec36396956460b5e8e/222b7/agile.png 163w", "/static/e2c62c37873243ec36396956460b5e8e/ff46a/agile.png 325w", "/static/e2c62c37873243ec36396956460b5e8e/119c7/agile.png 499w"],
              "sizes": "(max-width: 499px) 100vw, 499px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Agile`}</figcaption>{`
  `}</figure></p>
    <p><strong parentName="p">{`Scrum`}</strong>{` is a framework that helps teams work together. Much like a rugby team (where it gets its name) training for the big game, scrum encourages teams to learn through experiences, self-organize while working on a problem, and reflect on their wins and losses to continuously improve.`}</p>
    <p>{`A `}<strong parentName="p">{`sprint`}</strong>{` is a short, time-boxed period when a scrum team works to complete a set amount of work. Sprints are at the very heart of scrum  and agile methodologies, and getting sprints right will help your agile team ship better software with fewer headaches`}</p>
    <p>{`Below are the `}<strong parentName="p">{`four scrum ceremonies`}</strong>{` that occur in each sprint. `}</p>
    <ul>
      <li parentName="ul">{`Spring Planning`}</li>
      <li parentName="ul">{`Daily Scrum`}</li>
      <li parentName="ul">{`Sprint Review`}</li>
      <li parentName="ul">{`Sprint Retrospective`}</li>
    </ul>
    <h3 {...{
      "id": "aspect-oriented-programming-aop"
    }}>{`Aspect Oriented Programming (AOP)`}</h3>
    <h2 {...{
      "id": "b"
    }}>{`B`}</h2>
    <h3 {...{
      "id": "bill-of-materials-bom"
    }}>{`Bill Of Materials (BOM)`}</h3>
    <h2 {...{
      "id": "c"
    }}>{`C`}</h2>
    <h3 {...{
      "id": "cloud-native"
    }}>{`Cloud Native`}</h3>
    <p>{`The term cloud native refers to the concept of `}<strong parentName="p">{`building and running applications`}</strong>{` to take advantage of the distributed computing offered by the cloud delivery model. Cloud native apps are designed and built to exploit the scale, elasticity, resiliency, and flexibility the cloud provides.`}</p>
    <h3 {...{
      "id": "container"
    }}>{`Container`}</h3>
    <p>{`A container is a software package that `}<strong parentName="p">{`contains everything the software needs to run`}</strong>{`. This includes the executable program as well as system tools, libraries, and settings`}</p>
    <h3 {...{
      "id": "continuous-integration-ci"
    }}>{`Continuous integration (CI)`}</h3>
    <p>{`A software engineering practice in which frequent, isolated changes are immediately tested and reported on when they are added to a larger code base.`}</p>
    <h3 {...{
      "id": "continuous-delivery-cd"
    }}>{`Continuous Delivery (CD)`}</h3>
    <p>{`A software engineering approach in which continuous integration, automated testing, and automated deployment capabilities allow software to be developed and deployed rapidly, reliably, and repeatedly with minimal human intervention.`}</p>
    <h3 {...{
      "id": "cross-origin-resource-sharing-cors"
    }}>{`Cross-Origin Resource Sharing (CORS)`}</h3>
    <h2 {...{
      "id": "d"
    }}>{`D`}</h2>
    <h3 {...{
      "id": "distributed-tracing"
    }}>{`Distributed Tracing`}</h3>
    <h3 {...{
      "id": "distributed-system"
    }}>{`Distributed System`}</h3>
    <h3 {...{
      "id": "domain-driven-design"
    }}>{`Domain-Driven Design`}</h3>
    <h3 {...{
      "id": "dependency-injection"
    }}>{`Dependency Injection`}</h3>
    <h3 {...{
      "id": "devops"
    }}>{`DevOps`}</h3>
    <p>{`DevOps is about removing the barriers between traditionally siloed teams, development and operations. Under a DevOps model, development and operations teams work together across the entire software application life cycle, from development and test through deployment to operations`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1015dcf5e27561114bb64348fdd8da03/e53e8/devops.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "51.533742331288344%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwklEQVQozzWSy2tTQRSHz5xbk9Y7Yx8bRRdGfJEmbdpqW9LaziQNKhXTRtGaNndupLY+WuyiFRQVxQciiuID/AN8gAsRFVuqKIpaa+wrN4lIRe1WFHHhwo2MTNTFcBYDH9/vnB8wvwBaKYD5OLByvpB5eSv1C4v5eCOtDs0rgXooZg0IX7uRZG0gmfzzoiOjJGt3kNlkDU5Y8O+P/AV5mvQcoH7x2xQtygxHFK0QipXzuyVQZ5ZCDYAaBJyRJejIAyRrfzGedSljOK6MFwlFMvZDTFkmydkA1C+02Wnm4ylaIT7RmtAJWiX20ApxlwbEcxoMjxe7g8tBKUIy9iQ6UmFaXsNpOYRvrT4ct1LoyO8kZx9DRxoaWEcrhTZ6vgg8BYV7N4H7VDsU7W4FGhD9edtgOI2vEiOYshTJ2Xfcx9vAdS4GrjMxKINqbX4TJ6VCR7YCrRB9OiZb1XwU1H4Pjls9+MZaj45sagIAtrz5qut8TBlPuxTJ2qP4KkHZiuZttDa0zVzXElkEnhLycWfCGO1UOCkvacM+k+eBA6AGl+BYohenZD++teKg9gF5nzyrzQpubVfmupZ7jQDzaLWI0ipxktaGjpXCmlXwY9cG41GnwgnrhgbW00A+cnYlACnqbgX3wSi4Lm8F+NYdIxlbkQ/JOTMSSdH68Jzr4pbeBWWNYAzHwXVhC7gPRwGn5H2dAGfk3v9HucJ8fJL6xWNaJYbo2lAHDYjrtCH8seD+jhH41RNUAFDwIH6b5GyFLxNPMC2TOC37MS0v6kORjO2Q2WQRMC8HtprrXe5jXq5oXViZkX+1Wc0/F3VuXFY4uBncR6LaeCmmrDFtk4/4OqH3+hkdeQgduThfG+bneUtdbuoXXubj3czHh5iPt9OGMJ3fsQHcx9vQdT5mwM8e0KZkNhlBRw5gWvaQjB0k72zQMJKx8Q/DuvrR7kjV1gAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Devops",
              "title": "Devops",
              "src": "/static/1015dcf5e27561114bb64348fdd8da03/a6d36/devops.png",
              "srcSet": ["/static/1015dcf5e27561114bb64348fdd8da03/222b7/devops.png 163w", "/static/1015dcf5e27561114bb64348fdd8da03/ff46a/devops.png 325w", "/static/1015dcf5e27561114bb64348fdd8da03/a6d36/devops.png 650w", "/static/1015dcf5e27561114bb64348fdd8da03/e548f/devops.png 975w", "/static/1015dcf5e27561114bb64348fdd8da03/3c492/devops.png 1300w", "/static/1015dcf5e27561114bb64348fdd8da03/e53e8/devops.png 1982w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Devops`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "e"
    }}>{`E`}</h2>
    <h2 {...{
      "id": "f"
    }}>{`F`}</h2>
    <h3 {...{
      "id": "fault-isolation"
    }}>{`Fault isolation`}</h3>
    <h3 {...{
      "id": "fault-tolerant"
    }}>{`Fault Tolerant`}</h3>
    <h2 {...{
      "id": "g"
    }}>{`G`}</h2>
    <h2 {...{
      "id": "h"
    }}>{`H`}</h2>
    <h2 {...{
      "id": "i"
    }}>{`I`}</h2>
    <h3 {...{
      "id": "inversion-of-control"
    }}>{`Inversion of Control`}</h3>
    <h3 {...{
      "id": "infrastructure-as-code-iac"
    }}>{`Infrastructure as Code (IaC)`}</h3>
    <h2 {...{
      "id": "j"
    }}>{`J`}</h2>
    <h2 {...{
      "id": "k"
    }}>{`K`}</h2>
    <h2 {...{
      "id": "l"
    }}>{`L`}</h2>
    <h2 {...{
      "id": "m"
    }}>{`M`}</h2>
    <h3 {...{
      "id": "monolithic"
    }}>{`Monolithic`}</h3>
    <h3 {...{
      "id": "microservices"
    }}>{`Microservices`}</h3>
    <h2 {...{
      "id": "n"
    }}>{`N`}</h2>
    <h2 {...{
      "id": "o"
    }}>{`O`}</h2>
    <h3 {...{
      "id": "object-oriented-programming"
    }}>{`Object Oriented Programming`}</h3>
    <p>{`Object Oriented programming (OOP) is a programming paradigm that relies on the concept of classes and objects. It is used to structure a software program into simple, reusable pieces of code blueprints (usually called classes), which are used to create individual instances of objects.`}</p>
    <h3 {...{
      "id": "orchestration"
    }}>{`Orchestration`}</h3>
    <h3 {...{
      "id": "obfuscation"
    }}>{`Obfuscation`}</h3>
    <h3 {...{
      "id": "open-authorization-oauth"
    }}>{`Open Authorization (OAuth)`}</h3>
    <h2 {...{
      "id": "p"
    }}>{`P`}</h2>
    <h2 {...{
      "id": "q"
    }}>{`Q`}</h2>
    <h2 {...{
      "id": "r"
    }}>{`R`}</h2>
    <h2 {...{
      "id": "s"
    }}>{`S`}</h2>
    <h3 {...{
      "id": "security-assertion-markup-language-saml"
    }}>{`Security Assertion Markup Language (SAML)`}</h3>
    <h3 {...{
      "id": "serverless"
    }}>{`Serverless`}</h3>
    <h3 {...{
      "id": "site-reliability-engineering-sre"
    }}>{`Site Reliability Engineering (SRE)`}</h3>
    <h2 {...{
      "id": "t"
    }}>{`T`}</h2>
    <h2 {...{
      "id": "u"
    }}>{`U`}</h2>
    <h2 {...{
      "id": "v"
    }}>{`V`}</h2>
    <h2 {...{
      "id": "w"
    }}>{`W`}</h2>
    <h3 {...{
      "id": "waterfall"
    }}>{`Waterfall`}</h3>
    <h2 {...{
      "id": "x"
    }}>{`X`}</h2>
    <h2 {...{
      "id": "y"
    }}>{`Y`}</h2>
    <h2 {...{
      "id": "z"
    }}>{`Z`}</h2>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <ul>
      <li parentName="ul">
        <a href="https://dzone.com/articles/microservices-zone-glossary" target="_blank">25 Microservices Terms You Need to Know</a>
      </li>
      <li parentName="ul">
        <a href="https://blog.container-solutions.com/a-glossary-of-cloud-native-terms" target="_blank">A Glossary Of Cloud Native Terms</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      