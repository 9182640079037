import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "JAVA 8 - Cheat Sheet",
  "description": "Syntax cheatsheet for Java8",
  "date": "2019-09-15T23:46:37.121Z",
  "updated": "2019-09-15T23:46:37.121Z",
  "cover": "../../../images/blog-banners/cheatsheet-banner-1200x690.png",
  "category": "cheatsheets",
  "tags": ["java8", "cheatsheets"],
  "keywords": ["java8", "lamda", "lamda expressions", "Default Lambda in Java8", "Java Time API", "Collection API improvements", "Concurrency API improvements", "Java IO improvements", "Functional Interfaces and Lambda Expressions", "default and static methods in Interfaces", "static methods", "forEach()", "Java IO improvements"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "lambda-expression"
    }}>{`Lambda Expression`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Calculate the double of a`}</span>{`
a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// or simply without type`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Sum of 2 parameters`}</span></code></pre></div>
    <p>{`If the lambda is more than one expression we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{ }`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`return`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` sum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` y`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` avg `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` sum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` avg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`A lambda expression cannot stand alone in Java, it need to be associated to a functional interface.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyMath`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getDoubleOf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyMath`}</span>{` d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// associated to the interface`}</span>{`
d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDoubleOf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// is 8`}</span></code></pre></div>
    <hr></hr>
    <p>{`All examples with “list” use :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` list `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bohr`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Darwin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Galilei`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Tesla`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Einstein`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Newton`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <h2 {...{
      "id": "collections"
    }}>{`Collections`}</h2>
    <p><strong parentName="p">{`sort`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sort(list, comparator)`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Comparator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`comparing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// same`}</span>{`
list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Comparator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`comparing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// same`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> [Bohr, Tesla, Darwin, Newton, Galilei, Einstein]`}</span></code></pre></div>
    <p><strong parentName="p">{`removeIf`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`removeIf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> [Darwin, Galilei, Einstein, Newton]`}</span></code></pre></div>
    <p><strong parentName="p">{`merge`}</strong>{`
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`merge(key, value, remappingFunction)`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Map`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` names `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HashMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Albert"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Ein?"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Marie"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Curie"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Max"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Plank"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Value "Albert" exists`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// {Marie=Curie, Max=Plank, Albert=Einstein}`}</span>{`
names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`merge`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Albert"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stein"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`old`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` old`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`substring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Value "Newname" don't exists`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// {Marie=Curie, Newname=stein, Max=Plank, Albert=Einstein}`}</span>{`
names`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`merge`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Newname"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"stein"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`old`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` old`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`substring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` val`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "method-expressions-classstaticmethod"
    }}>{`Method Expressions Class::staticMethod`}</h2>
    <p>{`Allows to reference methods (and constructors) without executing them`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Lambda Form:`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`getPrimes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StaticMethod`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isPrime`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Method Reference:`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`getPrimes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`numbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StaticMethod`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isPrime`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Method Reference`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Lambda Form`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`StaticMethod::isPrime`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`n -> StaticMethod.isPrime(n)`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`String::toUpperCase`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(String w) -> w.toUpperCase()`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`String::compareTo`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(String s, String t) -> s.compareTo(t)`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`System.out::println`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`x -> System.out.println(x)`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`Double::new`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`n -> new Double(n)`}</code></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`String[]::new`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`(int n) -> new String[n]`}</code></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "streams"
    }}>{`Streams`}</h2>
    <p>{`Similar to collections, but`}</p>
    <ul>
      <li parentName="ul">{`They don’t store their own data`}</li>
      <li parentName="ul">{`The data comes from elsewhere (collection, file, db, web, …)`}</li>
      <li parentName="ul"><em parentName="li">{`immutable`}</em>{` (produce new streams)`}</li>
      <li parentName="ul"><em parentName="li">{`lazy`}</em>{` (only computes what is necessary !)`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Will compute just 3 "filter"`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` longNames `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` list
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`limit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`Create a new stream`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Jazz"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Blues"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Rock"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`myArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// or from an array`}</span>{`
list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// or from a list`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Infinit stream [0; inf[`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` integers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`iterate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`Collecting results`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Collect into an array (::new is the constructor reference)`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` myArray `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toArray`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Collect into a List or Set`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` myList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`collect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toList`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Set`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` mySet `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`collect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toSet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Collect into a String`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` str `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`collect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`joining`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`", "`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`map`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map(mapper)`}</code>{`
Applying a function to each element`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Apply "toLowerCase" for each element`}</span>{`
res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toLowerCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toLowerCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> bohr darwin galilei tesla einstein newton`}</span>{`

res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> 2 3 4 5 6`}</span></code></pre></div>
    <p><strong parentName="p">{`filter`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`filter(predicate)`}</code>{`
Retains elements that match the predicate`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Filter elements that begin with "E"`}</span>{`
res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`substring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"E"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> Einstein`}</span>{`

res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> 1 2`}</span></code></pre></div>
    <p><strong parentName="p">{`reduce`}</strong>{`
Reduce the elements to a single value`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` reduced `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`acc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` el`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` acc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"|"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` el`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> |Bohr|Darwin|Galilei|Tesla|Einstein|Newton`}</span></code></pre></div>
    <p><strong parentName="p">{`limit`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`limit(maxSize)`}</code>{`
The n first elements`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`limit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> Bohr Darwin Galilei`}</span></code></pre></div>
    <p><strong parentName="p">{`skip`}</strong>{`
Discarding the first n elements`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` strem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`skip`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// skip Bohr and Darwin`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> Galilei Tesla Einstein Newton`}</span></code></pre></div>
    <p><strong parentName="p">{`distinct`}</strong>{`
Remove duplicated elemetns`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`distinct`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> 1 0`}</span></code></pre></div>
    <p><strong parentName="p">{`sorted`}</strong>{`
Sort elements (must be `}<em parentName="p">{`Comparable`}</em>{`)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sorted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> Bohr Darwin Einstein Galilei Newton Tesla `}</span></code></pre></div>
    <p><strong parentName="p">{`allMatch`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Check if there is a "e" in each elements`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`boolean`}</span>{` res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` words`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`allMatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`contains`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`anyMatch: Check if there is a “e” in an element
noneMatch: Check if there is no “e” in elements`}</p>
    <p><strong parentName="p">{`parallel`}</strong>{`
Returns an equivalent stream that is parallel`}</p>
    <p><strong parentName="p">{`findAny`}</strong>{`
faster than findFirst on parallel streams`}</p>
    <h3 {...{
      "id": "primitive-type-streams"
    }}>{`Primitive-Type Streams`}</h3>
    <p>{`Wrappers (like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream<Integer>`}</code>{`) are inefficients. It requires a lot of unboxing and boxing for each element. Better to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`IntStream`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DoubleStream`}</code>{`, etc.`}</p>
    <p><strong parentName="p">{`Creation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span>{` stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`myArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// from an array`}</span>{`
stream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`range`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// range from 5 to 80`}</span>{`

`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Random`}</span>{` gen `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span>{` rand `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`gen`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// stream of randoms`}</span></code></pre></div>
    <p>{`Use `}<em parentName="p">{`mapToX`}</em>{` (mapToObj, mapToDouble, etc.) if the function yields Object, double, etc. values.`}</p>
    <h3 {...{
      "id": "grouping-results"
    }}>{`Grouping Results`}</h3>
    <p><strong parentName="p">{`Collectors.groupingBy`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Groupe by length`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Map`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`List`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` groups `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`collect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`groupingBy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//> 4=[Bohr], 5=[Tesla], 6=[Darwin, Newton], ...`}</span></code></pre></div>
    <p><strong parentName="p">{`Collectors.toSet`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Same as before but with Set`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`groupingBy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
	w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`substring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toSet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span></code></pre></div>
    <p><strong parentName="p">{`Collectors.counting`}</strong>{`
Count the number of values in a group`}</p>
    <p><strong parentName="p">{`Collectors.summing__`}</strong>{`
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`summingInt`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`summingLong`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`summingDouble`}</code>{` to sum group values`}</p>
    <p><strong parentName="p">{`Collectors.averaging__`}</strong>{`
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`averagingInt`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`averagingLong`}</code>{`, … `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Average length of each element of a group`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Collectors`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`averagingInt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><em parentName="p">{`PS`}</em>{`: Don’t forget Optional (like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Map<T, Optional<T>>`}</code>{`) with some Collection methods (like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Collectors.maxBy`}</code>{`).`}</p>
    <h3 {...{
      "id": "parallel-streams"
    }}>{`Parallel Streams`}</h3>
    <p><strong parentName="p">{`Creation`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` parStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parallelStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` parStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`myArray`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parallel`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`unordered`}</strong>{`
Can speed up the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`limit`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`distinct`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`parallelStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`unordered`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`distinct`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><em parentName="p">{`PS`}</em>{`: Work with the streams library. Eg. use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`filter(x -> x.length() < 9)`}</code>{` instead of a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`forEach`}</code>{` with an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`if`}</code>{`.`}</p>
    <h2 {...{
      "id": "optional"
    }}>{`Optional`}</h2>
    <p>{`In Java, it is common to use null to denote absence of result.
Problems when no checks: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`NullPointerException`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Optional<String> contains a string or nothing`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Optional`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stream
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findFirst`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// length of the value or "" if nothing`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`orElse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`length`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// run the lambda if there is a value`}</span>{`
res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ifPresent`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`v `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` results`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`v`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Return an Optional`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Optional`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Double`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`squareRoot`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`double`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Optional`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`of`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Math`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sqrt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
   `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Optional`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`empty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <hr></hr>
    <p><strong parentName="p">{`Note on inferance limitations`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Pair`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`A`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`first`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`second`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`A steam of type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stream<Pair<String, Long>>`}</code>{` :`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`stream.sorted(Comparator.comparing(Pair::first)) // ok`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`stream.sorted(Comparator.comparing(Pair::first).thenComparing(Pair::second)) // dont work`}</code></li>
    </ul>
    <p>{`Java cannot infer type for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.comparing(Pair::first)`}</code>{` part and fallback to Object, on which `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Pair::first`}</code>{` cannot be applied.`}</p>
    <p>{`The required type for the whole expression cannot be propagated through the method call (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.thenComparing`}</code>{`) and used to infer type of the first part.`}</p>
    <p>{`Type `}<em parentName="p">{`must`}</em>{` be given explicitly.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`stream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sorted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Comparator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Pair`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Long`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`comparing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Pair`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`first`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`thenComparing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Pair`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`second`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ok`}</span></code></pre></div>
    <hr></hr>
    <p>{`This cheat sheet was based on the lecture of Cay Horstmann
`}<a parentName="p" {...{
        "href": "http://horstmann.com/heig-vd/spring2015/poo/"
      }}>{`http://horstmann.com/heig-vd/spring2015/poo/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      