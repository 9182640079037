import React, {useEffect} from 'react';

const ArticleInlineAd = (props) => {
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error("====> Error occurred while p", e);
    }
  }, [])
  return (
    <ins className="adsbygoogle"
     style={{display: "block"}}
     data-ad-client="ca-pub-5715094337709253"
     data-ad-slot="7172789808"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
  );
};

export default ArticleInlineAd;