import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": false,
  "author": "meenakshivemuri",
  "title": "Six of My Online Stores Failed",
  "date": "2019-11-06",
  "updated": "2019-03-06T23:46:37.121Z",
  "description": "Did you know that “despite its name, salted duck eggs can also be made from chicken eggs, though the taste and texture will be somewhat different, and the egg yolk will be less rich.”?",
  "category": "microservice",
  "tags": ["javascript", "hooks", "featured"],
  "cover": "./preview.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Did you know that “despite its name, salted duck eggs can also be made from chicken eggs, though the taste and texture will be somewhat different, and the egg yolk will be less rich.”?`}</p>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur pharetra sapien nibh, sed tempor mauris suscipit ac. Duis libero nibh, porttitor at posuere at, luctus et urna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce et augue vitae nisl ultrices posuere. Sed facilisis metus rutrum, commodo lorem sed, consequat sem. Morbi enim orci, molestie a rutrum cursus, pellentesque nec ligula. Etiam porttitor iaculis purus sed imperdiet. Nulla consectetur eu sem ut dapibus. Duis nec viverra nisl, quis tincidunt massa.`}</p>
    <p>{`Nam ut egestas dolor. Vestibulum ac dignissim sem, vel sollicitudin urna. Vivamus consequat est urna, eget feugiat orci porta fermentum. Maecenas pulvinar lacinia sapien. Fusce vitae orci tempus, congue lectus vel, luctus enim. Ut tempor felis eu ex accumsan volutpat. Vestibulum gravida vel est sed hendrerit. Curabitur finibus dignissim ipsum quis cursus. Sed eleifend non dui ac dictum. Nam dictum augue eget lorem ullamcorper, in iaculis urna dapibus. Nulla nunc ante, mattis in accumsan et, aliquet eget magna. Donec venenatis ut diam at condimentum. Mauris malesuada elit vel ante blandit scelerisque. Cras enim orci, luctus at consequat non, interdum ac ex. Donec quis venenatis eros.`}</p>
    <p>{`Aliquam condimentum eu tortor eget feugiat. Maecenas tincidunt ultrices arcu venenatis rutrum. Suspendisse eu lectus eu erat aliquet tempus a ac urna. Integer euismod erat elementum magna pellentesque dapibus. Praesent interdum dolor felis, et auctor purus tempus rhoncus. Suspendisse cursus enim eu lacus semper, sed dictum neque tincidunt. Praesent pellentesque, quam vel congue accumsan, massa erat pulvinar velit, interdum fermentum nunc lacus posuere massa. Donec auctor semper metus, vel tempor neque cursus eu. Nam ultrices, ex sed dapibus dignissim, quam lorem auctor dui, eget varius felis erat lacinia lacus. Fusce vestibulum nec tortor in congue. Phasellus id quam efficitur, rutrum est at, hendrerit velit. Nulla laoreet ultricies metus quis pharetra. Curabitur libero ante, suscipit sit amet ex consectetur, mollis cursus magna.`}</p>
    <p>{`Sed nec eros feugiat, porta sapien non, porta metus. Morbi pharetra eros non commodo aliquet. Cras at magna massa. Proin rhoncus libero arcu, vehicula pretium dui egestas nec. Nunc ornare dui et egestas sodales. Mauris imperdiet risus ut ex pharetra cursus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla massa nulla, congue vitae nulla sit amet, fringilla laoreet felis. Donec risus ante, lobortis at vulputate eu, euismod sed metus. Duis eu molestie dui. Etiam gravida augue tortor, id congue ex luctus a. Donec ultrices id nisi in vulputate.`}</p>
    <p>{`Proin dolor augue, accumsan id luctus ut, tristique eu ligula. Sed eu blandit tortor. Maecenas semper nunc eu augue semper, ac tempus leo blandit. Suspendisse nunc erat, tincidunt at diam nec, varius varius sapien. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec accumsan, ipsum quis aliquet dapibus, quam purus fermentum ex, vel dignissim dolor nisl vulputate neque. Suspendisse sit amet tempus ipsum, quis vehicula eros. Interdum et malesuada fames ac ante ipsum primis in faucibus.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      