import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "showSideBar": true,
  "author": "narramadan",
  "title": "Docker Cheatsheet",
  "description": "Syntax cheatsheets for quick reference to get things done with Docker.",
  "date": "2020-03-15T15:00:00.000Z",
  "updated": "2020-03-15T15:00:00.000Z",
  "cover": "../../../images/blog-banners/cheatsheet-banner-1200x690.png",
  "category": "cheatsheets",
  "tags": ["docker", "cheatsheets"],
  "keywords": ["docker", "cheatsheet", "how to do in docker", "docker cheatsheets", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "managing-container"
    }}>{`Managing Container`}</h2>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`create`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` create -t -i fedora `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`start`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` start -a -i dff32a272ad4c`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`rename`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rename`}</span>{` my_container my_new_container`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`run`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it ubuntu-ssh-k /bin/bash`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`delete`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` myfedora`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`update`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` update --cpu-shares `}<span parentName="code" {...{
            "className": "token number"
          }}>{`512`}</span>{` -m 300M dff32a272ad4 happy_kare`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker start`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker stop`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker restart`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker pause`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker unpause`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker wait`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker kill`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker attach`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker exec -i -t my-nginx-1 /bin/bash`}</code></pre></div>
    <h2 {...{
      "id": "container-information"
    }}>{`Container information`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker ps

$ docker ps -a`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`View a list of all Docker container IDs`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` container `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -aq`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker logs`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker inspect --format '{{ .NetworkSettings.IPAddress }}' $(docker ps -q)`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker events`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker port`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker top`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker stats`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker diff`}</code></pre></div>
    <h2 {...{
      "id": "managing-images"
    }}>{`Managing Images`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker images`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker import`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker build`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker commit`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker rmi`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker load`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker save`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker history`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker tag`}</code></pre></div>
    <h2 {...{
      "id": "manage-network"
    }}>{`Manage Network`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker network create
$ docker network rm
$ docker network ls
$ docker network inspect
$ docker network connect
$ docker network disconnect`}</code></pre></div>
    <h2 {...{
      "id": "using-repository"
    }}>{`Using Repository`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title="
    }}><pre parentName="div" {...{
        "className": "language-bash:title="
      }}><code parentName="pre" {...{
          "className": "language-bash:title="
        }}>{`$ docker login --username=

$ docker logout

$ docker search mysql

$ docker pull ubuntu

$ docker push `}</code></pre></div>
    <h2 {...{
      "id": "cleanup"
    }}>{`Cleanup`}</h2>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop all Docker containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` stop `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`docker`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`ps`}</span>{` -a -q`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Remove all Docker containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`docker`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`ps`}</span>{` -a -q`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Prune unused docker networks`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` network prune`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash:title=stop%20the%20dockervcontainers%20for%20confluent"
    }}><pre parentName="div" {...{
        "className": "language-bash:title=stop%20the%20dockervcontainers%20for%20confluent"
      }}><code parentName="pre" {...{
          "className": "language-bash:title=stop%20the%20dockervcontainers%20for%20confluent"
        }}>{`$ docker container stop $(docker container ls -a -q -f "label=io.confluent.docker")`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop the containers and prune the Docker system`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`-- Running these commands deletes containers, networks, volumes, and images`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` freeing up disk space
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` container stop `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`docker`}</span>{` container `}<span parentName="span" {...{
              "className": "token function"
            }}>{`ls`}</span>{` -a -q -f `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"label=io.confluent.docker"`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` system prune -a -f --volumes`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      