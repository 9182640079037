import styled from 'styled-components';

export const MermaidSvgWrapper = styled.div`
    @media (min-width: 990px) {
        width: 900px;
    }
    @media (min-width: 1100px) {
        width: 1050px;
    }
    @media (min-width: 1250px) {
        width: 1170px;
    }
`