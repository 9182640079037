import * as React from "react"
import AuthorsPageWrapper from "./style"
import Authors from "./Authors"

type AuthorsPageProps = {}

const AuthorsPage: React.FunctionComponent<AuthorsPageProps> = ({
  ...props
}) => {
  return (
    <AuthorsPageWrapper {...props}>
      <Authors />
    </AuthorsPageWrapper>
  )
}

export default AuthorsPage
