import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "A Guide to TreeMap in Java",
  "description": "Detailed article on exploring in and out of TreeMap implementation of Map interface from Java Collections Framework(JCF).",
  "date": "2019-11-06T23:46:37.121Z",
  "updated": "2019-11-06T23:46:37.121Z",
  "cover": "../../../images/blog-banners/Java-banner-1200x690.png",
  "category": "java",
  "tags": ["java", "map", "featured"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`In this article, we are going to explore TreeMap implementation of Map interface from Java Collections Framework(JCF).`}</p>
    <p>{`TreeMap is a map implementation that keeps its entries sorted according to the natural ordering of its keys or better still using a comparator if provided by the user at construction time.`}</p>
    <p>{`Previously, we have covered HashMap and LinkedHashMap implementations and we will realize that there is quite a bit of information about how these classes work that is similar.`}</p>
    <p>{`The mentioned articles are highly recommended reading before going forth with this one.`}</p>
    <h2 {...{
      "id": "default-sorting-in-treemap"
    }}>{`Default Sorting in TreeMap`}</h2>
    <p>{`By default, TreeMap sorts all its entries according to their natural ordering. For an integer, this would mean ascending order and for strings, alphabetical order.`}</p>
    <p>{`Let’s see the natural ordering in a test:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`givenTreeMap_whenOrdersEntriesNaturally_thenCorrect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` map `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[1, 2, 3, 4, 5]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keySet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Notice that we placed the integer keys in a non-orderly manner but on retrieving the key set, we confirm that they are indeed maintained in ascending order. This is the natural ordering of integers.`}</p>
    <p>{`Likewise, when we use strings, they will be sorted in their natural order, i.e. alphabetically:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`givenTreeMap_whenOrdersEntriesNaturally_thenCorrect2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` map `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"b"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"e"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"d"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[a, b, c, d, e]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keySet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "custom-sorting-in-treemap"
    }}>{`Custom Sorting in TreeMap`}</h2>
    <p>{`If we’re not satisfied with the natural ordering of TreeMap, we can also define our own rule for ordering by means of a comparator during construction of a tree map.`}</p>
    <p>{`In the example below, we want the integer keys to be ordered in descending order:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`givenTreeMap_whenOrdersEntriesByComparator_thenCorrect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` map `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Comparator`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reverseOrder`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
         
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[5, 4, 3, 2, 1]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keySet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`A hash map does not guarantee the order of keys stored and specifically does not guarantee that this order will remain the same over time, but a tree map guarantees that the keys will always be sorted according to the specified order.`}</p>
    <h2 {...{
      "id": "importance-of-treemap-sorting"
    }}>{`Importance of TreeMap Sorting`}</h2>
    <p>{`We now know that TreeMap stores all its entries in sorted order. Because of this attribute of tree maps, we can perform queries like; find “largest”, find “smallest”, find all keys less than or greater than a certain value, etc.`}</p>
    <p>{`The code below only covers a small percentage of these cases:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`givenTreeMap_whenPerformsQueries_thenCorrect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` map `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`put`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
         
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Integer`}</span>{` highestKey `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`lastKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Integer`}</span>{` lowestKey `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`firstKey`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Set`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` keysLessThan3 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`headMap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keySet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Set`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Integer`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` keysGreaterThanEqTo3 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` map`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`tailMap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keySet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Integer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` highestKey`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Integer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lowestKey`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[1, 2]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` keysLessThan3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"[3, 4, 5]"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` keysGreaterThanEqTo3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "internal-implementation-of-treemap"
    }}>{`Internal Implementation of TreeMap`}</h2>
    <p>{`TreeMap implements NavigableMap interface and bases it’s internal working on the principles of red-black trees:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TreeMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`K`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`V`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AbstractMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`K`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`V`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`implements`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NavigableMap`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`K`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`V`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Cloneable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`java`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`io`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`Serializable`}</span></code></pre></div>
    <p>{`The principle of red-black trees is beyond the scope of this article, however, there are key things to remember in order to understand how they fit into TreeMap.`}</p>
    <p>{`First of all, a red-black tree is a data structure that consists of nodes; picture an inverted mango tree with its root in the sky and the branches growing downward. The root will contain the first element added to the tree.`}</p>
    <p>{`The rule is that starting from the root, any element in the left branch of any node is always less than the element in the node itself. Those on the right are always greater. What defines greater or less than is determined by the natural ordering of the elements or the defined comparator at construction as we saw earlier.`}</p>
    <p>{`This rule guarantees that the entries of a treemap will always be in sorted and predictable order.`}</p>
    <p>{`Secondly, a red-black tree is a self-balancing binary search tree. This attribute and the above guarantee that basic operations like search, get, put and remove take logarithmic time O(log n).`}</p>
    <p>{`Being self-balancing is key here. As we keep inserting and deleting entries, picture the tree growing longer on one edge or shorter on the other.`}</p>
    <p>{`This would mean that an operation would take a shorter time on the shorter branch and longer time on the branch which is furthest from the root, something we would not want to happen.`}</p>
    <p>{`Therefore, this is taken care of in the design of red-black trees. For every insertion and deletion, the maximum height of the tree on any edge is maintained at O(log n) i.e. the tree balances itself continuously.`}</p>
    <p>{`Just like hash map and linked hash map, a tree map is not synchronized and therefore the rules for using it in a multi-threaded environment are similar to those in the other two map implementations.`}</p>
    <h2 {...{
      "id": "choosing-the-right-map"
    }}>{`Choosing the Right Map`}</h2>
    <p>{`Having looked at `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HashMap`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`LinkedHashMap`}</code>{` implementations previously and now TreeMap, it is important to make a brief comparison between the three to guide us on which one fits where.`}</p>
    <p>{`A hash map is good as a general purpose map implementation that provides rapid storage and retrieval operations. However, it falls short because of its chaotic and unorderly arrangement of entries.`}</p>
    <p>{`This causes it to perform poorly in scenarios where there is a lot of iteration as the entire capacity of the underlying array affects traversal other than just the number of entries.`}</p>
    <p>{`A linked hash map possesses the good attributes of hash maps and adds order to the entries. It performs better where there is a lot of iteration because only the number of entries is taken into account regardless of capacity.`}</p>
    <p>{`A tree map takes ordering to the next level by providing complete control over how the keys should be sorted. On the flip side, it offers worse general performance than the other two alternatives.`}</p>
    <p>{`We could say a linked hash map reduces the chaos in the ordering of a hash map without incurring the performance penalty of a tree map.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In this article, we have explored Java TreeMap class and its internal implementation. Since it is the last in a series of common Map interface implementations, we also went ahead to briefly discuss where it fits best in relation to the other two.`}</p>
    <p>{`The full source code for all the examples used in this article can be found in the GitHub project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      