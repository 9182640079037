import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": true,
  "author": "narramadan",
  "title": "Validating integrity of files with md5 checksum",
  "description": "Commands for different OS to validate integrity of files with md5 checksum",
  "date": "2020-06-27T10:00:00.000Z",
  "updated": "2020-06-27T10:00:00.000Z",
  "cover": "../../../images/blog-banners/titbits-banner-1200x690.png",
  "category": "titbits",
  "tags": ["titbit", "checksum", "md5sum", "certutil"],
  "keywords": ["md5sum", "md5", "md5checksum", "md5checksum windows10", "md5checksum ubuntu", "md5checksum linux", "md5checksum gitbash", "md5checksum macos", "md5checksum mac", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`MD5`}</code>{` checksum is a 32-character hexadecimal number that verifies 128-bit `}<ExternalLink href="https://en.wikipedia.org/wiki/MD5" mdxType="ExternalLink">{`MD5`}</ExternalLink>{` `}<ExternalLink href="https://en.wikipedia.org/wiki/Cryptographic_hash_function" mdxType="ExternalLink">{`hashes`}</ExternalLink>{` on a file. If two files have the same MD5 checksum value, then there is a high probability that the two files are the same.`}</p>
    <p>{`However, it is very unlikely that any two non-identical files in the real world will have the same MD5 hash, unless they have been specifically created to have the same hash.`}</p>
    <p>{`After downloading, transferring or comparing, we can compute the MD5 checksum on the file. By doing this, we can verify the integrity of the files. Virtually any change to a file will cause its MD5 hash to changes virtually any change to a file will cause its MD5 hash to change.`}</p>
    <p>{`There are a variety of MD5 checksum tools or programs available to calculate the checksum. Below are few ways to do so.`}</p>
    <h2 {...{
      "id": "using-md5sum"
    }}>{`Using md5sum`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`md5sum`}</code>{` is program that is included in most `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Unix`}</code>{`-like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`operating systems`}</code>{` or compatibility layers such as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Cygwin`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Compute md5sum for the input file`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ md5sum samplefile.txt
3b85ec9ab2984b91070128be6aae25eb  samplefile.txt`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Check MD5 hash for multiple files`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ md5sum filetohashA.txt filetohashB.txt filetohashC.txt

595f44fec1e92a71d3e9e77456ba80d1  filetohashA.txt
71f920fa275127a7b60fa4d4d41432a3  filetohashB.txt
43c191bf6d6c3f263a8cd0efd4a058ab  filetohashC.txt`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Check MD5`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'D43F2404CA13E22594E5C8B04D3BBB81  filetohashA.txt'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` md5sum -c
filetohashA.txt: OK`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create MD5 hash file hash.md5`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ md5sum filetohashA.txt filetohashB.txt filetohashC.txt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` hash.md5

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`cat`}</span>{` hash.md5
595f44fec1e92a71d3e9e77456ba80d1  filetohashA.txt
71f920fa275127a7b60fa4d4d41432a3  filetohashB.txt
43c191bf6d6c3f263a8cd0efd4a058ab  filetohashC.txt

$ md5sum -c hash.md5
filetohashA.txt: OK
filetohashB.txt: OK
filetohashC.txt: OK`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Checksum Fail`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`echo`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'D43F2404CA13E22594E5C8B04D3BBB81  README.md'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` md5sum -c
README.md: FAILED
md5sum: WARNING: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` computed checksum did NOT match`}</code></pre></div>
    <p><strong parentName="p">{`Syntax and Options`}</strong></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`md5sum [OPTION]… [FILE]…`}</code></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`md5sum --help`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`Usage: md5sum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`OPTION`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`. `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`FILE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
Print or check MD5 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`128`}</span>{`-bit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` checksums.

With no FILE, or when FILE is -, `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`read`}</span>{` standard input.

  -b, --binary         `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`read`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` binary mode
  -c, --check          `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`read`}</span>{` MD5 sums from the FILEs and check them
      --tag            create a BSD-style checksum
  -t, --text           `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`read`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` text mode `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`default`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  -z, --zero           end each output line with NUL, not newline,
                       and disable `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{` name escaping

The following five options are useful only when verifying checksums:
      --ignore-missing  don`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'t fail or report status for missing files
      --quiet          don'`}</span>{`t print OK `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` each successfully verified `}<span parentName="code" {...{
            "className": "token function"
          }}>{`file`}</span>{`
      --status         don`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'t output anything, status code shows success
      --strict         exit non-zero for improperly formatted checksum lines
  -w, --warn           warn about improperly formatted checksum lines

      --help display this help and exit
      --version output version information and exit

The sums are computed as described in RFC 1321.  When checking, the input
should be a former output of this program.  The default mode is to print a
line with checksum, a space, a character indicating input mode ('`}</span>{`*`}<span parentName="code" {...{
            "className": "token string"
          }}>{`' for binary,
'`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`' for text or where binary is insignificant), and name for each FILE.

GNU coreutils online help: <https://www.gnu.org/software/coreutils/>
Full documentation at: <https://www.gnu.org/software/coreutils/md5sum>
or available locally via: info '`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coreutils`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` md5sum invocation'`}</code></pre></div>
    <h2 {...{
      "id": "using-certutil"
    }}>{`Using Certutil`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Certutil.exe`}</code>{` is a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`windows`}</code>{` command-line program that is installed as part of Certificate Services. You can use Certutil.exe to dump and display certification authority (CA) configuration information, configure Certificate Services, backup and restore CA components, and verify certificates, key pairs, and certificate chains.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Certutil`}</code>{` has many functions, mostly related to viewing and managing certificates, but the –hashfile subcommand can be used on any file to get a hash in `}<strong parentName="p">{`MD5`}</strong>{`, `}<strong parentName="p">{`SHA256`}</strong>{`, or `}<strong parentName="p">{`several other`}</strong>{` formats.`}</p>
    <p>{`Using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-hashfile`}</code>{` option, we can generate and display a cryptographic hash for a specific file.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`C:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` certutil -hashfile -?

Hash algorithms: MD2 MD4 MD5 SHA1 SHA256 SHA384 SHA512`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Compute MD5 hash Using hashfile option`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`C:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` certutil -hashfile Readme.md

MD5 `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`hash`}</span>{` of Readme.md
595f44fec1e92a71d3e9e77456ba80d1
CertUtil: -hshfile `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`command`}</span>{` completed successfully.`}</code></pre></div>
    <h2 {...{
      "id": "using-java"
    }}>{`Using Java`}</h2>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Using standard java classes`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checksum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`File`}</span>{` file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InputStream`}</span>{` fin `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`FileInputStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`java`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`security`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`MessageDigest`}</span>{` md5er `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MessageDigest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getInstance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MD5"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`byte`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` buffer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`byte`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1024`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` read`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      read `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` fin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`read`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`buffer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`read `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        md5er`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`update`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`buffer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` read`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`read `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    fin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`byte`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` digest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` md5er`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`digest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`digest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` strDigest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"0x"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` digest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      strDigest `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Integer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`digest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0xff`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
                `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0x100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`substring`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toUpperCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` strDigest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Using Apache Commons dependency`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`org`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`apache`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`commons`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`codec`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`digest`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`DigestUtils`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`md5Hex`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`org`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`apache`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`commons`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`io`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`FileUtils`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`readFileToByteArray`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`UsingSpring and Apache Commons dependency`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`org`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`springframework`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`util`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`DigestUtils`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`md5DigestAsHex`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}><span parentName="span" {...{
              "className": "token namespace"
            }}>{`org`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`apache`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`commons`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`io`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span></span>{`FileUtils`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`readFileToByteArray`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Using Guava dependency`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`maven`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dependency`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`groupId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`com`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`google`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`guava`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`groupId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`artifactId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`guava`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`artifactId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`version`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token number"
          }}>{`29.0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`jre`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`dependency`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

gradle`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
compile group`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 'com`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`google`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`guava'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token char"
          }}>{`'guava'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` '`}<span parentName="code" {...{
            "className": "token number"
          }}>{`29.0`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`jre'

`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HashCode`}</span>{` md5 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Files`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`hash`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Hashing`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`md5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`byte`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` md5Bytes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` md5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asBytes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` md5Hex `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` md5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Using Fast MD5 dependency`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}>{`maven`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`dependency`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`groupId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`com`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`joyent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`util`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`groupId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`artifactId`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`fast`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`md5`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`artifactId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`version`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token number"
          }}>{`2.7`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`.1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`dependency`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

gradle`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
compile group`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 'com`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`joyent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`util'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 'fast`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`md5'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` version`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token char"
          }}>{`'2.7.1'`}</span>{`

`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` hash `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` MD5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`asHex`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`MD5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getHash`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`File`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`filename`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "using-python"
    }}>{`Using Python`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` hashlib

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`with`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"your_filename.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"rb"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    file_hash `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` hashlib`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`md5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`while`}</span>{` chunk `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` f`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`read`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8192`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        file_hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`update`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`chunk`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file_hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`digest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`file_hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`hexdigest`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# to get a printable str instead of bytes`}</span></code></pre></div>
    <h2 {...{
      "id": "using-go"
    }}>{`Using Go`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` main

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
	`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"crypto/md5"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"encoding/hex"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fmt"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"io"`}</span>{`
	`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"os"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`hash_file_md5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`filePath `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` returnMD5String `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
	file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` os`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Open`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`filePath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` returnMD5String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`defer`}</span>{` file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	hash `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` md5`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`New`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` io`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Copy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` file`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` returnMD5String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
	hashInBytes `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
	returnMD5String `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` hex`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`EncodeToString`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`hashInBytes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` returnMD5String`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`hash_file_md5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`os`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`hash`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      