import React, {useEffect} from 'react';
import './index.styles.css'

const Applause = (props) => {

    useEffect(() => {
        import('applause-button')
    }, []);

    return (
            <applause-button
                id="applause-button"
                color="#2dd4bf"
                multiclap={true}
                class="buttonsize"
                url={props.url}
            />
    );
};

export default Applause;