import * as React from 'react';
import {
  MermaidSvgWrapper
} from './Svg.style';

const SVG = (props) => {
  return (
    (props.id && props.id.includes("mermaid-")) ? 
      <MermaidSvgWrapper>
        <svg {...props} >{props.children}</svg>
      </MermaidSvgWrapper>
      : <svg {...props}>{props.children}</svg>
  );
};

export default SVG;