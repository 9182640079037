import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"

import SocialProfile from "~/components/SocialProfile/SocialProfile"
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
  IntroJobTitle,
  TotalPosts
} from "./style"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoGithub,
  IoLogoLinkedin
} from "react-icons/io"

interface AuthorInfoProps {
  alias: string
  name: string
  order: number
  image: any
  jobTitle: string
  description: string
  social: {
    facebook?: string
    github?: string
    instagram?: string
    linkedin?: string
    twitter?: string
  }
  totalPosts?: number
}

function prepareSocialLinks(social: any) {
  const SocialLinks = []

  if(social.github) SocialLinks.push({icon: <IoLogoGithub />, url: social.github, tooltip: "Github",})
  if(social.linkedin) SocialLinks.push({icon: <IoLogoLinkedin />,url: social.linkedin, tooltip: "Linkedin",})  
  if(social.facebook) SocialLinks.push({icon: <IoLogoFacebook />,url: social.facebook, tooltip: "Facebook",})  
  if(social.twitter) SocialLinks.push({icon: <IoLogoTwitter />,url: social.twitter, tooltip: "Twitter",})
  if(social.instagram) SocialLinks.push({icon: <IoLogoInstagram />,url: social.instagram, tooltip: "Instagram",})

  return SocialLinks
}

const Intro: React.FunctionComponent<AuthorInfoProps> = ({
  alias,
  name,
  order,
  image,
  jobTitle,
  description,
  social,
  totalPosts,
  ...props
  }) => {

  const SocialLinks = prepareSocialLinks(social)
  //console.log("===> Author :: totalPosts :: ", totalPosts)
  return (
    <IntroWrapper>
      <IntroImage>
        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="author" />
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          <Link className="bio" to={`/authors/${_.kebabCase(alias)}`}>
            {name}
            <TotalPosts>{totalPosts} {totalPosts == 1 ? 'Post' : 'Posts'}</TotalPosts>
          </Link>          
        </IntroTitle>
        <IntroJobTitle>{jobTitle}</IntroJobTitle>
        <Desciption>{description}</Desciption>
        <SocialProfile items={SocialLinks} />
      </IntroInfo>
    </IntroWrapper>
  )
}

export default Intro
