import React from "react"
import { Link } from "gatsby"

import {
    MuchLogoText,
    LearnLogoText,
    MuchLogoTextMobile,
    LearnLogoTextMobile,
} from "./Logo.style"

import LogoImage from "~/images/logo/logo.svg"

const Logo = (props) => {

    return (
        <>
            {!props.mobileMenu && (
                <Link to="/">
                    <img width="48px" height="48px" src={LogoImage} alt="logo" style={{display: 'inherit'}} />
                    <MuchLogoText>2much</MuchLogoText>
                    <LearnLogoText>2learn</LearnLogoText>                
                </Link>    
            )}
            
            {props.mobileMenu && (<Link to="/">
                <img width="48px" height="48px" src={LogoImage} alt="logo" style={{display: 'inherit'}} />
                <LearnLogoTextMobile>2learn</LearnLogoTextMobile>
                <MuchLogoTextMobile>2much</MuchLogoTextMobile>
            </Link>
            )}
        </>
    )
}

export default Logo