import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Software Development Environment setup on Macbook with M2 chip",
  "description": "Series of steps to setup software development environment on Macbook with M2 Chip.",
  "date": "2023-05-25T15:46:37.121+10:00",
  "updated": "2023-05-25T15:46:37.121+10:00",
  "cover": "../../../images/blog-banners/setup-macbook-devenv-banner-1200x690.png",
  "category": "setup",
  "tags": ["Mackbook", "Setup"],
  "keywords": ["Development Environment setup on Macbook with M2 chip", "Docker on M2", "Kubernetes on M2", "Java on M2", "Node on M2", "Apple M2", "Development M2", "Docker", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const ExternalLinksListContainer = makeShortcode("ExternalLinksListContainer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`Got myself a new Apple Macbook Pro M2 Max 14″ and this guide is all about setting up the development environment needed to properly configure the Apple silicon device and for future reference to reconfigure the machine again if need be.`}</p>
    <p>{`And reference to `}<ExternalLink href="https://www.makeuseof.com/tag/mac-terminal-commands-cheat-sheet/" mdxType="ExternalLink">{`Mac Cheat Sheets`}</ExternalLink>{` to help easeful switch from Windows to Mac !!`}</p>
    <h1 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h1>
    <p>{`Below are few prerequisites that should be installed prior to setting up the dev environment setup on Mac with M2 silicon chip.`}</p>
    <h2 {...{
      "id": "install-rosetta-on-your-mac"
    }}>{`Install Rosetta on your Mac`}</h2>
    <ExternalLink href="https://support.apple.com/en-au/HT211861" mdxType="ExternalLink">Rosetta 2</ExternalLink> enables a Mac with Apple silicon to use apps built for a Mac with an Intel processor. Run the bellow command to install Rosetta.
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ /usr/sbin/softwareupdate --install-rosetta --agree-to-license`}</code></pre></div>
    <h1 {...{
      "id": "browsers"
    }}>{`Browsers`}</h1>
    <ul>
      <li parentName="ul">{`Microsoft Edge`}</li>
      <li parentName="ul">{`Google chrome`}</li>
    </ul>
    <h1 {...{
      "id": "collobration"
    }}>{`Collobration`}</h1>
    <ul>
      <li parentName="ul">{`Microsoft Teams`}</li>
    </ul>
    <h1 {...{
      "id": "utilities"
    }}>{`Utilities`}</h1>
    <h2 {...{
      "id": "flycut---clipboard-manager"
    }}>{`Flycut - Clipboard manager`}</h2>
    <ExternalLink href="https://github.com/TermiT/Flycut" mdxType="ExternalLink">Flycut</ExternalLink> is a clean and simple clipboard manager for developers. It's based on an open source app called Jumpcut.
    <p>{`Flycut can be installed from appstrore.`}</p>
    <h2 {...{
      "id": "boop---developer-tool"
    }}>{`Boop - Developer Tool`}</h2>
    <ExternalLink href="https://github.com/IvanMathy/Boop" mdxType="ExternalLink">Boop</ExternalLink> is scriptable scratchpad for developers.
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "625px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0e58f4c75faa23c68e64aea24fcad4d0/80d71/boop.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.3680981595092%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACA0lEQVQ4y31T25KqMBDkUUtQJAkIQQHF+61KHyzRD/DF//+QfTynMqd6lnDYXd2HroEk0+memTiPx8M8n0+63++mqioCbrcbnc9nWi6XdLlc6Hq98vrpdGrWcMaer6rK5v51lJKkdWriOCEpJSmlviAMwy//OPMdOBNGkUF0hsOhEUKQjd1ut0Gn02H0ej3yPI9c1+XYBtZ83+dcIYRxgiDgm33fp6IoKMuyBrPZjKbTKStAcr/ffwmlQoqiiNU2hIPBgOu23+9pvV4zdrsdbbdbiuOYVVqCNjm+pfwsDVTCMjNDYZ7nTABSFP94PNJms0E5eB+XWrJ2xH5t+VMh2BGhZD6fN6Swi8MgsqTvCCGqIYwT3XSsLEsmg11845I0TZtLX1n+QaiThKIwpPF4zESwulqt2DYi1pIk4Y5akrcKBZqiE/KjkLTW3GlYPhwO3BjU0NYRBLDf7vBPQiFIKkVBPbip1twcjA2sApPJhOpZ5QgiqHU9lzzXJSkE5zaEPEOBILfX48KjDNYWxsUOdht9738t7QurLQuSoxFJHVOYxDSKRm+fmIVVi4sBfFvbjhTChCAcp0YWGWV5TkVtGdbRZVhGbfGPbqNBmACs4TWVZWmwx08PzEopI+vBtPhNHeoKIlwEosViAQH8nh3f9/9AtpTSNEl14itg39ps2TU1x8c/7nCYvHdu+IEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "boop",
            "title": "boop",
            "src": "/static/0e58f4c75faa23c68e64aea24fcad4d0/80d71/boop.png",
            "srcSet": ["/static/0e58f4c75faa23c68e64aea24fcad4d0/222b7/boop.png 163w", "/static/0e58f4c75faa23c68e64aea24fcad4d0/ff46a/boop.png 325w", "/static/0e58f4c75faa23c68e64aea24fcad4d0/80d71/boop.png 625w"],
            "sizes": "(max-width: 625px) 100vw, 625px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{` `}</p>
    <p>{`It includes scripts as below which helps the devs to quickly action on tasks that we generally rely upon some online portals.`}</p>
    <p>{`Few of them as below`}</p>
    <ul>
      <li parentName="ul">{`Format JSON`}</li>
      <li parentName="ul">{`Format YAML`}</li>
      <li parentName="ul">{`Base64 Encode`}</li>
      <li parentName="ul">{`Base64 Decode`}</li>
      <li parentName="ul">{`URL Encode`}</li>
      <li parentName="ul">{`Eval Javascript`}</li>
      <li parentName="ul">{`Remove duplicates`}</li>
      <li parentName="ul">{`and many more..`}</li>
    </ul>
    <p>{`Boop can be installed from appstrore.`}</p>
    <h2 {...{
      "id": "tabby"
    }}>{`Tabby`}</h2>
    <ExternalLink href="https://tabby.sh/" mdxType="ExternalLink">Tabby terminal</ExternalLink> is an infinitely customizable cross-platform terminal app for local shells, serial, SSH and Telnet connections
    <p>{`Install latest macos package(tabby-1.0.197-macos-arm64.pkg)`}</p>
    <p>{`To display git branch names and other stylings, install `}<ExternalLink href="https://github.com/ohmyzsh/ohmyzsh" mdxType="ExternalLink">{`ohmyzsh`}</ExternalLink></p>
    <p>{`Run the below command post installing Tabby terminal, for the terminal to instantly change with default theme.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sh -c "$(curl -fsSL https://raw.githubusercontent.com/ohmyzsh/ohmyzsh/master/tools/install.sh)"`}</code></pre></div>
    <p>{`Post installing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`zsh`}</code>{`, need to do the below to load bash alises into zsh.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ vi ~/.zshrc

Copy below at the bottom of the file

source ~/.bash_profile

:wq

$ source ~/.zshrc`}</code></pre></div>
    <h2 {...{
      "id": "madshot"
    }}>{`Madshot`}</h2>
    <p>{`With `}<ExternalLink href="https://www.madshot.net/" mdxType="ExternalLink">{`Madshot`}</ExternalLink>{`, we can instantanly annotate or draw boxes and save to clipboard and share the image post taking a screenshot of selected region for adding to JIRA or sharing over teams.`}</p>
    <p>{`Replacement for the inbuilt screenshot as it doesn’t have copy to clipboard after adding annotatios or drawings.`}</p>
    <p>{`Madshot can be installed from appstrore.`}</p>
    <h1 {...{
      "id": "ides"
    }}>{`IDE's`}</h1>
    <h2 {...{
      "id": "intellij-idea"
    }}>{`IntelliJ IDEA`}</h2>
    <p>{`Download and install IntelliJ IDEA Community Edition.`}</p>
    <p>{`Install below plugins after launching.`}</p>
    <ul>
      <li parentName="ul">{`Cucumber for Java`}</li>
      <li parentName="ul">{`Markdown`}</li>
    </ul>
    <h2 {...{
      "id": "vscode"
    }}>{`VSCode`}</h2>
    <p>{`Download VSCode archive and extract the content. Move the extracted app file to applications.`}</p>
    <p>{`Install below extensions by launching `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VS Code Quick Open (⌘+P)`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`ext install zesbenp.prettier-vscode

ext install donjayamanne.githistory

ext install johnpapa.vscode-peacock

ext install vscjava.vscode-java-pack

ext install redhat.java

ext install vscjava.vscode-java-debug

ext install vscjava.vscode-java-dependency

ext install vscjava.vscode-maven

ext install vscjava.vscode-java-test

ext install eamodio.gitlens

ext install VisualStudioExptTeam.vscodeintellicode

ext install ms-vscode-remote.remote-containers

ext install ms-azuretools.vscode-docker

ext install ms-kubernetes-tools.vscode-kubernetes-tools`}</code></pre></div>
    <h2 {...{
      "id": "sublime-text"
    }}>{`Sublime Text`}</h2>
    <ul>
      <li parentName="ul">{`Use this as replaced for Notepad++ on Windows`}</li>
    </ul>
    <h1 {...{
      "id": "applications"
    }}>{`Applications`}</h1>
    <h2 {...{
      "id": "docker-desktop"
    }}>{`Docker Desktop`}</h2>
    <p>{`Post installation, navigate to settings and enable kubernetes cluster.`}</p>
    <p>{`Run the below commands to verify if installation and configuration is successful.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ docker -v

$ kubectl version`}</code></pre></div>
    <p>{`To ensure Docker container images built for Intel chip work as expected on M2, Enable the below configurations in Docker Desktop, which will enable using Rosetta on M2.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c2620cee37ca010821292fdcaaab2040/0f688/docker-desktop-rosetta-config-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.331288343558285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKElEQVQoz22S3XKCMBCFeQ6LimAkCZCE/MiPSGPbi870tu//KqdjVAYtF99skouzu+ckStQ7uP9F2vxga7+xEy1EM2JXaCRcYUMl3kiJ+FBNvN7nREnpQJ1HKjvEzGDDDajpwUwPbgcQ4SaRJaHXtygtDWQ7IldHrDKGdS6DELMnUHMCkUckvA6TPqad8yoa7cURqr+AmQ5bppBWBnb8QHv5RHv5QtX0OCgDqh1obUGkBhFX6iexR4Mo4RpUt8gqgy2VIMpBDx727GHOHrIbArmyQfhaw1nWyyvPO6z2BeJcgbszqmZErjtQffMzqxz2woWa3estuDuFDpZET11IgZhZlI1H3fvJv3Uugh0bpiYvr8xXnVb+lxjVYHYIX4fb0zTFUiBLqf8B9JjvAwIb1RIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "docker desktop rosetta config 1",
            "title": "docker desktop rosetta config 1",
            "src": "/static/c2620cee37ca010821292fdcaaab2040/a6d36/docker-desktop-rosetta-config-1.png",
            "srcSet": ["/static/c2620cee37ca010821292fdcaaab2040/222b7/docker-desktop-rosetta-config-1.png 163w", "/static/c2620cee37ca010821292fdcaaab2040/ff46a/docker-desktop-rosetta-config-1.png 325w", "/static/c2620cee37ca010821292fdcaaab2040/a6d36/docker-desktop-rosetta-config-1.png 650w", "/static/c2620cee37ca010821292fdcaaab2040/e548f/docker-desktop-rosetta-config-1.png 975w", "/static/c2620cee37ca010821292fdcaaab2040/3c492/docker-desktop-rosetta-config-1.png 1300w", "/static/c2620cee37ca010821292fdcaaab2040/0f688/docker-desktop-rosetta-config-1.png 2786w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/37fcc5ba0e53f4f518441e2346df08e7/1105b/docker-desktop-rosetta-config-2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.67484662576687%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABQElEQVQY023RzW7UMBSG4VxDEdBOJhOmHSe24/w4duK0nqFoBgQLUBe0/FSI+7+LFyUFISEWz+L7FkdH5ySpMFxWPblsWe0MuWyoXMD4QDNM5KUm20kyMVO/yf9aC02yLmqa6ZbSTqSiYi170vGelbsjH+942bznTJ2eyCNn6sgzdfrH3L/lhdzPAw31GFE2kF5JMuURtz/Jbx7Jr7+xjY/k8QfZ9J0sfGU9fmHlHzh3n7n4w99z7h54ro8kmXbUrz9ShHds+zdkylJZTzdF2hBphwntAtIGysYuhGnZFIqNkIu8UIv5HEmmHObwCXX9gUt/YlM5lJvo4pE6njDhgAl7yn5CtANFN1J0A6L1S961nm3V8Up3pEVNstxNaNKd5mJbclVbbDxgb/b0cXZYspk39SOVD0+Gv+Zeu4H5H78AnTu4K7pxJKoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "docker desktop rosetta config 2",
            "title": "docker desktop rosetta config 2",
            "src": "/static/37fcc5ba0e53f4f518441e2346df08e7/a6d36/docker-desktop-rosetta-config-2.png",
            "srcSet": ["/static/37fcc5ba0e53f4f518441e2346df08e7/222b7/docker-desktop-rosetta-config-2.png 163w", "/static/37fcc5ba0e53f4f518441e2346df08e7/ff46a/docker-desktop-rosetta-config-2.png 325w", "/static/37fcc5ba0e53f4f518441e2346df08e7/a6d36/docker-desktop-rosetta-config-2.png 650w", "/static/37fcc5ba0e53f4f518441e2346df08e7/e548f/docker-desktop-rosetta-config-2.png 975w", "/static/37fcc5ba0e53f4f518441e2346df08e7/3c492/docker-desktop-rosetta-config-2.png 1300w", "/static/37fcc5ba0e53f4f518441e2346df08e7/1105b/docker-desktop-rosetta-config-2.png 2940w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "postman"
    }}>{`Postman`}</h2>
    <p>{`Download Postman archive and extract the content. Move the extracted app file to applications.`}</p>
    <h1 {...{
      "id": "binaries"
    }}>{`Binaries`}</h1>
    <h2 {...{
      "id": "xcode-command-line-tools"
    }}>{`Xcode Command Line Tools`}</h2>
    <p>{`Ref: `}<ExternalLink href="https://www.freecodecamp.org/news/install-xcode-command-line-tools/" mdxType="ExternalLink">{`Install Xcode Command Line Tools`}</ExternalLink></p>
    <p>{`Run below command to install xcode command line tool`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ xcode-select --install`}</code></pre></div>
    <h2 {...{
      "id": "homebrew"
    }}>{`Homebrew`}</h2>
    <p>{`Execute the below in terminal`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ /bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/HEAD/install.sh)"`}</code></pre></div>
    <p>{`Run the below to add homebrew to path`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ (echo; echo 'eval "$(/opt/homebrew/bin/brew shellenv)"') >> /Users/madan/.zprofile
    eval "$(/opt/homebrew/bin/brew shellenv)"`}</code></pre></div>
    <p>{`Run the below to verify if brew is installed successfully and available in the path for subsequent commands`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew search java`}</code></pre></div>
    <h2 {...{
      "id": "curl"
    }}>{`Curl`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install curl

$ curl --help`}</code></pre></div>
    <h2 {...{
      "id": "sdkman"
    }}>{`SDKMAN`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ curl -s "https://get.sdkman.io" | bash

$ source "$HOME/.sdkman/bin/sdkman-init.sh"

$ sdk version

$ sdk list`}</code></pre></div>
    <h2 {...{
      "id": "java"
    }}>{`Java`}</h2>
    <h3 {...{
      "id": "elcipse-temurin-distro"
    }}>{`Elcipse Temurin Distro`}</h3>
    <p>{`Install latest version of OpenJDK distribution from `}<ExternalLink href="https://adoptium.net/temurin/releases/" mdxType="ExternalLink">{`Eclipse Temurin`}</ExternalLink>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/adoptium/temurin17-binaries/releases/download/jdk-17.0.7%2B7/OpenJDK17U-jdk_aarch64_mac_hotspot_17.0.7_7.pkg"
      }}>{`https://github.com/adoptium/temurin17-binaries/releases/download/jdk-17.0.7%2B7/OpenJDK17U-jdk_aarch64_mac_hotspot_17.0.7_7.pkg`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ java --version`}</code></pre></div>
    <p>{`To uninstall, run the below command`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`$ rm -rf /Library/Java/JavaVirtualMachines/temurin-17.jdk`}</code></p>
    <h3 {...{
      "id": "using-sdkman"
    }}>{`Using SDKMAN`}</h3>
    <p>{`Identify and choose the version of Java that you wish to install `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk list java

$ sdk install java 8.0.372-zulu

$ java -version`}</code></pre></div>
    <p>{`Install another version of Java. Post installation, prompt would appear to choose this version as default. Opt in accordingly.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk install java 11.0.19-zulu`}</code></pre></div>
    <p>{`To switch between versions of Java, use the below commands`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# See which versions are installed
$ sdk list java 

$ sdk use java 11.0.19-zulu`}</code></pre></div>
    <p>{`To set a permanent default, use the sdk default command. For instance, to make JDK 11 the default, type:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk default java 11.0.0-open`}</code></pre></div>
    <h2 {...{
      "id": "maven"
    }}>{`Maven`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install maven

$ mvn -v`}</code></pre></div>
    <h2 {...{
      "id": "gradle"
    }}>{`Gradle`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install gradle

$ mvn -v`}</code></pre></div>
    <h2 {...{
      "id": "git"
    }}>{`Git`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install git

$ git -v`}</code></pre></div>
    <h2 {...{
      "id": "mitmweb"
    }}>{`mitmweb`}</h2>
    <ExternalLink href="https://mitmproxy.org/" mdxType="ExternalLink">mitmproxy</ExternalLink> is a free and open source interactive HTTPS proxy. 
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install mitmproxy`}</code></pre></div>
    <h2 {...{
      "id": "node"
    }}>{`Node`}</h2>
    <h3 {...{
      "id": "install-latest-version-of-node"
    }}>{`Install latest version of Node`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install node

$ node -v

$ npm -v

$ npm install -g yarn

$ yarn -v`}</code></pre></div>
    <h3 {...{
      "id": "install-older-version-of-node"
    }}>{`Install older version of Node`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install node@16`}</code></pre></div>
    <h3 {...{
      "id": "switch-between-node-versions"
    }}>{`Switch between node versions`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew unlink node

$ brew link node@16

$ echo 'export PATH="/usr/local/opt/node@16/bin:$PATH"' >> ~/.bash_profile
or
$ echo 'export PATH="/opt/homebrew/opt/node@16/bin:$PATH"' >> ~/.zshrc

$ source ~/.bash_profile
or 
$ source ~/.zshrc

$ node -v`}</code></pre></div>
    <h2 {...{
      "id": "mockoon"
    }}>{`Mockoon`}</h2>
    <ExternalLink href="https://mockoon.com/" mdxType="ExternalLink">Mockoon</ExternalLink> is the easiest and quickest way to design and run mock REST APIs
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`brew install --cask mockoon`}</code></pre></div>
    <h2 {...{
      "id": "meld"
    }}>{`Meld`}</h2>
    <p>{`Meld is Visual diff and merge tool, which helps you compare files, directories, and version controlled projects. It provides two- and three-way comparison of both files and directories, and has support for many popular version control systems`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`brew install --cask meld`}</code></pre></div>
    <p>{`meld is not verified for malwere and will restrict opening the app. Select option to open meld anyway from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`System Settings -> privacy & Secuirty`}</code></p>
    <h1 {...{
      "id": "macos-customizations"
    }}>{`MacOS Customizations`}</h1>
    <h2 {...{
      "id": "choose-open-with-vscode-for-selected-folder-from-finder"
    }}>{`Choose Open with VSCode for selected folder from finder`}</h2>
    <p><a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/64040393/open-a-folder-in-vscode-through-finder-in-macos"
      }}>{`https://stackoverflow.com/questions/64040393/open-a-folder-in-vscode-through-finder-in-macos`}</a></p>
    <h2 {...{
      "id": "change-default-command-actions-by-setting-aliases"
    }}>{`Change default command actions by setting aliases`}</h2>
    <p>{`Modify default behaviour of the command by passing additional options and setting the alias.`}</p>
    <p>{`Run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vi ~/.bash_profile`}</code>{` and add the below as needed`}</p>
    <h3 {...{
      "id": "ls"
    }}>{`ls`}</h3>
    <p>{`Change behaviour of ls by passing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-a`}</code>{` to show list of all files by default - `}<a parentName="p" {...{
        "href": "https://wpbeaches.com/make-an-alias-in-bash-or-zsh-shell-in-macos-with-terminal/"
      }}>{`https://wpbeaches.com/make-an-alias-in-bash-or-zsh-shell-in-macos-with-terminal/`}</a></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`alias ls='ls -lah'`}</code></pre></div>
    <p>{`To apply the changes in the current terminal, run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`source ~/.bash_profile`}</code></p>
    <h3 {...{
      "id": "watch"
    }}>{`watch`}</h3>
    <p>{`Watch will run a command repeatedly which can turn any command line program output into a “real time” display.`}</p>
    <p>{`Run below to install watch command`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew install watch`}</code></pre></div>
    <h1 {...{
      "id": "additional-tools-and-configurations"
    }}>{`Additional tools and configurations`}</h1>
    <h2 {...{
      "id": "mongodb"
    }}>{`Mongodb`}</h2>
    <p>{`Run the below command to install and access mongodb community edition`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew tap mongodb/brew

$ brew update

$ brew install mongodb-community@6.0`}</code></pre></div>
    <p>{`Below binaries are included as part of the installation:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongod`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongos`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongosh`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongotop`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongoimport`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`mongodump`}</code></li>
    </ul>
    <p>{`Installation create following files and directories:`}</p>
    <ul>
      <li parentName="ul">{`configuration file - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/opt/homebrew/etc/mongod.conf`}</code></li>
      <li parentName="ul">{`log directory - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/opt/homebrew/var/log/mongodb`}</code></li>
      <li parentName="ul">{`data directory - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/opt/homebrew/var/mongodb`}</code></li>
    </ul>
    <p>{`Run below commands to start and stop the servce:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew services start mongodb-community@6.0

$ brew services list

$ brew services stop mongodb-community@6.0`}</code></pre></div>
    <p>{`For `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongod`}</code>{` process to run as a background service, run the below command.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ mongod --config /opt/homebrew/etc/mongod.conf --fork

# To see if MongoDB is running as background process
$ ps aux | grep -v grep | grep mongod`}</code></pre></div>
    <p>{`To stop a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongod`}</code>{` running as a background process, connect to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongod`}</code>{` using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongosh`}</code>{`, and issue the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shutdown`}</code>{` command as needed.`}</p>
    <p>{`To connect to MongoDB, run command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongosh`}</code>{`.`}</p>
    <p>{`To set credentials, run the below post connecting to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongosh`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`> use admin

> db.createUser(
  {
    user: "root",
    pwd: "Test@123",
    roles: [ { role: "userAdminAnyDatabase", db: "admin" } ]
  }
)`}</code></pre></div>
    <p>{`Download and install `}<ExternalLink href="https://www.mongodb.com/try/download/compass" mdxType="ExternalLink">{`MongoDB Compass`}</ExternalLink>{` to access the database using GUI. Choose Platform as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`macOS arm64 (M1)`}</code>{`.`}</p>
    <p>{`Start accessing mongodb from compass with connection string - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mongodb://localhost:27017`}</code>{` and if needed use autetication with user credentials configured above.`}</p>
    <h2 {...{
      "id": "kubeshark"
    }}>{`Kubeshark`}</h2>
    <ExternalLink href="https://kubeshark.co/" mdxType="ExternalLink">Kubeshark</ExternalLink> is the API Traffic Analyzer for Kubernetes.
    <p>{`Ensure Kubernetes is installed and configured to try out kubeshark.`}</p>
    <p>{`Run the below commands to install kubeshark`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ brew tap kubeshark/kubeshark

$ brew install kubeshark

$ kubeshark version`}</code></pre></div>
    <p>{`Run below command to start kubeshark and to showup the access url for kubeshark`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ kubeshark tap --proxy-host 0.0.0.0`}</code></pre></div>
    <p>{`Run below command to clean all relics of Kubeshark from your cluster when using the CLI`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ kubeshark clean`}</code></pre></div>
    <ExternalLinksListContainer title={'References'} links={[{
      'url': 'https://www.makeuseof.com/tag/mac-terminal-commands-cheat-sheet/'
    }, {
      'url': 'https://medium.com/@hendurhance/how-to-set-up-m2-macbook-for-software-development-4a5e20a7866a'
    }]} mdxType="ExternalLinksListContainer">
    </ExternalLinksListContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      