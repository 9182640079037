import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": false,
  "showAuthorInfo": false,
  "author": "meenakshivemuri",
  "title": "Guide to Selenium with JUnit / TestNG",
  "description": "Guide to Selenium with JUnit / TestNG",
  "date": "2019-12-25T23:46:37.121Z",
  "updated": "2019-12-25T23:46:37.121Z",
  "cover": "../../../images/blog-banners/testing-banner-1200x690.png",
  "category": "testing",
  "tags": ["java", "streams", "featured"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`This article is a quick, practical introduction to working with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selenium`}</code>{` and writing tests with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JUnit`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`TestNG`}</code>{`.`}</p>
    <h2 {...{
      "id": "selenium-integration"
    }}>{`Selenium Integration`}</h2>
    <p>{`In this section, we’ll start with a simple scenario – opening a browser window, navigating to a given URL and looking for some desired content on the page.`}</p>
    <h3 {...{
      "id": "maven-dependencies"
    }}>{`Maven Dependencies`}</h3>
    <p>{`In the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pom.xml`}</code>{` file, add the following dependency:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`org.seleniumhq.selenium`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`groupId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`artifactId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`selenium-java`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`artifactId`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`3.4.0`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`version`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dependency`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`The latest version can be found in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Maven Central Repository`}</code>{`.`}</p>
    <h3 {...{
      "id": "selenium-configuration"
    }}>{`Selenium Configuration`}</h3>
    <p>{`First, create a new Java class file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SeleniumConfig`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumConfig`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`WebDriver`}</span>{` driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//...`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Given we’re using a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selenium 3.x`}</code>{` version, we have to specify the path of an executable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`GeckoDriver`}</code>{` file (based on your OS) using a system property called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`webdriver.gecko.driver`}</code>{` The latest version of the GeckoDriver may be downloaded from Github `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Geckodriver`}</code>{` Releases.`}</p>
    <p>{`Let’s now initialize the WebDriver in the constructor, we’ll also set 5 seconds as a time-out for WebDriver to wait for an element on the page to appear:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Capabilities`}</span>{` capabilities `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DesiredCapabilities`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`firefox`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    driver `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`FirefoxDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`capabilities`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    driver`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`manage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`timeouts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`implicitlyWait`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TimeUnit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`SECONDS`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setProperty`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"webdriver.gecko.driver"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`findFile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"geckodriver.mac"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`findFile`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` filename`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` paths`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"bin/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"target/classes"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
   `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` paths`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`File`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` filename`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` path `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` filename`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
   `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`""`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This configuration class contains a couple of methods that we will ignore for now, but we will see more about these on the second part of this series.`}</p>
    <p>{`Next, we will need to implement a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SeleniumExample`}</code>{` class:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumConfig`}</span>{` config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://www.baeldung.com/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        config `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumConfig`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ...`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In here, we will initialize the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SeleniumConfig`}</code>{` and set the desired URL to navigate to. Similarly, we’ll implement a simple API to close the browser and get the title of the page:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`closeWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getTitle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTitle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In order to navigate to the About section of baeldung.com, we need to create a closeOverlay() method that checks and closes the overlay on a homepage load. Thereafter, we navigate to the About Baeldung page using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getAboutBaeldungPage()`}</code>{` method:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getAboutBaeldungPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`closeOverlay`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clickAboutLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clickAboutUsLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`closeOverlay`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`WebElement`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` webElementList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findElements`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`By`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`tagName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`webElementList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
       webElementList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`webElement `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Close"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`equalsIgnoreCase`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`webElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAttribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"title"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`WebElement`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isDisplayed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findAny`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ifPresent`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`WebElement`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`click`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clickAboutLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`By`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`partialLinkText`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"About"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`click`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`clickAboutUsLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Actions`}</span>{` builder `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Actions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`WebElement`}</span>{` element `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`By`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`partialLinkText`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"About Baeldung."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    builder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`moveToElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`element`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`perform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We can check if the required information is available on the displayed page:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`boolean`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`isAuthorInformationAvailable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDriver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findElement`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`By`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`cssSelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"article > .row > div"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isDisplayed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Next, we are going to test this class with both JUnit and TestNG.`}</p>
    <h2 {...{
      "id": "with-junit"
    }}>{`With JUnit`}</h2>
    <p>{`Let’s create a new test class as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SeleniumWithJUnitLiveTest`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumWithJUnitLiveTest`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span>{` seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` expectedTitle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Baeldung | Java, Spring and Web Development tutorials"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// more code goes here...`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We’re going to use the @BeforeClass annotation from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`org.junit.BeforeClass`}</code>{` to do an initial setup. In this setUp() method we are going to initialize the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SeleniumExample`}</code>{` object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@BeforeClass`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setUp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In a similar way, when our test case finishes, we should close the newly opened browser. We’re going to do this with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@AfterClass`}</code>{` annotation – to clean up the settings when test case execution has finished:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@AfterClass`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tearDown`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`closeWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Please note the static modifier on our SeleniumExample member variable – because we need to use this variable in the setUp() and tearDown() static methods – `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@BeforeClass`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@AfterClass`}</code>{` can be invoked on static methods only.`}</p>
    <p>{`Finally, we can create our full test:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`whenAboutBaeldungIsLoaded_thenAboutEugenIsMentionedOnPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAboutBaeldungPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` actualTitle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTitle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertNotNull`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`actualTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expectedTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` actualTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertTrue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isAuthorInformationAvailable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This test method asserts that the title of the web page is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`not null`}</code>{` and is set as expected. Besides that, we check that the page contains the expected information.`}</p>
    <p>{`When the test runs, it simply opens the URL in Firefox and subsequently closes it after the title of the web page and content have been verified.`}</p>
    <h2 {...{
      "id": "with-testng"
    }}>{`With TestNG`}</h2>
    <p>{`Let’s now use TestNG to run our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`test case/suite`}</code>{`.`}</p>
    <p>{`Note that if you’re using Eclipse, the TestNG plugin may be downloaded and installed from the Eclipse Marketplace.`}</p>
    <p>{`First, let’s create a new test class:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumWithTestNGLiveTest`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span>{` seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` expectedTitle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Baeldung | Java, Spring and Web Development tutorials"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
 
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// more code goes here...`}</span>{`
 
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We’ll use a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@BeforeSuite`}</code>{` annotation from org.testng.annotations.`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`BeforeSuite`}</code>{` to instantiate our SeleniumExample class. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setUp()`}</code>{` method will be launched just before the test suite is activated:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@BeforeSuite`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setUp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SeleniumExample`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Similarly, we’ll use the @AfterSuite annotation from org.testng.annotations.AfterSuite to close our opened browser once the test suite has completed:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@AfterSuite`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`tearDown`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`closeWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Finally, let’s implement our test:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Test`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`whenAboutBaeldungIsLoaded_thenAboutEugenIsMentionedOnPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAboutBaeldungPage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` actualTitle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getTitle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertNotNull`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`actualTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertEquals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`expectedTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` actualTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`assertTrue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`seleniumExample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`isAuthorInformationAvailable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`After a successful completion of the test suite, we find HTML and XML reports in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`test-output`}</code>{` folder of the project. These reports summarize the test results.`}</p>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`In this quick article, we’ve focused on a quick intro to writing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Selenium 3`}</code>{` tests with both `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`JUnit`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`TestNG`}</code>{`.`}</p>
    <p>{`As always, the source for the article is available over at GitHub.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      