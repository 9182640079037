import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": true,
  "author": "narramadan",
  "title": "How to handle different return types from Future and ExecuteService invokeAll() ?",
  "description": "A way to handle different return types from Future and ExecuteService invokeAll()",
  "date": "2020-07-11T10:00:00.000Z",
  "updated": "2020-07-11T10:00:00.000Z",
  "cover": "../../../images/blog-banners/titbits-banner-1200x690.png",
  "category": "titbits",
  "tags": ["titbit", "java", "executorservice"],
  "keywords": ["executorservice", "java", "different return types", "executorservice future", "executorservice future different return type", "java executorservice", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article_july11_handle-different-return-types-from-future-and-executeservice" mdxType="GithubRepo">handle-different-return-types-from-future-and-executeservice</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Recently I had to stumble upon a requirement to optimize my code to perform parallel calls to multiple downstream systems returning different response objects and stitch to a specific value object. `}</p>
    <p>{`There is no common interface implemented by these different response objects and cannot define one as they are auto generated by swagger codegen.`}</p>
    <p>{`Had to explore options if there is any generic way of doing this, but couldn’t pursue due to time crunch and had to implement in a way which meets my requirement and also optimize my service to perform parallel execution.`}</p>
    <p>{`Below we shall start going through brief intro about Future, ExecutorService & Executors classes and later provide insights on the solution I had to implement.`}</p>
    <h2 {...{
      "id": "brief-intro-on-future--executorservice"
    }}>{`Brief intro on Future & ExecutorService`}</h2>
    <p>{`Combining `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`java.util.concurrent.Future<V>`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`java.util.concurrent.ExecutorService`}</code>{` is an awesome mechanism when trying to optimize the code to complete the compute in short period.`}</p>
    <h3 {...{
      "id": "javautilconcurrentfuture"
    }}>{`java.util.concurrent.Future`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Future<V>`}</code>{` is an interface that represents the result of an `}<strong parentName="p">{`asynchronous`}</strong>{` computation. Once the computation is finished, we can obtain the result of it by using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`get()`}</code>{` method.`}</p>
    <p>{`Calling `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`get()`}</code>{` method is a `}<strong parentName="p">{`blocking operation`}</strong>{` and waits until the outcome (V) is available. It could take a considerable amount of time.`}</p>
    <p>{`Instead of wasting time, we can apply two approaches. The first one is using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`get()`}</code>{` as well, but setting a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`timeout`}</code>{` value as a parameter that will prevent you from getting stuck if something goes away. The second way is by using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`isDone()`}</code>{` method, which takes a quick look at the Future and checks if it has finished its work or not.`}</p>
    <h3 {...{
      "id": "javautilconcurrentexecutorservice"
    }}>{`java.util.concurrent.ExecutorService`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`ExecutorService`}</code>{` represents an abstraction of `}<strong parentName="p">{`thread pools`}</strong>{` and can be created by the utility methods of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Executors`}</code>{` class. These methods can initialize a number of executors depending on the purpose they will be used for. `}</p>
    <p>{`There are several ways to delegate a task to ExecutorService:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`execute(Runnable)`}</code>{` – returns void and cannot access the result.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`submit(Runnable or Callable<T>)`}</code>{` – returns a Future object. The main difference is that when submitting `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Callable<T>`}</code>{`, the result can be accessed via the returned Future object.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`invokeAny(Collection<Callable<T>>)`}</code>{` – returns the result of one of the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Callable<T>`}</code>{` objects that finished its work successfully. The rest of the tasks are canceled.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><code parentName="p" {...{
            "className": "language-text"
          }}>{`invokeAll(Collection<Callable<T>>))`}</code>{` – returns a list of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Future<V>`}</code>{` objects. All tasks are executed and the outcome can be obtained via the returned result list.`}</p>
      </li>
    </ul>
    <p>{`When all the tasks have finished their work, the threads in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ExecutorService`}</code>{` are `}<strong parentName="p">{`still running`}</strong>{`. They are `}<strong parentName="p">{`not destroyed`}</strong>{` yet and are in a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`standby`}</code>{` mode. This will keep JVM running. `}</p>
    <p>{`For the purpose of bypassing this problem, Java offers you two methods – `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shutdown()`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shutdownNow()`}</code>{`. The key difference between them is the stopping of ExecutorService.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`shutdown()`}</code>{` `}<strong parentName="p">{`will not`}</strong>{` stop it immediately and will `}<strong parentName="p">{`wait for`}</strong>{` all running threads to finish. Meanwhile, ExecutorService will not accept new tasks.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`shutdownNow()`}</code>{` `}<strong parentName="p">{`will try`}</strong>{` to stop it immediately. It will try to `}<strong parentName="p">{`instantly stop`}</strong>{` all running tasks and to skip the processing of the waiting ones. The method returns a list of all running tasks for which there are no guarantees when they will be stopped.`}</p>
    <h3 {...{
      "id": "javautilconcurrentexecutors"
    }}>{`java.util.concurrent.Executors`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Executors`}</code>{` provides factory and utility methods for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Executor`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ExecutorService`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ScheduledExecutorService`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ThreadFactory`}</code>{`, and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Callable`}</code>{` classes.`}</p>
    <p>{`This class supports the following kinds of methods:`}</p>
    <ul>
      <li parentName="ul">{`Methods that create and return an `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ExecutorService`}</code>{` set up with commonly useful configuration settings.`}</li>
      <li parentName="ul">{`Methods that create and return a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ScheduledExecutorService`}</code>{` set up with commonly useful configuration settings.`}</li>
      <li parentName="ul">{`Methods that create and return a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`wrapped ExecutorService`}</code>{`, that disables reconfiguration by making implementation-specific methods inaccessible.`}</li>
      <li parentName="ul">{`Methods that create and return a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ThreadFactory`}</code>{` that sets newly created threads to a known state.`}</li>
      <li parentName="ul">{`Methods that create and return a `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Callable`}</code>{` out of other closure-like forms, so they can be used in execution methods requiring Callable.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Factory and Utility methods returning instance of ExecutorService`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates a thread pool that creates new threads as needed, but will reuse previously constructed threads when they are available.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newCachedThreadPool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates a thread pool that creates new threads as needed, but will reuse previously constructed threads when they are available, and uses the provided ThreadFactory to create new threads when needed.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newCachedThreadPool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ThreadFactory`}</span>{` threadFactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates a thread pool that reuses a fixed number of threads operating off a shared unbounded queue.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newFixedThreadPool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` nThreads`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates a thread pool that reuses a fixed number of threads operating off a shared unbounded queue, using the provided ThreadFactory to create new threads when needed.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newFixedThreadPool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` nThreads`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ThreadFactory`}</span>{` threadFactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates an Executor that uses a single worker thread operating off an unbounded queue.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newSingleThreadExecutor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Creates an Executor that uses a single worker thread operating off an unbounded queue, and uses the provided ThreadFactory to create a new thread when needed.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`newSingleThreadExecutor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ThreadFactory`}</span>{` threadFactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Returns an object that delegates all defined ExecutorService methods to the given executor, but not any other methods that might otherwise be accessible using casts.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{`	`}<span parentName="code" {...{
            "className": "token function"
          }}>{`unconfigurableExecutorService`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ExecutorService`}</span>{` executor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2 {...{
      "id": "solution"
    }}>{`Solution`}</h2>
    <p>{`Based on the requirement, we can get instance of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ExecutorService`}</code>{` from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Executors`}</code>{` factory methods. We can stick to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`newFixedThreadPool`}</code>{` which thread pool that reuses a fixed number of threads operating off a shared unbounded queue.`}</p>
    <p>{`Consider this use case. There are three services `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ServiceA`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ServiceB`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ServiceC`}</code>{` which returns objects which are not compatible with each other. These services should be accessed parallelly and then the response from these three services should be collated to a value object which will be returned by the processing method.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "329px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7265fb0bb1c8b221148b63169b8899cc/004a5/classdiagram.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "117.17791411042946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAAsTAAALEwEAmpwYAAADgElEQVQ4y32TWXPiRhCA+f8veUoltclTfG1hDIIFAeYyMpjbIBAICd0Xp5A5dCLNpOQljr2Jt2uqpmemv+me7p4IfCfBCVgqtZfb43pO55qG2N5JHUNs60LLVLsbhgjgB4m8X5ws/7TB9y+Vv65+kQVUVTIcgwhcimOS9qHKP5dcL/gUDlxgsK0Fm9aGKZ3NqeQ3jUwvaFTnC/4a41pF1wefwsAJjOUzJX0b4rdTCiHJBE2nJpO4IGbnWo7qFE4/gQM72K376rZAUciIuOWFNC9kKBoRpCwzi/P4g3f6PGzgw0UfY7ArunIzKV6SpUuyeDEtXVHla/bhelLKeMEnngEID/a6rjCMxvMLSRK7A3k0mUviXBAUll2qKvw02wBA8M/Fr7On7/y9+bY8ewDg/8P+QXzT8U3nJwZnGJx8S+OdVTjctXCQ6KMys7WZM2e8reiu3474g8L53ukNDsPwLdvUsCBoeU4Dwm48+StWu5wr6IyOT8nYcp4HfvvkNiHsKmPUWG6+v+Bf+ECW3AVma1Vv8aji6eUka8rlF76w44tHqezOsaNUsRdVro4Ya/0DDB2X6SW5UUIcIHz/Th4iCpGSRym+H6dbUXGQYHsxU30AxpPYShmrj3DgeXrzTqTjEyI6eL7C8RsC/zroX5PjKD29I4joaHDDUAlBSI1qX3fvPH9vrkDqt8eNEtmuErUinkpMWpV+Pj15LJJP5RGaHDcqxFN59FR8rhSso/neczgCCH0Qao5xNNUwJaaysfV9AOFRWrkH69wK57qHTRH5T23t0+5w1i3Ht8519oxd4Ho/1hm4DnQtYJnQsYPdzl1vX/+mA13b3xr+1oCODTwPBMBZ6sAygW1BO7QPHDfCNquZ5B/z2b3GF6QRwg+aEEKhU0OTf8rTrMrdc+Mk08EghNqwm4r9LlN5jSso0zTTqUbYRjGf+bLgsjKbEYnYpFEO4XY1n/6iMqjEpLlxjHjMvcItNPmbMkNlNqNQCPGIRvZ8b6Pm9toDtHvAqLO9GggzhC/59Iq/DzePTbbzEATwKBPbef64xKDdg8cm1ShENtO2yKIrFl0JudUUYbthhBuqJ9KZFZdZC7nFLMU0SwDAF3YoMtk1l12LeYPPTOu5yJLCe40EXr/rYtF24YLs1CCEqxnRe7wbNuKdarRRuCDqFQjhlqeeG8ggtLztVa5xLP83Kt/XvND+5c4AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Class Diagram",
              "title": "Class Diagram",
              "src": "/static/7265fb0bb1c8b221148b63169b8899cc/004a5/classdiagram.png",
              "srcSet": ["/static/7265fb0bb1c8b221148b63169b8899cc/222b7/classdiagram.png 163w", "/static/7265fb0bb1c8b221148b63169b8899cc/ff46a/classdiagram.png 325w", "/static/7265fb0bb1c8b221148b63169b8899cc/004a5/classdiagram.png 329w"],
              "sizes": "(max-width: 329px) 100vw, 329px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Class Diagram`}</figcaption>{`
  `}</figure></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Service Classes`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// ServiceA.java`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`com`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`toomuch2learn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`example`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`a`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceA`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`A`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`processA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClassNotFoundException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceA :: processA :: Begin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Thread`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Do Nothing`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceA :: processA :: End"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ServiceB.java`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`com`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`toomuch2learn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`example`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`b`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`java`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`security`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span></span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`InvalidKeyException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceB`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`processB`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InvalidKeyException`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceB :: processB :: Begin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Thread`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Do Nothing`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceB :: processB :: End"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ServiceC.java`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` `}<span parentName="code" {...{
            "className": "token namespace"
          }}>{`com`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`toomuch2learn`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`example`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`c`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceC`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`C`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`processC`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IllegalStateException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceC :: processC :: Begin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Thread`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sleep`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5000`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Do Nothing`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"==> ServiceC :: processC :: End"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`C`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Processor.java`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Processor`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Processor`}</span>{` processor `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Processor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProcessorVO`}</span>{` vo `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` processor`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`processRequest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`vo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`printStackTrace`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProcessorVO`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`processRequest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throws`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProcessorVO`}</span>{` vo `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// List of callable objects to handle different service calls`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`List`}</span><span parentName="span" {...{
              "className": "token generics"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Callable`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Object`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` servicesToCall `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`servicesToCall`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Prepare Executor service with fixed thread pool. Lets set to 2, as we have three services to call`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`ExecutorService`}</span>{` executorService `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Executors`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`newFixedThreadPool`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Invoke all callable objects to process them in independent threads asynchronously`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`List`}</span><span parentName="span" {...{
              "className": "token generics"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Future`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Object`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` futures `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` executorService`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`invokeAll`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`servicesToCall`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Shutdown the executor service`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            executorService`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`shutdown`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Loop through the future objects and have the details added to VO based upon the type of instance in future object`}</span>{`
            vo `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProcessorVO`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`for`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Future`}</span><span parentName="span" {...{
              "className": "token generics"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Object`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` future `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`:`}</span>{` futures`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`instanceof`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                    vo`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setValueFromA`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`valueFromA`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`else`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`instanceof`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                    vo`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setValueFromB`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`valueFromB`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`else`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`instanceof`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`C`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                    vo`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`setValueFromC`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`C`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`future`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`get`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`valueFromC`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`                `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`            `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`LambdaWrappedException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`throw`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Exception`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` vo`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/**`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`     * List of callable objects to handle different service calls`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`     *`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`     * @return List<Callable<Object>>`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`     */`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`    `}<span parentName="span" {...{
              "className": "token keyword"
            }}>{`private`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`List`}</span><span parentName="span" {...{
              "className": "token generics"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Callable`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`Object`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="span" {...{
              "className": "token function"
            }}>{`servicesToCall`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Callable`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Object`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` servicesToCall `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ArrayList`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Request to handle ServiceA`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        servicesToCall`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`->`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`A`}</span>{` a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`processA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClassNotFoundException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LambdaWrappedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`throwException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Request to handle ServiceB`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        servicesToCall`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`->`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`B`}</span>{` b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceB`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`processB`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`InvalidKeyException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LambdaWrappedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`throwException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Request to handle ServiceC`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`        servicesToCall`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`add`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`->`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span></span>{`            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`C`}</span>{` c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`try`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceC`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`processC`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`catch`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`IllegalStateException`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LambdaWrappedException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`throwException`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` servicesToCall`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To Summarize`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`servicesToCall()`}</code>{` method prepares `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`List<Callable<Object>>`}</code>{` objects by creating instance of callable using lambda expression for each service call.`}</li>
      <li parentName="ul">{`Instance of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ExecutorService`}</code>{` is created by calling `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`newFixedThreadPool(2)`}</code>{` factory method from `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Executors`}</code>{` class. We configured only `}<strong parentName="li">{`2`}</strong>{` threads in this example as we have `}<strong parentName="li">{`3`}</strong>{` services to call.`}</li>
      <li parentName="ul">{`Prepared list of callable objects is passed to ExecutorService `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`invokeAll`}</code>{` method. This will kickstart the invocation by creating two threads to process the callable objects.`}</li>
      <li parentName="ul">{`Shutdown the ExecutorService.`}</li>
      <li parentName="ul">{`Loop through the future objects. Identify the instance of object retrieved from future object and process accordingly.`}</li>
      <li parentName="ul">{`This way we are able to cater different object return types from future object.`}</li>
    </ul>
    <p>{`Running the main method should print something like below:`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Output`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`==`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{` ServiceA :: processA :: Begin`}</span><span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`==`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{` ServiceB :: processB :: Begin`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` ServiceB :: processB :: End
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`==`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span>{` ServiceC :: processC :: Begin`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` ServiceA :: processA :: End
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` ServiceC :: processC :: End
ProcessorVO`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`valueFromA`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'VALUE_FROM_A'`}</span>{`, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`valueFromB`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span>{`, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`valueFromC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2020`}</span>{`-07-11`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`The solution presented here might not be a proper approach. But this worked for me in one of my project where in there is no common interface available and have to map responses from different downstream APIs.`}</p>
    <GithubRepo href="https://github.com/2much2learn/article_july11_handle-different-return-types-from-future-and-executeservice" mdxType="GithubRepo">handle-different-return-types-from-future-and-executeservice</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      