import React from "react"
import Layout from "~/components/layout"
import SEO from "~/components/seo"
import About from "~/containers/About"

type AboutPageProps = {}

const AboutPage: React.FunctionComponent<AboutPageProps> = props => {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="2much2learn.com is a platform to share knowledge and experience from like minded authors to the developers community."
      />

      <About />
    </Layout>
  )
}

export default AboutPage
