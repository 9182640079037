import React from "react"
import { graphql } from "gatsby"
import Navbar from "~/components/Navbar/Navbar"
import ResetCss from "~/components/resetCSS"
import SEO from "~/components/seo"
import NotFound from "~/containers/NotFound"
import Footer from "~/components/Footer/Footer"

const NotFoundPage = (props: any) => {
  return (
    <>
      <ResetCss />
      <Navbar />
      <SEO title="404: Not Found" />
      <NotFound />
      <Footer>
        <p>
          © {new Date().getFullYear()} 
          <a href="https://2much2learn.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> · 
          <a href="https://2much2learn.com/contact" target="_blank" rel="noopener noreferrer">Contact</a> · 
          <a href="https://2much2learn.com/about" target="_blank" rel="noopener noreferrer">About</a> · 
          <a href="https://2much2learn.com/"> 2much2learn.com</a>
        </p>         
      </Footer>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
