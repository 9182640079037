import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Configure Java development environment on Ubuntu 19.10",
  "description": "A detailed step by step guide on configuring Java development environment on Ubuntu 19.10",
  "date": "2020-04-05T15:46:37.121Z",
  "updated": "2020-04-14T15:46:37.121Z",
  "cover": "../../../images/blog-banners/setup-banner-1200x690.png",
  "category": "setup",
  "tags": ["Java", "Setup", "Ubuntu", "featured"],
  "keywords": ["Ubuntu Dual boot on Windows 10", "Java Development environment on Ubuntu 19.10", "Ubuntu 19.10", "Kubuntu 19.10", "Windows 10", "Java Development", "Ubuntu Dual boot'", "Windows 10", "Ubuntu 19.10", "Java Setup on Ubuntu", "Windows 10 Dual boot", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`We developers always choose Windows when purchasing our laptops but have desire to get our hands dirty on Linux environments. `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows 10`}</code>{` provides `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows Subsystem for Linux`}</code>{` (WSL), which is a feature that creates a lightweight environment that allows you to install and run supported versions of Linux (such as Ubuntu, OpenSuse, Debian, etc.) without the complexity of setting up a virtual machine or different computer.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`WSL`}</code>{` is good for evaluation purposes. It does not provide us all the power and features of Linux System. It would be ideal to run Linux instance on VM instead of using WSL for extensive use with our development. Running Linux instance on VM has it pros and cons which we would not discuss here.`}</p>
    <p>{`Running Linux along side with Windows OS via Dual Boot is an ideal solution for most of the case. Follow instructions provided `}<ExternalLink href="https://www.techsupportpk.com/2019/10/how-to-install-ubuntu-1910-alongside-windows-10-dual-boot.html" mdxType="ExternalLink">{`here`}</ExternalLink>{` to install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ubuntu 19.10`}</code>{` alongside `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows 10`}</code>{` in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dual boot`}</code>{` environment.`}</p>
    <h2 {...{
      "id": "software-and-applications-that-we-setup-on-ubuntu-1910"
    }}>{`Software and Applications that we setup on Ubuntu 19.10`}</h2>
    <p>{`Below are the Softwares and Applications that we are going to setup on Ubuntu 19.10 that is dual boot along side with Windows 10. These are mainly focused for Java Development environment, but it is not limited for other development technologies.`}</p>
    <ul>
      <li parentName="ul">{`GraalVM`}</li>
      <li parentName="ul">{`Maven`}</li>
      <li parentName="ul">{`Gradle`}</li>
      <li parentName="ul">{`Kotlin`}</li>
      <li parentName="ul">{`Node`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`microk8s`}</li>
      <li parentName="ul">{`Python`}</li>
      <li parentName="ul">{`IDEs such as Notepadqq, Visual Studio Code, IntelliJ IDEA Community`}</li>
      <li parentName="ul">{`Postman`}</li>
      <li parentName="ul">{`Other useful utilies..`}</li>
    </ul>
    <h2 {...{
      "id": "uplifting-ubuntu-1910-to-kubuntu-1910"
    }}>{`Uplifting Ubuntu 19.10 to Kubuntu 19.10`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Kubuntu`}</code>{` is a community developed and supported project which provides the latest stable KDE software on top of a stable Ubuntu core. It provides applications which are more compelling with those provided with stock ubuntu.`}</p>
    <p>{`You can choose to download Kubuntu 19.10 and install in dual boot alongside Windows 10 or you can upgrade Ubuntu 19.10 to Kubuntu 19.10.`}</p>
    <p>{`Below are some snaphots of my pc running Kubuntu 19.10`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4e679da5ab7420e23bb3b0fc15595e2f/29114/kubuntu-desktop.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.44171779141104%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA+wAAAPsAHpfpy9AAACk0lEQVQozy3E+08SAQAA4PutrKjU+UIF4g0ncAccjzs87o4DkTcJofIQOJGGCsylEw1fWJBpPpiKS3FmuWGZuuL/a25t3z7AGN3CU8fW2bpj/odv6Wdw9XasdB/++CdaaU5sNWOfmtFKc/zj38DGnb944168tua/YekTbWxPEawArkgRSx6Q6RNbtu6Y/+4tNEaKN2/Wf4dKd6Ob9w9K94GNW+/KL9diY2jukpg50zOHUHhH7tsE/DMHutiukTmyZE5t+Qv7u0vXwpWn0PAsX3veP3AvX7uXGo6FKzp/QUyfGlLH6sjuQKAicq4C5NoNFNzUjJXR+I6Z2SPeHlmydTpXp3PndP7ckqtT2TNy9syc+Yqma0iyCo1vg/4PwuEij5oDQHNISUUUVBiyxSA6jAQWjaljA1PVM1VdsqpjqtrEvja+r45+UY1+BgNlsXudbyvwzDmOfhKA6IhyKKG0J2FHSu1gkNASmq6ZMzUiU8OmDpHErnZiRx3ZVgQrUt+GwF7g4jN9SKwL9LYLCABEvXLUJ0P9ctPIgMkPu7NK/wrkKeDJbev0ET51oIuUxdS0mJ7lovEuubNNYGZ1K1taeY9YbECqd8oQu1Q7LEGcMr1TRjECek5E51SBNV2kDIdKCu+yAIvz0UQfNNIhHHzZr33WIWl5zn78pB0QaiwSmBJBFrHaKtXYxIYgz8jwTSm+afKVIc5BIhzNGEcd6le9Zis8PbKhTgHeytWxepRP24QACJlANS5RYlKVSQYNChQEW0L1Sqne/5O9ErJHhHfxsU6+sYOna+cirX3wC7aS1S0DzBan1eEnbU6L3U3bPRhuNRIOwuYmrC4TacdpJ25xwHochA0gbFRoULWBQFBSixKwHvsHG7LRU9awF94AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Kubuntu Desktop",
              "title": "Kubuntu Desktop mimicking Windows Desktop layout",
              "src": "/static/4e679da5ab7420e23bb3b0fc15595e2f/a6d36/kubuntu-desktop.png",
              "srcSet": ["/static/4e679da5ab7420e23bb3b0fc15595e2f/222b7/kubuntu-desktop.png 163w", "/static/4e679da5ab7420e23bb3b0fc15595e2f/ff46a/kubuntu-desktop.png 325w", "/static/4e679da5ab7420e23bb3b0fc15595e2f/a6d36/kubuntu-desktop.png 650w", "/static/4e679da5ab7420e23bb3b0fc15595e2f/e548f/kubuntu-desktop.png 975w", "/static/4e679da5ab7420e23bb3b0fc15595e2f/3c492/kubuntu-desktop.png 1300w", "/static/4e679da5ab7420e23bb3b0fc15595e2f/29114/kubuntu-desktop.png 1920w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Kubuntu Desktop mimicking Windows Desktop layout`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c01c50515b328ca6f8a8b39897b7953f/d0c2f/konsole.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.37423312883436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA+wAAAPsAHpfpy9AAACVElEQVQoz22S227TQBBAXeqiVhUk6YWmLW182fUlvu7a3viSOE5oTBGVCj/LN/BGeOAHWr+AxBc03kF2mooiHo5mdmf2aDRaIaJpFPjxh4AkC0rjktCo9ElUen5QemFSJvlVmU6vSpYVpeVHpUVYaflrbD9qehaEja+vbz5n/bMLUXAnkyWZzYAWBYT5HMJ8AXQ8BzcpwKEMvGAEUTJpCZMxBHEGQTyGMB5DkEzApQwcEgFl6X3v8OhMGHzMv8ufZiC/z1ZaMePG7JYbxQ035rcc05jLKuIkjHjIRnxSFDzLcz6eNnHK3WzOVd2oFayBpKKq0z24FGTPXirEBcW1V6rtcuSQJ7AbcGQMuUcDzpKUB2zEXUK54xPuByH3KOOm7awaoYy0+07v4EKQFbRUkQYqwisFYdig4vYOZBUBCSOI4hiyfApxNoZ0kkOW5+C9W4BmubWCMcgIPwoRXqqaDoqmr5q4ARtmG2WEwSMUfBoAi1OIRnErZVkK9DoF03bq9YT/CrH2TNg0bYQOpeCEAYRZCizLIIoToBEDjzJA7Q71Z8Jvj4IHBWu1qulPNOcGzXFq1XNq1bFq3XVr3bTaOtLbnofmPdKNu053Lfzx90RN3OQbdHO43ulj7d96g2YOf3Z6B5cCMu0vlk9/2x65szxSuTSsXBJWtk8rzbQrfehUyBhWA0Wtmq/RojSoLQNFvZcU9EtB+Ovrbq8v7HcPjw/f9OWTk9PB0fGJdHp+IZ2/vZSafHdvX9rbfyXtvNyVtkVREsWd/zEQxR15e1vsb21tvfgDYB4XkTU8kD8AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Konsole Terminal",
              "title": "Multi tab layout with Konsole",
              "src": "/static/c01c50515b328ca6f8a8b39897b7953f/a6d36/konsole.png",
              "srcSet": ["/static/c01c50515b328ca6f8a8b39897b7953f/222b7/konsole.png 163w", "/static/c01c50515b328ca6f8a8b39897b7953f/ff46a/konsole.png 325w", "/static/c01c50515b328ca6f8a8b39897b7953f/a6d36/konsole.png 650w", "/static/c01c50515b328ca6f8a8b39897b7953f/e548f/konsole.png 975w", "/static/c01c50515b328ca6f8a8b39897b7953f/3c492/konsole.png 1300w", "/static/c01c50515b328ca6f8a8b39897b7953f/d0c2f/konsole.png 1362w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Multi tab layout with Konsole`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2120dfd95e89763894cd19fcfe6cb109/29114/konsole_splittedView.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.44171779141104%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA+wAAAPsAHpfpy9AAABeUlEQVQoz4XRWW7jMAwG4FxlYlv7YsuSta9Oit7/RAMjRWdQtCjAx/8jQfLWzkcsNdfmQlp3vWkj1b6bYz8sYSL0ns+R2lDWEy5nSiZCZoQnABeEb7n2mIsLka/rgrA6DmPdftjNWsIF1TvTiu+GbhpzOQMwLWAC8ANv712dbS+n8A1JKcKWW/c5b9pAQv8s4Kp5uS9ghmiG6CUnAAEmNze6H93XHtvDpMijqn2k2pQ5ACaf0deorzifw5UitSFixVKwsIVUfEpSKUjoLziOHnuPtYfclDtk1qWNWKoyB+Hi//QnniG6L+DCtlc/mq891HHkyMKaa0ulvnb+Fv+bnM7hcuZKQyYQ40gwzDjhAlH2Jf0NLs9HGiOUZmOWuyaMT+DjGYiyCYD7deofro1WCYVAXCK+IsapWDGTkHJAKJEb4iu+SgJMEeUzxK8WM0QX3lzRvhiXVleO2Fw+bX2L7Rny0LH79vT16dt7KKcvQ9lofPW5udRsKH8Bde56gokfTqsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Konsole Terminal",
              "title": "Multi tab splitted view layout with Konsole",
              "src": "/static/2120dfd95e89763894cd19fcfe6cb109/a6d36/konsole_splittedView.png",
              "srcSet": ["/static/2120dfd95e89763894cd19fcfe6cb109/222b7/konsole_splittedView.png 163w", "/static/2120dfd95e89763894cd19fcfe6cb109/ff46a/konsole_splittedView.png 325w", "/static/2120dfd95e89763894cd19fcfe6cb109/a6d36/konsole_splittedView.png 650w", "/static/2120dfd95e89763894cd19fcfe6cb109/e548f/konsole_splittedView.png 975w", "/static/2120dfd95e89763894cd19fcfe6cb109/3c492/konsole_splittedView.png 1300w", "/static/2120dfd95e89763894cd19fcfe6cb109/29114/konsole_splittedView.png 1920w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Multi tab splitted view layout with Konsole`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a36ee9a28d212cb50dbca67697628716/29114/kde-software.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.44171779141104%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA+wAAAPsAHpfpy9AAACqklEQVQozwGfAmD9ADlciT9ll0VtnUl0pa+7yNfX1ce0tMnGxtHR0cjMzcfZ4srNz8nIyM3Pz87Pz9nX1bC8yDtomjRekC1ViABNXoA7YpNBaJpDbZ7F0uD///7cyMjl4uP19/jn6Ono5OTp6uvs7e7x8fLv8PL+/fzF0d81YpQwW40rUYUAT2GGN12NOWKVQWeZv8rY9/b0ztfc4OPk7u/w4uTk4uPl5OXm5ufo6err5+jq9fT0vsvYMVyOLFSIJkyAADdbijBWiTRdjjlilL7K2Pn49tfg6OLm5+/v8OLj5ePk5ePk5eLj5Ojq6+jq6/j39b3J1ylQhCRJfCFCcwAkTn0qUYMwVYcwWYu7x9b5+Pjo3sPl5ODu7/Lj5OXj5OXj5eXi5OXp6uvo6uv49/a7xdMhRXggP28eN2QAKEd0KE19LlWGMFmKu8fV+fj30s2/4uLg7/Dy4+Xl4+Tl4+Tl4+Tl6err6Onr+Pj3ucHOGjNeFitPEyNAAB02YCE8aCVEcSJEcbW+zPf29tTS0OHh4u3u7+Hi5ODi4+Hj5ODi4+Xn6Obn6Pb297O4wAsXLQkRIAgNGgAaLVAdMlggOGEcN2K+xtD////f7vbt7u/6+fju7u3t7ezt7ezu7u319fXz8/T///++wccKEyYJDhoIChQAGChHGixNHDBVGzJYYW+IfomdaH2Ycn+VeoWadICVc3+Tc36Sc36Rd4CSdH2PfISTXWZ4EB42DRkuCxMkABUgNhcjORkkOxomPhIgOg8eORQhPBIgPRAfPBEgPBEfOxEfOhAdOQ0bNgwaMwkVLgoXLREcMg8aLg0YKQA4P0lARk49Q0lARUxCRk08P0Y6QktCR05DRk0+Q0pBRkw+Q0o8QUkzOkItNDwwNz40OkE1OkE1OkAyNzzG+3OVV2JXrQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "KDE Software",
              "title": "Software store",
              "src": "/static/a36ee9a28d212cb50dbca67697628716/a6d36/kde-software.png",
              "srcSet": ["/static/a36ee9a28d212cb50dbca67697628716/222b7/kde-software.png 163w", "/static/a36ee9a28d212cb50dbca67697628716/ff46a/kde-software.png 325w", "/static/a36ee9a28d212cb50dbca67697628716/a6d36/kde-software.png 650w", "/static/a36ee9a28d212cb50dbca67697628716/e548f/kde-software.png 975w", "/static/a36ee9a28d212cb50dbca67697628716/3c492/kde-software.png 1300w", "/static/a36ee9a28d212cb50dbca67697628716/29114/kde-software.png 1920w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Software store`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "setup-instructions"
    }}>{`Setup Instructions`}</h2>
    <p>{`Follow below steps to install & configure softwares and applications that we listed earlier:`}</p>
    <h3 {...{
      "id": "install-curl"
    }}>{`Install curl`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo apt install curl`}</code></pre></div>
    <h3 {...{
      "id": "install-wget"
    }}>{`Install wget`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo apt-get install wget`}</code></pre></div>
    <h3 {...{
      "id": "install-libz---needed-for-building-graalvm-native-image"
    }}>{`Install libz - needed for building graalvm native image`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo apt-get update -y
sudo apt-get install -y libz-dev`}</code></pre></div>
    <h3 {...{
      "id": "install-sdkman"
    }}>{`Install sdkman`}</h3>
    <ul>
      <li parentName="ul">{`open a new terminal and enter:`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ curl -s "https://get.sdkman.io" | bash`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Open new terminal and exter below command`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ source "$HOME/.sdkman/bin/sdkman-init.sh"`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Verify if installed properly by checking version`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk version`}</code></pre></div>
    <h3 {...{
      "id": "install-graalvm-jdk-11"
    }}>{`Install GraalVM JDK 11`}</h3>
    <ul>
      <li parentName="ul">{`Download GraalVM JDK11`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ cd /home/<user>/Downloads

$ wget https://github.com/graalvm/graalvm-ce-builds/releases/download/vm-20.0.0/graalvm-ce-java11-linux-amd64-20.0.0.tar.gz

$ tar -xvzf graalvm-ce-java11-linux-amd64-20.0.0.tar.gz`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Move the unpacked dir to /usr/lib/jvm/ and create a symbolic link to make your life easier when updating the GraalVM version:`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo mv graalvm-ce-java11-20.0.0/ /usr/lib/jvm/`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Install the GraalVM Java`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo update-alternatives --install /usr/bin/java java /usr/lib/jvm/bin/java 1`}</code></pre></div>
    <ul>
      <li parentName="ul">{`verify by checking the version number:`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ java -version`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Set Path by adding below exports to anywhere above end of the file. Restart the terminal.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ vi ~/.bashrc

export JAVA_HOME=/usr/lib/jvm/
export PATH=\${PATH}:\${JAVA_HOME}/bin

export GRAALVM_HOME=/usr/lib/jvm/`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Download Native image installer component package`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ cd /home/<user>/Downloads

$ wget https://github.com/graalvm/graalvm-ce-builds/releases/download/vm-20.0.0/native-image-installable-svm-java11-linux-amd64-20.0.0.jar`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Install Native Image using GraalVM Updater`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ gu -L install native-image-installable-svm-java11-linux-amd64-20.0.0.jar`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Verify if native-image is installed `}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ gu list`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Test native image`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ mkdir /home/<user>/learning/java

$ vi HelloWorld.java
public class HelloWorld {
  public static void main(String[] args) {
    System.out.println("Hello, World!");
  }
}

$ javac HelloWorld.java

$ native-image HelloWorld

$ ./helloworld`}</code></pre></div>
    <h3 {...{
      "id": "install-maven"
    }}>{`Install maven`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk install maven

$ mvn -v`}</code></pre></div>
    <h3 {...{
      "id": "install-gradle"
    }}>{`Install Gradle`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk install gradle
$ gradle -v`}</code></pre></div>
    <h3 {...{
      "id": "install-kotlin"
    }}>{`Install Kotlin`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sdk install kotlin
$ kotlin -version`}</code></pre></div>
    <h3 {...{
      "id": "install-git"
    }}>{`Install Git`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo apt install git
$ git --version`}</code></pre></div>
    <h3 {...{
      "id": "configure-git-credentials"
    }}>{`Configure git credentials`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ git config --global user.name "Madan Narra"
$ git config --global credential.helper store
$ git pull
$ provide credentials when prompted`}</code></pre></div>
    <h3 {...{
      "id": "install-nodejs-12"
    }}>{`Install Nodejs 12`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ curl -sL https://deb.nodesource.com/setup_12.x | sudo -E bash -
$ sudo apt-get install -y nodejs
$ sudo apt-get install -y npm`}</code></pre></div>
    <p>{`This should install Node v12.x.x and NPM v6.x.x.`}</p>
    <ul>
      <li parentName="ul">{`Verify version`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ node --version
$ npm --version`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Install Yarn`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo npm install -g yarn
$ yarn -v`}</code></pre></div>
    <h3 {...{
      "id": "install-docker"
    }}>{`Install Docker`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo apt-get update
$ sudo apt install docker.io`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Start and Automate Docker`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo systemctl start docker
$ sudo systemctl enable docker`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Verify Version`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ docker --version`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Add current user to docker group and set permission to docker.sock file to not get permission denied errors when running docker commands from logged in user rather then sudo user.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo groupadd docker

$ sudo usermod -aG docker \${USER}

$ sudo chown "$USER":"$USER" /home/"$USER"/.docker -R

$ sudo chmod g+rwx "$HOME/.docker" -R

$ sudo chmod 666 /var/run/docker.sock

$ newgrp docker`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Login to docker`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ docker login

Login with your Docker ID to push and pull images from Docker Hub. If you don't have a Docker ID, head over to https://hub.docker.com to create one.
Username: XXXXXXXXX
Password: 
WARNING! Your password will be stored unencrypted in /home/madan/.docker/config.json.
Configure a credential helper to remove this warning. See
https://docs.docker.com/engine/reference/commandline/login/#credentials-store

Login Succeeded`}</code></pre></div>
    <h3 {...{
      "id": "install-docker-compose"
    }}>{`Install Docker Compose`}</h3>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Download Docker Compose`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -L `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://github.com/docker/compose/releases/download/1.25.4/docker-compose-`}<span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`uname`}</span>{` -s`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`-`}<span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span><span parentName="span" {...{
                "className": "token function"
              }}>{`uname`}</span>{` -m`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{` -o /usr/local/bin/docker-compose`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Apply executable permissions to the binary`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` +x /usr/local/bin/docker-compose`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Test the installation`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` --version`}</code></pre></div>
    <h3 {...{
      "id": "install-microk8s"
    }}>{`Install microk8s`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo snap install microk8s --classic`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Join user group`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo usermod -a -G microk8s $USER
$ su - $USER`}</code></pre></div>
    <ul>
      <li parentName="ul">{`verify installation`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ microk8s.kubectl get nodes`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Create alias for kubectl`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo snap alias microk8s.kubectl kubectl`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Create shortcut for kubectl`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ vi ~/.bashrc
$ alias k='microk8s.kubectl'`}</code></pre></div>
    <ul>
      <li parentName="ul">{`configure firewall to allow pod-to-pod and pod-to-internet communication:`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo ufw allow in on cni0 && sudo ufw allow out on cni0
$ sudo ufw default allow routed`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Write cluster config information to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`$HOME/.kube/config`}</code>{` file`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ microk8s.kubectl config view --raw > $HOME/.kube/config`}</code></pre></div>
    <h3 {...{
      "id": "set-python-3-as-default"
    }}>{`Set Python 3 as default`}</h3>
    <p>{`Ubuntu comes with Python 2 & Python 3 by default. When documenting this article, below are the versions available in Ubuntu 19.10`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Python Versions Available`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ python --version
Python `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2.7`}</span>{`.17

$ python3 --version
Python `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.7`}</span>{`.5`}</code></pre></div>
    <p>{`Run the below command to use Python3 as default`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ update-alternatives --install /usr/bin/python python /usr/bin/python3 10`}</code></pre></div>
    <p>{`Now running below command will confirm Python3 is set as default`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ python --version
Python 3.7.5`}</code></pre></div>
    <h4 {...{
      "id": "install-pip---package-management-system"
    }}>{`Install pip - Package Management System`}</h4>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Install pip`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` update

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` python3-pip

$ pip3 --version
pip `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18.1`}</span>{` from /usr/lib/python3/dist-packages/pip `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`python `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`pip packages will be installed in “ folder. Add this directory to PATH to start using the installed packages`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Configure Path`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`vi`}</span>{` ~/.bashrc`}</code></pre></div>
    <p>{`Add the following line at the end of the file. Save the file`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`export PATH="/home/madan/.local/bin:$PATH"`}</code></pre></div>
    <p>{`Save the file and load the new $PATH to current shell session`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ source ~/.bashrc

$ echo $PATH`}</code></pre></div>
    <h3 {...{
      "id": "install-softwares-from-ubuntu-software"
    }}>{`Install softwares from Ubuntu Software`}</h3>
    <ul>
      <li parentName="ul">{`chromium`}</li>
      <li parentName="ul">{`koncole terminal`}</li>
      <li parentName="ul">{`Notepadqq`}</li>
      <li parentName="ul">{`Visual Studio Code`}</li>
    </ul>
    <h3 {...{
      "id": "intellij"
    }}>{`IntelliJ`}</h3>
    <p>{`Install Intellij IDEA Community edition from Ubuntu Software.`}</p>
    <p>{`Install the below plugins for more productivity with IntelliJ`}</p>
    <ul>
      <li parentName="ul">{`SketchIt - To generate PlantUML file with class diagram syntax for the selected package. Choose the package > Tools > click on SketchIt`}</li>
      <li parentName="ul">{`PlantUML Integration - To view graphical output of PlantUML file`}</li>
    </ul>
    <p>{`For PlantUML to work, we need to install Graphviz. Use the below command to install`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` graphviz`}</code></pre></div>
    <h3 {...{
      "id": "install-postman"
    }}>{`Install Postman`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo snap install postman`}</code></pre></div>
    <h2 {...{
      "id": "utilities"
    }}>{`Utilities`}</h2>
    <h3 {...{
      "id": "flameshot"
    }}>{`Flameshot`}</h3>
    <p>{`Flameshot is Powerful yet simple to use screenshot software.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo apt install flameshot`}</code></pre></div>
    <p>{`Assign `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PrtScr`}</code>{` to Falemshot in Kubuntu`}</p>
    <ul>
      <li parentName="ul">{`Go to System Settings > Search for Shortcuts`}</li>
      <li parentName="ul">{`Navigate to Custom Shortcuts and delete existing screenshot group if one exists by default.`}</li>
      <li parentName="ul">{`Create new group Screenshot and create new custom action within it.`}</li>
      <li parentName="ul">{`Assign Trigger to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PrtScr`}</code>{` by pressing the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PrtScr`}</code>{` button.`}</li>
      <li parentName="ul">{`Use `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/usr/bin/flameshot gui`}</code>{` in command/url input field.`}</li>
      <li parentName="ul">{`Select Screenshot checkbox and click Apply.`}</li>
      <li parentName="ul">{`Clicking `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PrtScr`}</code>{` button should now show Flameshot window.`}</li>
    </ul>
    <p>{`Assign `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PrtScr`}</code>{` to Flameshot in Ubuntu`}</p>
    <ul>
      <li parentName="ul">{`Release the PrtScr binding by this command`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`gsettings set org.gnome.settings-daemon.plugins.media-keys screenshot '[]'`}</code></pre></div>
    <ul>
      <li parentName="ul">{`Go to Settings -> Devices -> Keyboard and scroll to the end. Press + and you will create custom shortcut.`}</li>
      <li parentName="ul">{`Enter name: “flameshot”, command: /usr/bin/flameshot gui.`}</li>
      <li parentName="ul">{`Set shortcut to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PrtScr`}</code>{` (print).`}</li>
      <li parentName="ul">{`That is it. Next time you push `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PrtScr`}</code>{` flameshot will be launched.`}</li>
    </ul>
    <h3 {...{
      "id": "simplescreenrecorder"
    }}>{`SimpleScreenRecorder`}</h3>
    <p>{`SimpleScreenRecorder is a Linux program that record programs and games capturing the entire screen or part of it.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` simplescreenrecorder`}</code></pre></div>
    <h3 {...{
      "id": "gravit-designer"
    }}>{`Gravit Designer`}</h3>
    <p>{`Gravit Designer is a free full-featured vector graphic design app that works on ALL platforms. Unlock the full power of your creativity with fast and flexible tools tailored to professional designers.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo snap install gravit-designer`}</code></pre></div>
    <h2 {...{
      "id": "create-work-folder-and-set-permissions"
    }}>{`Create work folder and set permissions`}</h2>
    <p>{`Its always better to isolate our work into specific folder. Below are the commands to create folder `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`work`}</code>{` and setting appropriate permissions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ cd /home/<user>

$ sudo mkdir work`}</code></pre></div>
    <p>{`Changes the permission mode for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`work`}</code>{` directory so that the owner and group has full read/write/(execute or search) access and all others have read and execute/search access.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo chmod 775 work/`}</code></pre></div>
    <p>{`Alternatively add <USER_NAME> to the users group and then make users the group owner for worl folder`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`$ sudo adduser <USER_NAME> users

$ sudo chown -R <USER_NAME>:users work/`}</code></pre></div>
    <h2 {...{
      "id": "useful-linux-cheatsheet-commands"
    }}>{`Useful Linux cheatsheet Commands`}</h2>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Top – Linux Process Monitoring`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`top`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Htop – Linux Process Monitoring`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` snap `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`htop`}</span>{`

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`htop`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Lsof – List Open Files`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`lsof`}</span>{`

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`lsof`}</span>{` -i -P -n `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` LISTEN

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`lsof`}</span>{` -i:22 `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## see a specific port such as 22 ##`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Netstat – Network Statistics`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` net-tools

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`netstat`}</span>{` -a `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`more`}</span>{`

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`netstat`}</span>{` -tulpn `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` LISTEN

$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`netstat`}</span>{` -plnt `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`':80'`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`check which process binds a TCP Socket on port 8080`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fuser`}</span>{` -v -n tcp `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Dump socket statistics Alternative to netstat`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` ss -tulwn

$ ss -nt `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'( dst :8443 or dst :8080 )'`}</span>{`

$ ss -nt `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'( dst :5432 )'`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Kill Process`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`kill`}</span>{` PID

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`## Force kill`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`kill`}</span>{` -9 PID`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Resources consuming by specific process`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Show running Java Process`}</span>{`
$ jps

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Get stats for specific java process`}</span>{`
$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{` -p `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`PID`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` -o %cpu,%mem,cmd`}</code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`This post describes development environment on Ubuntu for Java developers but not limited to others. This is self reference post for myself to reinstall and configure my development environment with simple easy steps if something goes wrong with my Ubuntu OS.`}</p>
    <p>{`I liked Kubuntu with its look and feel mimicking windows environment. Its better to download Kubuntu OS and configure it to run along side Windows 10 rather then updating Ubuntu to Kubuntu.`}</p>
    <p>{`Hope this helps for those looking for who wants to get their hands dirty by developing on Ubuntu !!!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      