import * as React from 'react';
import {FaGithub} from 'react-icons/fa'

import ExternalLink from '~/utils/mdx/components/ExternalLink';

import {
  GithubRepoWrapper,
  DescriptionWrapper
} from './index.style';

const GithubRepo = (props) => {
  return (
    <GithubRepoWrapper>
      <FaGithub size={45}/> 
      <DescriptionWrapper>Clone the source code of the article from <ExternalLink href={props.href}>{props.children}</ExternalLink></DescriptionWrapper>
    </GithubRepoWrapper>
  );
};

export default GithubRepo;