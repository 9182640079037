import styled, { keyframes} from 'styled-components';

export const GithubRepoWrapper = styled.div`
    border: 2px solid #f73b98;
    border-radius: 10px;
    font-size: 16px;
    display: flex;
    align-items:center;
    padding: 10px;
    margin: 15px 5px;
`;

export const DescriptionWrapper = styled.span`
    padding: 0px 0px 0px 10px;
`;