import crud from '~/images/categories/crud.png'
import microservice from '~/images/categories/microservice.png'
import java from '~/images/categories/java.png'
import sql from '~/images/categories/sql.png'
import codechallange from '~/images/categories/codechallange.png'
import interviewquestions from '~/images/categories/interviewquestions.png'

const categoryConstants = {
    crudFromScratch: {
        order: 1,
        alias: `crudFromScratch`,
        name: `CRUD from Scratch`,
        image: crud,
        description: `Contains articles to implement an application from scratch containing the four basic functions - create, read, update, and delete (CRUD)`
    },
    microservice: {
        order: 2,
        alias: `microservice`,
        name: `Microservice`,
        image: microservice,
        description: `Contains articles on getting thing done in the microservice world`
    },
    java: {
        order: 3,
        alias: `java`,
        name: `Java`,
        image: java,
        description: `Contains articles, how-tos, code snippets, titbits using Java`
    },
    sql: {
        order: 4,
        alias: `sql`,
        name: `SQL`,
        image: sql,
        description: `Contains articles, how-tos, code snippets, titbits using SQL `
    },
    codeChallange: {
        order: 5,
        alias: `codeChallange`,
        name: `Code Challange`,
        image: codechallange,
        description: `Contains solutions to some of the Code Challanges that we face in Interviews`
    },
    interviewQuestions: {
        order: 6,
        alias: `interviewQuestions`,
        name: `Interview Questions`,
        image: interviewquestions,
        description: `Contains popular interview questions with clear, simple explanations `
    },
    javastreams: {
        order: 7,
        alias: `javaStreams`,
        name: `Java Streams`,
        image: java,
        description: `Contains articles, how-tos, code snippets, titbits using Java Streams`
    },
    testing: {
        order: 8,
        alias: `testing`,
        name: `Testing`,
        image: java,
        description: `Contains articles, how-tos, code snippets, titbits in Testing`
    },
    cheatsheets: {
        order: 9,
        alias: `cheatsheets`,
        name: `Cheatsheets`,
        image: java,
        description: `Contains cheatsheets for different technologies`
    },
    cicd: {
        order: 10,
        alias: `cicd`,
        name: `CI/CD`,
        image: java,
        description: `Contains cheatsheets for different technologies`
    },
    setup: {
        order: 11,
        alias: `setup`,
        name: `Setup Guide`,
        image: java,
        description: `Contains step by step details for setting up different tools for development/testing activities`
    },
    programming: {
        order: 12,
        alias: `programming`,
        name: `Programming`,
        image: java,
        description: `Contains articles on different programming paradigms, design patterns, core concepts`
    },
    frontend: {
        order: 13,
        alias: `frontend`,
        name: `Frontend`,
        image: crud,
        description: `Contains articles on implementing frontend applications`
    },
    titbits: {
        order: 14,
        alias: `titbits`,
        name: `Titbits`,
        image: java,
        description: `Contains titbits on getting things done with different tools and programs`
    },
    designfirst: {
        order: 14,
        alias: `designfirst`,
        name: `Design-first`,
        image: java,
        description: `Contains articles on different concepts around design-first approach with Restful API, Async API or Kafka Schema`
    },
}

export default categoryConstants;