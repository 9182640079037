import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Enabling HTTPS in Multi Module Spring Boot Microservices with Let's Encrypt",
  "description": "A detailed step by step guide on enabling HTTPS in Multi Module Spring Boot Microservices with Let's Encrypt",
  "date": "2021-01-10T15:46:37.121Z",
  "updated": "2021-01-10T15:46:37.121Z",
  "cover": "../../../images/blog-banners/springboot-https-docker-k8s-banner-1200x690.png",
  "category": "microservice",
  "tags": ["Java", "Spring Boot", "Spring Cloud", "Microservice", "featured"],
  "keywords": ["Step by Step guide using Spring Boot", "Spring Boot HTTPS", "Spring Cloud", "Spring Boot", "Spring Boot Secure", "Maven", "Java", "Java8", "Gradle", "Spring Boot Lets Encrypt", "Spring Boot Certbot", "Spring Boot Docker HTTPs", "Spring Boot Kubernetes HTTPs", "Gradle Multi Module Spring Boot Project", "Step by Step guide", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <h2 {...{
      "id": "technology-stack-for-implementing-the-restful-apis"
    }}>{`Technology stack for implementing the Restful APIs`}</h2>
    <h2 {...{
      "id": "bootstrapping-project-with-multi-module-spring-boot-application"
    }}>{`Bootstrapping Project with Multi Module Spring Boot Application`}</h2>
    <h2 {...{
      "id": "lets-encrypt"
    }}>{`Let's Encrypt`}</h2>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      