import React from "react"
import Layout from "~/components/layout"
import SEO from "~/components/seo"
import Contact from "~/containers/Contact"

type ContactPageProps = {}

const ContactPage: React.FunctionComponent<ContactPageProps> = props => {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="2much2learn.com is always looking for like minded authors who wants to share their work to the world."
      />

      <Contact />
    </Layout>
  )
}

export default ContactPage
