import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": false,
  "displayComments": true,
  "showAuthorInfo": false,
  "author": "narramadan",
  "title": "Getting it done with JAVA",
  "description": "Couple of things to do with Java",
  "date": "2019-09-15T23:46:37.121Z",
  "updated": "2019-09-15T23:46:37.121Z",
  "cover": "../../../images/blog-banners/cheatsheet-banner-1200x690.png",
  "category": "cheatsheets",
  "tags": ["java8", "cheatsheets"],
  "keywords": ["java8", "lamda", "lamda expressions", "Default Lambda in Java8", "Java Time API", "Collection API improvements", "Concurrency API improvements", "Java IO improvements", "Functional Interfaces and Lambda Expressions", "default and static methods in Interfaces", "static methods", "forEach()", "Java IO improvements"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven command to create new project from Quickstart Archetype`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ mvn archetype:generate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-DgroupId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`com.toomuch2learn `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-DartifactId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`example `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-DarchetypeArtifactId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`maven-archetype-quickstart -DarchetypeVersion`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1.4`}</span>{` -DinteractiveMode`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Maven copy all dependencies bundled in war to target folder`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`$ mvn `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` dependency:copy-dependencies`}</code></pre></div>
    <h2 {...{
      "id": "loop-n-times"
    }}>{`Loop n times`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Loop through closed range`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`rangeClosed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Do some operation`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`rangeClosed`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// build a custom iteration and limit the size of the iteration`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`IntStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`iterate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`->`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`limit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
         `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`System`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`out`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`::`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h1 {...{
      "id": "bigdecimal"
    }}>{`BigDecimal`}</h1>
    <h2 {...{
      "id": "generating-random-bigdecimal-value-from-given-range"
    }}>{`Generating random BigDecimal value from given range`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://programming.guide/java/generate-random-number-of-given-length.html"
        }}>{`https://programming.guide/java/generate-random-number-of-given-length.html`}</a></li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecRand`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`static`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`void`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` range `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span>{` max `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`range `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span>{` randFromDouble `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Math`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`random`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span>{` actualRandomDec `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` randFromDouble`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`divide`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`max`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigDecimal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ROUND_DOWN`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`BigInteger`}</span>{` actualRandom `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` actualRandomDec`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toBigInteger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      