import * as React from 'react';

import ExternalLink from '~/utils/mdx/components/ExternalLink';
import {
  FontWapper
} from './index.style';

const ExternalLinksListContainer = (props) => {
  const title = props.title;
  const links = props.links;

  const linkItems = links.map((link, key) =>
    <li key={key}>
      <ExternalLink href={link.url}>{link.name ? link.name : link.url}</ExternalLink>
    </li>
  );
  return (
    <div>
      <FontWapper>{title}</FontWapper>
      <ul>{linkItems}</ul>
    </div>
  );
};

export default ExternalLinksListContainer;