import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Setting up Cassandra with Docker",
  "description": "A detailed step by step guide on Setting up Cassandra with Docker.",
  "date": "2021-10-19T15:46:37.121+10:00",
  "updated": "2021-10-19T15:46:37.121+10:00",
  "cover": "../../../images/blog-banners/setup-banner-1200x690.png",
  "category": "setup",
  "tags": ["Cassandra", "Docker", "Containerization", "featured"],
  "keywords": ["Setting up Cassandra with Docker", "Cassandra with Docker", "Database with Docker", "Create Cassandra database with Docker", "Migrate Cassandra from Docker", "Provision Cassandra database with Docker", "Cassandra on Windows 10", "Cassandra", "Windows 10", "Docker", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article-oct192021-cassandra-with-docker" mdxType="GithubRepo">setting-up-cassandra-with-docker-on-windows</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`As part of any application development, we definitely need a database. There might be different use cases which need different databases. Having them all installed on our machine might not be an ideal scenario. In the world of containers, this would ease us to provision the choice of database easily for testing purpose without actually installing them. `}</p>
    <p>{`In this article we shall provision Apache Cassandra docker container and go through steps on using it.`}</p>
    <h2 {...{
      "id": "why-use-apache-cassandra"
    }}>{`Why use Apache Cassandra?`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Apache Cassandra`}</code>{` is an open source, `}<ExternalLink href="https://en.wikipedia.org/wiki/Distributed_database" mdxType="ExternalLink">{`distributed`}</ExternalLink>{`, `}<ExternalLink href="https://en.wikipedia.org/wiki/Wide-column_store" mdxType="ExternalLink">{`wide-column store`}</ExternalLink>{`, `}<ExternalLink href="https://en.wikipedia.org/wiki/NoSQL" mdxType="ExternalLink">{`NoSQL`}</ExternalLink>{` database which delivers always-on availability (`}<ExternalLink href="https://en.wikipedia.org/wiki/Single_point_of_failure" mdxType="ExternalLink">{`No SPOF`}</ExternalLink>{`).`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/2c8e669729468a3aa97b92bd9cdae6ef/eda13/apache-cassandra-diagrams-01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "49.079754601226995%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHt4rNNgr//xAAbEAACAQUAAAAAAAAAAAAAAAAAAgEDECExMv/aAAgBAQABBQJsEbG5p2//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAABEAD/2gAIAQEABj8Crmf/xAAdEAACAgEFAAAAAAAAAAAAAAABEQAhMRBBcaGx/9oACAEBAAE/IaAkCTNotF5FwTrTLgPNP//aAAwDAQACAAMAAAAQ5M//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQMBAT8QNX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQBRECExsf/aAAgBAQABPxB0ECCi4kLwI1HZ1Jnj24LXf5hCZwv/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Apache Cassandra",
              "title": "Apache Cassandra",
              "src": "/static/2c8e669729468a3aa97b92bd9cdae6ef/6aca1/apache-cassandra-diagrams-01.jpg",
              "srcSet": ["/static/2c8e669729468a3aa97b92bd9cdae6ef/d2f63/apache-cassandra-diagrams-01.jpg 163w", "/static/2c8e669729468a3aa97b92bd9cdae6ef/c989d/apache-cassandra-diagrams-01.jpg 325w", "/static/2c8e669729468a3aa97b92bd9cdae6ef/6aca1/apache-cassandra-diagrams-01.jpg 650w", "/static/2c8e669729468a3aa97b92bd9cdae6ef/7c09c/apache-cassandra-diagrams-01.jpg 975w", "/static/2c8e669729468a3aa97b92bd9cdae6ef/01ab0/apache-cassandra-diagrams-01.jpg 1300w", "/static/2c8e669729468a3aa97b92bd9cdae6ef/eda13/apache-cassandra-diagrams-01.jpg 3825w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Apache Cassandra`}</figcaption>{`
  `}</figure></p>
    <p>{`Below are some key features of Cassandra. Refer to the `}<ExternalLink href="https://www.tutorialspoint.com/cassandra/cassandra_introduction.htm" mdxType="ExternalLink">{`tutorial`}</ExternalLink>{` for more detail insights on `}<ExternalLink href="https://www.tutorialspoint.com/cassandra/cassandra_architecture.htm" mdxType="ExternalLink">{`Architecture`}</ExternalLink>{`, `}<ExternalLink href="https://www.tutorialspoint.com/cassandra/cassandra_data_model.htm" mdxType="ExternalLink">{`Data Model`}</ExternalLink>{` & `}<ExternalLink href="https://www.tutorialspoint.com/cassandra/cassandra_cqlsh.htm" mdxType="ExternalLink">{`Cqlsh`}</ExternalLink>{`.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Elastic scalability`}</strong>{` − Cassandra is highly scalable; it allows to add more hardware to accommodate more customers and more data as per requirement.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Always on architecture`}</strong>{` − Cassandra has no single point of failure and it is continuously available for business-critical applications that cannot afford a failure.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Fast linear-scale performance`}</strong>{` − Cassandra is linearly scalable, i.e., it increases your throughput as you increase the number of nodes in the cluster. Therefore it maintains a quick response time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Flexible data storage`}</strong>{` − Cassandra accommodates all possible data formats including: structured, semi-structured, and unstructured. It can dynamically accommodate changes to your data structures according to your need.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Easy data distribution`}</strong>{` − Cassandra provides the flexibility to distribute data where you need by replicating data across multiple data centers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Transaction support`}</strong>{` − Cassandra supports properties like Atomicity, Consistency, Isolation, and Durability (ACID).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Fast writes`}</strong>{` − Cassandra was designed to run on cheap commodity hardware. It performs blazingly fast writes and can store hundreds of terabytes of data, without sacrificing the read efficiency.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "what-are-we-going-to-do"
    }}>{`What are we going to do?`}</h2>
    <p>{`In this article, we shall go through on using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker`}</code>{` to explore `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Cassandra`}</code>{`. Below are series of things we will be doing in this article:`}</p>
    <ul>
      <li parentName="ul">{`Provision Cassandra container`}</li>
      <li parentName="ul">{`Connecting to Cassandra from container shell`}</li>
      <li parentName="ul">{`Create keyspace and perform some table operations`}</li>
      <li parentName="ul">{`Connecting to the database from Cassandra GUI Client`}</li>
      <li parentName="ul">{`Initialize Cassandra docker container with keyspace and data`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Having Docker installed on your machine is necessary to try out provisioning Cassandra container.`}</p>
    <p>{`Install `}<ExternalLink href="https://hub.docker.com/editions/community/docker-ce-desktop-windows" mdxType="ExternalLink">{`Docker Desktop`}</ExternalLink>{` to get started with Docker on Windows.`}</p>
    <p>{`Post installation, run the below command in command prompt to verify if docker & docker-compose is installed successfully or validate if they are working as expected if docker is installed prior.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` -v

Docker version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20.10`}</span>{`.8, build 3967b7d`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Compose Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` -v

Docker Compose version v2.0.0`}</code></pre></div>
    <h2 {...{
      "id": "cassandra-docker-image"
    }}>{`Cassandra Docker Image`}</h2>
    <p>{`In this article, we shall use `}<ExternalLink href="https://hub.docker.com/r/bitnami/cassandra/" mdxType="ExternalLink">{`bitname/cassandra`}</ExternalLink>{` docker image opposed to official `}<ExternalLink href="https://hub.docker.com/_/cassandra" mdxType="ExternalLink">{`cassandra`}</ExternalLink>{` image.`}</p>
    <p>{`Below are few salient reasons for opting `}<strong parentName="p">{`Bitnami Images`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Bitnami closely tracks upstream source changes and promptly publishes new versions of this image using our automated systems.`}</li>
      <li parentName="ul">{`With Bitnami images the latest bug fixes and features are available as soon as possible.`}</li>
      <li parentName="ul">{`Bitnami containers, virtual machines and cloud images use the same components and configuration approach - making it easy to switch between formats based on your project needs.`}</li>
      <li parentName="ul">{`Bitnami container images are released daily with the latest distribution packages available.`}</li>
    </ul>
    <h2 {...{
      "id": "provision-cassandra-container"
    }}>{`Provision Cassandra Container`}</h2>
    <p>{`It’s ideal option to orchestrate cassandra provisioning using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` compared to running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{`. Both options are provided below for reference.`}</p>
    <h3 {...{
      "id": "using-docker"
    }}>{`Using docker`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run ^
    --name cassandra ^
    -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7000`}</span>{`:7000 ^
    -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9042`}</span>{`:9042 ^
    -v %cd%:/bitnami ^
    -d bitnami/cassandra:latest

Unable to `}<span parentName="code" {...{
            "className": "token function"
          }}>{`find`}</span>{` image `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'bitnami/cassandra:latest'`}</span>{` locally
latest: Pulling from bitnami/cassandra
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
Digest: sha256:30f76ea2e81f5379e1ba4c31c0f8b0c5481cd379b01113e3375ecfd6d5961862
Status: Downloaded newer image `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` bitnami/cassandra:latest
d67aca9ca1a79109b1ccf3010b3b549c9f80c876c981406c8a169ab23ddb74a8`}</code></pre></div>
    <p>{`Options passed:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--detach, -d`}</code>{` - Run container in background and print container ID`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--publish, -p`}</code>{` - Publish a container’s port(s) to the host`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--name`}</code>{` - Assign a name to the container`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`-v`}</code>{` - Mount current directory path as volume. This should create `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cassandra`}</code>{` folder in the location where the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`docker run`}</code>{` is executed`}</li>
    </ul>
    <p>{`Verify if the container is provisioned by list containers command. Check logs to follow the traces on whats happening within the container.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
CONTAINER ID   IMAGE                      COMMAND                  CREATED         STATUS         PORTS                                            NAMES
1d897306c76c   bitnami/cassandra:latest   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/bitnami/script…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` seconds ago   Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` seconds   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:7000-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7000`}</span>{`/tcp, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:9042-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9042`}</span>{`/tcp   cassandra`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`View logs`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` logs -f cassandra
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.21
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.21 Welcome to the Bitnami cassandra container
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.21 Subscribe to project updates by watching https://github.com/bitnami/bitnami-docker-cassandra
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.21 Submit issues and feature requests at https://github.com/bitnami/bitnami-docker-cassandra/issues
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.21
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.22 INFO  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` ** Starting Cassandra setup **
cassandra `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:23:34.24 INFO  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` Validating settings `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` CASSANDRA_* `}<span parentName="code" {...{
            "className": "token function"
          }}>{`env`}</span>{` vars`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span></code></pre></div>
    <p>{`Cleanup by stopping and removing the container before trying out provisioning with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Cleanup`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` stop cassandra

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` cassandra`}</code></pre></div>
    <h3 {...{
      "id": "using-docker-compose"
    }}>{`Using docker-compose`}</h3>
    <p><strong parentName="p">{`Docker Compose`}</strong>{` is a tool for defining and running multi-container Docker applications. With Compose, we use a YAML file to configure your application’s services. Then, with a single command, we create and start all the services from our configuration.`}</p>
    <p>{`To start with, let’s create `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{` with the below configuration.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3'`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`cassandra`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.io/bitnami/cassandra`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`latest
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'7000:7000'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'9042:9042'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cassandra_data:/bitnami'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`healthcheck`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`test`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CMD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/bitnami/cassandra/bin/cqlsh"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-u cassandra"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-p cassandra"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-e \\"describe keyspaces\\""`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 15s
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`timeout`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 10s
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retries`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_SEEDS=cassandra
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_PASSWORD_SEEDER=yes
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_PASSWORD=cassandra
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`cassandra_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`driver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` local`}</code></pre></div>
    <p>{`If observed, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cassandra`}</code>{` service is defined with different configurations which can be helpful to orchestrate, configure and perform healthcheck if instance is provisioned and running successfully by listing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`keyspaces`}</code>{`.`}</p>
    <p>{`Run the below series of commands from the path where docker-compose.yml resides.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Validate yaml file and list services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` config --services`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Build and start services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`+`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Running `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`/2
 - Network cassandratest_default        Created                                                                      
 - Container cassandratest-cassandra-1  Started`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
NAME                                  COMMAND                  SERVICE             STATUS               PORTS
cassandratest-cassandra-1   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/bitnami/script…"`}</span>{`   cassandra           running `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`starting`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:7000-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7000`}</span>{`/tcp, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:9042-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9042`}</span>{`/tcp`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Display the running processes`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`top`}</span>{`
cassandratest-cassandra-1
`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`UID`}</span>{`    PID     `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`PPID`}</span>{`    C    STIME   TTY   TIME       CMD
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1001`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25498`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25477`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`21`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{`:48   ?     00:00:22   /opt/bitnami/java/bin/java -ea -da:net.openhft`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop and start services`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` stop

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` start`}</code></pre></div>
    <p>{`Run the below command to perform cleanup activities - Stops containers and removes containers, networks, volumes, and images created by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`up`}</code></p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Cleanup`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` down -v
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`+`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` Running `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`/3
 - Container cassandratest-cassandra-1  Removed  
 - Volume cassandratest_cassandra_data  Removed  
 - Network cassandratest_default        Removed`}</code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{`: If the file name is other than `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{`, then run the commands by providing the file as below`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`provide file`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` -f service-compose`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`services.yml up -d

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` -f service-compose`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`services.yml `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` -f service-compose`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`services.yml down -v`}</code></pre></div>
    <h2 {...{
      "id": "connecting-to-the-database-from-container-shell"
    }}>{`Connecting to the database from container shell`}</h2>
    <p>{`In order to interact and execute statements, we need to connect to container shell and login to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cqlsh`}</code>{` service.`}</p>
    <p>{`Run the below commands post provisioning the container instance either through `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose`}</code>{` as briefed above.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Provision container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{`
NAME                                  COMMAND                  SERVICE             STATUS               PORTS
cassandratest-cassandra-1   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/bitnami/script…"`}</span>{`   cassandra           running `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`starting`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:7000-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7000`}</span>{`/tcp, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:9042-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9042`}</span>{`/tcp`}</code></pre></div>
    <p>{`Connect to container shell using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker exec`}</code>{` by passing the name as listed in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose ps`}</code>{` output. Running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ls -ltrs`}</code>{` should list something as below post connecting to the shell.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Connect to container shell`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it cassandratest-cassandra-1 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span>{`

I have no name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`@12102dc0991f:/$ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -ltrs
total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`76`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Sep `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` lib
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Sep `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`16`}</span>{`:53 var
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` 09:48 sbin
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` 09:48 bin
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` lrwxrwxrwx   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`44`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` 09:49 entrypoint.sh -`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /opt/bitnami/scripts/cassandra/entrypoint.sh
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` lrwxrwxrwx   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`37`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` 09:49 run.sh -`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` /opt/bitnami/scripts/cassandra/run.sh
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxrwxr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` 09:49 docker-entrypoint-initdb.d
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`23`}</span>{`:27 etc
`}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` drwxr-xr-x   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` root root `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Oct `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`23`}</span>{`:27 bitnami
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span></code></pre></div>
    <h2 {...{
      "id": "perform-database-operations"
    }}>{`Perform database operations`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Cassandra Query Language (CQL)`}</code>{` is the primary language for communicating with the cassandra database. Syntax is slightly to SQL.`}</p>
    <p>{`To start with, we need to create `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Keyspace`}</code>{` in Cassandra which is similar to a database in SQL.`}</p>
    <p>{`For the purpose of this article, we shall create sample database to manage `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Cycling Races`}</code>{` documented in `}<ExternalLink href="https://docs.datastax.com/en/dse/5.1/cql/examples/cycling/doc/cyclingks.html" mdxType="ExternalLink">{`DataStax CQL Documentation`}</ExternalLink>{`.`}</p>
    <p>{`Though Cassandra is NoSQL Database, We can model the domain by creating normalized tables and create foreign reference keys. `}</p>
    <p>{`Below is the high level entity diagram on the database structure that we shall create under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cycling`}</code>{` keyspace.`}</p>
    <div {...{
      "className": "mermaid",
      "data-processed": "true"
    }}><svg parentName="div" {...{
        "id": "mermaid-1685782129932",
        "width": "100%",
        "xmlns": "http://www.w3.org/2000/svg",
        "height": "465",
        "style": {
          "maxWidth": "403.703125px"
        },
        "viewBox": "0 0 403.703125 465"
      }}><style parentName="svg">{`#mermaid-1685782129932 {font-family:"trebuchet ms",verdana,arial,sans-serif;font-size:16px;fill:#333;}#mermaid-1685782129932 .error-icon{fill:#552222;}#mermaid-1685782129932 .error-text{fill:#552222;stroke:#552222;}#mermaid-1685782129932 .edge-thickness-normal{stroke-width:2px;}#mermaid-1685782129932 .edge-thickness-thick{stroke-width:3.5px;}#mermaid-1685782129932 .edge-pattern-solid{stroke-dasharray:0;}#mermaid-1685782129932 .edge-pattern-dashed{stroke-dasharray:3;}#mermaid-1685782129932 .edge-pattern-dotted{stroke-dasharray:2;}#mermaid-1685782129932 .marker{fill:#333333;stroke:#333333;}#mermaid-1685782129932 .marker.cross{stroke:#333333;}#mermaid-1685782129932 svg{font-family:"trebuchet ms",verdana,arial,sans-serif;font-size:16px;}#mermaid-1685782129932 .entityBox{fill:#ECECFF;stroke:#9370DB;}#mermaid-1685782129932 .attributeBoxOdd{fill:#ffffff;stroke:#9370DB;}#mermaid-1685782129932 .attributeBoxEven{fill:#f2f2f2;stroke:#9370DB;}#mermaid-1685782129932 .relationshipLabelBox{fill:hsl(80, 100%, 96.2745098039%);opacity:0.7;background-color:hsl(80, 100%, 96.2745098039%);}#mermaid-1685782129932 .relationshipLabelBox rect{opacity:0.5;}#mermaid-1685782129932 .relationshipLine{stroke:#333333;}#mermaid-1685782129932 .node rect{fill:cornflowerblue;}#mermaid-1685782129932 :root{--mermaid-font-family:"trebuchet ms",verdana,arial,sans-serif;}`}</style><g parentName="svg"></g><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ONLY_ONE_START",
            "refX": "0",
            "refY": "9",
            "markerWidth": "18",
            "markerHeight": "18",
            "orient": "auto"
          }}><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M9,0 L9,18 M15,0 L15,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ONLY_ONE_END",
            "refX": "18",
            "refY": "9",
            "markerWidth": "18",
            "markerHeight": "18",
            "orient": "auto"
          }}><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M3,0 L3,18 M9,0 L9,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ZERO_OR_ONE_START",
            "refX": "0",
            "refY": "9",
            "markerWidth": "30",
            "markerHeight": "18",
            "orient": "auto"
          }}><circle parentName="marker" {...{
              "stroke": "gray",
              "fill": "white",
              "cx": "21",
              "cy": "9",
              "r": "6"
            }}></circle><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M9,0 L9,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ZERO_OR_ONE_END",
            "refX": "30",
            "refY": "9",
            "markerWidth": "30",
            "markerHeight": "18",
            "orient": "auto"
          }}><circle parentName="marker" {...{
              "stroke": "gray",
              "fill": "white",
              "cx": "9",
              "cy": "9",
              "r": "6"
            }}></circle><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M21,0 L21,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ONE_OR_MORE_START",
            "refX": "18",
            "refY": "18",
            "markerWidth": "45",
            "markerHeight": "36",
            "orient": "auto"
          }}><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M0,18 Q 18,0 36,18 Q 18,36 0,18 M42,9 L42,27"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ONE_OR_MORE_END",
            "refX": "27",
            "refY": "18",
            "markerWidth": "45",
            "markerHeight": "36",
            "orient": "auto"
          }}><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M3,9 L3,27 M9,18 Q27,0 45,18 Q27,36 9,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ZERO_OR_MORE_START",
            "refX": "18",
            "refY": "18",
            "markerWidth": "57",
            "markerHeight": "36",
            "orient": "auto"
          }}><circle parentName="marker" {...{
              "stroke": "gray",
              "fill": "white",
              "cx": "48",
              "cy": "18",
              "r": "6"
            }}></circle><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M0,18 Q18,0 36,18 Q18,36 0,18"
            }}></path></marker></defs><defs parentName="svg"><marker parentName="defs" {...{
            "id": "ZERO_OR_MORE_END",
            "refX": "39",
            "refY": "18",
            "markerWidth": "57",
            "markerHeight": "36",
            "orient": "auto"
          }}><circle parentName="marker" {...{
              "stroke": "gray",
              "fill": "white",
              "cx": "9",
              "cy": "18",
              "r": "6"
            }}></circle><path parentName="marker" {...{
              "stroke": "gray",
              "fill": "none",
              "d": "M21,18 Q39,0 57,18 Q39,36 21,18"
            }}></path></marker></defs><path parentName="svg" {...{
          "className": "er relationshipLine",
          "d": "M185.92578125,95L185.92578125,103.33333333333333C185.92578125,111.66666666666667,185.92578125,128.33333333333334,185.92578125,145C185.92578125,161.66666666666666,185.92578125,178.33333333333334,185.92578125,186.66666666666666L185.92578125,195",
          "stroke": "gray",
          "fill": "none",
          "markerEnd": "url(#ZERO_OR_MORE_END)",
          "markerStart": "url(#ONE_OR_MORE_START)"
        }}></path><path parentName="svg" {...{
          "className": "er relationshipLine",
          "d": "M136.24330357142856,270L125.20275297619047,278.3333333333333C114.16220238095237,286.6666666666667,92.08110119047619,303.3333333333333,81.04055059523809,320C70,336.6666666666667,70,353.3333333333333,70,361.6666666666667L70,370",
          "stroke": "gray",
          "fill": "none",
          "strokeDashArray": ["8", "8"],
          "markerEnd": "url(#ONE_OR_MORE_END)",
          "markerStart": "url(#ONE_OR_MORE_START)"
        }}></path><path parentName="svg" {...{
          "className": "er relationshipLine",
          "d": "M235.60825892857144,270L246.64880952380955,278.3333333333333C257.68936011904765,286.6666666666667,279.7704613095238,303.3333333333333,290.8110119047619,320C301.8515625,336.6666666666667,301.8515625,353.3333333333333,301.8515625,361.6666666666667L301.8515625,370",
          "stroke": "gray",
          "fill": "none",
          "strokeDashArray": ["8", "8"],
          "markerEnd": "url(#ONE_OR_MORE_END)",
          "markerStart": "url(#ONE_OR_MORE_START)"
        }}></path><g parentName="svg" {...{
          "id": "cyclist_name",
          "transform": "translate(135.90234375,20 )"
        }}><rect parentName="g" {...{
            "className": "er entityBox",
            "fill": "honeydew",
            "fillOpacity": "100%",
            "stroke": "gray",
            "x": "0",
            "y": "0",
            "width": "100.046875",
            "height": "75"
          }}></rect><text parentName="g" {...{
            "className": "er entityLabel",
            "id": "entity-cyclist_name",
            "x": "0",
            "y": "0",
            "dominantBaseline": "middle",
            "textAnchor": "middle",
            "style": {
              "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
              "fontSize": "12px"
            },
            "transform": "translate(50.0234375,37.5)"
          }}>{`cyclist_name`}</text></g><g parentName="svg" {...{
          "id": "cyclist_races",
          "transform": "translate(135.92578125,195 )"
        }}><rect parentName="g" {...{
            "className": "er entityBox",
            "fill": "honeydew",
            "fillOpacity": "100%",
            "stroke": "gray",
            "x": "0",
            "y": "0",
            "width": "100",
            "height": "75"
          }}></rect><text parentName="g" {...{
            "className": "er entityLabel",
            "id": "entity-cyclist_races",
            "x": "0",
            "y": "0",
            "dominantBaseline": "middle",
            "textAnchor": "middle",
            "style": {
              "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
              "fontSize": "12px"
            },
            "transform": "translate(50,37.5)"
          }}>{`cyclist_races`}</text></g><g parentName="svg" {...{
          "id": "race_times",
          "transform": "translate(20,370 )"
        }}><rect parentName="g" {...{
            "className": "er entityBox",
            "fill": "honeydew",
            "fillOpacity": "100%",
            "stroke": "gray",
            "x": "0",
            "y": "0",
            "width": "100",
            "height": "75"
          }}></rect><text parentName="g" {...{
            "className": "er entityLabel",
            "id": "entity-race_times",
            "x": "0",
            "y": "0",
            "dominantBaseline": "middle",
            "textAnchor": "middle",
            "style": {
              "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
              "fontSize": "12px"
            },
            "transform": "translate(50,37.5)"
          }}>{`race_times`}</text></g><g parentName="svg" {...{
          "id": "rank_by_year_and_name",
          "transform": "translate(220,370 )"
        }}><rect parentName="g" {...{
            "className": "er entityBox",
            "fill": "honeydew",
            "fillOpacity": "100%",
            "stroke": "gray",
            "x": "0",
            "y": "0",
            "width": "163.703125",
            "height": "75"
          }}></rect><text parentName="g" {...{
            "className": "er entityLabel",
            "id": "entity-rank_by_year_and_name",
            "x": "0",
            "y": "0",
            "dominantBaseline": "middle",
            "textAnchor": "middle",
            "style": {
              "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
              "fontSize": "12px"
            },
            "transform": "translate(81.8515625,37.5)"
          }}>{`rank_by_year_and_name`}</text></g><rect parentName="svg" {...{
          "className": "er relationshipLabelBox",
          "x": "171.76953125",
          "y": "138",
          "width": "28.3125",
          "height": "14",
          "fill": "white",
          "fillOpacity": "85%"
        }}></rect><text parentName="svg" {...{
          "className": "er relationshipLabel",
          "id": "rel1",
          "x": "185.92578125",
          "y": "145",
          "textAnchor": "middle",
          "dominantBaseline": "middle",
          "style": {
            "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
            "fontSize": "12px"
          }
        }}>{`races`}</text><rect parentName="svg" {...{
          "className": "er relationshipLabelBox",
          "x": "54.527984619140625",
          "y": "304.0273742675781",
          "width": "66.875",
          "height": "14",
          "fill": "white",
          "fillOpacity": "85%"
        }}></rect><text parentName="svg" {...{
          "className": "er relationshipLabel",
          "id": "rel2",
          "x": "87.96548461914062",
          "y": "311.0273742675781",
          "textAnchor": "middle",
          "dominantBaseline": "middle",
          "style": {
            "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
            "fontSize": "12px"
          }
        }}>{`race-timings`}</text><rect parentName="svg" {...{
          "className": "er relationshipLabelBox",
          "x": "264.08917236328125",
          "y": "304.02740478515625",
          "width": "39.59375",
          "height": "14",
          "fill": "white",
          "fillOpacity": "85%"
        }}></rect><text parentName="svg" {...{
          "className": "er relationshipLabel",
          "id": "rel3",
          "x": "283.88604736328125",
          "y": "311.02740478515625",
          "textAnchor": "middle",
          "dominantBaseline": "middle",
          "style": {
            "fontFamily": "\"trebuchet ms\", verdana, arial, sans-serif",
            "fontSize": "12px"
          }
        }}>{`ranking`}</text></svg></div>
    <p>{`As observed, the database is to track cyclist’s ranking in one or more race’s that is conducted as part of an event.`}</p>
    <p>{`The model design includes couple of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`joins`}</code>{` to mimic relationships, but Cassandra doesn’t enforce this as there are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`No Join`}</code>{` queries. We need to create the data model in demoralized way ensuring there is no referential integrity and thus ensuring optimal performance they need when they have to do so many joins on years’ worth of data.`}</p>
    <p>{`To start creating objects, we need to connect to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cqlsh`}</code>{`, which is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CLI`}</code>{` for interacting with Cassandra using CQL.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Connect to Cqlsh`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ /opt/bitnami/cassandra/bin/cqlsh -u cassandra -p cassandra`}</code></pre></div>
    <p>{`Create keyspace and list them`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create keyspace`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`cassandra@cqlsh`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` CREATE KEYSPACE IF NOT EXISTS cycling
  WITH REPLICATION `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'class'`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'SimpleStrategy'`}</span>{`,
   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'replication_factor'`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cassandra@cqlsh`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` DESCRIBE keyspaces`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cycling  system_auth         system_schema  system_views
system   system_distributed  system_traces  system_virtual_schema`}</code></pre></div>
    <p>{`Switch to cycling keyspace to start creating tables, types or any other database objects`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Switch to cycling keyspace`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`cassandra@cqlsh`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` use cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
cassandra@cqlsh:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`Below is few create and insert statements for reference. Refer to `}<ExternalLink href="https://github.com/2much2learn/article-oct192021-cassandra-with-docker/tree/main/schema/cassandra" mdxType="ExternalLink">{`schema`}</ExternalLink>{` for complete set of queries.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Cyclist Names`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`DROP`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TABLE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`IF`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`EXISTS`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`CREATE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TABLE`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  id UUID `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`PRIMARY`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`KEY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  lastname `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  firstname `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  details_ map`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` details_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e7cd5752`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`bc0d`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4157`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`a80f`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7523`}</span>{`add8dbcd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'VAN DER BREGGEN'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Anna'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` {`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_age'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'35'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_bday'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'27/07/1980'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_nation'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'AUSTRALIA'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` details_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e7ae5cf3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`d358`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`d99`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`b900`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`85902`}</span>{`fda9bb0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FRAME'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alex'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` {`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_age'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'54'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_bday'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'27/07/1961'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_nation'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'ITALY'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` details_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`220844`}</span>{`bf`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4860`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`49`}</span>{`d6`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{`a4b`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`b5d3a79cbfb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'TIRALONGO'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Paolo'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` {`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_age'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'23'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_bday'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'27/07/1992'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_nation'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'CANADA'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` details_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`ab09bec`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`e68e`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`48`}</span>{`d9`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`a5f8`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`97`}</span>{`e6fb4c9b47`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'KRUIKSWIJK'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Steven'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` {`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_age'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'23'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_bday'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'27/07/1992'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_nation'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'GERMANY'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` details_`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fb372533`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`eb95`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`bb4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`8685`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{`ef61e994caa`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'MATTHEWS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Michael'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` {`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_age'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'28'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_bday'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'27/07/1987'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'details_nation'`}</span>{`:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'NETHERLANDS'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Cyclist Races`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`DROP`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TABLE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`IF`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`EXISTS`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DROP`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TYPE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`IF`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`EXISTS`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`race`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`CREATE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TYPE`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`race `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
   race_title `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   race_date `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   race_time `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`time`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`CREATE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TABLE`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  id UUID `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`PRIMARY`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`KEY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  lastname `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  firstname `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  races list`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`FROZEN `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`race`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`races`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
   e7ae5cf3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`d358`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`d99`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`b900`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`85902`}</span>{`fda9bb0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` { race_title:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'17th Santos Tour Down Under Aalburg'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` race_date:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2017-04-14'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'07:00:00'`}</span>{` }`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
     { race_title:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'17th Santos Tour Down Under Gelderland'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` race_date:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2017-04-14'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'08:00:00'`}</span>{` } `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INSERT`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`INTO`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` firstname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` races`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`VALUES`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
	e7cd5752`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`bc0d`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4157`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`a80f`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7523`}</span>{`add8dbcd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'VAN DER BREGGEN'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Anna'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` {race_title:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Festival Luxembourgeois du cyclisme feminin Elsy Jacobs - Prologue - Garnich > Garnich'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_date:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2017-04-14'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'08:00:00'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		{race_title:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Festival Luxembourgeois du cyclisme feminin Elsy Jacobs - Stage 2 - Garnich > Garnich'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_date:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2017-04-14'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'06:00:00'`}</span>{`}`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
		{race_title:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Festival Luxembourgeois du cyclisme feminin Elsy Jacobs - Stage 3 - Mamer > Mamer'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_date:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'2017-04-14'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'10:00:00'`}</span>{`} `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`UPDATE`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races
	`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`SET`}</span>{` 
    lastname `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FRAME'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    firstname `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Alex'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    races`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` { race_time:`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'06:00:00'`}</span>{`}
		`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WHERE`}</span>{` id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` e7ae5cf3`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`d358`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{`d99`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`b900`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`85902`}</span>{`fda9bb0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The above queries should create `}<strong parentName="p">{`Two`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Tables`}</code>{` and `}<strong parentName="p">{`One`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`User Defined Type`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Database objects`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}>{`cassandra`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@cqlsh`}</span>{`:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DESCRIBE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TYPES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

race

cassandra`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@cqlsh`}</span>{`:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`DESCRIBE`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`TABLES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cyclist_name  cyclist_races`}</code></pre></div>
    <p>{`Play around with below `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Select`}</code>{` query statements`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Select statements`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sql"
    }}><pre parentName="div" {...{
        "className": "language-sql"
      }}><code parentName="pre" {...{
          "className": "language-sql"
        }}>{`
cassandra`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@cqlsh`}</span>{`:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`SELECT`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cassandra`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@cqlsh`}</span>{`:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`SELECT`}</span>{` lastname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` races `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`FROM`}</span>{` cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cyclist_races `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WHERE`}</span>{` id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` e7cd5752`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`bc0d`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4157`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`a80f`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7523`}</span>{`add8dbcd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "connecting-to-the-database-from-gui-client"
    }}>{`Connecting to the database from GUI Client`}</h2>
    <p>{`I could find `}<ExternalLink href="https://razorsql.com/docs/cassandra_database_browser.html" mdxType="ExternalLink">{`RazorSQL Cassandra Database Browser`}</ExternalLink>{` as an ideal option if there is a necessity to explore Cassandra database using GUI Client.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`RazorSQL`}</code>{` is a licensed product which provides `}<em parentName="p">{`30 Days`}</em>{` trail access. `}</p>
    <p>{`With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cqlsh`}</code>{` and wide options on Cassandra drivers available at hand, We wouldn’t require a GUI client for basic usage.`}</p>
    <h2 {...{
      "id": "initializing-database-during-container-provisioning"
    }}>{`Initializing Database during container provisioning`}</h2>
    <p>{`There would be a need for all of us to initialize the database as part of container provisioning for quick development & testing setup.`}</p>
    <p>{`A minor change in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker-compose.yml`}</code>{` to configure the init scripts defined under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`volumes`}</code>{` will do the trick.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`docker-compose.yml`}</div>
    <div {...{
      "className": "gatsby-highlight has-highlighted-lines",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'3'`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`services`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`cassandra`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` docker.io/bitnami/cassandra`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`latest
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'7000:7000'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'9042:9042'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "gatsby-highlight-code-line"
          }}>{`      `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{` ./schema/cassandra`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{`/docker`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`entrypoint`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`-`}</span>{`initdb.d`}</span>{`      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'cassandra_data:/bitnami'`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`healthcheck`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`test`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CMD"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/opt/bitnami/cassandra/bin/cqlsh"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-u cassandra"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-p cassandra"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-e \\"describe keyspaces\\""`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`interval`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 15s
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`timeout`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 10s
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retries`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`environment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_SEEDS=cassandra
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_PASSWORD_SEEDER=yes
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` CASSANDRA_PASSWORD=cassandra
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`cassandra_data`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`driver`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` local`}</code></pre></div>
    <p>{`As highlighted, couple of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cql`}</code>{` files are created in order under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`schema/cassandra`}</code>{` folder. During the container startup, these scripts will be executed in sequence and the database objects will be created for usage.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "355px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/89740238c282c8cef3d5487a27655e33/526ee/repo-structure.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "52.760736196319016%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABU0lEQVQoz42SWW7DIBRFvZN4YjKEgAGHyTbYjtP9r6jK0LSV2qrS/QKdh+55FJxjiCqEawBLhOtnUAVRhUnzefJTCgDLcXbXt21ZRj2I7ZLXdfbBhMl5ryEqf4VJU7Tg4LxZ1ynl6MPgnEK4xqQhXfv3sxjXBSYNJm1HAekaiCoAy0eL/6QQkoV4Tjnu16XvGWOInwi+K4D35n/MKiCqKENHTrQRcbSXPW97NoM4csyOiDLIGASw/KbzBSNcP8bfrknrvJlmv27zfs3O6znHEE1HwUl0mPwEv0IotE7F0YbRpckqxbc9pxSs08s2dV3zO3wzDKxT0+ydNz6el2V0Xodoh0GcRAfAoW0PXys8YUxu6gkFzg8px3Wbw2hTCnMKLgwhnoWkZ6et7XvFlebobrH4+EbNY7dCMtmzXh2V4lIypbmQtNc3wAxSm5OQTEh6X0HzDnRDco1cOcJDAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Repo structure",
              "title": "Repo structure",
              "src": "/static/89740238c282c8cef3d5487a27655e33/526ee/repo-structure.png",
              "srcSet": ["/static/89740238c282c8cef3d5487a27655e33/222b7/repo-structure.png 163w", "/static/89740238c282c8cef3d5487a27655e33/ff46a/repo-structure.png 325w", "/static/89740238c282c8cef3d5487a27655e33/526ee/repo-structure.png 355w"],
              "sizes": "(max-width: 355px) 100vw, 355px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Repo structure`}</figcaption>{`
  `}</figure></p>
    <p>{`Clone the `}<ExternalLink href="https://github.com/2much2learn/article-mar05-setting-up-cassandra-with-docker-on-windows" mdxType="ExternalLink">{`Repo`}</ExternalLink>{` and run the below command to orchestrate container creation which will initialize the configured schema.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Database initialization`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` clone https://github.com/2much2learn/article-oct192021-cassandra-with-docker.git

λ `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` article-oct192021-cassandra-with-docker

λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker-compose`}</span>{` up -d`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Connect to shell`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`λ `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it article-oct192021-cassandra-with-docker-cassandra-1 `}<span parentName="code" {...{
            "className": "token function"
          }}>{`bash`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Connect to cqlsh and verify initialized database objects`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`I have no name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`@d2fc96819e4b:/$ /opt/bitnami/cassandra/bin/cqlsh -u cassandra -p cassandra

cassandra@cqlsh`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` DESCRIBE keyspaces`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cycling  system_auth         system_schema  system_views
system   system_distributed  system_traces  system_virtual_schema

cassandra@cqlsh`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` use cycling`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cassandra@cqlsh:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
cassandra@cqlsh:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` DESCRIBE tables`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

cyclist_name  cyclist_races  race_times  rank_by_year_and_name

cassandra@cqlsh:cycling`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` DESCRIBE types`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

race`}</code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`This article is more of a little hack on making our application development faster by quickly setting up database of our choice without worrying on annoying steps on installing and configuring the database. As Cassandra is one of the most commonly used NoSql databases, having it provisioned with Docker and connecting to our local application is quite simple and easy.`}</p>
    <GithubRepo href="https://github.com/2much2learn/article-oct192021-cassandra-with-docker" mdxType="GithubRepo">setting-up-cassandra-with-docker-on-windows</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      