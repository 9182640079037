import React from "react"

import SVG from "~/components/Svg/Svg"
import Ad480x60 from "~/components/AdContainer/Ad480x60"

import Seperator from "~/utils/mdx/components/Seperator"
import Todo from "~/utils/mdx/components/Todo"
import ExternalLink from "~/utils/mdx/components/ExternalLink"
import ExternalLinksListContainer from "~/utils/mdx/components/ExternalLinksListContainer"
import GithubRepo from "~/utils/mdx/components/GithubRepo"
import Collapsable from "~/utils/mdx/components/Collapsable"
import ArticleInlineAd from "~/utils/mdx/components/ArticleInlineAd"

// Include all components that will be parsed by MDX as React components here.
// Any React component you'd like to allow your editors to use should be placed here.
export const MDX_SHORTCODES = {
  Ad480x60: props => <Ad480x60/>,
  Seperator: props => <Seperator/>,
  Todo: props => <Todo  {...props}/>,
  Collapsable: props => <Collapsable {...props}/>,
  ExternalLink: props => <ExternalLink  {...props}/>,
  ExternalLinksListContainer: props => <ExternalLinksListContainer {...props}/>,
  GithubRepo: props => <GithubRepo {...props}/>,
  ArticleInlineAdRepo: props => <ArticleInlineAd {...props}/>
}

// Include any tags you'd like to replace with React components
export const MDX_COMPONENTS = {
  svg: props => <SVG {...props} />,
}
