import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"

import Author from "../Author"
import BlogPostsWrapper, { SecTitle } from "./style"

type PostsProps = {}

const Posts: React.FunctionComponent<PostsProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: {
            templateKey: {eq: "article"},
            published: {eq: true}
          }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              author {
                order
                alias
                name
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 210
                      height: 210
                      quality: 100
                    )
                  }
                }
                jobTitle
                description
                social {
                  facebook
                  github
                  instagram
                  linkedin
                  twitter
                }
              }              
            }
          }
        }
      }
      authors: allMdx(
        filter: {
          frontmatter: {
            templateKey: {eq: "article"},
            published: {eq: true}
          }
        }
        sort: {order: ASC, fields: [frontmatter___author___order]}
      ) {
        group(field: frontmatter___author___alias) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  const edges = _.uniqBy(Data.allMdx.edges, 'node.frontmatter.author.alias');
  const authors = Data.authors.group

  return (
    <BlogPostsWrapper>
      <SecTitle>Authors</SecTitle>
      {edges.map((edge: any, i) => {
        const author = edge.node.frontmatter.author 
        const authorFromArray = _.find(authors, ['fieldValue', author.alias]);

        //console.log("===> authors :: author :: ", author, "===> authorFromArray :: ", authorFromArray);

        return (
          <Author key={i} {...author} totalPosts={authorFromArray.totalCount}/>
        )
      })}
    </BlogPostsWrapper>
  )
}

export default Posts
