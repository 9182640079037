import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": false,
  "author": "meenakshivemuri",
  "title": "Technology companies can do more",
  "date": "2019-03-05T22:12:03.284Z",
  "updated": "2019-03-06T23:46:37.121Z",
  "category": "microservice",
  "tags": ["unity", "hooks", "react"],
  "cover": "./preview.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Given the discourse around this issue, it can be easy to either overestimate the scope of this problem, or discount solving it as irrelevant to the libraries’ mission. Recent studies have shown that the scale and impact of fake news is less significant than much of the initial reporting would suggest. While many people get their news from social media, they do not always consider it their primary source of information (Illing, 2017). Moreover, the basic trends that drive fake news include an increasingly partisan media culture that predates social media (Mittell, 2016). Perhaps most importantly, there is evidence to suggest that when people are presented with “inoculating” messages beforehand, for example information about the scientific consensus or information about the general strategies used in misinformation campaigns, it made a significant positive difference in how they interpreted subsequent information even when it taps into their preexisting identities (Cook et al., 2017; van der Linden et al., 2017). That is to say, the rise of fake news, while a serious problem, does not signal a general inability for people to identify and process facts or that there are no strategies that can effectively counteract these tendencies. Rather it signals something important about how emotions and identities, particularly when exploited by technology platforms, can influence our understanding of new information. Moreover, outside of those emotionally driven, identity-based incentives, there is less of a reason for people to use technology to generate purposefully misleading information.`}</p>
    <p>{`Yet, just as the technology companies’ first impulse was to ignore their newfound responsibilities as media companies, libraries too must begin to re-think their obligations in light of the ways people are actually using information. Already, both librarians and other stakeholders are working to reconfigure information literacy instruction. As we look to the future, the definition of purposefully fake news used at the outset of this column may be less relevant, as new variants of these themes complicate the picture. There are many factors that can lead to patrons being misled (both purposefully and accidently) by information sources. Efforts to update information literacy are beyond the scope of this column, but it will be an ongoing effort that will continue to evolve as the profession grapples with new research – both research into how people interpret information as well as how various actors attempt to deceive them. As poll numbers demonstrate, these trends are occurring even as people’s trust in institutions diminishes. While trust in libraries remains relatively high, the overall trend is worrisome for institutions of all kinds.`}</p>
    <p>{`What a deep understanding of technology can do for this effort is highlight the systemic forces that allow misinformation to spread. This includes the incentives behind socially shared news, which are, in turn, built on human-created algorithms to harness basic human impulses. Across the board, people are, and always have been, bad at recognizing and accepting information that goes against their preconceptions. As psychologist Jonathan Haidt suggests, in many arenas, we may believe we are rational scientists, coldly evaluating information, but in reality, we operate more like press secretaries, spinning available facts to confirm our preexisting beliefs (Haidt, 2013). It is, therefore, valuable and important work to provide updated information literacy practices to library patrons. It has the potential to change their lives and help create a better society. Yet it is likely not sufficient in and of itself, simply because of the limited scale, and, perhaps, more importantly, the inability to reach people who most need this assistance.`}</p>
    <p>{`There are no easy answers for how to meet this need, but understanding the technology trends in action suggests part of the path forward. It suggests that information literacy efforts must help users to not only identify reliable sources but also recognize and take into account how their own tendencies can mislead them. That to be effective, information literacy must spread beyond people who desire to be active participants, and instead be built into widespread tools. This will require partnerships that connect interested individuals, libraries and technology companies. In short, it will be an ongoing challenge.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      