import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "~/components/layout"
import PostCard from "~/components/PostCard/postCard"
import SEO from "~/components/seo"
import { TagPostsWrapper, TagPageHeading, TagName } from "./templates.style"
import _ from 'lodash'
import Categories from '~/utils/CategoryConstants'

const Category = ({ pageContext, data }: any) => {
  const { category } = pageContext
  const { edges, totalCount } = data.allMdx
  const categoryInfo =  _.get(Categories, category)
  return (
    <Layout>
      <SEO title={categoryInfo.name} description={`A collection of ${totalCount} post`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{categoryInfo.name}</TagName>
          {`A collection of ${totalCount} post`}
        </TagPageHeading>
        {edges.map(({ node, index }: any) => (
          <PostCard
            key={node.fields.slug}
            title={node.frontmatter.title}
            url={node.fields.slug}
            description={node.frontmatter.description || node.excerpt}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query($alias: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { 
          category: {eq: $alias}
          published: {eq: true}
        } 
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMM YYYY [</span>]")
            title
            category
            tags
            description
          }
        }
      }
    }
  }
`
