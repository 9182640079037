import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "article",
  "published": true,
  "displayComments": true,
  "showAuthorInfo": true,
  "showSideBar": false,
  "author": "narramadan",
  "title": "Setting up PostgreSQL with Docker",
  "description": "A detailed step by step guide on Setting up PostgreSQL with Docker.",
  "date": "2020-03-09T15:46:37.121Z",
  "updated": "2020-03-09T15:46:37.121Z",
  "cover": "../../../images/blog-banners/setup-banner-1200x690.png",
  "category": "setup",
  "tags": ["PostgreSQL", "Docker", "Containerization"],
  "keywords": ["Setting up PostgreSQL with Docker", "PostgreSQL with Docker", "Database with Docker", "Create PostgreSQL database with Docker", "Migrate PostgreSQL from Docker", "Provision PostgreSQL database with Docker", "PostgreSQL on Windows 10", "PostgreSQL", "Windows 10", "Docker", "narra.madan", "narramadan", "narra.madan@outlook.com", "Madan Narra", "Narra Madan"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GithubRepo = makeShortcode("GithubRepo");
const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <GithubRepo href="https://github.com/2much2learn/article-mar05-setting-up-postgressql-with-docker-on-windows-10" mdxType="GithubRepo">setting-up-postgressql-with-docker-on-windows-10</GithubRepo>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`As part of any application development, we definitely need a database. There might be different use cases which need different databases. Having them all installed on our machine might not be an ideal scenario. In the world of containers, this would ease us to provision the choice of database easily for testing purpose without actually installing them. `}</p>
    <p>{`In this article we shall provision PostgreSQL docker container and go through steps on using it.`}</p>
    <h2 {...{
      "id": "why-use-postgresql"
    }}>{`Why use PostgreSQL?`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`PostgreSQL`}</code>{` is a powerful, open source `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`object-relational`}</code>{` database system that uses and extends the SQL language combined with many features that safely store and scale the most complicated data workloads.`}</p>
    <p>{`A fundamental characteristic of an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`object-relational`}</code>{` database is support for user-defined objects and their behaviours including `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data types`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`functions`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`operators`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`domains`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`indexes`}</code>{`. This makes PostgreSQL extremely flexible and robust. Among other things, complex data structures can be created, stored and retrieved.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`PostgreSQL`}</code>{` comes with many features aimed to help developers build applications, administrators to protect data integrity and build fault-tolerant environments, and help you manage your data no matter how big or small the dataset. In addition to being free and open source, PostgreSQL is highly extensible. For example, you can define your own data types, build out custom functions, even write code from different programming languages without recompiling your database.`}</p>
    <h2 {...{
      "id": "what-are-we-going-to-do"
    }}>{`What are we going to do?`}</h2>
    <p>{`In this article, we shall go through how we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker`}</code>{` to explore `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PostgreSQL`}</code>{`. We shall run series of commands with Docker that is installed on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows 10`}</code>{`. Below are series of things we will be doing:`}</p>
    <ul>
      <li parentName="ul">{`Create database`}</li>
      <li parentName="ul">{`Connecting to the database from container shell`}</li>
      <li parentName="ul">{`Connecting to the database from SQL Client`}</li>
      <li parentName="ul">{`Import Schema & Data when provisioning new database`}</li>
      <li parentName="ul">{`Stopping and Starting container`}</li>
      <li parentName="ul">{`Perform database operations`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <p>{`Having Docker installed on your machine is necessary to try out provisioning PostgreSQL container.`}</p>
    <h3 {...{
      "id": "install-docker-on-windows-10-home-edition"
    }}>{`Install Docker on Windows 10 Home Edition`}</h3>
    <p>{`As `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Desktop`}</code>{` does not support `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Windows 10 Home Edition`}</code>{`, we need to install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Docker Toolbox for Windows`}</code>{`. Follow the detailed steps provided `}<ExternalLink href="https://docs.docker.com/v17.12/toolbox/toolbox_install_windows/" mdxType="ExternalLink">{`here`}</ExternalLink>{` to get docker toolbox installed and configured.`}</p>
    <p>{`If everything is installed and configured as suggested, running the below command should list the version of docker.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Docker Version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` -v

Docker version `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19.03`}</span>{`.1, build 74b1e89e8a`}</code></pre></div>
    <h3 {...{
      "id": "configure-port-forwarding-rule-on-the-default-virtualbox-image"
    }}>{`Configure Port Forwarding Rule on the default VirtualBox image`}</h3>
    <p>{`If we need to connect to the provisioned database from SQL client installed on Windows 10, we need to configure `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`port forwarding`}</code>{` for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`VirtualBox`}</code>{` image that is created as part of the Docker setup on Windows 10 Home Edition. Follow the steps to configure port forwarding for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ssh`}</code>{` & `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`port 5432`}</code>{` as below:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Right Click on the default image and choose `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Settings`}</code></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Choose `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Network`}</code>{` > `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Adapter1`}</code>{` > expand `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Advanced`}</code>{` > click `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Port Forwarding`}</code></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Configure `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`Port Forwarding`}</code>{` rules as below`}</p>
      </li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8a4090aa35367e1742e2ea6cc64424a0/31493/VirtualBox_portforwarding.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "22.085889570552148%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAvElEQVQY01VNywrCQBDrT/tXXvTgR4gHERQ8FG23Dx+4tPtqd7fdyAyoGAgkTCbJRH1H27SoRIWyFOi6Hs45GGNh7Y8fjzgwfzcHrTXrqqqRkRi9R4wR8iXR9z1rojGGxygjpYRSGptdjvU2h1YKj/uTy9rmhrK5YH/cIhNCcAkhpfQlwRoDpRR70nYYsViesVhdkdLMGRolusHgdDwgowfCPM9/pJIQArz37OmJPIKj6b/MNE0IIaIoSrwByI4x8CanqnwAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Configure VirtualBox Port forwarding",
              "title": "Configure VirtualBox Port forwarding",
              "src": "/static/8a4090aa35367e1742e2ea6cc64424a0/a6d36/VirtualBox_portforwarding.png",
              "srcSet": ["/static/8a4090aa35367e1742e2ea6cc64424a0/222b7/VirtualBox_portforwarding.png 163w", "/static/8a4090aa35367e1742e2ea6cc64424a0/ff46a/VirtualBox_portforwarding.png 325w", "/static/8a4090aa35367e1742e2ea6cc64424a0/a6d36/VirtualBox_portforwarding.png 650w", "/static/8a4090aa35367e1742e2ea6cc64424a0/31493/VirtualBox_portforwarding.png 664w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Configure VirtualBox Port forwarding`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "provision-postgresql-container"
    }}>{`Provision PostgreSQL Container`}</h2>
    <p>{`Before provisioning PostgreSQL container, we need to create a data container with a mounted volume which will be used to store the database that we create. Execute the below command to create a data container.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create Volume`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:/`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` create -v /article_postgres_docker --name PostgresData alpine`}</code></pre></div>
    <p>{`Executing the below command with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{` will pull the image and start the container with name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pgdocker`}</code>{`. PostgreSQL stores its data in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/var/lib/postgresql/data`}</code>{`, so we are mounting the created data container volume with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--volume-from`}</code>{` flag. Also as seen we are exposing port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5432`}</code>{` (the PostgreSQL default) and running the container in detached (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-d`}</code>{`) mode (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background`}</code>{`). Password to be used for the database is configured with the environment variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`POSTGRES_PASSWORD`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start Docker Container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{`:5432 --name pgdocker -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`POSTGRES_PASSWORD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`password -d --volumes-from PostgresData postgres`}</code></pre></div>
    <p>{`Options passed:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--detach, -d`}</code>{` - Run container in background and print container ID`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--publish, -p`}</code>{` - Publish a container’s port(s) to the host`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--name`}</code>{` - Assign a name to the container`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--volumes-from`}</code>{` - Mount volumes from the specified container(s)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--env, -e`}</code>{` - Set environment variables`}</li>
    </ul>
    <p>{`Running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker ps -a`}</code>{` should list all containers. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker ps`}</code>{` just shows containers running and not shows the volume that is created.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List all containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{` -a

CONTAINER ID        IMAGE               COMMAND                  CREATED             STATUS              PORTS                    NAMES
1f5ae3781326        postgres            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"docker-entrypoint.s…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` minutes ago       Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` minutes        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:5432-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{`/tcp   pgdocker
64773150d120        alpine              `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/bin/sh"`}</span>{`                `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span>{` minutes ago       Created                                      PostgresData`}</code></pre></div>
    <p>{`Options passed:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`--all, -a`}</code>{` - Show all containers (default shows just running)`}</li>
    </ul>
    <h2 {...{
      "id": "create-database"
    }}>{`Create database`}</h2>
    <p>{`Run the below command with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker exec`}</code>{` to create `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`testdb`}</code>{` database with which we can play around.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create database`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` pgdocker psql -U postgres -c `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"create database testdb"`}</span></code></pre></div>
    <h2 {...{
      "id": "connecting-to-the-database-from-container-shell"
    }}>{`Connecting to the database from container shell`}</h2>
    <p>{`To start interacting with the database, we can use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker exec`}</code>{` command to launch interactive shell running inside the container. Options `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-i`}</code>{` allows us to make the container to wait for interaction from host but actual interaction from the console (terminal) is possible after we “allocate tty driver” with flag `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-t`}</code>{`. This is an easy alternative to login to PostgreSQL and start exploring it.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Launch shell`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it pgdocker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span></code></pre></div>
    <p>{`Once logged into shell, access `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`psql`}</code>{` terminal and try out few commands like listing databases, create table, insert data etc.,`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`psql terminal`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# psql -U postgres`}</span>{`
psql `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Debian `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{`-2.pgdg100+1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`))`}</span>{`
Type `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"help"`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` help.`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List Databases`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\l`}</span>{`
                                 List of databases
   Name    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Owner   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Encoding `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Collate   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`   Ctype    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`   Access privileges
-----------+----------+----------+------------+------------+-----------------------
 postgres  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
 template0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`c/postgres          +
           `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`CTc/postgres
 template1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`c/postgres          +
           `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`CTc/postgres
 testdb      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Display version`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# select version();`}</span>{`
                                                     version
------------------------------------------------------------------------------------------------------------------
 PostgreSQL `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Debian `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{`-2.pgdg100+1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` on x86_64-pc-linux-gnu, compiled by gcc `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Debian `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8.3`}</span>{`.0-6`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8.3`}</span>{`.0, `}<span parentName="code" {...{
            "className": "token number"
          }}>{`64`}</span>{`-bit
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` row`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Switch to testdb database`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\c testdb`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create Table`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`testdb`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# CREATE TABLE tbl_keyvalue (id int, key varchar(255), value varchar(255));`}</span>{`
CREATE TABLE`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Insert data`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`testdb`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# INSERT INTO tbl_keyvalue (id,key, value) VALUES (1, 'key1', 'value1');`}</span>{`
INSERT `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List tables`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`testdb`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\dt`}</span>{`
-- Lists all tables `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` the current database.
            List of relations
 Schema `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`     Name     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Type  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Owner
--------+--------------+-------+----------
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` tbl_keyvalue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` row`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2 {...{
      "id": "connecting-to-the-database-from-sql-client"
    }}>{`Connecting to the database from SQL Client`}</h2>
    <p>{`As we are exposing the default TCP port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5432`}</code>{` from provisioned PostgreSQL container, we can connect to it using any standard SQL client. For this article we shall use `}<ExternalLink href="https://www.heidisql.com/" mdxType="ExternalLink">{`HeidiSQL`}</ExternalLink>{`, which lets us to connect to most of the database systems MariaDB, MySQL, Microsoft SQL or PostgreSQL.`}</p>
    <p>{`Install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HeidiSQL`}</code>{` and start the application. This should open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Session manager`}</code>{`. Configure database connectivity details and open the session.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/a27400bfb394d1558e5761b90b6402ce/f6386/heidi-sql-session-manager.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "60.122699386503065%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwklEQVQoz31Ti27bMAz0/3/S/mAo0C1YE9urgzTzM7JsPfyUE/sGMnGWYEUJHCha1pHiUV74/oo8S5GlGYQQGIYBXdeh73u0bYu27e4x43F9A/1nrYUxBl5RCKT5CVJKuGnC+Xxm9MMApTWkrKBlgTz5g1iU+JWdkMUxjLVomuY/eLJWSCsLoxTYloXdOI6opORE52mCLEu8/viJN9/H7zBElmXI8/yONfa01lhtWRYGmRtHiJOAtQ3HlP3j8IGh7zkZgdpDoDVdvSzLf4RMdvNXQgdrDGh/miYmpAqon9QzInDOPZHS9yfCRz+ODo9Gh0RRoMgLJHHMAlKv11sRKPEXhCPquuYqyKjaKIpYTarS3kSh9QpW+SvCIs/5EBkJFAQhizTPMy6XC4OqoqTkaaSee/ggChF2XX+PtVJI4oTntaoq1LVCXdXcCmDBPC8YHF/Z4DOjjKUQULdxouukSYq2aWCtucJYFoKUb9oOWanhUTZqLo+Cc/fyqToiU0rf9/kV9QPa/urXl3LtYYtKlvDCMEQQBNjv99hsNjgcDthtd+zX50TEtVKwRmN3POHb9x1ewgTvUQTf93E8HrHdbjn+C8VEmPWV9P45AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "HeidiSQL Session Manager",
              "title": "HeidiSQL Session Manager",
              "src": "/static/a27400bfb394d1558e5761b90b6402ce/a6d36/heidi-sql-session-manager.png",
              "srcSet": ["/static/a27400bfb394d1558e5761b90b6402ce/222b7/heidi-sql-session-manager.png 163w", "/static/a27400bfb394d1558e5761b90b6402ce/ff46a/heidi-sql-session-manager.png 325w", "/static/a27400bfb394d1558e5761b90b6402ce/a6d36/heidi-sql-session-manager.png 650w", "/static/a27400bfb394d1558e5761b90b6402ce/f6386/heidi-sql-session-manager.png 686w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`HeidiSQL Session Manager`}</figcaption>{`
  `}</figure></p>
    <p>{`This should open up the application with tables listed under public section in the connected database.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "650px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f9abf0818cdf42c5e7da41674ebaec62/96e86/heidisql-db-explorer.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "36.809815950920246%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABSElEQVQoz22QWW7cMBBEdf8r5RZJPhxnRjPaYolDcsRNErXwBaIdAwlSQKE+mqyurqKurtzqjqbrKW8Xqq6h1x6pFEIMKKXxVqK1QhpL/9AoPfIcDdO8MC8LMUbmec4syntDWTVIPTI8euRT83QTQgp+VA1VVVOXJZefVy5tx/V25+XeUlYdZdXyNgwMg8A5RwiBYgkrb3XLMgf+hbGOaZoZesE4mvzBOs/oPN57rLXZ6NTlI2nxSwa+vdyQZsKv4GNCTYm7nPKj4zhIKeUFf/R/OGcni4uKfG815WOmttC5xNdH4surz13Ny4RQAn12Gd5TjeOY03ofCD7grMvp8sn8tTV98MSBkoptW9HGI6R5r8GYbGqM5ekESkuMtRwpsa0rxb4fn3FP76zAtu25p33bifuCiz4bruuaa1jjyrL7fEHc42cdvwHz2RnQL3ErXgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "HeidiSQL database explorer",
              "title": "HeidiSQL database explorer",
              "src": "/static/f9abf0818cdf42c5e7da41674ebaec62/a6d36/heidisql-db-explorer.png",
              "srcSet": ["/static/f9abf0818cdf42c5e7da41674ebaec62/222b7/heidisql-db-explorer.png 163w", "/static/f9abf0818cdf42c5e7da41674ebaec62/ff46a/heidisql-db-explorer.png 325w", "/static/f9abf0818cdf42c5e7da41674ebaec62/a6d36/heidisql-db-explorer.png 650w", "/static/f9abf0818cdf42c5e7da41674ebaec62/e548f/heidisql-db-explorer.png 975w", "/static/f9abf0818cdf42c5e7da41674ebaec62/96e86/heidisql-db-explorer.png 1089w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`HeidiSQL database explorer`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "stopping-and-starting-container"
    }}>{`Stopping and Starting container`}</h2>
    <p>{`As we mounted data container volume to persist PostgreSQL data, we will not loose data even after stopping and starting the database.`}</p>
    <p>{`Execute the below commands to stop the container and start the container`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`List running containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ps`}</span>{` -a
CONTAINER ID        IMAGE               COMMAND                  CREATED             STATUS              PORTS                    NAMES
9e4dab542448        postgres            `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"docker-entrypoint.s…"`}</span>{`   `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` hours ago         Up `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` hours          `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.0`}</span>{`.0.0:5432-`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{`/tcp   pgdocker
d29dc7a359c8        alpine              `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/bin/sh"`}</span>{`                `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` hours ago         Created                                      PostgresData`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop Container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` stop 9e4dab542448`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start Container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` start 9e4dab542448`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Launch psql terminal`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it pgdocker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# psql -U postgres testdb`}</span>{`

`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`testdb`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\dt`}</span>{`
            List of relations
 Schema `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`     Name     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Type  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Owner
--------+--------------+-------+----------
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` tbl_keyvalue `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` row`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2 {...{
      "id": "discarding-the-container"
    }}>{`Discarding the container`}</h2>
    <p>{`Execute the below commands to stop and remove database container and its associated volume.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Stop container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` stop pgdocker`}</code></pre></div>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Remove Containers`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`rm`}</span>{` pgdocker PostgresData`}</code></pre></div>
    <h2 {...{
      "id": "import-schema--data-when-provisioning-new-database"
    }}>{`Import Schema & Data when provisioning new database`}</h2>
    <p>{`Generally whenever we are working on database for testing, we need to provision it with some base schema and test data. To try this out, we shall use PostgreSQL sample database that is available from one of the Github projects - `}<ExternalLink href="https://github.com/devrimgunduz/pagila" mdxType="ExternalLink">{`Pagila`}</ExternalLink>{` which is a port of the `}<ExternalLink href="https://dev.mysql.com/doc/sakila/en/sakila-introduction.html" mdxType="ExternalLink">{`Sakila`}</ExternalLink>{` example database available for MySQL.`}</p>
    <p>{`Clone github `}<ExternalLink href="https://github.com/2much2learn/article-mar05-setting-up-postgressql-with-docker-on-windows-10" mdxType="ExternalLink">{`repository`}</ExternalLink>{` to get the schema and data files to tryout importing them to the provisioned PostgreSQL container.`}</p>
    <p>{`To bootstrap the database with cloned sql files, we need to create shell script which can be executed upon startup. Create `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bootstrap.sh`}</code>{` file as below which will create database with the provided `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DB_NAME`}</code>{` environment variable and import the schema and initial data using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`psql`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`bootstrap.sh`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "docker"
    }}><pre parentName="div" {...{
        "className": "language-docker"
      }}><code parentName="pre" {...{
          "className": "language-docker"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#!/bin/bash`}</span>{`
set -e

POSTGRES="psql --username \${POSTGRES_USER}"

echo "Creating database: \${DB_NAME}"

$POSTGRES <<EOSQL
CREATE DATABASE \${DB_NAME} OWNER \${POSTGRES_USER};
EOSQL

echo "Creating schema..."
psql -d \${DB_NAME} -a -U\${POSTGRES_USER} -f /pagila-schema.sql

echo "Populating database initial data"
psql -d \${DB_NAME} -a  -U\${POSTGRES_USER} -f /pagila-insert-data.sql

echo "Populating database..."
psql -d \${DB_NAME} -a  -U\${POSTGRES_USER} -f /pagila-data.sql`}</code></pre></div>
    <p>{`Create `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockefile`}</code>{` as below to build the custom image. As observed, we are copying individual sql files to the image.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Dockerfile`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "docker"
    }}><pre parentName="div" {...{
        "className": "language-docker"
      }}><code parentName="pre" {...{
          "className": "language-docker"
        }}><span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`FROM`}</span>{` postgres:11`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Custom initialization scripts`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`COPY`}</span>{` bootstrap.sh /docker-entrypoint-initdb.d/bootstrap.sh`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`COPY`}</span>{` pagila-schema.sql /pagila-schema.sql`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`COPY`}</span>{` pagila-insert-data.sql /pagila-insert-data.sql`}</span>{`
`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`COPY`}</span>{` pagila-data.sql /pagila-data.sql`}</span>{`

`}<span parentName="code" {...{
            "className": "token instruction"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`RUN`}</span>{` chmod +x /docker-entrypoint-initdb.d/bootstrap.sh && sed -i -e `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'s/\\r$//'`}</span>{` /docker-entrypoint-initdb.d/bootstrap.sh`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`/bin/bash^M: bad interpreter: No such file or directory`}</code>{` issue will be thrown when bootstrap.sh file is created on windows and executed in docker container. We need to include `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sed`}</code>{` command in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`RUN`}</code>{` section in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dockerfile`}</code>{` to replace the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`line ending characters`}</code>{` before executing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bootstrap.sh`}</code>{` file upon startup.`}</p>
    <p>{`Execute the below command to create custom docker image with name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postgre-pagila-db`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` build -t postgre-pagila-db `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <p>{`Before starting the container, lets create a data container with a mounted volume which will be used to store the database that we create. Execute the below command to create data container.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Create Volume`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` create -v /article_postgres_docker --name PostgresData alpine`}</code></pre></div>
    <p>{`Executing the below command with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{` will use the custom image and start the container with name `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pgdocker-pagila-db`}</code>{`. PostgreSQL stores its data in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/var/lib/postgresql/data`}</code>{`, so we are mounting the created data container volume with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`--volume-from`}</code>{` flag. Also as seen we are exposing port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5432`}</code>{` (the PostgreSQL default) and running the container in detached (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-d`}</code>{`) mode (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background`}</code>{`). Database name and Password to be used for the database is configured with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DB_NAME`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`POSTGRES_PASSWORD`}</code>{` environment variable.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Start Docker Container`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5432`}</span>{`:5432 --name pgdocker -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`DB_NAME`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`pagila -e `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`POSTGRES_PASSWORD`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`password -d --volumes-from PostgresData postgre-pagila-db`}</code></pre></div>
    <p>{`Launch shell and start exploring the database created with schema and data using below commands.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Launch shell and explore Pagila database`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`-- Launch shell
~:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -it pgdocker `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sh`}</span>{`

-- connect to psql terminal
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# psql -U postgres`}</span>{`
psql `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Debian `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.2`}</span>{`-2.pgdg100+1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`))`}</span>{`
Type `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"help"`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` help.

-- List databases
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\l`}</span>{`
                                 List of databases
   Name    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Owner   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Encoding `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Collate   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`   Ctype    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`   Access privileges
-----------+----------+----------+------------+------------+-----------------------
 pagila    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
 postgres  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
 template0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`c/postgres          +
           `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`CTc/postgres
 template1 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` UTF8     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` en_US.utf8 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`c/postgres          +
           `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`CTc/postgres
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` rows`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

-- Switch to pagila database
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`postgres`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\c pagila`}</span>{`
You are now connected to database `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"pagila"`}</span>{` as user `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"postgres"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`

-- List tables
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pagila`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\dt`}</span>{`
              List of relations
 Schema `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`       Name       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` Type  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  Owner
--------+------------------+-------+----------
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` actor            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` address          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` category         `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` city             `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` country          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` customer         `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` film             `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` film_actor       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` film_category    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` inventory        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` language         `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_01 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_02 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_03 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_04 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_05 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` payment_p2017_06 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` rental           `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` staff            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
 public `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` store            `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` table `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` postgres
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`21`}</span>{` rows`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

-- Clear `}<span parentName="code" {...{
            "className": "token function"
          }}>{`screen`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pagila`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# \\! clear`}</span></code></pre></div>
    <h2 {...{
      "id": "perform-database-operations"
    }}>{`Perform database operations`}</h2>
    <p>{`Let’s perform some database operations on the provisioned database with schema and data.`}</p>
    <div {...{
      "className": "gatsby-code-title gatsby-remark-code-title"
    }}>{`Perform database operations`}</div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
-- list `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` actors
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pagila`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# select * from actor limit 5;`}</span>{`
 actor_id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` first_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`  last_name   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`      last_update
----------+------------+--------------+------------------------
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` PENELOPE   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` GUINESS      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{`-02-15 09:34:33+00
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` NICK       `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` WAHLBERG     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{`-02-15 09:34:33+00
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` ED         `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` CHASE        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{`-02-15 09:34:33+00
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` JENNIFER   `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` DAVIS        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{`-02-15 09:34:33+00
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` JOHNNY     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` LOLLOBRIGIDA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{`-02-15 09:34:33+00
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` rows`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

-- update an actor first_name
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pagila`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# update actor set first_name='EDWARD' where first_name = 'ED';`}</span>{`
UPDATE `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`

-- Verify `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` record is updated
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pagila`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`# select * from actor where actor_id=3;`}</span>{`
 actor_id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` first_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` last_name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`          last_update
----------+------------+-----------+-------------------------------
        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` EDWARD     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` CHASE     `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2020`}</span>{`-03-09 01:42:44.975053+00
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` row`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`This article is more of a little hack on making our application development faster by quickly setting up database of our choice without worrying on annoying steps on installing and configuring the database. As PostgreSQL is one of the more common used databases, having it provisioned with Docker and connecting to our local application is quite simple and easy.`}</p>
    <GithubRepo href="https://github.com/2much2learn/article-mar05-setting-up-postgressql-with-docker-on-windows-10" mdxType="GithubRepo">setting-up-postgressql-with-docker-on-windows-10</GithubRepo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      