import styled, { keyframes} from 'styled-components';

const blinkingText = keyframes`
    0%		{ background-color: #10c018;}
    50%		{ background-color: #ef0a1a;}
    100%	{ background-color: #04a1d5;}
`
export const TodoWrapper = styled.div`
    padding: 10px
    border-top: 2px #8c8b8b;
    border-radius: 10px;
    animation: ${blinkingText} 1.2s infinite
`;

